import React, { Fragment } from 'react';

const ProfileLicenceButton = ({ licence }) => {
   let licenceType;

   if (licence === 'Guest') {
      licenceType = (
         <button className="h-9 px-2 mr-3 font-Pulp font-normal text-lg tracking-wide text-white border-gray-700 bg-gray-700 rounded border-2 focus:outline-none">
            {licence}
         </button>
      );
      return licenceType;
   }

   if (licence === 'DJ') {
      licenceType = (
         <button className="h-9 px-2 mr-3 font-Pulp font-normal text-lg tracking-wide text-white border-mixinlab-grape bg-mixinlab-grape rounded border-2 focus:outline-none">
            {licence}
         </button>
      );
      return licenceType;
   }

   if (licence === 'Hangout') {
      licenceType = (
         <button className="h-9 px-2 mr-3 font-Pulp font-normal text-lg tracking-wide text-white border-mixinlab-pewter bg-mixinlab-pewter rounded border-2 focus:outline-none">
            {licence}
         </button>
      );
      return licenceType;
   }

   return <Fragment>{licenceType}</Fragment>;
};

export default ProfileLicenceButton;
