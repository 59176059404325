// React.
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Import actions.
import { checkPassword } from '../actions/auth';
import { updateUserPassword } from '../actions/me';

// Import components.
import AlertFrontEnd from './AlertFrontEnd';

// Import icons.
import tick from '../assets/tick.svg';

const SettingsResetPassword = ({ auth, checkPassword, updateUserPassword }) => {
   const [state, setState] = useState({
      alert: false,
      alertMsg: '',
      currentPasswordCorrect: false,
      newPasswordCorrect: false,
      confirmPasswordCorrect: false,
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
   });

   useEffect(() => {
      setState({
         ...state,
         currentPasswordCorrect: auth.match
      });
   }, [auth]);

   const checkCurrentPassword = () => {
      checkPassword({ password: state.currentPassword });
   };

   const handleChangeCurrent = (e) => {
      const { value } = e.target;

      setState((prevState) => {
         return {
            ...prevState,
            currentPassword: value
         };
      });
   };

   const handleChangeNew = (e) => {
      const { value } = e.target;

      setState((prevState) => {
         return {
            ...prevState,
            newPassword: value
         };
      });
   };

   const handleChangeConfirm = (e) => {
      const { value } = e.target;

      setState((prevState) => {
         return {
            ...prevState,
            confirmPassword: value
         };
      });
   };

   const handleValidate = () => {
      const validate = state.newPassword.search(
         /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
      );

      if (!validate === false) {
         setState({
            ...state,
            newPasswordCorrect: false,
            confirmPasswordCorrect: false
         });
      }

      if (!validate === true) {
         setState({
            ...state,
            newPasswordCorrect: true,
            confirmPasswordCorrect: false
         });
      }

      if (!validate === true && state.newPassword === state.confirmPassword) {
         setState({
            ...state,
            newPasswordCorrect: true,
            confirmPasswordCorrect: true
         });
      }
   };

   const handleOnBlur = () => {
      if (!state.currentPasswordCorrect) {
         setState({
            ...state,
            alert: true,
            alertMsg: 'Incorrect password'
         });
         setTimeout(() => {
            setState({
               ...state,
               alert: false,
               alertMsg: ''
            });
         }, 2500);
      }
   };

   const handleSubmit = (e) => {
      e.preventDefault();

      updateUserPassword({
         password: state.currentPassword,
         new_password: state.confirmPassword
      });
   };

   const validateCurrentPassword = state.currentPasswordCorrect ? (
      <div
         className="w-9 h-9 bg-no-repeat mt-1 cursor-default"
         style={{
            backgroundImage: `url(${tick})`
         }}
      ></div>
   ) : null;

   const validateNewPassword = state.newPasswordCorrect ? (
      <div
         className="w-9 h-9 bg-no-repeat mt-1 cursor-default"
         style={{
            backgroundImage: `url(${tick})`
         }}
      ></div>
   ) : null;

   const confirmNewPassword = state.confirmPasswordCorrect ? (
      <div
         className="w-9 h-9 bg-no-repeat mt-1 cursor-default"
         style={{
            backgroundImage: `url(${tick})`
         }}
      ></div>
   ) : null;

   const alert = state.alert ? <AlertFrontEnd msg={state.alertMsg} /> : null;

   const button = state.confirmPasswordCorrect ? (
      <button
         onClick={handleSubmit}
         type="submit"
         className="py-1.5 w-44 mt-6 font-Pulp font-normal text-md tracking-wide text-white rounded-md border-2 bg-mixinlab-teal border-mixinlab-teal hover:bg-white hover:text-mixinlab-teal cursor-pointer focus:outline-none"
      >
         Change Password
      </button>
   ) : (
      <div className="py-1.5 text-center w-44 mt-6 font-Pulp font-normal opacity-30 text-md tracking-wide text-white rounded-md border-2 bg-mixinlab-teal border-mixinlab-teal cursor-pointer focus:outline-none">
         Change Password
      </div>
   );

   return (
      <Fragment>
         <form>
            <div className="p-2">
               {alert}

               <div className="flex justify-between">
                  <div className="w-10/12">
                     <div>
                        <input
                           className="text-lg border-b border-gray-300 font-semibold w-full text-black focus:outline-none p-1.5 pt-0 border-0"
                           type="text"
                           name="current_password"
                           placeholder="Current Password"
                           value={state.currentPassword}
                           onKeyUp={checkCurrentPassword}
                           onChange={handleChangeCurrent}
                           onBlur={handleOnBlur}
                        />
                        <div className="p-1.5 text-xs text-gray-400">
                           Current password
                        </div>
                     </div>
                  </div>
                  {validateCurrentPassword}
               </div>

               <div className="mt-8 mb-8 w-full mt-3 h-2 bg-mixinlab-teal"></div>

               <div className="flex justify-between">
                  <div className="w-10/12">
                     <div>
                        <input
                           className="text-lg border-b border-gray-300 font-semibold w-full text-black focus:outline-none p-1.5 border-0"
                           type="text"
                           name="new_password"
                           placeholder="New Password"
                           value={state.newPassword}
                           onChange={handleChangeNew}
                           onKeyUp={handleValidate}
                        />
                        <div className="p-1.5 text-xs text-gray-400">
                           Min 8 characters. Must contain a capital letter, number and
                           symbol
                        </div>
                     </div>
                  </div>
                  {validateNewPassword}
               </div>

               <div className="flex justify-between mt-4">
                  <div className="w-10/12">
                     <div>
                        <input
                           className="text-lg border-b border-gray-300 font-semibold w-full text-black focus:outline-none p-1.5 border-0"
                           type="text"
                           name="new_password_confirm"
                           placeholder="Confirm password"
                           value={state.confirmPassword}
                           onChange={handleChangeConfirm}
                           onKeyUp={handleValidate}
                        />
                        <div className="p-1.5 text-xs text-gray-400">
                           Re-enter password
                        </div>
                     </div>
                  </div>
                  {confirmNewPassword}
               </div>

               <div className="mx-auto mt-6">{button}</div>
            </div>
         </form>
      </Fragment>
   );
};

SettingsResetPassword.propTypes = {
   updateUserPassword: PropTypes.func.isRequired,
   checkPassword: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth
});

export default connect(mapStateToProps, { checkPassword, updateUserPassword })(
   SettingsResetPassword
);
