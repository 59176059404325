// React.
import React from 'react';

// Placeholder image.
import profilePlaceholderIconPerson from '../assets/placeholder_person.svg';
import dataStorePath from '../configs/dataStorePath';

const ProfileImageModal = ({ blob, user_img, handleInput }) => {
   const placeholder = (
      <div className="flex flex-wrap h-40 w-40 sm:h-36 sm:w-36 z-10 rounded-full absolute top-2 sm:top-3.5 left-5 bg-gray-100 border-5 border-white">
         <label
            htmlFor="profile"
            className="flex justify-center w-full h-full rounded-full pt-11 cursor-pointer"
         >
            <div
               className="w-14 h-14 sm:w-12 sm:h-12 bg-no-repeat bg-center"
               style={{
                  backgroundImage: `url(${profilePlaceholderIconPerson})`
               }}
               alt={'Placeholder'}
            ></div>
         </label>
         <input
            className="hidden w-full h-full"
            id="profile"
            type="file"
            onChange={handleInput}
         />
      </div>
   );

   const initial_img = (
      <div className="flex flex-wrap h-40 w-40 z-10 rounded-full absolute top-3.5 left-5 bg-gray-100 border-5 border-white">
         <label
            htmlFor="profile"
            className="w-full h-full bg-cover bg-no-repeat bg-center rounded-full cursor-pointer hover:opacity-80"
            style={{
               backgroundImage: `url(${dataStorePath.user_profile_images + user_img})`
            }}
         ></label>
         <input
            className="hidden w-full h-full"
            id="profile"
            type="file"
            onChange={handleInput}
         />
      </div>
   );

   const uploading = (
      <div className="flex flex-wrap h-40 w-40 z-10 rounded-full absolute top-2 left-5 bg-gray-100 border-5 border-white">
         <label
            htmlFor="profile"
            className="w-full h-full bg-cover bg-no-repeat bg-center rounded-full cursor-pointer hover:opacity-80"
            style={{
               backgroundImage: `url(${blob})`
            }}
         ></label>
         <input
            className="hidden w-full h-full"
            id="profile"
            type="file"
            onChange={handleInput}
         />
      </div>
   );

   // Instantiate bio component.
   let profile_image;

   if (!user_img && !blob) {
      profile_image = placeholder;
      return profile_image;
   } else if (!user_img && blob) {
      profile_image = uploading;
      return profile_image;
   } else if (user_img && !blob) {
      profile_image = initial_img;
      return profile_image;
   } else if (user_img && blob) {
      profile_image = uploading;
      return profile_image;
   }

   return <form>{profile_image}</form>;
};

export default ProfileImageModal;
