// React and PropTypes.
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Redux.
import { connect } from 'react-redux';

// Import actions.

// Import components.
import Update from './Update';
import Spinner from './Spinner';

// Component.
const UpdateModal = ({ is_update_modal_open, doCloseUpdateModal, userName }) => {
   const [state, setState] = useState({ show_spinner: false });

   // Reset spinner when content uploaded.
   useEffect(() => {
      if (is_update_modal_open) {
         setState({
            ...state,
            show_spinner: false
         });
      }
   }, [is_update_modal_open]);

   // Clear local state on modal close.
   const handleClose = () => {
      doCloseUpdateModal();
   };

   const showSpinner = () => {
      setState({ ...state, show_spinner: true });
   };

   let modal;
   let modalMask;

   const update = (
      <Update
         defaultText={`@${userName} `}
         atModal={true}
         handleClose={handleClose}
         showSpinner={showSpinner}
         hideGiphyModalMask={true}
      />
   );

   if (is_update_modal_open) {
      modal = (
         <div className="fixed top-2 left-1/2 transform -translate-x-1/2 translate-y-6 md:translate-y-0 md:top-0 md:h-screen md:w-full w-600 z-70 pb-3 pr-4 pl-8 bg-white rounded-lg md:rounded-none shadow-modal">
            <div className="mt-4 relative">
               {state.show_spinner ? (
                  <div className="absolute bg-white h-full w-full z-10 pt-8">
                     <Spinner />
                  </div>
               ) : null}
               {update}
            </div>
         </div>
      );

      modalMask = (
         <div
            className="fixed top-0 left-0 w-screen h-screen z-60 bg-black opacity-30 cursor-pointer"
            onClick={handleClose}
         ></div>
      );
   }

   return (
      <Fragment>
         {modal}
         {modalMask}
      </Fragment>
   );
};

UpdateModal.propTypes = {
   auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth
});

export default connect(mapStateToProps)(UpdateModal);
