import { SET_ALERT, REMOVE_ALERT, LOG_OUT } from '../actions/types';
const initialState = [];

const alert = (state = initialState, action) => {
   switch (action.type) {
      case SET_ALERT:
         return [...state, action.payload];
      case REMOVE_ALERT:
         return state.filter((alert) => alert.id !== action.payload);
      case LOG_OUT:
         return [];
      default:
         return state;
   }
};

export default alert;
