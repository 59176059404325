import { combineReducers } from 'redux';
import admin from './admin';
import alert from './alert';
import auth from './auth';
import content from './content';
import contentSingle from './contentSingle';
import discover from './discover';
import follow from './follow';
import me from './me';
import topFive from './topFive';
import user from './user';
import userContext from './userContext';
import mix from './mix';
import notification from './notification';

// These are my sub-stores and the properties/values they contain.
export default combineReducers({
   admin,
   alert,
   auth,
   content,
   contentSingle,
   discover,
   follow,
   me,
   topFive,
   user,
   userContext,
   mix,
   notification
});
