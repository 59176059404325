// React.
import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

// Dev
import * as dotenv from 'dotenv';

// Stripe
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// Redux
import { Provider } from 'react-redux';
import store from './store';

// Redux actions.
import { loadToken } from './actions/auth';

// Import pages.
import Join from './pages/Join';
import Plan from './pages/Plan';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import Reset from './pages/Reset';
import ResetPassword from './pages/ResetPassword';
import Home from './pages/Home';
import User from './pages/User';
import SingleContent from './pages/SingleContent';
import SingleContentComments from './pages/SingleContentComments';
import Upload from './pages/Upload';
import EmailVerify from './pages/EmailVerify';
import EmailVerified from './pages/EmailVerified';
import Notifications from './pages/Notifications';
import Settings from './pages/Settings';
import PageNotFound from './pages/PageNotFound';
import BuyMinutes from './pages/BuyMinutes';
import Purchase from './pages/Purchase';

// Import components.
import PrivateRoute from './components/routing/PrivateRoute';
import Nav from '../src/components/Nav';
import RightColumn from '../src/components/RightColumn';
import ModalWrapper from '../src/components/discover/modal/ModalWrapper';

ReactGA.initialize('UA-252462526-1');
const browserHistory = createBrowserHistory();
browserHistory.listen((location, action) => {
   ReactGA.pageview(location.pathname + location.search);
});

// Authenticate.
dotenv.config();
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const App = () => {
   console.log(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
   useEffect(() => {
      store.dispatch(loadToken());
   }, []);

   // Discover modal state.
   const [showModal, setShowModal] = useState({ discover: false });

   return (
      /**
       * We are wrapping the entire app with Stipe elements
       * to get full advantage of stipes' fraud prevention features
       */
      <Elements stripe={stripePromise}>
         <div className="relative">
            <Provider store={store}>
               <Router>
                  <Switch>
                     <Route exact path="/" component={Join} />
                     <Route exact path="/plan" component={Plan} />
                     <Route exact path="/email-verify" component={EmailVerify} />
                     <Route exact path="/email-verified" component={EmailVerified} />
                     <Route exact path="/signup" component={SignUp} />
                     <Route exact path="/login" component={Login} />
                     <Route exact path="/reset" component={Reset} />
                     <Route exact path="/reset-password" component={ResetPassword} />
                     <Route exact path="/404" component={PageNotFound} />
                     <Fragment>
                        <div className="flex justify-center">
                           <div className="z-40">
                              <ModalWrapper showModal={showModal.discover} />
                           </div>
                           {/* Sidebar 1 */}
                           <div className="relative mb-60  sm:w-screen">
                              <div className="z-20">
                                 <RightColumn />
                              </div>
                              <div className="flex lg:justify-center">
                                 <div className="w-56 xl:w-16 md:hidden mr-8 z-20 xl:mr-6 lg:mr-0">
                                    <div className="fixed">
                                       <Nav />
                                    </div>
                                 </div>
                                 <Route
                                    exact
                                    path="/content/:content_id"
                                    component={SingleContent}
                                 />

                                 {/* Pages */}
                                 <PrivateRoute exact path="/home" component={Home} />
                                 <PrivateRoute
                                    exact
                                    path="/user/:user_name"
                                    component={User}
                                 />

                                 <PrivateRoute
                                    exact
                                    path="/comment/:content_id"
                                    component={SingleContentComments}
                                 />

                                 <PrivateRoute
                                    exact
                                    path="/notifications"
                                    component={Notifications}
                                 />

                                 <PrivateRoute
                                    exact
                                    path="/upload"
                                    component={Upload}
                                 />

                                 <PrivateRoute
                                    exact
                                    path="/settings"
                                    component={Settings}
                                 />
                                 <PrivateRoute
                                    exact
                                    path="/buy-minutes"
                                    component={BuyMinutes}
                                 />
                                 <PrivateRoute
                                    exact
                                    path="/purchase/:product_slug/:helper_id?"
                                    component={Purchase}
                                 />
                              </div>
                           </div>
                        </div>
                     </Fragment>
                  </Switch>
               </Router>
            </Provider>
         </div>
      </Elements>
   );
};

export default App;
