import React, { Fragment } from 'react';

const ProfileSubNav = ({ posts, mixes, bio }) => {
   return (
      <Fragment>
         <div className="sm:block">
            <div className="mt-4 sm:mt-6 w-full block flex flex-row sm:justify-around">
               <button
                  onClick={posts}
                  className="w-1/6 h-12 mx-3 border-b-5 border-white hover:border-mixinlab-teal focus:border-mixinlab-teal font-Pulp font-normal text-lg tracking-wide text-black cursor-pointer focus:outline-none"
               >
                  <h5 className="text-lg">Posts</h5>
               </button>

               <button
                  onClick={mixes}
                  className="w-1/6 h-12 mx-3 border-b-5 border-white hover:border-mixinlab-teal focus:border-mixinlab-teal font-Pulp font-normal text-lg tracking-wide text-black cursor-pointer focus:outline-none"
               >
                  <h5 className="text-lg">Mixes</h5>
               </button>

               <button
                  onClick={bio}
                  className="w-1/6 h-12 mx-3 border-b-5 border-white hover:border-mixinlab-teal focus:border-mixinlab-teal font-Pulp font-normal text-lg tracking-wide text-black cursor-pointer focus:outline-none"
               >
                  <h5 className="text-lg">Bio</h5>
               </button>
            </div>
         </div>
      </Fragment>
   );
};

export default ProfileSubNav;
