import React, { Fragment } from 'react';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import { followUnfollow } from '../actions/follow';

// Import components.
import ProfileLicenceButtonSmall from './ProfileLicenceButtonSmall';
import CloseButtonSmall from './CloseButtonSmall';

// Import icons.
import locationIcon from '../assets/location.svg';
import webIcon from '../assets/web.svg';

// Import utils.
import dataStorePath from '../configs/dataStorePath';

const PostProfileCard = ({
   userContext,
   showModal,
   is_followed,
   user_id,
   followUnfollow,
   plan,
   display_follow_toggle_button,
   closePostProfileCardOnFollowUnfollow,
   closePostProfileCard
}) => {
   // Follow / Unfollow action.
   const handleClick1 = () => {
      followUnfollow(user_id);
      closePostProfileCardOnFollowUnfollow();
   };

   // Conditionally render components.
   const location = !userContext.location ? null : (
      <div className="flex flex-row mt-2">
         <img className="h-5 w-5 mr-1" src={locationIcon} alt={'Location'} />
         <h5 className="font-normal text-base leading-tight break-all">
            Location <span className="font-medium">{userContext.location}</span>
         </h5>
      </div>
   );

   const web = !userContext.web_full ? null : (
      <div className="flex flex-row mt-2">
         <img className="h-5 w-5 mr-1" src={webIcon} alt={'Web'} />
         <h5 className="font-normal text-base leading-tight">
            <a
               href={userContext.web_full}
               target="blank"
               className="font-medium hover:text-mixinlab-teal break-all"
            >
               {userContext.web_display}
            </a>
         </h5>
      </div>
   );

   const FollowUnfollow = !display_follow_toggle_button ? (
      is_followed ? (
         <button
            onClick={handleClick1}
            className="h-8 px-2 ml-3 font-Pulp font-normal text-sm tracking-wide text-mixinlab-teal rounded border-2 border-mixinlab-teal hover:bg-mixinlab-teal hover:text-white cursor-pointer focus:outline-none"
         >
            Unfollow
         </button>
      ) : (
         <button
            onClick={handleClick1}
            className="h-8 px-2 ml-3 font-Pulp font-normal text-sm tracking-wide text-mixinlab-teal rounded border-2 border-mixinlab-teal hover:bg-mixinlab-teal hover:text-white cursor-pointer focus:outline-none"
         >
            Follow
         </button>
      )
   ) : null;

   // Instantiate component.
   let modal;

   // HIDE THIS COMPONENT ON MOBILE DEVICES AND MAKE THE PROFILE IMAGE LINK TO USER PAGE.
   if (showModal) {
      return (modal = (
         <div>
            <div className="absolute top-14 left-14 h-auto w-10/12 sm:w-9/12 z-50 p-4 pb-6 bg-white rounded-lg shadow-modal cursor-auto">
               <div className="flex">
                  <div
                     className="flex-none xs:hidden h-18 w-18 xs:w-14 xs:h-14 rounded-full bg-center bg-cover bg-no-repeat mr-4"
                     style={{
                        backgroundImage: `url(${
                           dataStorePath.user_profile_images +
                           userContext.profile_image
                        })`
                     }}
                  ></div>

                  <div className="w-full">
                     <div className="flex justify-between">
                        <div className="flex xs:justify-start mb-4 w-full">
                           <div>
                              <ProfileLicenceButtonSmall licence={plan} />
                           </div>

                           <div>{FollowUnfollow}</div>
                        </div>

                        <div className="ml-3">
                           <CloseButtonSmall close={closePostProfileCard} />
                        </div>
                     </div>

                     <div>
                        <div className="font-Pulp font-normal sm:text-lg text-xl leading-none break-word">
                           {userContext.display_name}
                        </div>

                        <div className="text-mixinlab-teal xs:text-base text-lg break-word">
                           @{userContext.user_name}
                        </div>
                     </div>
                  </div>
               </div>

               <div className="font-normal text-base break-word mt-1">
                  {userContext.short_bio}
               </div>
               <div className="font-normal text-base">{location}</div>
               <div className="font-normal text-base">{web}</div>
            </div>
         </div>
      ));
   }

   return <Fragment>{modal}</Fragment>;
};

PostProfileCard.propTypes = {
   followUnfollow: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired,
   me: PropTypes.object.isRequired,
   follow: PropTypes.object.isRequired,
   userContext: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth,
   me: state.me,
   follow: state.follow,
   userContext: state.userContext
});

export default connect(mapStateToProps, { followUnfollow })(PostProfileCard);
