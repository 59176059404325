import {
   COMMENT_SUCCESS,
   COMMENT_FAIL,
   GET_SINGLE_CONTENT_SUCCESS,
   GET_SINGLE_CONTENT_FAIL,
   DELETE_COMMENT_SUCCESS,
   DELETE_COMMENT_FAIL,
   SHARE_CONTENT_SUCCESS,
   SHARE_CONTENT_FAIL,
   UPDATE_LIKE_ARRAY_SUCCESS_CONTENT_SINGLE,
   UPDATE_LIKE_ARRAY_FAIL_CONTENT_SINGLE,
   CLEAR_SINGLE_CONTENT,
   LOG_OUT
} from '../actions/types';

const initialState = {
   loading: true,
   status_comment: null,
   single_content: [],
   single_content_comments: [],
   single_content_like_count: null,
   single_content_share_count: null,
   single_content_comment_count: null,
   error: {}
};

const contentSingle = (state = initialState, action) => {
   switch (action.type) {
      case GET_SINGLE_CONTENT_SUCCESS:
         return {
            ...state,
            single_content: action.payload,
            single_content_comments: action.payload.comments
         };
      case GET_SINGLE_CONTENT_FAIL:
         return {
            ...state,
            error: action.payload
         };
      case SHARE_CONTENT_SUCCESS:
         return {
            ...state,
            single_content_share_count:
               action.payload.data.content.shared_content.share_count_VIRTUAL,
            status_share_content: action.payload.status
            // posts: [action.payload.data.content, ...state.posts]
         };
      case SHARE_CONTENT_FAIL:
         return {
            ...state,
            error: action.payload
         };
      case COMMENT_SUCCESS:
         return {
            ...state,
            status_comment: action.payload.status,
            single_content_comments: action.payload.data.content.comments,
            single_content_comment_count:
               action.payload.data.content.comment_count_VIRTUAL
         };
      case COMMENT_FAIL:
         return {
            ...state,
            status_comment: null,
            error: action.payload
         };

      case DELETE_COMMENT_SUCCESS:
         return {
            ...state,
            single_content_comments: action.payload.content.comments
         };
      case DELETE_COMMENT_FAIL:
         return {
            ...state,
            loading: false,
            error: action.payload
         };
      case CLEAR_SINGLE_CONTENT:
         return {
            ...state,
            loading: true,
            status_comment: null,
            single_content: [],
            single_content_comments: [],
            single_content_like_count: null,
            single_content_share_count: null,
            single_content_comment_count: null,
            error: {}
         };
      case UPDATE_LIKE_ARRAY_SUCCESS_CONTENT_SINGLE:
         return {
            ...state
            // single_content_like_count: action.payload.likeCount,
            // posts: state.posts.map((post) =>
            //    post._id === action.payload.contentId
            //       ? {
            //            ...post,
            //            like_count_VIRTUAL: action.payload.likeCount
            //         }
            //       : post
            // ),
            // status_like: 'success'
         };
      case UPDATE_LIKE_ARRAY_FAIL_CONTENT_SINGLE:
         return {
            ...state,
            status_like: 'fail'
         };
      case LOG_OUT:
         return {
            error: {}
         };
      default:
         return state;
   }
};

export default contentSingle;
