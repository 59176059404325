// React and PropTypes.
import React, { Link, Redirect } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Components
import GaPageView from '../components/GaPageView';

const PageNotFound = ({ auth }) => {
   return (
      <div className="container mx-auto h-screen">
         <GaPageView/>
         <div className="flex lg:flex-wrap h-screen">
            <div className="bg-mixinlab-teal flex justify-center w-1/2 lg:w-full">
               <div className="pt-5 w-10/12">
                  <div className="h-32">
                     <div className="bg-logo-beta bg-no-repeat bg-center w-56 h-full"></div>
                  </div>
                  <h1 className="w-9/12 font-Pulp font-bold text-5xl leading-tight mt-6">
                     Page Not Found
                  </h1>
               </div>
            </div>

            <div
               className="bg-cover bg-no-repeat bg-center w-1/2 lg:hidden h-screen bg-gray-100"
               style={{
                  backgroundImage: `url("https://mixcamp-production.ams3.digitaloceanspaces.com/app/girl.jpg")`
               }}
            ></div>
         </div>
      </div>
   );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(PageNotFound);
