// React.
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Import components
import UserWrapper from './user/UserWrapper';
import MusicWrapper from './music/MusicWrapper';

const DiscoverWrapper = () => {
   const [state, setState] = useState({ show_user: true, show_music: false });

   // Use history.
   let history = useHistory();

   const showMusic = () => {
      setState({
         ...state,
         show_music: true,
         show_user: false
      });
   };

   const showUser = () => {
      setState({
         ...state,
         show_music: false,
         show_user: true
      });
   };

   const onUserSelect = (user) => {
      history.push(`/user/${user}`);
   };

   const user =
      state.show_user && !state.show_music ? (
         <UserWrapper showMusic={showMusic} onUserSelect={onUserSelect} />
      ) : null;

   const music =
      !state.show_user && state.show_music ? (
         <MusicWrapper showUser={showUser} />
      ) : null;

   return (
      <div>
         <div className="w-full pl-3 pb-2.5 font-Pulp font-semibold text-2xl">
            <div className="w-32 cursor-default">Discover</div>
         </div>
         {music}
         {user}
      </div>
   );
};

export default DiscoverWrapper;
