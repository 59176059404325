// Import react.
import React, { Fragment, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Axio
import axios from 'axios';

// Constants
// import constants from '../configs/constants';

/**
 * @TODO - Make loading of pics fast
 * @TODO - Cancel continues key strokes
 * @param auth
 * @param handleClose
 * @param handleSelectGiphy
 * @returns {JSX.Element}
 * @constructor
 */

const Giphy = ({ handleSelectGiphy }) => {
   const [gifGrid, setGifGrid] = useState([]);
   const [results, setResults] = useState([]);
   const [state, setState] = useState({
      search: '',
      search_error: ''
   });

   useEffect(() => {
      if (state.search !== '') {
         searchGiphy();
      } else {
         populateTrendingGiphy();
      }
   }, [state.search]);

   useEffect(() => {
      let gifGrid = [];
      if (results.length > 0) {
         results.forEach((gif) => {
            gifGrid.push(
               <div className="cursor-pointer" key={uuidv4()}>
                  <img
                     className="w-44 h-auto rounded rounded-lg p-1"
                     onClick={handleSelectImage}
                     data-image={gif.downsized.url}
                     key={uuidv4()}
                     src={gif.fixed_height_downsampled.url}
                  />
               </div>
            );
         });
      }
      setGifGrid(gifGrid);
   }, [results]);

   // Count character length.
   const handleChange = (e) => {
      const { name, value } = e.target;

      setState((prevState) => {
         return {
            ...prevState,
            [name]: value
         };
      });
   };

   const handleSelectImage = (e) => {
      const imageData = e.target.getAttribute('data-image');
      handleSelectGiphy(imageData);
   };

   const populateTrendingGiphy = async () => {
      try {
         const cancelTokenSource = axios.CancelToken.source();
         const config = {
            headers: {
               'Content-Type': 'application/json'
            },
            cancelToken: cancelTokenSource.token
         };

         const res = await axios.post(
            `/api/rich-content/populate-trending`,
            { query: state.search },
            config
         );
         const gifs = res.data.results.data.map((gif) => {
            return gif.images;
         });
         setResults(gifs);
      } catch (err) {
         console.log(err);
      }
   };

   const searchGiphy = async () => {
      try {
         const cancelTokenSource = axios.CancelToken.source();
         const config = {
            headers: {
               'Content-Type': 'application/json'
            },
            cancelToken: cancelTokenSource.token
         };

         const res = await axios.post(
            `/api/rich-content/giphy-search`,
            { query: state.search },
            config
         );
         const gifs = res.data.results.data.map((gif) => {
            return gif.images;
         });
         setResults(gifs);
      } catch (err) {
         console.log(err);
      }
   };

   return (
      <Fragment>
         <div className="h-500 sm:h-768 pb-6">
            <div className="flex items-center border border-gray-300 rounded-md p-2 mb-3">
               <input
                  className="text-lg font-semibold w-full text-black focus:outline-none pl-1"
                  type="text"
                  name="search"
                  placeholder="Enter your search"
                  onChange={handleChange}
                  value={state.search}
               />
            </div>

            <div className="justify-center pt-1 items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
               <div className="grid grid-cols-3 pr-2 sm:grid-rows-1 h-450 sm:h-700 sm:pb-6 overflow-auto">
                  {gifGrid}
               </div>
            </div>
         </div>
      </Fragment>
   );
};

Giphy.propTypes = {
   auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth
});

export default connect(mapStateToProps)(Giphy);
