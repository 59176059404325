import axios from 'axios';

import {
   GET_TOP_FIVE_SUCCESS,
   GET_TOP_FIVE_FAIL,
   RESET_TOP_FIVE_LOADED,
   CREATE_TOP_FIVE_SUCCESS,
   CREATE_TOP_FIVE_FAIL,
   UPDATE_TOP_FIVE_SUCCESS,
   UPDATE_TOP_FIVE_FAIL,
   DELETE_TOP_FIVE_SUCCESS,
   DELETE_TOP_FIVE_FAIL
} from './types';

// Get user's Top 5.
export const getTopFive = (user_name) => async (dispatch) => {
   try {
      const res = await axios.get(`/api/user/get-top-five/${user_name}`);
      dispatch({
         type: GET_TOP_FIVE_SUCCESS,
         payload: res.data.data.content.top_fives
      });
   } catch (err) {
      dispatch({
         type: GET_TOP_FIVE_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

export const getTopFiveLoadedReset = () => async (dispatch) => {
   dispatch({
      type: RESET_TOP_FIVE_LOADED
   });
};

export const createTopFive = (top_five) => async (dispatch) => {
   try {
      const config = {
         headers: {
            'Content-Type': 'application/json'
         }
      };
      const res = await axios.post('/api/user/create-top-five', top_five, config);
      dispatch({
         type: CREATE_TOP_FIVE_SUCCESS,
         payload: res.data.data.content.top_fives
      });
   } catch (err) {
      dispatch({
         type: CREATE_TOP_FIVE_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

/**
 * Update TopFive Main Component Sort Order
 */
export const updateTopFiveComSortOrder = (top_fives) => async (dispatch) => {
   try {
      const config = {
         headers: {
            'Content-Type': 'application/json'
         }
      };
      const res = await axios.post(
         '/api/user/main-top-five-sort-order',
         top_fives,
         config
      );
      dispatch({
         type: UPDATE_TOP_FIVE_SUCCESS,
         payload: res.data.data.content.top_fives
      });
   } catch (err) {
      dispatch({
         type: UPDATE_TOP_FIVE_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

export const updateTopFive = (top_five) => async (dispatch) => {
   try {
      const config = {
         headers: {
            'Content-Type': 'application/json'
         }
      };
      const res = await axios.post('/api/user/update-top-five', top_five, config);
      dispatch({
         type: UPDATE_TOP_FIVE_SUCCESS,
         payload: res.data.data.content.top_fives
      });
   } catch (err) {
      dispatch({
         type: UPDATE_TOP_FIVE_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// Reset the topFive.loaded flag after loading.
export const resetTopFiveLoaded = () => async (dispatch) => {
   dispatch({
      type: RESET_TOP_FIVE_LOADED
   });
};

// Delete a Top 5.
export const deleteTopFive = (top_five_id) => async (dispatch) => {
   try {
      const config = {
         headers: {
            'Content-Type': 'application/json'
         }
      };
      const res = await axios.post('/api/user/delete-top-five', top_five_id, config);
      dispatch({
         type: DELETE_TOP_FIVE_SUCCESS,
         payload: res.data.data.content.top_fives
      });
   } catch (err) {
      dispatch({
         type: DELETE_TOP_FIVE_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};
