// React.
import React, { Fragment, useState } from 'react';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components.
import PageHeaderMix from './PageHeaderMix.js';
import UploadFile from './UploadFile';
import UploadTitle from './UploadTitle';
import UploadTrackWrapper from './UploadTrackWrapper';

const UploadWrapper = ({ mix }) => {
   const [state, setState] = useState({
      mix_upload_title: true,
      mix_upload_tracks: false,
      pre_tracklist: true,
      mix_upload_file: false,
      mix_upload_track_listing: true,
      mix_upload_track_header: false,
      has_chosen: false,
      title_next: false,
      mix_title: '',
      blob_img: null,
      file_img: null,
      file_err_msg: null
   });

   // Handle input for ProfileImageModal.
   const handleImageInput = (e) => {
      e.preventDefault();

      let reader = new FileReader();
      let file = e.target.files[0];


      // Format check conditions.
      const checkFileFormat = () => {
         const formats = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
         const checkFormats = formats.includes(file.type);
         return checkFormats;
      };

      // Disable placeholder change.
      const disablePlaceHolderChange = () => {
         if (checkFileFormat()) {
            return reader.result;
         } else {
            return null;
         }
      };

      // Check img file.
      const checkFile = () => {
         let file_err;
         if (file.size > 2000000) {
            file_err = 'File too large (max. 2MB)';
            setTimeout(function () {
               setState({
                  ...state,
                  title: '',
                  blob_img: null,
                  file_img: null,
                  file_err_msg: null
               });
            }, 3000);
         }
         if (file.size <= 2000000) {
            file_err = null;
         }
         if (!checkFileFormat()) {
            file_err = 'Wrong format! Try .jpg .png or .gif';
            setTimeout(function () {
               setState({
                  ...state,
                  blob_img: null,
                  file_img: null,
                  file_err_msg: null
               });
            }, 3000);
         }
         return file_err;
      };

      reader.onloadend = () => {
         setState({
            ...state,
            blob_img: disablePlaceHolderChange(),
            file_img: file,
            file_err_msg: checkFile(),
            success: true
         });
      };

      // Getting an error here...
      if (reader) {
         reader.readAsDataURL(file);
      }
   };

   // Add title.
   const handleAddTitle = (e) => {
      const { value } = e.target;

      setState((prevState) => {
         return {
            ...prevState,
            mix_title: value
         };
      });
   };

   const hasChosen = () => {
      setState({ ...state, has_chosen: true });
   };

   const titleNext = () => {
      // Close instruction alert and move to next stage.
      setState({
         ...state,
         mix_upload_file: true,
         title_next: true,
         mix_upload_instruction_kind: false
      });
   };

   const addTracklist = () => {
      setState({
         ...state,
         pre_tracklist: false,
         mix_upload_title: true,
         mix_upload_file: false,
         mix_upload_tracks: true
      });
   };

   // Can't do this because it stops mix publish working.
   const resetUploadState = () => {
      setState({
         ...state,
         mix_upload_title: true,
         mix_upload_instructions: true,
         mix_upload_instruction_kind: true,
         mix_upload_tracks: false,
         pre_tracklist: true,
         mix_upload_file: false,
         mix_upload_track_listing: true,
         mix_upload_track_header: false,
         has_chosen: false,
         title_next: false,
         mix_title: '',
         blob_img: null,
         file_img: null,
         file_err_msg: null
      });
   };

   const title = state.mix_upload_title ? (
      <UploadTitle
         handleImageInput={handleImageInput}
         handleAddTitle={handleAddTitle}
         titleNext={titleNext}
         title_next={state.title_next}
         mix_title={state.mix_title}
         blob_img={state.blob_img}
         file_img={state.file_img}
         file_err_msg={state.file_err_msg}
      />
   ) : null;

   const file = state.mix_upload_file ? (
      <UploadFile
         has_chosen={state.has_chosen}
         hasChosen={hasChosen}
         pre_tracklist={state.pre_tracklist}
         mix_title={state.mix_title}
         file_img={state.file_img}
         addTracklist={addTracklist}
         resetUploadState={resetUploadState}
      />
   ) : null;

   const mix_header = state.mix_upload_track_header ? (
      <div className="flex border-gray-200 border-r-2 border-t-2 border-l-2">
         <div className="flex-none w-20 h-20 relative">
            <div className="group flex h-full w-full z-10 absolute border-r-2 border-gray-200 bg-gray-100 cursor-pointer">
               <div
                  className="w-full h-full bg-no-repeat bg-center bg-cover"
                  style={{
                     backgroundImage: `url(${state.blob_img})`
                  }}
                  alt={'Placeholder'}
               ></div>
            </div>
         </div>
         <form className="flex w-full justify-between p-4">
            <div className="grid content-center">
               <div className="text-xl px-2 mr-5 h-8 pt-0.5 font-Pulp font-normal w-full text-black focus:outline-none">
                  {state.mix_title}
               </div>
            </div>
         </form>
      </div>
   ) : null;

   const tracks =
      mix.mix_uploaded_status === 'success' ? (
         <UploadTrackWrapper
            mix_title={state.mix_title}
            resetUploadState={resetUploadState}
         />
      ) : null;

   return (
      <Fragment>
         <div className="pb-60">
            <PageHeaderMix page={'Upload a mix'} />
            {title}
            {file}
            {mix_header}
            {tracks}
         </div>
      </Fragment>
   );
};

UploadWrapper.propTypes = {
   mix: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({ mix: state.mix });

export default connect(mapStateToProps)(UploadWrapper);
