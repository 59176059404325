// Import react.
import React, { Fragment } from 'react';

// Import components.
import RichContentDomainFeed from './RichContentDomainFeed';
import RichContentThumbFeed from './RichContentThumbFeed';

// Import utils.
// import { getIcon, getPlatformDisplayName, displayMedia } from '../utils/richUpdate';

/**
 * NOTE :
 * Check UpdateRichContent to check if any changes to this component should reflect in there
 * Keyword: [RICH_CONTENT_COMPONENT]
 */

const RichContentFeed = ({ data }) => {
   // const logo = (
   //    <a target="_blank" href={`${data.url}`}>
   //       <img src={getIcon(data.platform)} className="h-5 mr-1 cursor-pointer" />
   //    </a>
   // );

   // const platformDomain = data.platform_domain ? (
   //    <a target="_blank" href={`${data.platform_domain_link}`}>
   //       <div className="grid content-center h-full text-gray-500 font-Pulp text-base cursor-pointer">
   //          | {getPlatformDisplayName(data.platform_domain)}
   //       </div>
   //    </a>
   // ) : null;

   const title = data.title ? (
      <a target="_blank" href={`${data.url}`}>
         <div className="leading-4 mt-1 mb-3 text-sm font-semibold text-mixinlab-teal_dark cursor-pointer mr-10 sm:mr-0">
            {data.title}
         </div>
      </a>
   ) : null;

   // const thumb = data.thumbnail ? (
   //    <a target="_blank" href={`${data.url}`}>
   //       {displayMedia(data)}
   //    </a>
   // ) : null;

   const richContent = (
      <div className="flex justify-center">
         <div className="border border-gray-300 p-4 w-11/12 mb-1.5 bg-white rounded-md shadow-modalRichUpdate">
            <div className="flex">
               <div className="flex justify-between w-full">
                  <div className="flex"></div>
                  <div className="flex sm:block w-full">
                     <RichContentDomainFeed shared_content={data} />
                  </div>
               </div>
            </div>

            {title}
            <div className="relative">
               <RichContentThumbFeed shared_content={data} />
            </div>
         </div>
      </div>
   );

   return <Fragment>{richContent}</Fragment>;
};

export default RichContentFeed;
