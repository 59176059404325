// React and PropTypes.
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import components.
import NavModal from './NavModal';

// Import actions.
import { logout } from '../actions/auth';
import { toggleDiscoverModal } from '../actions/discover';

// Import icons.
import homeIcon from '../assets/home.svg';
import profileIcon from '../assets/profile.svg';
import moreIcon from '../assets/more.svg';
import searchIcon from '../assets/search.svg';

const NavMobile = ({
   logout,
   toggleDiscoverModal,
   me: { user_name }
}) => {
   const [showModal, setShowModal] = useState({
      settings: false,
      discover: false
   });

   const toggleModalSettings = () =>
      setShowModal({ ...showModal, settings: !showModal.settings });

   const handleScrollTop = () => {
      window.scrollTo({
         top: 0,
         behavior: "smooth"
      });
   };

   return (
      <Fragment>
         <NavModal
            onClick1={toggleModalSettings}
            onClick2={logout}
            showModal={showModal.settings}
         />

         <div className="relative w-full flex flex-row justify-around bg-white pb-2">
            <Link to="/home">
               <div className="w-12 h-12 grid content-center justify-center hover:bg-gray-200 rounded-full cursor-pointer">
                  <div
                     className="bg-60 bg-no-repeat bg-center h-14 w-14 cursor-pointer"
                     style={{
                        backgroundImage: `url(${homeIcon})`
                     }}
                     alt={'Home'}
                  ></div>
               </div>
            </Link>

            <Link onClick={handleScrollTop} to={`/user/${user_name}`}>
               <div className="w-12 h-12 grid content-center justify-center hover:bg-gray-200 rounded-full cursor-pointer">
                  <div
                     className="bg-60 bg-no-repeat bg-center h-14 w-14 cursor-pointer"
                     style={{
                        backgroundImage: `url(${profileIcon})`
                     }}
                     alt={'Profile'}
                  ></div>
               </div>
            </Link>

            <div
               onClick={toggleDiscoverModal}
               className="w-12 h-12 grid content-center justify-center hover:bg-gray-200 rounded-full cursor-pointer"
            >
               <div
                  className="bg-60 bg-no-repeat bg-center h-14 w-14 cursor-pointer"
                  style={{
                     backgroundImage: `url(${searchIcon})`
                  }}
                  alt={'Notifications'}
               ></div>
            </div>

            <div
               onClick={toggleModalSettings}
               className="w-12 h-12 grid content-center justify-center hover:bg-gray-200 rounded-full cursor-pointer"
            >
               <div
                  className="bg-60 bg-no-repeat bg-center h-14 w-14 cursor-pointer"
                  style={{
                     backgroundImage: `url(${moreIcon})`
                  }}
                  alt={'Notifications'}
               ></div>
            </div>
         </div>
      </Fragment>
   );
};

NavMobile.propTypes = {
   logout: PropTypes.func.isRequired,
   toggleDiscoverModal: PropTypes.func.isRequired,
   me: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({ me: state.me });

export default connect(mapStateToProps, { logout, toggleDiscoverModal })(NavMobile);
