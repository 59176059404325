//Import React.
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import components.
import MixPlayerButtonLoad from './MixPlayerButtonLoad';
import MixPlayerButtonPlay from './MixPlayerButtonPlay';
import MixPlayerButtonPause from './MixPlayerButtonPause';
import Comment from './Comment';
import Share from './Share';
import Like from './Like';
import ContentIcon from './Content';
import moreGrayIcon from '../assets/more_gray.svg';
import MixTrackListing from './MixTrackListing';
import MixTrackListingHotTrack from './MixTrackListingHotTrack';
import MixMenu from './MixMenu';
import MixMenuMask from './MixMenuMask';
// import SomethingWentWrong from './SomethingWentWrong';
// import PostShareNotAvailable from './PostShareNotAvailable';

// Import actions
import { clearTimelineMix, loadTimelineMix } from '../actions/mix';
import { clearSingleContent } from '../actions/contentSingle';
import {
   shareContent,
   deleteContent,
   getPostId,
   likeContent
} from '../actions/content';

// Import utils.
import addDataStorePathMixImages from '../utils/addDataStorePathMixImages';
// import socialContextDisplay from '../utils/socialContextDisplay';

// Import icons.
import expand from '../assets/expand.svg';
import collapse from '../assets/collapse.svg';
import PostComment from './PostComment';
import spinner_playing from '../assets/spinner_playing.svg';
import spinner_playing_inactive from '../assets/spinner_playing_inactive.svg';
import dataStorePath from '../configs/dataStorePath';

const MixPost = ({
   mix,
   is_single_content,
   is_social_widgets = true,
   content,
   is_mix_search = false,
   in_focus,
   is_playing,
   loadTimelineMix,
   clearTimelineMix,
   getPostId,
   id,
   mix_id,
   cover,
   mix_title,
   display_name,
   user_image,
   text,
   user_name,
   created_at,
   tracks,
   hot_track,
   comment_count,
   share_count,
   like_count,
   shareContent,
   likeContent,
   is_author_me,
   deleteContent,
   // social_context,
   track_match,
   creator_match,
   label_match
}) => {
   let [state, setState] = useState({
      show_tracks: false,
      mix_menu: false,
      post_comment_modal: false,
      show_play_pause_button: false
   });

   // Close comment modal once comment is done.
   useEffect(() => {
      if (content.status_comment === 'success') {
         setState({
            ...state,
            post_comment_modal: false
         });
      }
   }, [content.status_comment]);

   // 1. load mix into audioplayer.js
   // 2. if mix in timeline === mix in player switch play button component
   // 3. create action to set current_mix_player_is_playing to true
   // 4. create action to set current_mix_player_is_playing to false
   // 5. assign both actions to relevant buttons

   // FIND POST THAT CORRESPONDS TO MIX ID IN MIX
   useEffect(() => {
      if (mix.current_mix_id === mix_id) {
         setState({ ...state, show_play_pause_button: true });
      }
   }, [mix.current_mix_id, mix_id]);

   // // if mix_id is not present display
   // if (!mix_id) {
   //    return (
   //       <SomethingWentWrong msg={'Error occurred while trying to load this mix.'} />
   //    );
   // }

   const handleClickLoad = () => {
      console.log('handleClickLoad');

      clearTimelineMix();
      loadTimelineMix(id, mix_id);
   };

   const handleLikeContent = () => {
      likeContent(id);
   };

   const handleShareContent = () => {
      // Instantiate new form.
      const formData = new FormData();

      // Append.
      formData.append('type', 'share');
      formData.append('shared_content', id);

      shareContent(formData);
   };

   // Delete mix.
   const handleDelete = () => {
      deleteContent(id);
      setState({ ...state, mix_menu: false });
   };

   // Comment modals.
   const openCommentModal = () => {
      getPostId(id);
      setState({
         ...state,
         post_comment_modal: true,
         post_comment_modal_allowed: false
      });
   };

   const closeCommentModal = () => {
      setState({
         ...state,
         post_comment_modal: false,
         post_comment_modal_allowed: true
      });
   };

   // Convert upload date to readable format.
   const makeDateReadable = () => {
      return (
         <h5 className="text-sm text-mixinlab-pewter xs:mb-3">
            Uploaded <span className="font-semibold">{created_at}</span>
         </h5>
      );
   };

   const handleScrollTop = () => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      });
   };

   const openTracks = () => {
      setState({ ...state, show_tracks: true });
   };

   const closeTracks = () => {
      setState({ ...state, show_tracks: false });
   };

   // Extract list of creators.
   const listOfCreators = (type = 'creators') => {
      // Loop through all tracks in the mix to get a list of creators
      // and make a single creators list as a string
      let list_creators = [];
      if (is_mix_search) {
         list_creators = tracks.map((track) => {
            if (track !== null) {
               return track.creators.map((creator) => {
                  return creator.name;
               });
            } else {
               return 'Not available';
            }
         });
      } else {
         list_creators = tracks.map((track) => {
            if (track?.track) {
               return track.track.creators.map((creator) => {
                  return creator.creator.name;
               });
            } else {
               return 'Not available';
            }
         });
      }

      let newCreators = '';

      for (let i = 0; i < list_creators.length; i++) {
         if (i === list_creators.length - 1) {
            newCreators += list_creators[i];
         } else {
            newCreators += list_creators[i] + ', ';
         }
      }

      // Convert String into an array to filter unique artists
      let newCreatorsArr = newCreators.split(',');
      // Make a unique value set
      let uniqueArtists = [];
      uniqueArtists = [...new Set(newCreatorsArr.map((item) => item))];

      if (type === 'creators') {
         let artistNames = '';
         const uniqueArtistsSliced = uniqueArtists.slice(0, 7);
         uniqueArtistsSliced.map((artist, index) => {
            uniqueArtistsSliced.length === index + 1
               ? (artistNames += artist.trim())
               : (artistNames += artist.trim() + ', ');
         });
         return artistNames + '...';
      } else if (type === 'number') {
         return uniqueArtists.length;
      }
   };

   // Post report.
   const openMixMenu = () => {
      setState({
         ...state,
         mix_menu: true
      });
   };

   const closeMixMenu = () => {
      setState({
         ...state,
         mix_menu: false
      });
   };

   // Social context
   // let social_context_output_arr = socialContextDisplay(social_context, id);

   const mixTrackListingPropFormat = (props) => {
      return props;
   };

   const hotTrack = state.show_tracks
      ? null
      : tracks.map((track, index) => {

          let mixTrackListingProps = null;

          if (is_mix_search) {
             mixTrackListingProps = mixTrackListingPropFormat({
                key: index,
                index: index,
                cover: track?.release[0]?.cover ?? null,
                title: track?.title ?? 'Not Available',
                creators: track?.creators ?? [],
                label: track?.label[0]?.name ?? 'Not Available',
                is_last_track: true,
                is_post: false
             });
          } else {
             mixTrackListingProps = mixTrackListingPropFormat({
                key: index,
                index: index,
                cover: track?.track?.release?.cover ?? null,
                title: track?.track?.title ?? 'Not Available',
                creators: track?.track?.creators ?? [],
                label: track?.track?.release?.label?.name ?? 'Not Available',
                is_last_track: true,
                is_post: true
             });
          }

           let trackId = track?.track?._id;

           if (is_mix_search) {
              trackId = track._id;
           }

           if (trackId == hot_track) {
              return (
                 <MixTrackListingHotTrack
                     key={mixTrackListingProps.key}
                     index={mixTrackListingProps.index}
                     cover={mixTrackListingProps.cover}
                     title={mixTrackListingProps.title}
                     creators={mixTrackListingProps.creators}
                     label={mixTrackListingProps.label}
                     is_post={mixTrackListingProps.is_post}
                     is_last_track={mixTrackListingProps.is_last_track}
                     is_hot_track={true}
                 />
              );
           }
        });

   const track_listing = state.show_tracks
      ? tracks.map((track, index) => {
           let trackId = track?.track?._id;
           if (is_mix_search) {
              trackId = track._id;
           }

           let is_last_track = false;
           if (tracks.length === index + 1) {
              is_last_track = true;
           }

           let mixTrackListingProps = null;

           if (is_mix_search) {
              mixTrackListingProps = mixTrackListingPropFormat({
                 key: index,
                 index: index,
                 cover: track?.release[0]?.cover ?? null,
                 title: track?.title ?? 'Not Available',
                 creators: track?.creators ?? [],
                 label: track?.label[0]?.name ?? 'Not Available',
                 is_last_track: is_last_track,
                 is_post: false
              });
           } else {
              mixTrackListingProps = mixTrackListingPropFormat({
                 key: index,
                 index: index,
                 cover: track?.track?.release?.cover ?? null,
                 title: track?.track?.title ?? 'Not Available',
                 creators: track?.track?.creators ?? [],
                 label: track?.track?.release?.label?.name ?? 'Not Available',
                 is_last_track: is_last_track,
                 is_post: true
              });
           }

           console.log(track);
           console.log(hot_track);

           if (trackId == hot_track) {
              return (
                 <MixTrackListingHotTrack
                    key={mixTrackListingProps.key}
                    index={mixTrackListingProps.index}
                    cover={mixTrackListingProps.cover}
                    title={mixTrackListingProps.title}
                    creators={mixTrackListingProps.creators}
                    label={mixTrackListingProps.label}
                    is_post={mixTrackListingProps.is_post}
                    is_last_track={is_last_track}
                 />
              );
           } else {
              return (
                 <MixTrackListing
                    key={mixTrackListingProps.key}
                    index={mixTrackListingProps.index}
                    cover={mixTrackListingProps.cover}
                    title={mixTrackListingProps.title}
                    creators={mixTrackListingProps.creators}
                    label={mixTrackListingProps.label}
                    is_post={mixTrackListingProps.is_post}
                    is_last_track={is_last_track}
                 />
              );
           }
        })
      : null;

   const drop_down = state.show_tracks ? (
      <div
         onClick={closeTracks}
         className="flex h-8 w-8 items-center justify-center hover:bg-mixinlab-indigo_light rounded-full cursor-pointer"
      >
         <div
            className="bg-no-repeat bg-center bg-50 h-8 w-8"
            style={{
               backgroundImage: `url(${collapse})`
            }}
            alt={'More'}
         ></div>
      </div>
   ) : (
      <div
         onClick={openTracks}
         className="flex h-8 w-8 items-center justify-center hover:bg-mixinlab-indigo_light rounded-full cursor-pointer"
      >
         <div
            className="bg-no-repeat bg-center bg-50 h-8 w-8"
            style={{
               backgroundImage: `url(${expand})`
            }}
            alt={'More'}
         ></div>
      </div>
   );

   const dropDownComponent = is_author_me ? (
      <div className="flex justify-end w-10 mr-2 mt-2">
         <div
            className="flex h-8 w-8 items-center justify-center border border-gray-800 hover:border-gray-200 rounded-full cursor-pointer"
            onClick={openMixMenu}
         >
            <div
               className="bg-80 bg-no-repeat bg-center h-8 w-8"
               style={{
                  backgroundImage: `url(${moreGrayIcon})`
               }}
               alt={'More'}
            ></div>
         </div>
      </div>
   ) : null;

   const playPauseButton = in_focus ? (
      mix.current_mix_is_playing ? (
         <div className="flex-none justify-center w-14 pl-2 mr-4">
            <MixPlayerButtonPause />
         </div>
      ) : (
         <div className="flex-none justify-center w-14 pl-2 mr-4">
            <MixPlayerButtonPlay />
         </div>
      )
   ) : (
      <div className="flex-none justify-center w-14 pl-2 mr-4">
         <MixPlayerButtonLoad handleClickLoad={handleClickLoad} />
      </div>
   );

   const spinnerPlaying = is_playing ? (
      <div
         className="w-9 h-12 mr-3 bg-no-repeat bg-bottom"
         style={{
            backgroundImage: `url(${spinner_playing})`
         }}
         alt={'spinner'}
      ></div>
   ) : (
      <div
         className="w-9 h-12 mr-3 bg-no-repeat bg-bottom"
         style={{
            backgroundImage: `url(${spinner_playing_inactive})`
         }}
         alt={'spinner'}
      ></div>
   );

   // const socialContext =
   //    social_context_output_arr.length === 0 ? null : (
   //       <div className="flex mt-3.5 pl-16 ml-1 w-11/12">
   //          {social_context_output_arr}
   //       </div>
   //    );

   const searchMatchScore =
      track_match || creator_match || label_match ? (
         <div className="w-28 h-24 p-2.5 pr-3 pt-2 tracking-wide font-semibold text-right">
            <h3 className="text-mixinlab-pewter">Match</h3>
            <div className="flex justify-end">
               <div>
                  <h5 className="font-normal text-xs text-mixinlab-pewter">Track</h5>
                  <h5 className="font-normal text-xs text-mixinlab-pewter">
                     Creator
                  </h5>
                  <h5 className="font-normal text-xs text-mixinlab-pewter">Label</h5>
               </div>
               <div className="w-auto text-center ml-2">
                  <h5 className="font-normal text-xs text-white">{track_match}</h5>
                  <h5 className="font-normal text-xs text-white">{creator_match}</h5>
                  <h5 className="font-normal text-xs text-white">{label_match}</h5>
               </div>
            </div>
         </div>
      ) : null;

   const socialWidgets = is_social_widgets ? (
      <div className="flex justify-around py-1.5 px-2">
         <Comment openCommentModal={openCommentModal} commentCount={comment_count} />
         <Share handleShareContent={handleShareContent} shareCount={share_count} />
         <Like handleLikeContent={handleLikeContent} likeCount={like_count} />
         <ContentIcon contentId={id} is_single_content={is_single_content} />
      </div>
   ) : (
      <div className="flex justify-around py-1.5 px-2 bg-gray-50 font-normal text-xs">
         User deleted this mix from Feed.
      </div>
   );

   const hover = is_single_content ? null : 'hover:bg-gray-50';

   return (
      <Fragment>
         <div
            className={`relative sm:border-r-0 border-r-2 border-b-2 sm:border-l-0 border-l-2 border-gray-200 ${hover} cursor-default`}
         >
            <div className="flex bg-gray-800">
               <Link
                  onClick={() => {
                     clearSingleContent();
                  }}
                  to={`/content/${id}`}
               >
                  <div
                     className="flex-none bg-gray-700 h-full w-100 bg-cover bg-no-repeat bg-center xs:hidden"
                     style={{
                        backgroundImage: `url(${addDataStorePathMixImages(cover)})`
                     }}
                  ></div>
               </Link>

               <div className="flex w-full justify-end">
                  <div className="w-full p-2 pl-4">
                     <Link
                        onClick={() => {
                           clearSingleContent();
                        }}
                        to={`/content/${id}`}
                     >
                        <h2 className="font-Pulp font-normal text-2xl sm:text-xl xs:mt-2 mt-1 tracking-wide leading-tight text-white">
                           {mix_title}
                        </h2>
                     </Link>

                     <div className="flex">
                        <Link onClick={handleScrollTop} to={`/user/${user_name}`}>
                           <div
                              className="flex-none h-11 w-11 mt-1 bg-gray-100 bg-cover mr-2.5 bg-no-repeat bg-center rounded-full"
                              style={{
                                 backgroundImage: `url(${
                                    dataStorePath.user_profile_images + user_image
                                 })`
                              }}
                           ></div>
                        </Link>
                        <div>
                           <h5 className="text-sm text-mixinlab-pewter mt-0.5">
                              Mixed by{' '}
                              <span className="text-mixinlab-indigo_mid font-semibold">
                                 <Link
                                    onClick={handleScrollTop}
                                    to={`/user/${user_name}`}
                                 >
                                    {display_name}
                                 </Link>
                              </span>
                           </h5>
                           {makeDateReadable()}
                        </div>
                     </div>
                  </div>
               </div>
               {searchMatchScore}
               {dropDownComponent}
            </div>

            {/* ==================== Play section ======================= */}

            {/* {socialContext} */}

            <div className="flex px-2 pt-2">
               <div className="h-16"></div>
               <div className="grid content-center">{playPauseButton}</div>
               {spinnerPlaying}
               <div className="w-full flex justify-between">
                  <div className="grid content-center">
                     <div className="flex text-sm w-full mr-4 pb-1">
                        <span className="font-bold">
                           Includes tracks from:{' '}
                           <span className="font-normal">
                              {listOfCreators('creators')}
                           </span>
                        </span>
                     </div>
                  </div>
                  <div className="flex-none justify-end h-8 w-8 mt-1">
                     {drop_down}
                  </div>
               </div>
            </div>
            <div className="pl-9 sm:pl-3 sm:pr-3">{hotTrack}</div>
            {track_listing}

            <MixMenu
               showModal={state.mix_menu}
               is_author_me={is_author_me}
               handleDelete={handleDelete}
            />
            <MixMenuMask showModal={state.mix_menu} closeModal={closeMixMenu} />

            <PostComment
               postId={id}
               user_image={user_image}
               display_name={display_name}
               created_at={created_at}
               text={text}
               showCommentModal={state.post_comment_modal}
               closeCommentModal={closeCommentModal}
            />

            {socialWidgets}
         </div>
      </Fragment>
   );
};

MixPost.propTypes = {
   deleteContent: PropTypes.func.isRequired,
   loadTimelineMix: PropTypes.func.isRequired,
   clearTimelineMix: PropTypes.func.isRequired,
   getPostId: PropTypes.func.isRequired,
   likeContent: PropTypes.func.isRequired,
   mix: PropTypes.object.isRequired,
   content: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   mix: state.mix,
   content: state.content
});

export default connect(mapStateToProps, {
   loadTimelineMix,
   clearTimelineMix,
   shareContent,
   deleteContent,
   getPostId,
   likeContent
})(MixPost);
