import { Link } from 'react-router-dom';
import React from 'react';

let socialContextProcess = (followers, activity) => {
   // if the activity array length is then nothing to process we return
   if (activity.length == 0) return '';

   let output_array = [];
   let others = null;
   let max_num_of_followers = 2; // max number of friends to list
   let found = [];
   let is_plural = false;
   let words = {};

   activity.map((el) => {
      if (followers?.some((follow) => el.user?._id == follow.user?._id)) {
         found.push(el);
      }
   });

   others = activity.length - found.length;

   if (others > 1) {
      is_plural = true;
   }

   is_plural
      ? (words = { other: 'others', person: 'people' })
      : (words = { other: 'other', person: 'person' });

   // Sample output[{ user1: {display_name: 'Ben'}, {user_name: 'ben_cole'} } ]
   //console.log(found);
   return [
      { found: found },
      { others: others, others_word: words.other, person_word: words.person }
   ];
};

export default socialContextProcess;
