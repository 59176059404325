// React and PropTypes.
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// NPM
import TextareaAutosize from 'react-textarea-autosize';

// Redux.
import { connect } from 'react-redux';

// Import actions.
import { updateMe } from '../actions/me';

// Import components.
import ProfileBgModal from './ProfileBgModal';
import ProfileImageModal from './ProfileImageModal';
import CloseButton from './CloseButton';
import AlertFrontEnd from './AlertFrontEnd';

// Import utils.
import validateUrl from '../utils/validateUrl';

// Component.
const ProfileModal = ({ auth, me, profileState, close, updateMe }) => {
   const [state, setState] = useState({
      user_img_loaded: false,
      user_bg_loaded: true,
      user_img: null,
      user_bg: null,
      display_name: '',
      short_bio: '',
      location: '',
      web_full: '',
      display_name_count: 0,
      short_bio_count: 0,
      location_count: 0,
      web_full_count: 0,
      blob_img: null,
      blob_bg: null,
      file_img: null,
      file_bg: null,
      file_err_msg: null
   });

   useEffect(() => {
      // Initialise component state.
      let meInit = {};

      // Initial image profile.
      if (me.user_image) {
         meInit.user_img = me.user_image;
      } else {
         meInit.user_img = '';
      }

      // Initial image bg.
      if (me.user_bg) {
         meInit.user_bg = me.user_bg;
      } else {
         meInit.user_bg = '';
      }
      // Display name.
      if (me.display_name) {
         meInit.display_name = me.display_name;
      } else {
         meInit.display_name = '';
      }

      // Short bio.
      if (me.short_bio) {
         meInit.short_bio = me.short_bio;
      } else {
         meInit.short_bio = '';
      }

      // Location.
      if (me.location) {
         meInit.location = me.location;
      } else {
         meInit.location = '';
      }

      // Web full.
      if (me.web_full) {
         meInit.web_full = me.web_full;
      } else {
         meInit.web_full = '';
      }

      // Display name count.
      if (me.display_name) {
         meInit.display_name_count = me.display_name.length;
      } else {
         meInit.display_name_count = 0;
      }

      // Short bio count.
      if (me.short_bio) {
         meInit.short_bio_count = me.short_bio.length;
      } else {
         meInit.short_bio_count = 0;
      }

      // Location count.
      if (me.location) {
         meInit.location_count = me.location.length;
      } else {
         meInit.location_count = 0;
      }

      // Web count.
      if (me.web_full) {
         meInit.web_full_count = me.web_full.length;
      } else {
         meInit.web_full_count = 0;
      }

      if (!auth.loading && auth.isAuthenticated) {
         setState({
            user_img: meInit.user_img,
            user_bg: meInit.user_bg,
            display_name: meInit.display_name,
            short_bio: meInit.short_bio,
            location: meInit.location,
            web_full: meInit.web_full,
            display_name_count: meInit.display_name_count,
            short_bio_count: meInit.short_bio_count,
            location_count: meInit.location_count,
            web_full_count: meInit.web_full_count,
            web_full_valid: meInit.web_full_valid
         });
      }
   }, [
      auth.loading,
      auth.isAuthenticated,
      me.user_image,
      me.user_bg,
      me.display_name,
      me.location,
      me.short_bio,
      me.web_full
   ]);

   // Handle input for ProfileImageModal.
   const handleImageInput = (e) => {
      e.preventDefault();

      let reader = new FileReader();
      let file = e.target.files[0];

      // Format check conditions.
      const checkFileFormat = () => {
         const formats = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
         const checkFormats = formats.includes(file.type);
         return checkFormats;
      };

      // Disable placeholder change.
      const disablePlaceHolderChange = () => {
         if (checkFileFormat()) {
            return reader.result;
         } else {
            return null;
         }
      };

      // Check file.
      const checkFile = () => {
         let file_err;
         if (file.size > 2000000) {
            file_err = 'File too large (max. 2MB)';
            setTimeout(function () {
               setState({
                  ...state,
                  blob_img: null,
                  file_img: null,
                  file_err_msg: null
               });
            }, 3000);
         }
         if (file.size <= 2000000) {
            file_err = null;
         }
         if (!checkFileFormat()) {
            file_err = 'Wrong format! Try .jpg .png or .gif';
            setTimeout(function () {
               setState({
                  ...state,
                  blob_img: null,
                  file_img: null,
                  file_err_msg: null
               });
            }, 3000);
         }
         return file_err;
      };

      reader.onloadend = () => {
         if (e.target.id === 'profile') {
            setState({
               ...state,
               blob_img: disablePlaceHolderChange(),
               file_img: file,
               file_err_msg: checkFile(),
               success: true
            });
         }
         if (e.target.id === 'background') {
            setState({
               ...state,
               blob_bg: disablePlaceHolderChange(),
               file_bg: file,
               file_err_msg: checkFile(),
               success: true
            });
         }
      };
      reader.readAsDataURL(file);
   };

   // Count character length.
   const handleChange = (e) => {
      const { name, value } = e.target;

      if (name === 'display_name') {
         setState({
            ...state,
            display_name_count: value.length
         });
      }
      if (name === 'short_bio') {
         setState({
            ...state,
            short_bio_count: value.length
         });
      }
      if (name === 'location') {
         setState({
            ...state,
            location_count: value.length
         });
      }

      if (name === 'web_full') {
         setState({
            ...state,
            web_full_count: value.length
         });
      }

      setState((prevState) => {
         return {
            ...prevState,
            [name]: value
         };
      });
   };

   // Clear local state on modal close.
   const handleClose = () => {
      close();
      if (state.user_img && state.file_img) {
         setState({
            blob_img: null
         });
      }

      if (state.bg_img && state.file_bg) {
         setState({
            blob_bg: null
         });
      }
   };

   // Submit.
   const onSubmit = async (e) => {
      e.preventDefault();

      // Configure profile fields.
      let me = {
         display_name: state.display_name,
         short_bio: state.short_bio,
         location: state.location,
         web_full: state.web_full,
         web_display: null
      };

      // Display name.
      if (me.display_name === '' || me.display_name === 'Display name') {
         me.display_name = '';
      }

      // Short bio.
      if (me.short_bio === '' || me.short_bio === 'Short bio') {
         me.short_bio = '';
      }

      // Location.
      if (me.location === '' || me.location === 'Location') {
         me.location = '';
      }

      // Web.
      if (me.web_full === '' || me.web_full === 'Website') {
         me.web_full = '';
         me.web_display = '';
      } else if (me.web_full === '' || me.web_full !== 'Website') {
         // Validate web address in state.
         const valid = validateUrl(me.web_full);

         if (valid.is_valid === false) {
         } else if (valid.is_valid === true) {
            me.web_full = valid.full;
            me.web_display = valid.display;
         }
      }

      // Instantiate new form.
      const formData = new FormData();

      // Append text fields.
      formData.append('display_name', me.display_name);
      formData.append('short_bio', me.short_bio);
      formData.append('location', me.location);
      formData.append('web_full', me.web_full); // Is 'web' right?

      // User form.
      if (!state.blob_img && !state.blob_bg) {
         await updateMe(formData);
      }

      // User form + user image.
      if (state.blob_img && !state.blob_bg) {
         formData.append('user_image', state.file_img);
         await updateMe(formData);
      }

      // User form + bg image.
      if (!state.blob_img && state.blob_bg) {
         formData.append('user_bg', state.file_bg);
         await updateMe(formData);
      }

      // User form + user image + bg image.
      if (state.blob_img && state.blob_bg) {
         formData.append('user_image', state.file_img);
         formData.append('user_bg', state.file_bg);
         await updateMe(formData); // update state to image 'loaded: false'.
      }
   };

   // Instantiate components.
   let modal;
   let modalMask;

   if (profileState) {
      modal = (
         <div className="fixed top-2 left-1/2 transform -translate-x-1/2 translate-y-6 md:translate-y-0 md:top-0 md:h-screen md:w-full w-600 z-70 p-6 pt-4 bg-white rounded-lg md:rounded-none shadow-modal">
            <div className="mx-auto items-center w-full mb-4">
               <div className="grid grid-cols-2 gap-4">
                  <div className="flex flex-wrap content-end">
                     <h1 className="font-Pulp font-bold text-3xl mb-5 ">
                        Edit profile
                     </h1>
                  </div>
                  <div className="flex justify-end">
                     <CloseButton close={handleClose} />
                  </div>
               </div>

               <div>
                  {state.file_err_msg ? (
                     <AlertFrontEnd msg={state.file_err_msg} />
                  ) : null}
               </div>

               <div className="relative">
                  <ProfileImageModal
                     user_img={state.user_img}
                     blob={state.blob_img}
                     setState={setState}
                     handleInput={handleImageInput}
                  />

                  <ProfileBgModal
                     user_bg={state.user_bg}
                     blob={state.blob_bg}
                     setState={setState}
                     handleInput={handleImageInput}
                  />
               </div>

               <form onSubmit={onSubmit} className="flex flex-col mt-6 sm:mt-6">
                  {/* Display name */}
                  <label htmlFor="display_name"></label>
                  <div className="flex items-center border border-gray-300 rounded-md p-2 mb-1">
                     <input
                        className="text-lg font-semibold w-full text-black focus:outline-none pl-1"
                        type="text"
                        name="display_name"
                        placeholder="Display name"
                        onChange={handleChange}
                        value={state.display_name}
                     />
                  </div>
                  <div>
                     <h6 className="text-sm text-gray-500 pl-2">
                        Artist name or nickname
                        <span
                           className={`ml-2 ${
                              state.display_name_count > 30
                                 ? 'text-mixinlab-cherry font-bold'
                                 : 'text-mixinlab-teal'
                           } `}
                        >
                           {state.display_name_count}
                        </span>
                        <span className="text-black"> / 30 chars.</span>
                     </h6>
                  </div>

                  {/* Short bio */}
                  <label htmlFor="short_bio"></label>
                  <div className="flex items-center border border-gray-300 rounded-md p-2 mb-1 mt-3">
                     <TextareaAutosize
                        type="text"
                        name="short_bio"
                        onChange={handleChange}
                        className="text-lg font-semibold w-full text-black focus:outline-none pl-1"
                        defaultValue={state.short_bio}
                        placeholder="Short bio"
                     />
                  </div>
                  <div>
                     <h6 className="text-sm text-gray-500 pl-2">
                        Short bio
                        <span
                           className={`ml-2 ${
                              state.short_bio_count > 140
                                 ? 'text-mixinlab-cherry font-bold'
                                 : 'text-mixinlab-teal'
                           } `}
                        >
                           {state.short_bio_count}
                        </span>
                        <span className="text-black"> / 140 chars.</span>
                     </h6>
                  </div>

                  {/* Location */}
                  <label htmlFor="location"></label>
                  <div className="flex items-center border border-gray-300 rounded-md p-2 mb-1 mt-3">
                     <input
                        className="text-lg font-semibold w-full text-black focus:outline-none pl-1"
                        type="text"
                        name="location"
                        placeholder="Location"
                        onChange={handleChange}
                        value={state.location}
                     />
                  </div>
                  <div>
                     <h6 className="text-sm text-gray-500 pl-2">
                        Location
                        <span
                           className={`ml-2 ${
                              state.location_count > 30
                                 ? 'text-mixinlab-cherry font-bold'
                                 : 'text-mixinlab-teal'
                           } `}
                        >
                           {state.location_count}
                        </span>
                        <span className="text-black"> / 30 chars.</span>
                     </h6>
                  </div>

                  {/* Web */}
                  <label htmlFor="web_full"></label>
                  <div className="flex items-center border border-gray-300 rounded-md p-2 mb-1 mt-3">
                     <input
                        className="text-lg font-semibold w-full text-black focus:outline-none pl-1"
                        type="text"
                        name="web_full"
                        placeholder="Web"
                        onChange={handleChange}
                        value={state.web_full}
                     />
                  </div>
                  <div>
                     <h6 className="text-sm text-gray-500 pl-2">
                        Web
                        <span
                           className={`ml-2 ${
                              state.web_full_count > 70
                                 ? 'text-mixinlab-cherry'
                                 : 'text-mixinlab-teal'
                           } `}
                        >
                           {state.web_full_count}
                        </span>
                        <span className="text-black"> / 70 chars.</span>
                        <span
                           className={`ml-2 ${
                              state.web_full_valid === 'Invalid URL'
                                 ? 'text-mixinlab-cherry'
                                 : 'text-mixinlab-teal'
                           } `}
                        >
                           {state.web_full_valid}
                        </span>
                     </h6>
                  </div>

                  {/* Save button. */}
                  {state.display_name_count > 30 ||
                  state.short_bio_count > 140 ||
                  state.location_count > 30 ||
                  state.web_full_count > 70 ? (
                     <div className="mx-auto mt-6">
                        <div className="flex h-11 w-24 pt-1.5 font-Pulp font-normal text-xl tracking-wide opacity-50 text-white rounded-lg border-2 bg-mixinlab-cherry border-mixinlab-cherry cursor-pointer focus:outline-none">
                           <div className="mx-auto">Save</div>
                        </div>
                     </div>
                  ) : (
                     <div className="mx-auto mt-6">
                        <button
                           type="submit"
                           className="h-11 w-24 p-1 font-Pulp font-normal text-xl tracking-wide text-white rounded-lg border-2 bg-mixinlab-teal border-mixinlab-teal hover:bg-white hover:text-mixinlab-teal cursor-pointer focus:outline-none"
                        >
                           Save
                        </button>
                     </div>
                  )}
               </form>
            </div>
         </div>
      );

      modalMask = (
         <div
            className="fixed top-0 left-0 w-screen h-screen z-60 bg-black opacity-30 cursor-pointer"
            onClick={handleClose}
         ></div>
      );
   }

   return (
      <Fragment>
         {modal}
         {modalMask}
      </Fragment>
   );
};

ProfileModal.propTypes = {
   updateMe: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired,
   me: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth,
   me: state.me
});

export default connect(mapStateToProps, {
   updateMe
})(ProfileModal);
