// React.
import React, { Fragment, useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

// Moment.
import moment from 'moment';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import utils.
import socialContextProcess from '../utils/socialContextProcess';

// Import actions.
import {
   getHomeContent,
   getHomeContentScroll,
   getUserContent
} from '../actions/content';

// Import components
import Post from './Post';
import PostShare from './PostShare';
import Spinner from './Spinner';
import MixPost from './MixPost';
import MixPostShare from './MixPostShare';
import Marque from '../assets/mixinlab-marque-end-feed.svg';

const PostsWrapper = ({
   getHomeContent,
   getUserContent,
   getHomeContentScroll,
   auth,
   me,
   follow,
   content,
   mix
}) => {
   const [state, setState] = useState({
      posts_arr: []
   });

   // Route match.
   let is_user_page = useRouteMatch({
      path: '/user/:user',
      strict: true,
      sensitive: true
   });

   // Handle load more posts.
   const handleLoadMorePosts = () => {
      getHomeContentScroll(content.page_num);
   };

   // 1. On Profile page load get user content once RouteMatch has found user in params.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated && is_user_page) {
         if (is_user_page) {
            getUserContent(is_user_page.params.user);
         }
      }
   }, [auth]);

   // 2. On Home page load get user.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated && !is_user_page) {
         getHomeContent();
      }
   }, [auth]);

   // 3. Set local state with content.posts from content reducer.
   useEffect(() => {
      const PostsArr = content.posts;
      if (!auth.loading && auth.isAuthenticated) {
         setState({ ...state, posts_arr: PostsArr });
      }
      const newPostsArr = PostsArr.map((obj) => {
         // 👇️ if id equals 2 replace object
         if (obj.type == 'mix') {
            if (
               obj.mix?._id === mix?.current_mix_id &&
               obj?._id === mix?.current_mix_post_id
            ) {
               obj.in_focus = true;
               obj.current_mix_is_playing = mix?.current_mix_is_playing;
               return obj;
            } else {
               obj.in_focus = false;
               obj.current_mix_is_playing = false;
               return obj;
            }
         }
         if (obj.type == 'share') {
            if (obj.shared_content?.type === 'mix') {
               if (
                  obj?.shared_content?.mix?._id === mix?.current_mix_id &&
                  obj?._id === mix?.current_mix_post_id
               ) {
                  obj.in_focus = true;
                  obj.current_mix_is_playing = mix?.current_mix_is_playing;
                  return obj;
               } else {
                  obj.in_focus = false;
                  obj.current_mix_is_playing = false;
                  return obj;
               }
            }
         }
         // 👇️ otherwise return object as is
         return obj;
      });

      setState({ ...state, posts_arr: newPostsArr });
   }, [auth, content.posts, mix.current_mix_id, mix.current_mix_is_playing]);

   // 4. Get new content on update.
   useEffect(() => {
      if (
         !auth.loading &&
         auth.isAuthenticated &&
         // content.status_share_content === null &&
         content.status_update === 'success'
      ) {
         getHomeContent();
      }
   }, [auth, getHomeContentScroll, content.status_update]);

   // On Follow / Unfollow
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated && follow.follow_unfollow_done) {
         getHomeContent();
      }
   }, [auth, getHomeContent, follow]);

   // Compile feed posts.
   // =============================================================================

   let Posts;

   if (Array.isArray(state.posts_arr)) {
      Posts = state.posts_arr.map((post, index) => {
         let social_context = socialContextProcess(me.followers, post.likes);

         /**
          * We want the logged in user to be able to see meModal of PostMenu which will allow
          * Him to Delete his own posts and shared posts.
          * For other posts He will be able to See modalUser which will allow him to
          * Unfollow, Block or Report other peoples content
          * */

         let isAuthorMe = false;
         if (me.id === post.user._id) {
            isAuthorMe = true;
         }

         /**
          * To determine if the follow button should be visible on PostProfileCard
          * We have to check if the Profile we are going to display on PostProfileCard is for logged in user
          * if it is Logged in user so DO NOT Display FollowToggleButton
          **/
         let displayFollowToggleButton = false;
         if (post.type === 'share') {
            if (me.id === post.shared_content) {
               // in a share if original post is not the posted by the logged in user
               displayFollowToggleButton = true;
            }
         } else {
            if (me.id === post.user._id) {
               displayFollowToggleButton = true;
            }
         }

         if (post.type === 'text' || post.type === 'image') {
            // Null safe checks to go header.

            return (
               <Post
                  key={index}
                  id={post?.id}
                  user_id={post?.user?._id}
                  plan={post?.user?.licence?.display_name}
                  liked_by={null}
                  user_image={post?.user?.user_image}
                  display_name={post?.user?.display_name}
                  user_name={post?.user?.user_name}
                  created_at={moment(
                     moment
                        .utc(post.createdAt)
                        .utcOffset(new Date().getTimezoneOffset()),
                     'YYYY-MM-DD HH:mm:ss'
                  ).fromNow()}
                  text={post?.text}
                  giphy={post?.giphy}
                  media={post?.media}
                  comment_count={post?.comment_count_VIRTUAL}
                  share_count={post?.share_count_VIRTUAL}
                  like_count={post?.like_count_VIRTUAL}
                  is_author_me={isAuthorMe}
                  display_follow_toggle_button={displayFollowToggleButton}
                  social_context={social_context}
               />
            );
         }

         if (post.type === 'share') {
            if (post?.shared_content?.type === 'mix') {
               return (
                  <MixPostShare
                     key={index}
                     id={post?.id}
                     in_focus={post?.in_focus}
                     is_playing={post?.current_mix_is_playing}
                     share_id={post?.shared_content?.id}
                     mix_id={post?.shared_content?.mix?.id}
                     hot_track={post?.shared_content?.mix?.hot_track}
                     cover={post?.shared_content?.mix?.cover}
                     mix_title={post?.shared_content?.mix?.title}
                     display_name={post?.user?.display_name}
                     user_name={post?.user?.user_name}
                     mix_owner_display_name={post?.shared_content?.user?.display_name}
                     mix_owner_user_name={post?.shared_content?.user?.user_name}
                     tracks={post?.shared_content?.mix?.tracks}
                     comment_count={post?.comment_count_VIRTUAL}
                     share_count={post?.shared_content.share_count_VIRTUAL}
                     like_count={post?.like_count_VIRTUAL}
                     user_image={post?.shared_content?.user?.user_image}
                     created_at={moment(post.createdAt, 'YYYYMMDD').fromNow()}
                     is_author_me={isAuthorMe}
                     time_stamp={me?.time_stamp}
                  />
               );
            } else {
               return (
                  <PostShare
                     key={index}
                     id={post?.id}
                     user_id={post?.user?._id}
                     plan={post?.shared_content?.user?.licence?.display_name}
                     share_id={post?.shared_content?.id}
                     share={post?.user?.display_name}
                     share_user_name={post?.user?.user_name}
                     share_comment={post?.text}
                     share_user_id={post?.shared_content?.user?._id}
                     user_image={post?.user?.user_image}
                     display_name={post?.shared_content?.user?.display_name}
                     shared_user_image={post?.shared_content?.user?.user_image}
                     user_name={post?.shared_content?.user?.user_name}
                     created_at={moment(post.createdAt, 'YYYYMMDD').fromNow()}
                     text={post?.shared_content?.text}
                     giphy={post?.shared_content?.giphy}
                     media={post?.shared_content?.media}
                     comment_count={post?.comment_count_VIRTUAL}
                     share_count={post?.shared_content?.share_count_VIRTUAL}
                     like_count={post?.like_count_VIRTUAL}
                     is_author_me={isAuthorMe}
                     display_follow_toggle_button={displayFollowToggleButton}
                  />
               );
            }
         }

         if (post.type === 'mix') {
            if (post?.mix?.cover) {
               return (
                  <MixPost
                     key={index}
                     id={post?.id}
                     in_focus={post?.in_focus}
                     is_playing={post?.current_mix_is_playing}
                     mix_id={post?.mix?.id}
                     hot_track={post?.mix?.hot_track}
                     cover={post?.mix?.cover}
                     mix_title={post?.mix?.title}
                     display_name={post?.user?.display_name}
                     user_name={post?.user?.user_name}
                     tracks={post?.mix?.tracks}
                     comment_count={post?.comment_count_VIRTUAL}
                     share_count={post?.share_count_VIRTUAL}
                     like_count={post?.like_count_VIRTUAL}
                     user_image={post?.user?.user_image}
                     created_at={moment(post.createdAt, 'YYYYMMDD').fromNow()}
                     is_author_me={isAuthorMe}
                     time_stamp={me.time_stamp}
                     social_context={social_context}
                  />
               );
            } else {
               return null;
            }
         }
         return null;
      });
   }

   // Display correct feed end / Load more posts
   const Load =
      content.post_num_results < 40 ? (
         <div className="w-full h-32 flex justify-center pt-12">
            <div
               className="no-repeat bg-center w-18 h-18 bg-contain"
               style={{
                  backgroundImage: `url(${Marque})`
               }}
            ></div>
         </div>
      ) : (
         <div
            onClick={handleLoadMorePosts}
            className="w-full h-32 pt-12 font-Pulp text-center text-mixinlab-teal text-xl"
         >
            <span className="cursor-pointer">Load more posts</span>
         </div>
      );

   return (
      <Fragment>
         {content.loading ? (
            <Spinner />
         ) : (
            <div className="w-full pb-60">
               {Posts}
               {Load}
            </div>
         )}
      </Fragment>
   );
};

PostsWrapper.propTypes = {
   getHomeContentScroll: PropTypes.func.isRequired,
   getHomeContent: PropTypes.func.isRequired,
   getUserContent: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired,
   content: PropTypes.object.isRequired,
   mix: PropTypes.object.isRequired,
   me: PropTypes.object.isRequired,
   follow: PropTypes.object.isRequired
};
const mapStateToProps = (state) => ({
   auth: state.auth,
   content: state.content,
   mix: state.mix,
   me: state.me,
   follow: state.follow
});
export default connect(mapStateToProps, {
   getHomeContent,
   getUserContent,
   getHomeContentScroll
})(PostsWrapper);
