// Import React.
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import components.
import MixPlayerButtonLoad from './MixPlayerButtonLoad';
import MixPlayerButtonPlay from './MixPlayerButtonPlay';
import MixPlayerButtonPause from './MixPlayerButtonPause';
import Comment from './Comment';
import Share from './Share';
import Like from './Like';
import MixTrackListing from './MixTrackListing';
import MixTrackListingHotTrack from './MixTrackListingHotTrack';
import MixMenu from './PostMenu';
import MixMenuMask from './MixMenuMask';
import MixWidget from './MixWidget';
import profilePlaceholderIconPerson from '../assets/placeholder_person.svg';
import CommentFeedNEW from './CommentFeedNEW';
import PostCommentInput from './PostCommentInput';

// Import actions
import { clearTimelineMix, loadTimelineMix } from '../actions/mix';
import {
   shareContent,
   deleteContent,
   getPostId,
   likeContent
} from '../actions/content';

// Import icons.
import expand from '../assets/expand.svg';
import collapse from '../assets/collapse.svg';
import PostComment from './PostComment';
import spinner_playing from '../assets/spinner_playing.svg';
import spinner_playing_inactive from '../assets/spinner_playing_inactive.svg';
import likeIcon from '../assets/fire_gray.svg';
import ContentIcon from './Content';
import moreIcon from '../assets/more.svg';
import shareIcon from '../assets/share_gray.svg';

// Import utils.
import dataStorePath from '../configs/dataStorePath';

const MixPostShare = ({
   content,
   comments,
   mix,
   loadTimelineMix,
   clearTimelineMix,
   getPostId,
   id,
   mix_id,
   share_id,
   is_single_content,
   cover,
   mix_title,
   display_name,
   user_image,
   mix_owner_display_name,
   mix_owner_user_name,
   text,
   user_name,
   created_at,
   tracks,
   hot_track,
   comment_count,
   share_count,
   like_count,
   shareContent,
   likeContent,
   is_author_me,
   deleteContent,
   in_focus,
   is_playing,
   social_context
}) => {
   let [state, setState] = useState({
      show_tracks: false,
      post_comment_modal: false,
      mix_menu: false
   });

   useEffect(() => {
      if (content.status_comment === 'success') {
         setState({
            ...state,
            mix_menu: false,
            post_comment_modal: false
         });
      }
   }, [content.status_comment]);

   const handleClickLoad = (e) => {
      clearTimelineMix();
      loadTimelineMix(id, mix_id);
   };

   const handleLikeContent = (e) => {
      likeContent(id);
   };

   const handleShareContent = (e) => {
      // Instantiate new form.
      const formData = new FormData();
      // Append.
      formData.append('type', 'share');
      formData.append('shared_content', share_id);

      shareContent(formData);
   };

   // Delete mix.
   const handleDelete = () => {
      deleteContent(id);
      setState({ ...state, mix_menu: false });
   };

   // Comment modals.
   const openCommentModal = () => {
      getPostId(id);
      setState({
         ...state,
         post_comment_modal: true,
         post_comment_modal_allowed: false
      });
   };

   const closeCommentModal = () => {
      setState({
         ...state,
         post_comment_modal: false,
         post_comment_modal_allowed: true
      });
   };

   const openTracks = () => {
      setState({ ...state, show_tracks: true });
   };

   const closeTracks = () => {
      setState({ ...state, show_tracks: false });
   };

   // Extract list of creators.
   const listOfCreators = (type = 'creators') => {
      // Loop through all tracks in the mix to get a list of creators
      // and make a single creators list as a string

      let list_creators = tracks.map((track) => {
         if (track !== null) {
            return track.track.creators.map((creator) => {
               return creator.creator.name;
            });
         } else {
            return 'Not available';
         }
      });
      let newCreators = '';

      for (let i = 0; i < list_creators.length; i++) {
         if (i === list_creators.length - 1) {
            newCreators += list_creators[i];
         } else {
            newCreators += list_creators[i] + ', ';
         }
      }

      // Convert String into an array to filter unique artists
      let newCreatorsArr = newCreators.split(',');
      // Make a unique value set
      let uniqueArtists = [];
      uniqueArtists = [...new Set(newCreatorsArr.map((item) => item))];

      if (type === 'creators') {
         let artistNames = '';
         const uniqueArtistsSliced = uniqueArtists.slice(0, 7);
         uniqueArtistsSliced.map((artist, index) => {
            uniqueArtistsSliced.length === index + 1
               ? (artistNames += artist.trim())
               : (artistNames += artist.trim() + ', ');
         });
         return artistNames + '...';
      } else if (type === 'number') {
         return uniqueArtists.length;
      }
   };

   // Post report.
   const openMixMenu = (e) => {
      setState({
         ...state,
         mix_menu: true
      });
   };

   const closeMixMenu = (e) => {
      setState({
         ...state,
         mix_menu: false
      });
   };

   // Conditionally render post shares.
   const postShareText = user_name ? (
      <div className="flex text-gray-500 pt-0.5">
         <div className="flex pr-2">
            <img src={shareIcon} alt="share icon" className="h-5" />
         </div>

         {/* Shared by */}
         <h6 className="text-sm align-middle">
            <span className="mr-1">Shared by</span>
            <Link
               to={`/user/${user_name}`}
               className="mr-1.5 font-normal font-bold hover:underline cursor-pointer"
            >
               {display_name}
            </Link>
            <span>~ {created_at}</span>
         </h6>
      </div>
   ) : null;

   const track_listing = state.show_tracks
      ? tracks.map((track, index) => {
           let is_last_track = false;
           if (tracks.length === index + 1) {
              is_last_track = true;
           }

           console.log(track.track.id)
           console.log(hot_track)

           if (track?.track?.id == hot_track) {
              return (
                 <MixTrackListingHotTrack
                    key={index}
                    index={index}
                    cover={track?.track?.release?.cover ?? null}
                    title={track?.track?.title ?? 'Not Available'}
                    creators={track?.track?.creators ?? []}
                    label={track?.track?.release?.label?.name ?? 'Not Available'}
                    is_post={true}
                    is_last_track={is_last_track}
                 />
              );
           } else {
              return (
                 <MixTrackListing
                    key={index}
                    index={index}
                    cover={track?.track?.release?.cover ?? null}
                    title={track?.track?.title ?? 'Not Available'}
                    creators={track?.track?.creators ?? []}
                    label={track?.track?.release?.label?.name ?? 'Not Available'}
                    is_post={true}
                    is_last_track={is_last_track}
                 />
              );
           }

           //   return (
           //      <MixTrackListing
           //         key={index}
           //         index={index}
           //         cover={track?.track?.release?.cover}
           //         title={track?.track?.title ?? 'Not available'}
           //         creators={track?.track?.creators}
           //         label={track?.track?.release?.label?.name ?? 'Not available'}
           //         is_post={true}
           //         is_last_track={is_last_track}
           //      />
           //   );
        })
      : null;

   // Conditionally render post likes.
   const postLikes = social_context ? (
      <div className="flex text-gray-500 mt-1">
         <div className="flex pr-2">
            <img src={likeIcon} alt="share icon" className="h-5" />
         </div>

         {/* Liked by */}
         <div>
            <h6 className="text-sm align-middle">{social_context}</h6>
         </div>
      </div>
   ) : null;

   const drop_down = state.show_tracks ? (
      <div
         onClick={closeTracks}
         className="flex h-8 w-8 items-center justify-center hover:bg-mixinlab-indigo_light rounded-full cursor-pointer"
      >
         <div
            className="bg-no-repeat bg-center bg-50 h-8 w-8"
            style={{
               backgroundImage: `url(${collapse})`
            }}
            alt={'More'}
         ></div>
      </div>
   ) : (
      <div
         onClick={openTracks}
         className="flex h-8 w-8 items-center justify-center hover:bg-mixinlab-indigo_light rounded-full cursor-pointer"
      >
         <div
            className="bg-no-repeat bg-center bg-50 h-8 w-8"
            style={{
               backgroundImage: `url(${expand})`
            }}
            alt={'More'}
         ></div>
      </div>
   );

   const dropDownComponent = is_author_me ? (
      <div className="flex-none justify-end w-8">
         <div
            className="flex h-8 w-8 items-center justify-center hover:bg-mixinlab-teal_15 rounded-full cursor-pointer"
            onClick={openMixMenu}
         >
            <div
               className="bg-80 bg-no-repeat bg-center h-8 w-8"
               style={{
                  backgroundImage: `url(${moreIcon})`
               }}
               alt={'More'}
            ></div>
         </div>
      </div>
   ) : null;

   const profileImage = user_image ? (
      <div className="flex-none mt-1 w-12 h-9">
         <div
            className="h-8 w-8 rounded-full bg-gray-100 bg-cover bg-no-repeat bg-center cursor-pointer"
            // onClick={openPostProfileCard}
            // style={{
            //    backgroundImage: `url(${addDataStorePath(user_image)}?${time_stamp})`
            // }}
            style={{
               backgroundImage: `url(${
                  dataStorePath.user_profile_images + user_image
               })`
            }}
         ></div>
      </div>
   ) : (
      <div className="flex-none mt-1 w-14 sm:w-12 sm:mr-0.5">
         <div
            className="h-12 w-12 sm:h-11 sm:w-11 mr-3 rounded-full bg-50 bg-gray-100 bg-center bg-no-repeat cursor-pointer"
            style={{
               backgroundImage: `url(${profilePlaceholderIconPerson})`
            }}
         ></div>
      </div>
   );

   const playPauseButton = in_focus ? (
      mix.current_mix_is_playing ? (
         <div className="flex-none justify-center w-14 pl-2 mr-4">
            <MixPlayerButtonPause />
         </div>
      ) : (
         <div className="flex-none justify-center w-14 pl-2 mr-4">
            <MixPlayerButtonPlay />
         </div>
      )
   ) : (
      <div className="flex-none justify-center w-14 pl-2 mr-4">
         <MixPlayerButtonLoad handleClickLoad={handleClickLoad} />
      </div>
   );

   const spinnerPlaying = is_playing ? (
      <div
         className="w-9 h-12 mr-3 bg-no-repeat bg-bottom"
         style={{
            backgroundImage: `url(${spinner_playing})`
         }}
         alt={'spinner'}
      ></div>
   ) : (
      <div
         className="w-9 h-12 mr-3 bg-no-repeat bg-bottom"
         style={{
            backgroundImage: `url(${spinner_playing_inactive})`
         }}
         alt={'spinner'}
      ></div>
   );

   const socialWidgets = is_single_content ? null : (
      // (
      //    <div className="flex">
      //       <div className="flex pb-2 pt-2 w-14 sm:w-12 ml-12">
      //          <div className="mr-8 ml-2">
      //             <Share
      //                handleShareContent={handleShareContent}
      //                shareCount={share_count}
      //             />
      //          </div>
      //          <Like handleLikeContent={handleLikeContent} likeCount={like_count} />
      //       </div>
      //    </div>
      // )
      <div className="flex justify-around py-1.5 px-2">
         <Comment openCommentModal={openCommentModal} commentCount={comment_count} />
         <Share handleShareContent={handleShareContent} shareCount={share_count} />
         <Like handleLikeContent={handleLikeContent} likeCount={like_count} />
         <ContentIcon contentId={id} is_single_content={is_single_content} />
      </div>
   );

   const hover = is_single_content ? null : 'hover:bg-gray-50';

   // const more = is_single_content ? null : (
   //    <div
   //       className="h-8 w-8 items-center hover:bg-mixinlab-teal_15 rounded-full cursor-pointer"
   //       onClick={openPostMenu}
   //    >
   //       <div
   //          className="bg-80 bg-no-repeat bg-center h-8 w-8"
   //          style={{
   //             backgroundImage: `url(${moreIcon})`
   //          }}
   //          alt={'More'}
   //       ></div>
   //    </div>
   // );

   const commentFeed = is_single_content ? (
      <CommentFeedNEW comments={comments} id={id} />
   ) : null;

   const postCommentInput = is_single_content ? (
      <PostCommentInput postId={id} />
   ) : null;

   return (
      <Fragment>
         <div
            className={`relative sm:border-r-0 border-r-2 border-b-2 sm:border-l-0 border-l-2 border-gray-200 ${hover} cursor-default`}
         >
            {/* {foundMe} */}
            <div className="flex">
               {/* Social context */}
               {postLikes}
            </div>

            <MixMenu
               showModal={state.mix_menu}
               is_author_me={is_author_me}
               handleDelete={handleDelete}
            />
            <MixMenuMask showModal={state.mix_menu} closeModal={closeMixMenu} />

            <div>
               <div className="px-3 pt-3">
                  <div className="flex">
                     {profileImage}
                     <div className="w-full flex justify-between">
                        {postShareText}
                        {dropDownComponent}
                     </div>
                  </div>
               </div>
            </div>

            {/* Mix widget */}
            <div className="mx-14 px-3">
               <MixWidget
                  cover={cover}
                  content_id={id}
                  mix_title={mix_title}
                  mix_owner_user_name={mix_owner_user_name}
                  mix_owner_display_name={mix_owner_display_name}
               />
            </div>

            {/* ==================== Play section ======================= */}
            <div className="flex px-2 mt-4 mb-2">
               <div className="grid content-center">{playPauseButton}</div>
               {spinnerPlaying}

               <div className="w-full flex justify-between">
                  <div className="grid content-center">
                     <div className="flex text-sm w-11/12">
                        <span className="font-bold">
                           Includes tracks from:{' '}
                           <span className="font-normal">
                              {listOfCreators('creators')}
                           </span>
                        </span>
                     </div>
                  </div>
                  <div className="flex-none justify-end h-8 w-8 mt-1">
                     {drop_down}
                  </div>
               </div>
            </div>
            {track_listing}

            <PostComment
               postId={id}
               user_image={user_image}
               display_name={display_name}
               created_at={created_at}
               text={text}
               showCommentModal={state.post_comment_modal}
               closeCommentModal={closeCommentModal}
            />
            {socialWidgets}
            {postCommentInput}
            {commentFeed}
         </div>
      </Fragment>
   );
};

MixPostShare.propTypes = {
   deleteContent: PropTypes.func.isRequired,
   loadTimelineMix: PropTypes.func.isRequired,
   clearTimelineMix: PropTypes.func.isRequired,
   getPostId: PropTypes.func.isRequired,
   likeContent: PropTypes.func.isRequired,
   mix: PropTypes.object.isRequired,
   auth: PropTypes.object.isRequired,
   content: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   mix: state.mix,
   auth: state.auth,
   content: state.content
});

export default connect(mapStateToProps, {
   loadTimelineMix,
   clearTimelineMix,
   shareContent,
   deleteContent,
   getPostId,
   likeContent
})(MixPostShare);
