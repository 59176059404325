// React.
import React, { Fragment } from 'react';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import { discoverMusicReset } from '../actions/mix';

// Import icons.
import back_arrow from '../assets/back_arrow.svg';

const PageHeaderSearch = ({ mix, page, show_back_button, discoverMusicReset }) => {
   const mixCount = () => {
      let mix_count = (
         <h2 className="text-gray-400 font-light mt-0.5 ml-3 text-xl font-light">
            Results:{' '}
            <span className="font-semibold text-mixinlab-cerise">
               {mix.user_mixes_count}
            </span>
         </h2>
      );
      return mix_count;
   };

   const displaySearchParams = () => {
      let search_query = [];

      if (page.display_name) search_query.push(page.display_name);
      if (page.mix_title) search_query.push(page.mix_title);
      if (page.track_title) search_query.push(page.track_title);
      if (page.creator_name) search_query.push(page.creator_name);
      if (page.label_name) search_query.push(page.label_name);

      let search_string = [];

      search_query.forEach((item, index) => {
         search_string.push(
            <span key={index} className="ml-1">
               {item}
            </span>
         );
         if (index !== search_query.length - 1) {
            search_string.push(<span className="text-mixinlab-cerise"> + </span>);
         }
      });
      return search_string;
   };

   return (
      <Fragment>
         <div className="border-gray-200 pt-2 pb-4 lg:px-3">
            <div className="flex">
               <div className="pt-1 pb-11 h-10" onClick={discoverMusicReset}>
                  {show_back_button ? (
                     <div
                        className="mr-1 bg-80 bg-no-repeat bg-center h-10 w-10 hover:bg-gray-200 rounded-full cursor-pointer"
                        style={{
                           backgroundImage: `url(${back_arrow})`
                        }}
                        alt={'Back'}
                     ></div>
                  ) : null}
               </div>
               <div className="pt-1.5 font-Pulp font-semibold text-xl tracking-wide mr-2">
                  <h1 className="mt-0.5 ml-3">
                     <span className="text-gray-400 font-light mr-1.5">Search:</span>
                     {displaySearchParams()}
                  </h1>
                  {mixCount()}
               </div>
            </div>
         </div>
      </Fragment>
   );
};

PageHeaderSearch.propTypes = {
   mix: PropTypes.object.isRequired,
   auth: PropTypes.object.isRequired,
   discoverMusicReset: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
   mix: state.mix,
   auth: state.auth
});

export default connect(mapStateToProps, { discoverMusicReset })(PageHeaderSearch);
