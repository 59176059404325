// React.
import React from 'react';

// Import components.
import UserSearch from './UserSearch';

const UserWrapper = ({ showMusic, onUserSelect }) => {
   return (
      <div>
         <div className="flex relative">
            <div className="w-36 pt-1 border-r-0 border-t-2 border-l-2 border-gray-200 font-Pulp font-semibold text-black text-lg text-center cursor-default">
               People
            </div>
            <div className="h-10 border-r-2 border-gray-200"></div>
            <div
               onClick={showMusic}
               className="w-36 pr-0.5 h-10 pt-1 border-l-0 border-t-2 border-r-2 border-b-2 border-gray-200 font-Pulp font-semibold text-lg text-center cursor-pointer"
            >
               Music
            </div>
         </div>
         <div className="border-l-2 border-r-2 border-b-2 p-4 pt-2 pb-5 border-gray-200">
            <UserSearch
               onUserSelect={onUserSelect}
               showMusic={showMusic}
            />
         </div>
      </div>
   );
};

export default UserWrapper;
