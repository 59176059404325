import React, { Fragment, useState, useEffect } from 'react';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Moment.
import moment from 'moment';

// Components.
import PageHeaderSearch from './PageHeaderSearch';
import MixPost from './MixPost';

const HomeFeedSearch = ({ mix, discover }) => {
   const [state, setState] = useState({ results_mixes: [] });

   useEffect(() => {
      setState({
         ...state,
         results_mixes: mix.user_mixes
      });
   }, [mix.user_mixes]);

   let MixesWrapper;

   // 1. Set local state with mix.user_mixes that have added arrays of matched params to the mix obj.
   // 2. Once user clicks play(load_mix_into_reducer) add UE hook to select that mix and add is_playing prop to mix obj.
   // 3. Change MixMatch to MixPost.

   // This creates arrays for matched tracks.
   if (Array.isArray(mix.user_mixes)) {
      MixesWrapper = mix.user_mixes.map((post, index) => {
         // track
         let matched_tracks = [];
         if (discover.track_title) {
            let discoverTrackTitleRegex = new RegExp(
               `.*${discover.track_title}.*`,
               'i'
            );

            matched_tracks = post.tracks.filter((track) => {
               return track.title.match(discoverTrackTitleRegex);
            });
         }

         // creator
         let matched_creators = [];
         if (discover.creator_name) {
            let discoverCreatorRegex = new RegExp(
               `.*${discover.creator_name}.*`,
               'i'
            );

            matched_creators = post.tracks.filter((track) => {
               return track.creators.filter((creator) => {
                  console.log(creator);
                  return creator.name.match(discoverCreatorRegex);
               });
            });
         }

         // label
         let matched_labels = [];
         if (discover.label_name) {
            let discoverLabelRegex = new RegExp(`.*${discover.label_name}.*`, 'i');
            matched_labels = post.tracks.filter((track) => {
               return track.label[0].name.match(discoverLabelRegex);
            });
         }
         // checks if current mix is in focus as well as if the current mix is playing
         if (post._id === mix.current_mix_id) {
            post.in_focus = true;
            post.current_mix_is_playing = mix.current_mix_is_playing;
            //return post;
         } else {
            post.in_focus = false;
            post.current_mix_is_playing = false;
            //return post;
         }

         const mix_result = (
            <MixPost
               key={index}
               is_mix_search={true}
               id={post.content[0][0]?._id}
               in_focus={post.in_focus}
               is_playing={post.current_mix_is_playing}
               mix_id={post._id}
               cover={post.cover[0]}
               mix_title={post.title}
               display_name={post.user[0][0].display_name}
               user_name={post.user[0][0].user_name}
               user_image={post.user[0][0].user_image}
               tracks={post.displayTracks}
               hot_track={post.hot_track[0]}
               comment_count={post.content[0][0]?.comments.length}
               share_count={post.content[0][0]?.shares.length}
               like_count={post.content[0][0]?.likes.length}
               created_at={moment(post.createdAt, 'YYYYMMDD').fromNow()}
               track_match={matched_tracks.length}
               creator_match={matched_creators.length}
               label_match={matched_labels.length}
               is_social_widgets={post.content[0][0] ? true : false}
               // is_author_me={isAuthorMe}
               // time_stamp={me.time_stamp}
               // social_context={social_context}
            />
         );
         return mix_result;
      });
   }

   return (
      <Fragment>
         <PageHeaderSearch page={discover} show_back_button={true} />
         <div className='pb-60'>{MixesWrapper}</div>
      </Fragment>
   );
};

HomeFeedSearch.propTypes = {
   mix: PropTypes.object.isRequired,
   discover: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   mix: state.mix,
   discover: state.discover
});

export default connect(mapStateToProps)(HomeFeedSearch);
