// Import React.
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import components
import ProfileLicenceButtonSmall from '../components/ProfileLicenceButtonSmall';

// Import actions.
import { followUnfollow } from '../actions/follow';

// Import utils.
import addDataStorePath from '../utils/addDataStorePath';
import dataStorePath from '../configs/dataStorePath';
import { resetStatus } from '../actions/auth';

const Follow = ({
   id,
   licence,
   user_image,
   display_name,
   user_name,
   short_bio,
   followsMe,
   followUnfollow,
   backButton
}) => {
   // Unfollow action.
   const handleClick = () => {
      followUnfollow(id);
   };

   // Conditionally render components.
   const FollowUnfollow = followsMe ? (
      <div className="flex">
         <button
            onClick={handleClick}
            className="h-8 px-2 font-Pulp font-normal text-sm tracking-wide text-mixinlab-teal rounded border-2 border-mixinlab-teal hover:bg-mixinlab-teal hover:text-white cursor-pointer focus:outline-none"
         >
            Unfollow
         </button>
      </div>
   ) : (
      <div className="flex">
         <button
            onClick={handleClick}
            className="h-8 px-2 font-Pulp font-normal text-sm tracking-wide text-mixinlab-teal rounded border-2 border-mixinlab-teal hover:bg-mixinlab-teal hover:text-white cursor-pointer focus:outline-none"
         >
            Follow
         </button>
      </div>
   );

   return (
      <Fragment>
         <div className="relative w-full py-4 px-3 sm:border-r-0 border-r-2 border-b-2 sm:border-l-0 border-l-2 border-gray-200 hover:bg-gray-50">
            {/* Comment text */}
            <div className="flex flex-row ">
               {/* Profile image */}
               <div className="w-14">
                  <div
                     className="h-10 w-10 rounded-full bg-cover bg-no-repeat bg-center cursor-pointer"
                     style={{
                        backgroundImage: `url(${
                           dataStorePath.user_profile_images + user_image
                        })`
                     }}
                  ></div>
               </div>

               <div className="w-full">
                  <div className="flex justify-between w-full md:block">
                     <div>
                        <div className="flex">
                           {/* Display name */}
                           <h4 className="font-Pulp font-normal text-lg tracking-wide pt-0.5 pr-2 cursor-pointer">
                              <span className="hover:underline">
                                 <Link onClick={backButton} to={`/user/${user_name}`}>
                                    {display_name}
                                 </Link>
                              </span>
                              <span> ~ </span>
                           </h4>
                           <h4 className="font-Pulp text-mixinlab-teal font-thin text-lg tracking-wide pr-2 cursor-pointer">
                              <span className="hover:underline">
                                 {' '}
                                 <Link onClick={backButton} to={`/user/${user_name}`}>
                                    @{user_name}
                                 </Link>
                              </span>
                           </h4>
                        </div>
                        <h4 className="font-normal text-base pr-2">{short_bio}</h4>
                     </div>

                     <div className="md:w-full md:flex md:justify-end">
                        <div className="flex w-40 justify-between md:mt-3">
                           <ProfileLicenceButtonSmall licence={licence} />
                           {FollowUnfollow}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

Follow.propTypes = {
   followUnfollow: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { followUnfollow })(Follow);
