// React.
import React, { Fragment } from 'react';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Actions.
import { deactivateUser, reactivateUser } from '../actions/me';

const SettingsDeactivate = ({ me, deactivateUser, reactivateUser, isDectivated }) => {
   const performAction = () => {
      isDectivated === false ? deactivateUser(me.id) : reactivateUser(me.id);
   };

   const buttonText =
      isDectivated === false ? 'Deactivate Account' : 'Reactivate Account';

   return (
      <Fragment>
         <div className="p-2">
            <div className="text-xl font-Pulp font-bold">
               This will deactivate your account
            </div>
            <div className="mt-5 text-base font-normal">
               You're about to start the process of deactivating your Bandcamp
               account. Your display name, @username, and public profile will no
               longer be viewable on bandcamp.com.
            </div>
            <div className="mx-auto mt-6">
               <button
                  type="submit"
                  onClick={performAction}
                  className="h-11 px-4 p-1 mt-6 font-Pulp font-normal text-md tracking-wide text-red-400 rounded-md border-2 border-red-400 hover:bg-white hover:bg-red-400 hover:text-white cursor-pointer focus:outline-none"
               >
                  {buttonText}
               </button>
            </div>
         </div>
      </Fragment>
   );
};

SettingsDeactivate.propTypes = {
   me: PropTypes.object.isRequired,
   deactivateUser: PropTypes.func.isRequired,
   reactivateUser: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
   me: state.me
});

export default connect(mapStateToProps, { deactivateUser, reactivateUser })(
   SettingsDeactivate
);
