// Import React.
import React, { Fragment, useState } from 'react';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import { likeContent } from '../actions/content';

// Import images.
import fire from '../assets/fire.gif';
import fireTeal from '../assets/fire_teal.gif';
import fireTealFocus from '../assets/fire_teal_focus.gif';

const Like = ({ handleLikeContent, likeCount }) => {
   const [isHovering, setIsHovered] = useState(false);
   const onMouseEnter = () => setIsHovered(true);
   const onMouseLeave = () => setIsHovered(false);

   const like_component = (
      <div
         className="flex flex-row items-center group"
         onMouseEnter={onMouseEnter}
         onMouseLeave={onMouseLeave}
      >
         {likeCount === 0 ? (
            isHovering ? (
               <div
                  onClick={handleLikeContent}
                  className="flex flex-row h-9 w-9 cursor-pointer bg-center bg-70 bg-no-repeat rounded-full group-hover:bg-mixinlab-teal_15"
                  style={{
                     backgroundImage: `url(${fireTeal})`
                  }}
               ></div>
            ) : (
               <div
                  onClick={handleLikeContent}
                  className="flex flex-row h-9 w-9 cursor-pointer bg-center bg-70 bg-no-repeat rounded-full group-hover:bg-mixinlab-teal_15"
                  style={{
                     backgroundImage: `url(${fire})`
                  }}
               ></div>
            )
         ) : (
            <div
               onClick={handleLikeContent}
               className="flex flex-row h-9 w-9 cursor-pointer bg-center bg-70 bg-no-repeat rounded-full group-hover:bg-mixinlab-teal_15"
               style={{
                  backgroundImage: `url(${fireTealFocus})`
               }}
            ></div>
         )}

         <h6
            onClick={handleLikeContent}
            className={`${
               likeCount === 0
                  ? 'text-xs text-black group-hover:text-mixinlab-teal font-medium ml-1'
                  : 'text-xs text-mixinlab-teal font-medium ml-1'
            }`}
         >
            {likeCount}
         </h6>
      </div>
   );

   return <Fragment>{like_component}</Fragment>;
};

Like.propTypes = {
   likeContent: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { likeContent })(Like);
