// React and PropTypes.
import React, { Link, Redirect } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Components.
import GaPageView from '../components/GaPageView';

const cover_image = {
   wonderland: `url("https://mixcamp-production.ams3.digitaloceanspaces.com/app/wonderland.jpg")`,
   wonderland_dark: `url("https://mixcamp-production.ams3.digitaloceanspaces.com/app/wonderland_dark.jpg")`
};

const Join = ({ auth }) => {
   // Redirect if logged in.
   if (auth.isAuthenticated) {
      return <Redirect to="/home" />;
   }

   const left_panel_lg = (
      <div
         style={{
            backgroundImage: cover_image.wonderland_dark
         }}
         className="hidden lg:block bg-mixinlab-slate bg-top bg-cover flex justify-center w-1/2 lg:w-full px-10 sm:px-6"
      >
         <div className="w-11/12">
            <div className="h-32">
               <div className="bg-logo-beta bg-no-repeat bg-center w-56 h-full"></div>
            </div>
            <h1 className="w-10/12 font-Pulp font-bold text-8xl sm:text-6xl xs:text-5xl leading-close sm:leading-none mt-2 text-mixinlab-indigo_text">
               A new <span className="text-mixinlab-indigo_mid">dance mix</span>{' '}
               streaming platform for{' '}
               <span className="text-mixinlab-indigo_mid">DJs.</span>
            </h1>

            <ul className="mt-8 text-white text-3xl xs:text-2xl list-disc ml-6 leading-sung">
               <li>Upload and share your mixes</li>
               <li>Better music discovery</li>
               <li>PAYG mix uploads</li>
               <li>
                  Uploads from <span className="font-semibold">$2.49</span>
               </li>
            </ul>
            <h2 className="font-Pulp text-mixinlab-indigo_text font-semibold text-xl mt-20 pl-1 pb-2 tracking-wide">
               Signup to our BETA trial
            </h2>

            <div className="xs:block flex mt-4 mb-36">
               <div className="h-14">
                  <Link
                     to="/plan"
                     className="text-center pt-2 pb-2 px-6 mr-5 font-Pulp font-normal text-xl tracking-wide text-white rounded-lg border-2 border-white cursor-pointer focus:outline-none"
                  >
                     Sign up
                  </Link>
               </div>

               <div className="h-14">
                  <Link
                     to="/login"
                     className="text-center pt-2 pb-2 px-6 font-Pulp font-normal text-xl tracking-wide text-white rounded-lg border-2 border-white cursor-pointer focus:outline-none"
                  >
                     Login
                  </Link>
               </div>
            </div>

            <a
               className="fixed bottom-6 right-10 text-sm font-thin cursor-pointer text-gray-300 visited:text-gray-300"
               href="https://scrolldown.com.au"
               target="_blank"
            >
               App development:{' '}
               <span className="font-bold tracking-wider font-Pulp text-white">
                  Scrolldown
               </span>
            </a>
         </div>
      </div>
   );

   const left_panel_sm = (
      <div className="lg:hidden block bg-mixinlab-slate flex justify-center w-1/2 lg:w-full px-5">
         <div className="w-11/12">
            <div className="h-32">
               <div className="bg-logo-beta bg-no-repeat bg-center w-64 lg:w-60 h-full"></div>
            </div>
            <h1 className="w-10/12 font-Pulp font-bold text-8xl leading-close mt-2 text-mixinlab-indigo_text">
               A new <span className="text-mixinlab-indigo_mid">dance mix</span>{' '}
               streaming platform for{' '}
               <span className="text-mixinlab-indigo_mid">DJs.</span>
            </h1>

            <ul className="mt-8 text-white text-3xl list-disc ml-7 leading-sung">
               <li>Upload and share your mixes</li>
               <li>Better music discovery</li>
               <li>PAYG mix uploads</li>
            </ul>
            <h2 className="font-Pulp text-mixinlab-indigo_text font-semibold text-xl mt-20 pl-1 pb-2 tracking-wide">
               Signup to our BETA trial
            </h2>

            <div className="flex mt-2 mb-20">
               <Link
                  to="/plan"
                  className="text-center pt-2 pb-1.5 px-6 mr-5 font-Pulp font-normal text-xl tracking-wide text-white rounded-lg border-2 border-white hover:bg-white hover:text-mixinlab-dark_rust cursor-pointer focus:outline-none"
               >
                  Sign up
               </Link>
               <Link
                  to="/login"
                  className="text-center pt-2 pb-1.5 px-6 font-Pulp font-normal text-xl tracking-wide text-white rounded-lg border-2 border-white hover:bg-white hover:text-mixinlab-dark_rust cursor-pointer focus:outline-none"
               >
                  Login
               </Link>
            </div>
         </div>
      </div>
   );

   return (
      <div className="container mx-auto h-screen">
         <GaPageView />
         <div className="flex lg:flex-wrap h-screen">
            {left_panel_lg}
            {left_panel_sm}
            <div
               className="relative bg-cover bg-mixinlab-slate bg-no-repeat bg-top w-1/2 lg:hidden h-screen bg-gray-100"
               style={{
                  backgroundImage: cover_image.wonderland
               }}
            >
               <div className="absolute grid content-center rotate-5 z-10 leading-7 w-40 h-40 mt-28 -left-20 top-96 rounded-full border-2 border-white text-white text-2xl text-center">
                  Uploads <br /> from <br />
                  $2.49
               </div>

               <a
                  className="fixed bottom-6 right-10 text-sm font-thin cursor-pointer text-gray-300 visited:text-gray-300"
                  href="https://scrolldown.com.au"
                  target="_blank"
               >
                  App development:{' '}
                  <span className="font-bold tracking-wider font-Pulp text-white">
                     Scrolldown
                  </span>
               </a>
            </div>
         </div>
      </div>
   );
};

const mapStateToProps = (state) => ({
   auth: state.auth
});

export default connect(mapStateToProps)(Join);
