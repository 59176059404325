// Import React.
import React, { Fragment } from 'react';

// Import styles.
import styles from '../css/styles.module.css';

// Import utils.
import addDataStorePathReleaseCover from '../utils/addDataStorePathReleaseCover';

const MixTrackListingHotTrack = ({
   is_matched,
   index,
   cover,
   title,
   creators,
   label,
   is_post,
   is_hot_track,
   is_last_track
}) => {
   const match = is_matched ? (
      <div className="font-semibold text-xs w-full text-right h-3">
         <span className="font-semibold mr-1">Match details</span>{' '}
         <span className="text-mixinlab-indigo">Track + Creator + Label</span>
      </div>
   ) : null;


   // Extract list of creators.
   const listOfCreators = () => {
      let list_creators = [];
      list_creators = creators.map((creator) => {
         if (is_post) {
            return creator.creator.name.toString();
         } else {
            return creator.name.toString();
         }
      });

      let newCreators = '';

      for (let i = 0; i < list_creators.length; i++) {
         if (i === list_creators.length - 1) {
            newCreators += list_creators[i];
         } else {
            newCreators += list_creators[i] + ', ';
         }
      }
      return newCreators;
   };
   const parseCreators = listOfCreators(creators);

   const coverComponent = cover ? (
      <div
         className="flex-none rounded-sm w-14 h-14 bg-no-repeat bg-center bg-cover"
         style={{
            backgroundImage: `url(${addDataStorePathReleaseCover(cover)})`
         }}
      ></div>
   ) : (
      <div className="flex-none border-gray-200 border-3 rounded-md w-14 h-14">
         <div className="grid content-center h-full">
            <div className="w-8 h-8 mx-auto border-3 border-gray-200 rounded-full"></div>
         </div>
      </div>
   );

   const indexComponent = is_hot_track ? null : (
      <div className="flex-none w-6 sm:w-3 mr-3 sm:mr-4 text-center text-sm">
         <div className="grid content-center h-full">
            <div>{index + 1}</div>
         </div>
      </div>
   );

   let track_listing_style =
      'border-gray-300 border-b pl-3 pr-4 mx-4 sm:mx-2 hover:bg-gray-50';

   if (is_last_track) {
      track_listing_style = 'border-b-0 pl-3 pr-4 mx-4 sm:mx-2';
   }

   return (
      <Fragment>
         <div className={track_listing_style}>
            {match}

            <div className="flex sm:block justify-between py-1.5 sm:py-4">
               <div className="flex w-full">
                  {indexComponent}

                  <div className={`${styles.hotTrack} flex w-full`}>
                     <div className="grid content-center bg-mixinlab-cherry w-12 rounded-l-lg">
                        <div className="transform -rotate-90 text-center pt-1 origin-center">
                           <div className="font-Pulp text-white text-xl h-4 mb-0.5">
                              Hot
                           </div>
                           <div className="font-Pulp text-sm text-white">track</div>
                        </div>
                     </div>

                     <div className="bg-gray-400 w-full pl-3 pr-4 py-1.5 flex justify-between rounded-r-lg">
                        <div className="flex w-full mr-3">
                           <div className="grid content-center">{coverComponent}</div>

                           <div className="grid content-center">
                              <div className="ml-4 leading-tight w-full">
                                 <div className="text-base text-white font-semibold tracking-wide font-Pulp">
                                    {title}
                                 </div>
                                 <div className="text-xs font-bold">
                                    Creator:{' '}
                                    <span className="font-normal">
                                       {parseCreators}
                                    </span>
                                 </div>
                                 <div className="text-xs font-bold">
                                    Label:{' '}
                                    <span className="font-normal">{label}</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        {/* <div className="grid content-center group w-12 ml-3 flex-none">
                           <button className="h-8 grid content-center font-Pulp font-normal text-sm tracking-wide text-white group-hover:text-gray-500 group-hover:bg-white border-white rounded border-2 focus:outline-none">
                              Play
                           </button>
                        </div> */}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default MixTrackListingHotTrack;
