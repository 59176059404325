// React and PropTypes.
import React, { useState, Fragment, useEffect } from 'react';

// Import NPM package
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Import images.
import DragDotsLight from '../assets/drag_dots_light.svg';
import TopFiveEditModal from './TopFiveEditModal';

// Import redux.
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Actions
import { deleteTopFive, updateTopFive } from '../actions/topFive';

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
   // some basic styles to make the items look a bit nicer

   userSelect: 'none',
   padding: '2px 2px 2px 8px',
   margin: `0 0 ${grid}px 0`,
   color: isDragging ? 'white' : 'black',
   boxShadow: isDragging
      ? '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
      : null,

   // change background colour if dragging
   background: isDragging ? '#00beaa' : 'white',

   // styles we need to apply on draggables
   ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
   background: isDraggingOver ? 'white' : 'white',
   padding: grid,
   width: '100%',
   position: 'relative'
});

const queryAttr = 'data-rbd-drag-handle-draggable-id';

const TopFive = ({
   me,
   user,
   id,
   myList,
   feedDragHandle,
   title,
   deleteTopFive,
   updateTopFive
}) => {
   const [placeholderProps, setPlaceholderProps] = useState({});
   const [openEditModal, setOpenEditModal] = useState({ open: false });
   const [topFives, updateTopFives] = useState(myList);

   useEffect(() => {
      updateTopFives(myList);
   });

   const openEdit = () => {
      setOpenEditModal({ ...openEditModal, open: true });
   };

   const closeEdit = () => {
      setOpenEditModal({ ...openEditModal, open: false });
   };

   const updateTopFiveOnDrag = (item) => {
      const top_five = {
         id: id,
         sort_order: 1,
         title: title,
         items: [
            { title: item[0].title },
            { title: item[1].title },
            { title: item[2].title },
            { title: item[3].title },
            { title: item[4].title }
         ]
      };
      updateTopFive(top_five);
   };

   const handlOnDragEnd = (result) => {
      if (result.destination !== null) {
         const items = Array.from(topFives);
         const [recodedItem] = items.splice(result.source.index, 1);
         items.splice(result.destination.index, 0, recodedItem);
         updateTopFiveOnDrag(items);
      }
      setPlaceholderProps({});
   };

   const handleEdit = () => {
      openEdit();
   };

   const handleDelete = () => {
      const topFiveId = {
         id: id
      };
      deleteTopFive(topFiveId);
   };

   const onDragUpdate = (update) => {
      if (!update.destination) {
         return;
      }
      const draggableId = update.draggableId;
      const destinationIndex = update.destination.index;

      const domQuery = `[${queryAttr}='${draggableId}']`;
      const draggedDOM = document.querySelector(domQuery);

      if (!draggedDOM) {
         return;
      }
      const { clientHeight, clientWidth } = draggedDOM;

      const clientY =
         parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
         [...draggedDOM.parentNode.children]
            .slice(0, destinationIndex)
            .reduce((total, curr) => {
               const style = curr.currentStyle || window.getComputedStyle(curr);
               const marginBottom = parseFloat(style.marginBottom);
               return total + curr.clientHeight + marginBottom;
            }, 0);

      setPlaceholderProps({
         clientHeight,
         clientWidth,
         clientY,
         clientX: parseFloat(
            window.getComputedStyle(draggedDOM.parentNode).paddingLeft
         ),
         border: '1px dashed #00beaa',
         borderRadius: '5px'
      });
   };

   const buttons = (
      <div className="flex group bg-white w-full mb-2">
         <div className="flex-none">
            <div className="ml-2 flex">
               <button
                  onClick={handleEdit}
                  className="h-6 mr-4 px-2 font-Pulp font-normal text-xs tracking-wide rounded border-2 border-white group-hover:border-mixinlab-teal hover:!bg-mixinlab-teal text-white group-hover:text-mixinlab-teal hover:!text-white cursor-pointer focus:outline-none"
               >
                  Edit
               </button>

               <button
                  onClick={handleDelete}
                  className="h-6 mr-4 px-2 font-Pulp font-normal text-xs tracking-wide rounded border-2 border-white group-hover:border-mixinlab-teal hover:!bg-mixinlab-teal text-white group-hover:text-mixinlab-teal hover:!text-white cursor-pointer focus:outline-none"
               >
                  Delete
               </button>
            </div>
         </div>

         <div className="flex justify-end w-full">
            <div
               {...feedDragHandle}
               className="w-4 h-4 mt-1.5 mr-0.5 bg-contain bg-no-repeat bg-center"
               style={{
                  backgroundImage: `url(${DragDotsLight})`
               }}
            ></div>
         </div>
      </div>
   );

   return (
      <Fragment>
         <TopFiveEditModal
            id={id}
            topFives={topFives}
            title={title}
            openEditModal={openEditModal.open}
            closeEdit={closeEdit}
         />

         <div className="border-2 bg-white border-gray-200 p-3 mb-7">
            {buttons}

            <h5 className="font-semibold text-lg text-black w-full pl-2.5">
               {title}
            </h5>

            <DragDropContext onDragEnd={handlOnDragEnd} onDragUpdate={onDragUpdate}>
               <Droppable droppableId="droppable">
                  {(provided, snapshot, index) => (
                     <div
                        key={index}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                     >
                        {topFives.map(({ id, title }, index) => {
                           return (
                              <Draggable key={id} draggableId={id} index={index}>
                                 {(provided, snapshot) => (
                                    <div
                                       className="rounded-md"
                                       ref={provided.innerRef}
                                       {...provided.draggableProps}
                                       {...provided.dragHandleProps}
                                       style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                       )}
                                    >
                                       <span {...provided.dragHandleProps}></span>
                                       <span className="text-sm">
                                          {snapshot.isDragging ? null : (
                                             <span className="font-semibold mr-2">
                                                {index + 1}
                                             </span>
                                          )}
                                          <span className="px-2 py-1">{title}</span>
                                       </span>
                                    </div>
                                 )}
                              </Draggable>
                           );
                        })}
                        {provided.placeholder}
                        {/* <CustomPlaceholder snapshot={snapshot} /> */}
                        <div
                           style={{
                              position: 'absolute',
                              top: placeholderProps.clientY,
                              left: placeholderProps.clientX,
                              height: placeholderProps.clientHeight,
                              width: placeholderProps.clientWidth,
                              border: placeholderProps.border,
                              borderRadius: placeholderProps['borderRadius']
                           }}
                        />
                     </div>
                  )}
               </Droppable>
            </DragDropContext>
         </div>
      </Fragment>
   );
};

TopFive.propTypes = {
   me: PropTypes.object.isRequired,
   user: PropTypes.object.isRequired,
   deleteTopFive: PropTypes.func.isRequired,
   updateTopFive: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({ me: state.me, user: state.user });

export default connect(mapStateToProps, {
   deleteTopFive,
   updateTopFive
})(TopFive);
