// React.
import React, { Fragment } from 'react';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import helpers.
import {
   youtubeDomain,
   twitchDomain,
   otherDomain
} from '../helpers/platformHandlers';

const RichContentDomain = ({ content }) => {

   // Renamed for simplicity.
   const shared_content = content.update_link_details;

   // Instantiate component.
   let domain;

   switch (shared_content?.platform) {
      case 'YOUTUBE':
         domain = youtubeDomain(shared_content);
         break;
      case 'TWITCH':
         domain = twitchDomain(shared_content);
         break;
      default:
         domain = otherDomain(shared_content);
   }

   return <Fragment>{domain}</Fragment>;
};

RichContentDomain.propTypes = {
   content: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({ content: state.content });

export default connect(mapStateToProps)(RichContentDomain);
