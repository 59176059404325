// Import React.
import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import { getUserContext, clearUserContext } from '../actions/userContext';
import {
   shareContent,
   deleteContent,
   getPostId,
   likeContent
} from '../actions/content';
import { getFollowing } from '../actions/follow';
import { blockUser } from '../actions/user';

// Import components.
import PostMenu from './PostMenu';
import PostMenuMask from './PostMenuMask';
import PostProfileCard from './PostProfileCard';
import PostProfileCardMask from './PostProfileCardMask';
import PostShareNotAvailable from './PostShareNotAvailable';
import PostComment from './PostComment';
import PostReport from './PostReport';
import Comment from './Comment';
import Share from './Share';
import Like from './Like';
import ContentIcon from './Content';
import shareIcon from '../assets/share_gray.svg';
import RichContentFeed from './RichContentFeed';
import CommentFeedNEW from './CommentFeedNEW';
import PostCommentInput from './PostCommentInput';

// Import icons.
import moreIcon from '../assets/more.svg';
import profilePlaceholderIconPerson from '../assets/placeholder_person.svg';
import dataStorePath from '../configs/dataStorePath';

// Import Utils.
import {
   extractLinksFromString,
   formatRichPost,
   isRichPost,
   getLinkDetailsFromUrl
} from '../utils/richUpdate';

const PostShare = ({
   auth,
   is_single_content,
   content,
   comments,
   follow,
   shareContent,
   deleteContent,
   // getUserContext,
   clearUserContext,
   getPostId,
   // getFollowing,
   likeContent,
   id,
   // me,
   plan,
   share_id,
   share,
   share_user_name,
   share_user_id,
   shared_user_image,
   user_id,
   user_image,
   display_name,
   user_name,
   created_at,
   text,
   giphy,
   media,
   comment_count,
   share_count,
   like_count,
   is_author_me,
   display_follow_toggle_button
}) => {
   const [state, setState] = useState({
      post_menu: false,
      post_profile_card: false,
      post_comment_modal: false,
      post_report: false,
      is_followed: false,
      rich_content_api_success: false,
      rich_content_api_results: null,
      rich_content_display: false,
      links_in_text: []
   });

   // Extract rich content data from text and call APIs to get data obj, when text loads.
   useEffect(() => {
      let links_in_text = extractLinksFromString(text);
      if (!auth.loading && auth.isAuthenticated && links_in_text.length > 0) {
         getLinkDetailsFromUrl(links_in_text[0])
            .then((response) => {
               setState({
                  ...state,
                  rich_content_api_success: true,
                  rich_content_api_results: response
               });
            })
            .catch((err) => {
               console.log(err);
            });
      }
   }, [auth, text]);

   // Wait for response from API to be true.
   useEffect(() => {
      if (
         !auth.loading &&
         auth.isAuthenticated &&
         state.rich_content_api_success === true
      ) {
         setState({
            ...state,
            rich_content_display: true
         });
      }
   }, [auth, state.rich_content_api_success]);

   // Close comment modal once comment is done.
   useEffect(() => {
      if (
         !auth.loading &&
         auth.isAuthenticated &&
         content.status_comment === 'success'
      )
         setState({
            ...state,
            post_comment_modal: false,
            post_comment_modal_allowed: true
         });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [auth, content.status_comment]);

   // Check if I am following post author.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         // Check if any the author of this post exists in my following array.
         setState({
            ...state,
            is_followed: follow?.following?.some(
               (follows) => follows?.id === share_user_id
            )
         });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [auth, follow, share_user_id]);

   // Close PostProfileCard when unfollow / Follow is done.
   const closePostProfileCardOnFollowUnfollow = () => {
      setState({
         ...state,
         post_profile_card: false
      });
   };

   // Post profile card.
   // const openPostProfileCard = (e) => {
   //    getUserContext(user_name);
   //    getFollowing(me.user_name);

   //    setState({
   //       ...state,
   //       post_profile_card: true,
   //       post_comment_modal_allowed: false
   //    });
   // };

   const closePostProfileCard = (e) => {
      clearUserContext();
      setState({
         ...state,
         post_profile_card: false,
         post_comment_modal_allowed: true,
         is_followed: false
      });
   };

   // Open post modal.
   const openPostMenu = (e) => {
      setState({
         ...state,
         post_menu: true,
         post_comment_modal_allowed: false
      });
   };

   const closePostMenu = (e) => {
      setState({
         ...state,
         post_menu: false,
         post_comment_modal_allowed: true
      });
   };

   // Comment modal.
   const openCommentModal = (e) => {
      getPostId(id);
      setState({
         ...state,
         post_comment_modal: true,
         post_comment_modal_allowed: false
      });
   };

   const closeCommentModal = (e) => {
      setState({
         ...state,
         post_comment_modal: false,
         post_comment_modal_allowed: true
      });
   };

   // Post report.
   const openPostReport = (e) => {
      setState({
         ...state,
         post_report: true,
         post_menu: false
      });
   };

   const closePostReport = (e) => {
      setState({
         ...state,
         post_report: false
      });
   };

   const handleShareContent = (e) => {
      // Instantiate new form.
      const formData = new FormData();

      // Append.
      formData.append('type', 'share');
      formData.append('shared_content', share_id);

      shareContent(formData);
   };

   const handleLikeContent = (e) => {
      likeContent(id);
   };

   const handleBlockUser = (e) => {
      likeContent(id);
   };

   const handleDeleteContent = (e) => {
      deleteContent(id);
      setState({ ...state, post_menu: false });
   };

   const handleStopProp = (e) => {
      window.scrollTo({
         top: 0,
         behavior: "smooth"
      });
   };

   const postMedia = media ? (
      <div className="pr-10 py-3">
         <img
            src={`${dataStorePath.content_images + media}`}
            className="w-auto max-h-200 rounded-lg"
            alt={'Post'}
             onError={(e) => {
               e.target.onerror = null;
               e.target.src = '/images/content_not_available.png';
            }}
         />
      </div>
   ) : null;

   const postGiphy = giphy ? (
      <div className="pr-10 sm:pr-3 xs:pr-0 py-3 xs:pb-0">
         <img src={`${giphy}`} className="w-auto max-h-200 rounded-lg" alt={'Post'} />
      </div>
   ) : null;

   // Conditionally render post shares.
   const sharedByText = share ? (
      <Fragment>
         <div className="flex text-gray-500 pt-1.5">
            <div className="flex pr-2">
               <img src={shareIcon} alt="share icon" className="h-5" />
            </div>
            {/* Shared by */}
            <div>
               <h6 className="text-sm align-middle">
                  <span className="mr-1">Shared by</span>
                  <Link
                     onClick={handleStopProp}
                     to={`/user/${share_user_name}`}
                     className="font-normal font-bold hover:underline cursor-pointer text-gray-500"
                  >
                     {share}
                  </Link>
               </h6>
            </div>
         </div>
      </Fragment>
   ) : null;

   const sharedContentText = (
      // <div className={styles.postTextWidth}>
      <p className="font-normal w-full font-base whitespace-pre-line leading-snug pb-1 mt-1 sm:mt-1">
         <span className="break-words">{formatRichPost(text)}</span>
      </p>
      // </div>
   );

   const sharedContent = isRichPost(text) ? (
      <div>
         <div className="flex mt-4">
            <h4 className="font-Pulp font-semibold text-xl leading-none tracking-wide pt-1 pr-2 cursor-pointer">
               <Link to={`/user/${user_name}`}>
                  <span className="hover:underline">{display_name}</span>
               </Link>

               <span className="font-Pulp font-extralight text-lg tracking-wide text-gray-500 pt-1 pl-2">
                  <span className="text-sm mr-1.5">~</span>
                  {created_at}
               </span>
            </h4>
         </div>
         {sharedContentText}
         <div className="w-full mt-2">
            {state.rich_content_display ? (
               <RichContentFeed data={state.rich_content_api_results} />
            ) : null}
         </div>
      </div>
   ) : (
      <div className="mt-1 mb-2 mx-14 xs:mr-4 leading-tight bg-white border-gray-300 p-3 border rounded-md">
         <div className="flex xs:block">
            <div className="w-14 mr-1 xs:mb-1">
               {shared_user_image ? (
                  <div
                     className="h-12 w-12 xs:h-10 xs:w-10 mr-3 rounded-full bg-gray-100 bg-cover bg-no-repeat bg-center"
                     style={{
                        backgroundImage: `url(${
                           dataStorePath.user_profile_images + shared_user_image
                        })`
                     }}
                  ></div>
               ) : (
                  <div
                     className="h-12 w-12 sm:h-11 sm:w-11 mr-3 rounded-full bg-50 bg-gray-100 bg-center bg-no-repeat"
                     style={{
                        backgroundImage: `url(${profilePlaceholderIconPerson})`
                     }}
                  ></div>
               )}
            </div>

            <div>
               <h4 className="font-Pulp font-semibold text-xl leading-none tracking-wide cursor-pointer">
                  <Link to={`/user/${user_name}`}>
                     <span className="hover:underline">{display_name}</span>
                  </Link>

                  <span className="font-Pulp font-extralight text-lg tracking-wide text-gray-500 pt-1 pl-2">
                     <span className="text-sm mr-1.5">~</span>
                     {created_at}
                  </span>
               </h4>
               {sharedContentText}
               {postMedia}
               {postGiphy}
            </div>
         </div>
      </div>
   );

   const displaySharedContent =
      share_id && user_name && share_user_name ? (
         sharedContent
      ) : (
         <div className="my-3 mx-14">
            <PostShareNotAvailable />
         </div>
      );

   const profileImage = user_image ? (
      <div className="flex-none mt-1 w-10 h-10 mr-3">
         <div
            className="h-8 w-8 rounded-full bg-gray-100 bg-cover bg-no-repeat bg-center cursor-pointer"
            // style={{
            //    backgroundImage: `url(${addDataStorePath(user_image)}?${time_stamp})`
            // }}
            // onClick={openPostProfileCard}
            style={{
               backgroundImage: `url(${
                  dataStorePath.user_profile_images + user_image
               })`
            }}
         ></div>
      </div>
   ) : (
      <div className="flex-none mt-1 w-10 h-10 mr-3">
         <div
            className="h-8 w-8 rounded-full bg-50 bg-gray-100 bg-center bg-no-repeat cursor-pointer"
            style={{
               backgroundImage: `url(${profilePlaceholderIconPerson})`
            }}
         ></div>
      </div>
   );

   const commentFeed = is_single_content ? (
      <CommentFeedNEW comments={comments} id={id} />
   ) : null;

   const postCommentInput = is_single_content ? (
      <PostCommentInput postId={id} />
   ) : null;

   const hover = is_single_content ? null : 'hover:bg-gray-50';

   const socialWidgets = is_single_content ? null : (
      <div className="flex justify-around py-1.5 px-2">
         <Comment openCommentModal={openCommentModal} commentCount={comment_count} />
         <Share handleShareContent={handleShareContent} shareCount={share_count} />
         <Like handleLikeContent={handleLikeContent} likeCount={like_count} />
         <ContentIcon contentId={id} is_single_content={is_single_content} />
      </div>
   );

   const more = is_single_content ? null : (
      <div
         className="h-8 w-8 items-center hover:bg-mixinlab-teal_15 rounded-full cursor-pointer"
         onClick={openPostMenu}
      >
         <div
            className="bg-80 bg-no-repeat bg-center h-8 w-8"
            style={{
               backgroundImage: `url(${moreIcon})`
            }}
            alt={'More'}
         ></div>
      </div>
   );

   return (
      <Fragment>
         <div
            className={`relative sm:border-r-0 border-r-2 border-b-2 sm:border-l-0 border-l-2 border-gray-200 ${hover} cursor-default`}
         >
            {/* PostMenu component. */}
            <PostMenu
               showModal={state.post_menu}
               handleDelete={handleDeleteContent}
               handleBlockUser={handleBlockUser}
               openPostReport={openPostReport}
               closePostReport={closePostReport}
               is_author_me={is_author_me}
               user_id={user_id}
            />

            <PostMenuMask showModal={state.post_menu} closeModal={closePostMenu} />

            <PostProfileCard
               showModal={state.post_profile_card}
               is_author_me={is_author_me}
               plan={plan}
               is_followed={state.is_followed}
               user_id={share_user_id}
               display_follow_toggle_button={display_follow_toggle_button}
               closePostProfileCardOnFollowUnfollow={
                  closePostProfileCardOnFollowUnfollow
               }
            />

            <PostProfileCardMask
               closePostProfileCard={closePostProfileCard}
               showModal={state.post_profile_card}
               is_author_me={is_author_me}
            />

            <PostComment
               postId={id}
               user_image={user_image}
               display_name={display_name}
               created_at={created_at}
               text={text}
               showCommentModal={state.post_comment_modal}
               closeCommentModal={closeCommentModal}
            />

            <PostReport
               postId={id}
               user_image={user_image}
               display_name={display_name}
               created_at={created_at}
               text={text}
               show_post_report={state.post_report}
               closePostReport={closePostReport}
            />

            <div>
               <div className="px-3 pt-3">
                  <div className="flex">
                     {profileImage}
                     <div className="w-full flex justify-between">
                        {sharedByText}
                        <div className="justify-end w-8">{more}</div>
                     </div>
                  </div>
                  {displaySharedContent}
               </div>

               {socialWidgets}
            </div>
            {postCommentInput}
            {commentFeed}
         </div>
      </Fragment>
   );
};

PostShare.propTypes = {
   getUserContext: PropTypes.func.isRequired,
   clearUserContext: PropTypes.func.isRequired,
   shareContent: PropTypes.func.isRequired,
   deleteContent: PropTypes.func.isRequired,
   getFollowing: PropTypes.func.isRequired,
   getPostId: PropTypes.func.isRequired,
   likeContent: PropTypes.func.isRequired,
   blockUser: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired,
   me: PropTypes.object.isRequired,
   follow: PropTypes.object.isRequired,
   content: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth,
   me: state.me,
   follow: state.follow,
   content: state.content
});

export default connect(mapStateToProps, {
   getUserContext,
   clearUserContext,
   shareContent,
   deleteContent,
   getPostId,
   likeContent,
   getFollowing,
   blockUser
})(PostShare);
