// React.
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Moment.
import moment from 'moment';

// Components.
import PageHeader from './PageHeader.js';
import PageHeaderSingleContent from './PageHeaderSingleContent.js';
import Post from './Post';
import PostShare from './PostShare';
import MixPostSingle from './MixPostSingle';
import MixPostShare from './MixPostShare';
import Spinner from './Spinner';

// Import actions.
import { getSingleContentById } from '../actions/contentSingle';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const SingleContentWrapper = ({ auth, mix, contentSingle, getSingleContentById }) => {
   const [singlePost, setSinglePost] = useState(null);
   const [singlePostComments, setSinglePostComments] = useState(null);
   const [likeCount, setLikeCount] = useState(0);
   const [shareCount, setShareCount] = useState(0);
   const [commentCount, setCommentCount] = useState(0);
   const { content_id } = useParams();

   // Scroll to top.
   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      });
   }, []);

   // Get single content on load.
   useEffect(() => {
      if (contentSingle.single_content) {
         getSingleContentById(content_id);
      }
   }, [content_id]);

   // Assign singleContent.post to local state upon API response.
   useEffect(() => {
      if (
         contentSingle?.single_content?.length !== 0
      ) {
         setSinglePost(contentSingle?.single_content);
         setSinglePostComments(contentSingle?.single_content_comments);
      }
   }, [contentSingle.single_content]);

   // Update comments when new comment length changes.
   useEffect(() => {
      if (
         singlePostComments !== null &&
         singlePostComments !== contentSingle?.single_content_comments
      ) {
         setSinglePostComments(contentSingle?.single_content_comments);
      }
   }, [contentSingle.single_content_comments]);

   // Update Likes, Comments, Shares counts on load.
   useEffect(() => {
      if (contentSingle?.single_content) {
         setLikeCount(singlePost?.like_count_VIRTUAL);
         setCommentCount(singlePost?.comment_count_VIRTUAL);
         setShareCount(singlePost?.share_count_VIRTUAL);
      }
   }, [singlePost?.like_count_VIRTUAL, singlePost?.comment_count_VIRTUAL]);

   // Update Like Count upon action click.
   useEffect(() => {
      if (contentSingle.single_content) {
         setLikeCount(contentSingle.single_content_like_count);
      }
   }, [contentSingle.single_content_like_count]);

   // Update Comment Count upon action clicks.
   useEffect(() => {
      if (contentSingle.single_content) {
         setCommentCount(contentSingle.single_content_comment_count);
      }
   }, [contentSingle.single_content_comment_count]);

   // Update Share Count upon action clicks.
   useEffect(() => {
      if (contentSingle.single_content) {
         setShareCount(contentSingle.single_content_share_count + 1);
      }
   }, [contentSingle.single_content_share_count]);

   /**
    * Detect correct post type and Switch correct Post component.
    * @param type
    * @constructor
    */
   let showPost = (
      <Post
         is_single_content={true}
         key={singlePost?._id}
         id={singlePost?._id}
         user_id={singlePost?.user?._id ?? 'Not Available'}
         plan={singlePost?.user?.licence?.display_name ?? 'Not Available'}
         liked_by={null}
         user_image={singlePost?.user?.user_image ?? 'Not Available'}
         display_name={singlePost?.user?.display_name ?? 'Not Available'}
         user_name={singlePost?.user?.user_name ?? 'Not Available'}
         created_at={moment(
            moment
               .utc(singlePost?.createdAt)
               .utcOffset(new Date().getTimezoneOffset()),
            'YYYY-MM-DD HH:mm:ss'
         ).fromNow()}
         text={singlePost?.text ?? ''}
         media={singlePost?.media}
         comment_count={commentCount}
         share_count={shareCount}
         like_count={likeCount}
         is_author_me={false}
         display_follow_toggle_button={null}
         social_context={null}
         comments={singlePostComments}
      />
   );

   const filterPostShare = () => {
      let content;
      if (!singlePost?.shared_content) {
         content = (
            <div className="w-full mt-3 p-5 font-Pulp text-center text-lg text-gray-600 tracking-wide font-thin bg-gray-100 rounded-md">
               Shared content removed
            </div>
         );
      } else if (singlePost.shared_content.type === 'mix') {
         content = (
            <MixPostShare
               key={singlePost?._id}
               is_single_content={true}
               id={singlePost?.id}
               in_focus={singlePost?.shared_content?.mix?._id === mix?.current_mix_id}
               is_playing={mix.current_mix_is_playing && singlePost?.shared_content?.mix?._id === mix?.current_mix_id}
               cover={singlePost?.shared_content?.mix?.cover ?? null}
               mix_id={singlePost?.shared_content?.mix?._id ?? null}
               mix_title={singlePost?.shared_content?.mix?.title ?? 'Not available'}
               display_name={singlePost?.user?.display_name ?? 'Not available'}
               user_name={singlePost?.user?.user_name ?? null}
               tracks={singlePost?.shared_content?.mix?.tracks ?? null}
               hot_track={singlePost?.shared_content?.mix?.hot_track}
               comment_count={commentCount}
               share_count={shareCount}
               like_count={likeCount}
               user_image={singlePost?.shared_content?.user?.user_image ?? null}
               share_id={singlePost?.shared_content?.id ?? null}
               mix_owner_display_name={
                  singlePost?.shared_content?.user?.display_name ?? 'Not available'
               }
               mix_owner_user_name={
                  singlePost?.shared_content?.user?.display_name ?? 'Not available'
               }
               created_at={moment(singlePost?.createdAt, 'YYYYMMDD').fromNow()}
               is_author_me={false}
               time_stamp={null}
               comments={singlePostComments}
            />
         );
      } else {
         content = (
            <PostShare
               key={singlePost?._id}
               is_single_content={true}
               id={singlePost?._id}
               user_id={singlePost?.user?._id ?? 'User not available'}
               plan={
                  singlePost?.shared_content?.user?.licence?.display_name ??
                  'Licence type not available'
               }
               share_id={singlePost?.shared_content?.id ?? 'Share id not available'}
               share={singlePost?.user?.display_name ?? 'User not available'}
               share_user_name={singlePost?.user?.user_name ?? 'User not available'}
               share_comment={singlePost?.text ?? 'Share comment not available'}
               share_user_id={
                  singlePost?.shared_content?.user?._id ?? 'User not available'
               }
               shared_user_image={
                  singlePost?.shared_content?.user?.user_image ?? 'User not available'
               }
               user_image={singlePost?.user?.user_image ?? 'User image not available'}
               display_name={
                  singlePost?.shared_content?.user?.display_name ??
                  'User not available'
               }
               user_name={
                  singlePost?.shared_content?.user?.user_name ?? 'User not available'
               }
               created_at={moment(singlePost.createdAt, 'YYYYMMDD').fromNow()}
               text={singlePost?.shared_content?.text ?? 'Text content not available'}
               media={singlePost?.shared_content?.media ?? null}
               comment_count={commentCount}
               share_count={shareCount}
               like_count={likeCount}
               is_author_me={false}
               display_follow_toggle_button={null}
               showComments={null}
               comments={singlePostComments}
            />
         );
      }
      return content;
   };

   const showMixPost = (
      <MixPostSingle
         key={singlePost?._id}
         is_single_content={true}
         id={singlePost?.id}
         in_focus={singlePost?.mix?._id === mix?.current_mix_id}
         is_playing={mix.current_mix_is_playing && singlePost?.mix?._id === mix?.current_mix_id}
         cover={singlePost?.mix?.cover ?? null}
         mix_id={singlePost?.mix?._id ?? null}
         mix_title={singlePost?.mix?.title ?? 'Not available'}
         display_name={singlePost?.user?.display_name ?? 'Not available'}
         user_name={singlePost?.user?.user_name ?? null}
         tracks={singlePost?.mix?.tracks ?? null}
         hot_track={singlePost?.mix?.hot_track}
         comment_count={commentCount}
         share_count={shareCount}
         like_count={likeCount}
         user_image={singlePost?.user?.user_image ?? null}
         created_at={moment(singlePost?.createdAt, 'YYYYMMDD').fromNow()}
         is_author_me={false}
         time_stamp={null}
         showComments={null}
         comments={singlePostComments}
      />
   );

   const SelectAndDisplayPostType = (type) => {
      let content;
      switch (type) {
         case 'text':
         case 'image':
            return showPost;
         case 'share':
            return filterPostShare();
         case 'mix':
            return showMixPost;
         case 'video':
            break;
         case 'audio':
            break;
         default:
            return;
      }
      return content;
   };

   /**
    * We must have following properties in singlePost to successfully
    * display any type of post
    */

   let showContent = null;

   if (singlePost?.type && singlePost?.user) {
      showContent = (
         <div>
            <PageHeaderSingleContent page={'Back'} show_back_button={true} />
            {SelectAndDisplayPostType(singlePost.type)}
         </div>
      );
   } else {
      if (contentSingle.loading) {
         showContent = (
            <div>
               <PageHeader page={'Content Loading ... '} />
               <Spinner />
            </div>
         );
      } else {
         showContent = (
            <div>
               <PageHeader page={singlePost?.error ?? 'Error Loading Content '} />
               <div className="w-full mt-3 p-5 font-Pulp text-center text-lg text-gray-600 tracking-wide font-thin bg-gray-100 rounded-md">
                  Content removed
               </div>
            </div>
         );
      }
   }

   return (
      <Fragment>
         <div className="pb-60">{showContent}</div>
      </Fragment>
   );
};

SingleContentWrapper.propTypes = {
   getSingleContentById: PropTypes.func.isRequired,
   mix: PropTypes.object.isRequired,
   contentSingle: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   mix: state.mix,
   contentSingle: state.contentSingle
});

export default connect(mapStateToProps, { getSingleContentById })(
   SingleContentWrapper
);
