// Import React.
import React, { Fragment } from 'react';

let is_last_track = false;

let track_listing_style = 'border-b border-gray-300 pl-2';

if (is_last_track) {
   track_listing_style = 'border-b border-gray-300 pl-2';
}

const UploadTracklistingPlaceholder = () => {
   return (
      <Fragment>
         <div className={track_listing_style}>
            <div className="flex justify-between group">
               <div className="flex pb-3 pt-1.5">
                  <div className="flex mr-4">
                     <div className="relative grid content-center justify-center h-full w-5 mr-4 ml-3 text-center text-sm">
                        1
                     </div>

                     <div className="grid content-center h-full mt-0.5 ml-0.5">
                        <div className="flex-none border-gray-200 border-3 rounded-md w-14 h-14">
                           <div className="grid content-center h-full">
                              <div className="w-8 h-8 mx-auto border-3 border-gray-200 rounded-full"></div>
                           </div>
                        </div>
                     </div>

                     <div className="ml-6 w-full text-gray-400">
                        <div className="text-base font-Pulp cursor-default">
                           Title
                        </div>
                        <div className="text-xs font-semibold">Creators</div>
                        <div className="text-xs font-semibold">Label</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default UploadTracklistingPlaceholder;
