// React.
import React, { Fragment, useEffect } from 'react';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import { getMe } from '../actions/me';
import { getNotificationsCount } from '../actions/notification';

// Import components.
import PageHeader from '../components/PageHeader';
import SettingsWrapper from '../components/SettingsWrapper';
import GaPageView from "../components/GaPageView";

const Settings = ({ auth, getMe, getNotificationsCount }) => {
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         getMe();
         getNotificationsCount();
      }
   }, [auth, getMe]);
   return (
      <Fragment>
         <div className="w-600 sm:w-full h-screen">
             <GaPageView/>
            <PageHeader page={'Settings'} />
            <SettingsWrapper />
         </div>
         <div className="w-72 pt-64 mt-6 lg:hidden ml-14 xl:ml-6">
            <div className="w-32 pl-3"></div>
         </div>
      </Fragment>
   );
};

Settings.propTypes = {
   getMe: PropTypes.func.isRequired,
   getNotificationsCount: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth
});

export default connect(mapStateToProps, { getMe, getNotificationsCount })(Settings);
