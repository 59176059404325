// React.
import React, { Fragment } from 'react';

// Spinner.
import spinner from '../assets/spinner.svg';

const Spinner = () => {
   return (
      <Fragment>
         <div className="grid content-center h-28">
            <div
               className="w-full h-18 bg-no-repeat bg-center"
               style={{
                  backgroundImage: `url(${spinner})`
               }}
            ></div>
         </div>
      </Fragment>
   );
};

export default Spinner;
