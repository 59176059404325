// React.
import React from 'react';

// Placeholder image.
import profilePlaceholder from '../assets/placeholder.svg';

// Import utils.
import dataStorePath from '../configs/dataStorePath';

const ProfileBg = ({ user_bg, time_stamp, openModal }) => {
   return (
      <div>
         {user_bg ? (
            <div
               onClick={openModal}
               className="flex flex-wrap content-center w-full h-52 sm:h-44 bg-gray-100 bg-cover bg-no-repeat bg-center"
               style={{
                  backgroundImage: `url(${
                     dataStorePath.user_profile_bg_images + user_bg
                  })`
               }}
               alt={'Profile'}
            ></div>
         ) : (
            <div onClick={openModal} className="w-full h-52 sm:h-44 bg-gray-100">
               <div className="grid content-center h-full">
                  <label
                     htmlFor="profile-input"
                     className="flex justify-center xs:justify-end w-full xs:w-9/12 h-full pb-1"
                  >
                     <div
                        className="w-14 h-14 sm:w-12 sm:h-12 bg-auto bg-no-repeat bg-center"
                        style={{
                           backgroundImage: `url(${profilePlaceholder})`
                        }}
                        alt={'Image placeholder'}
                     ></div>
                  </label>
               </div>
            </div>
         )}
      </div>
   );
};

export default ProfileBg;
