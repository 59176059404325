// React.
import React, { Fragment, useRef } from 'react';

// Import CSS.
import styles from '../../../css/daterange.module.css';

const MusicDateRange = ({ changeDateRange }) => {
   // Add ref to slider?
   const dateRange = useRef();

   return (
      <Fragment>
         <label htmlFor="dateSlider"></label>
         <input
            name="dateSlider"
            ref={dateRange}
            className={`${styles.dateRange} mx-3 mt-2`}
            type="range"
            defaultValue="1"
            min="1"
            max="3"
            step="1"
            onChange={changeDateRange}
         />
      </Fragment>
   );
};

export default MusicDateRange;
