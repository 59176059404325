import {
   GET_TOP_FIVE_SUCCESS,
   GET_TOP_FIVE_FAIL,
   CREATE_TOP_FIVE_SUCCESS,
   CREATE_TOP_FIVE_FAIL,
   UPDATE_TOP_FIVE_SUCCESS,
   UPDATE_TOP_FIVE_FAIL,
   DELETE_TOP_FIVE_SUCCESS,
   DELETE_TOP_FIVE_FAIL,
   RESET_TOP_FIVE_LOADED,
   LOG_OUT
} from '../actions/types';

const initialState = {
   items: [],
   loaded: false,
   created: false,
   updated: false,
   error: {}
};

const topFive = (state = initialState, action) => {
   switch (action.type) {
      case GET_TOP_FIVE_SUCCESS:
         return {
            ...state,
            items: action.payload,
            loaded: true,
            created: false,
            updated: false,
            error: {}
         };
      case GET_TOP_FIVE_FAIL:
         return {
            ...state,
            items: [],
            loaded: false,
            created: false,
            updated: false,
            error: action.payload
         };
      case RESET_TOP_FIVE_LOADED:
         return {
            ...state,
            loaded: false
         };
      case CREATE_TOP_FIVE_SUCCESS:
         return {
            ...state,
            loaded: false,
            created: true,
            updated: false,
            error: action.payload
         };
      case CREATE_TOP_FIVE_FAIL:
         return {
            ...state,
            loaded: false,
            created: false,
            updated: false,
            error: action.payload
         };
      case UPDATE_TOP_FIVE_SUCCESS:
         return {
            ...state,
            loaded: false,
            updated: true,
            created: false,
            error: action.payload
         };
      case UPDATE_TOP_FIVE_FAIL:
         return {
            ...state,
            loaded: false,
            updated: false,
            created: false,
            error: action.payload
         };
      case DELETE_TOP_FIVE_SUCCESS:
         return {
            ...state,
            loaded: false,
            deleted: true,
            created: false,
            error: action.payload
         };
      case DELETE_TOP_FIVE_FAIL:
         return {
            ...state,
            loaded: false,
            deleted: false,
            created: false,
            error: action.payload
         };
      case LOG_OUT:
         return {
            ...state,
            items: [],
            loaded: false,
            created: false,
            updated: false,
            error: {}
         };
      default:
         return state;
   }
};

export default topFive;
