// React.
import React, { Fragment, useEffect } from 'react';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import { getMe } from '../actions/me';
import { getNotificationsCount } from '../actions/notification';

// Import components
import UserFeed from '../components/UserFeed';
import TopFiveFeed from '../components/TopFiveFeed';
import GaPageView from '../components/GaPageView';

const User = ({ auth, getMe, getNotificationsCount }) => {
   const scrollToTopSlowly = () => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      });
   };

   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         getMe();
         getNotificationsCount();
         scrollToTopSlowly();
      }
   }, [auth]);

   return (
      <Fragment>
         <GaPageView/>
         <div className="w-600 sm:w-full h-screen pb-60">
            <UserFeed />
         </div>
         <div className="w-72 pt-72 mt-6 lg:hidden ml-14 xl:ml-6">
            <TopFiveFeed />
         </div>
      </Fragment>
   );
};

User.propTypes = {
   getMe: PropTypes.func.isRequired,
   getNotificationsCount: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth
});

export default connect(mapStateToProps, { getMe, getNotificationsCount })(User);
