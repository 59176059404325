// Import React.
import React, { Fragment } from 'react';

const PostShareNotAvailable = () => {
   return (
      <Fragment>
         <div className="w-full p-5 font-Pulp text-center text-lg text-gray-600 tracking-wide font-thin bg-gray-100 rounded-md">
            Content removed
         </div>
      </Fragment>
   );
};

export default PostShareNotAvailable;
