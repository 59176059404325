//Import React.
import React, { Fragment, useState, useEffect } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';

// Redux.
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import { getUser } from '../actions/user';
import { checkIfUserIsFollowed } from '../actions/follow';
import { followUnfollow } from '../actions/follow';
import { resetFollowUnfollowDone } from '../actions/follow';
import { resetMeLoaded } from '../actions/me';

// Import components.
import ProfileImage from './ProfileImage';
import ProfileBg from './ProfileBg';
import ProfileSubNav from './ProfileSubNav';
import ProfileModal from './ProfileModal';
import Spinner from './Spinner';
import ProfileLicenceButton from './ProfileLicenceButton';

// Import icons.
import calendarIcon from '../assets/calendar.svg';
import locationIcon from '../assets/location.svg';
import webIcon from '../assets/web.svg';
import UpdateModal from './UpdateModal';

const Profile = ({
   me,
   auth,
   user,
   follow,
   content,
   getUser,
   resetMeLoaded,
   checkIfUserIsFollowed,
   followUnfollow,
   resetFollowUnfollowDone,
   posts,
   mixes,
   bio,
   showFollowing,
   showFollowers
}) => {
   // Route match.
   let is_user_page = useRouteMatch({
      path: '/user/:user',
      strict: true,
      sensitive: true
   });

   // State to control open/close of ProfileModal.js.
   const [state, setState] = useState({
      open: false,
      close: false,
      is_update_modal_open: false,
      tool_tip: false
   });

   const [delayHandler, setDelayHandler] = useState(null);

   const openModal = () => {
      setState({ ...state, open: true, close: false });
   };

   const openUpdateModal = () => {
      setState({ ...state, is_update_modal_open: true });
   };

   const closeUpdateModal = () => {
      setState({ ...state, is_update_modal_open: false });
   };

   const closeModalAndUpdate = () => {
      setState({ ...state, open: false, close: true });
   };
   const closeModal = () => {
      setState({ ...state, open: false, close: false });
   };

   // Params.
   const { user_name } = useParams();

   // Get whichever user is passed from params.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         getUser(user_name);
         resetMeLoaded();
      }
   }, [getUser, resetMeLoaded, auth, user_name]);

   // Reset after profile update.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated && me.loaded) {
         getUser(user_name);
         resetMeLoaded();
      }
   }, [getUser, resetMeLoaded, auth, user_name, me.loaded]);

   // Close Update Modal on content.status_update

   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         if (content.status_update === 'success') {
            closeUpdateModal();
         }
      }
   }, [auth, content.status_update]);

   // Re-render on follow/unfollow.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated && follow.follow_unfollow_done) {
         getUser(user_name);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [auth, getUser, follow.follow_unfollow_done]);

   // Checks if I follow user in user reducer and determine whether to show Edit button.
   useEffect(() => {
      // Null check to see if user is following at least one other user.
      if (user.id) {
         if (!auth.loading && auth.isAuthenticated && me.id !== user.id) {
            checkIfUserIsFollowed(user.id);
         }
      }
   }, [auth, me.id, user.id, checkIfUserIsFollowed]);

   // Checks if follow.follow_unfollow_done has changes to true, then rerenders the button.
   useEffect(() => {
      // Null check to see if user is following at least one other user.
      if (user.id) {
         if (!auth.loading && auth.isAuthenticated && follow.follow_unfollow_done) {
            checkIfUserIsFollowed(user.id);
         }
      }
   }, [auth, user.id, checkIfUserIsFollowed, follow.follow_unfollow_done]);

   // Reset follow_unfollow_done.
   useEffect(() => {
      // Null check to see if user is following at least one other user.
      if (user.id) {
         if (!auth.loading && auth.isAuthenticated && follow.follow_unfollow_done) {
            resetFollowUnfollowDone();
         }
      }
   }, [
      auth,
      user.id,
      checkIfUserIsFollowed,
      follow.follow_unfollow_done,
      resetFollowUnfollowDone
   ]);

   // This hook closes ProfileModal on 'save'.
   useEffect(() => {
      if ((!auth.loading && auth.isAuthenticated, me.loaded)) {
         resetMeLoaded();
         setState({
            ...state,
            open: false,
            close: false
         });
      }
      // eslint-disable-next-line
   }, [auth, me.loaded]);

   // Convert joined date to readable format.
   const makeDateReadable = () => {
      const date = new Date(user.date);
      const month = date.toLocaleString('default', { month: 'long' });
      const year = date.getUTCFullYear();

      return (
         <div>
            Joined <span className="font-medium">{`${month} ${year}`}</span>
         </div>
      );
   };
   const parseDate = makeDateReadable();

   // Simplify URL for display.
   const displayUrl = () => {
      if (user.web) {
         const displayWeb = user.web.replace('http://', '');
         return displayWeb;
      } else {
         return null;
      }
   };
   displayUrl();

   const scrollToTopSlowly = () => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      });
   };

   // Unfollow action.
   const handleClick1 = () => {
      followUnfollow(user.id);
   };

   // Show Following action.
   const handleClick2 = () => {
      scrollToTopSlowly();
      showFollowing();
   };

   // Show Followers action.
   const handleClick3 = () => {
      scrollToTopSlowly();
      showFollowers();
   };

   const handleMouseEnter = () => {
      setDelayHandler(
         setTimeout(() => {
            setState({ tool_tip: true });
         }, 1000)
      );
   };

   const handleMouseLeave = () => {
      clearTimeout(delayHandler);
      setState({
         ...state,
         tool_tip: false
      });
   };

   // Conditionally render profile info.
   const short_bio = !user.short_bio ? null : (
      <h5 className="font-normal text-base leading-tight mt-2">{user.short_bio}</h5>
   );

   const joined = user.joined ? null : (
      <div className="flex flex-row mt-2">
         <img className="h-5 w-5 mr-1" src={calendarIcon} alt={'Calendar'} />
         <h5 className="font-normal text-base leading-tight">{parseDate}</h5>
      </div>
   );

   const location = !user.location ? null : (
      <div className="flex flex-row mt-2">
         <img className="h-5 w-5 mr-1" src={locationIcon} alt={'Location'} />
         <h5 className="font-normal text-base leading-tight">
            Location <span className="font-medium">{user.location}</span>
         </h5>
      </div>
   );

   const web = !user.web_full ? null : (
      <div className="flex flex-row mt-2">
         <img className="h-5 w-5 mr-1" src={webIcon} alt={'Web'} />
         <h5 className="font-normal text-base leading-tight">
            <a
               href={user.web_full}
               target="blank"
               className="font-medium hover:text-mixinlab-teal break-all"
            >
               {user.web_display}
            </a>
         </h5>
      </div>
   );

   const AtRefUser =
      me.user_name !== user.user_name ? (
         <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="relative"
         >
            {state.tool_tip ? (
               <div>
                  <div className="absolute -top-16 -left-14 bg-white border-2 border-mixinlab-teal p-1 pb-2 leading-tight text-center text-mixinlab-teal font-semibold w-72 rounded rounded-md shadow-modal z-10 text-sm">
                     Creates a post on your feed and sends a notification to {user.user_name}
                  </div>
               </div>
            ) : null}
            <button
               className="h-9 px-2 mr-3 font-Pulp font-normal text-lg tracking-wide text-mixinlab-teal rounded border-2 border-mixinlab-teal hover:bg-mixinlab-teal hover:text-white cursor-pointer focus:outline-none"
               onClick={openUpdateModal}
            >
               @{user.user_name}
            </button>
         </div>
      ) : null;

   const FollowUnfollow = (
      <div>
         {me.user_name === user.user_name ? (
            <button
               className="h-9 px-2 font-Pulp font-normal text-lg tracking-wide text-mixinlab-teal rounded border-2 border-mixinlab-teal hover:bg-mixinlab-teal hover:text-white cursor-pointer focus:outline-none"
               onClick={openModal}
            >
               Edit
            </button>
         ) : follow.is_followed ? (
            <button
               onClick={handleClick1}
               className="h-9 px-2 font-Pulp font-normal text-lg tracking-wide text-mixinlab-teal rounded border-2 border-mixinlab-teal hover:bg-mixinlab-teal hover:text-white cursor-pointer focus:outline-none"
            >
               Unfollow
            </button>
         ) : (
            <button
               onClick={handleClick1}
               className="h-9 px-2 font-Pulp font-normal text-lg tracking-wide text-mixinlab-teal rounded border-2 border-mixinlab-teal hover:bg-mixinlab-teal hover:text-white cursor-pointer focus:outline-none"
            >
               Follow
            </button>
         )}
      </div>
   );

   return (
      <Fragment>
         {user.loading || is_user_page.params.user !== user.user_name ? (
            <Spinner />
         ) : (
            <div>
               <ProfileModal
                  profileState={state.open}
                  update={closeModalAndUpdate}
                  close={closeModal}
               />

               <UpdateModal
                  is_update_modal_open={state.is_update_modal_open}
                  doCloseUpdateModal={closeUpdateModal}
                  userName={is_user_page.params.user}
               />

               <div className="w-full border-r-2 border-b-2 border-l-2 sm:border-l-0 sm:border-r-0 border-gray-200 relative">
                  <ProfileImage
                     user_image={user.user_image}
                     time_stamp={me.time_stamp}
                  />
                  <ProfileBg user_bg={user.user_bg} time_stamp={me.time_stamp} />

                  <div className="flex flex-row pr-5">
                     <div className="flex flex-row justify-end sm:justify-start w-full pt-4 pl-5 pb-3">
                        <ProfileLicenceButton licence={user.licence} />
                        {AtRefUser}
                        {FollowUnfollow}
                     </div>
                  </div>

                  <div className="mx-5">
                     <h1 className="font-Pulp font-semibold text-3xl">
                        {user.display_name}
                     </h1>
                     <h2 className="font-Pulp font-thin text-mixinlab-teal tracking-wide text-2xl">
                        @{user.user_name}
                     </h2>

                     {short_bio}
                     {joined}
                     {location}
                     {web}

                     <div className="flex flex-row mt-2">
                        <h5
                           className="font-semibold text-base leading-tight mr-2 cursor-pointer hover:underline"
                           onClick={handleClick2}
                        >
                           Following
                        </h5>
                        <h5 className="font-bold text-base text-mixinlab-teal leading-tight mr-5">
                           {user.following_length}
                        </h5>
                        <h5
                           className="font-semibold text-base leading-tight mr-2 cursor-pointer hover:underline"
                           onClick={handleClick3}
                        >
                           Followers
                        </h5>
                        <h5 className="font-bold text-mixinlab-teal text-base leading-tight">
                           {user.followers_length}
                        </h5>
                     </div>

                     {/* Sub-Nav. */}
                     <ProfileSubNav posts={posts} mixes={mixes} bio={bio} />
                  </div>
               </div>
            </div>
         )}
      </Fragment>
   );
};

Profile.propTypes = {
   getUser: PropTypes.func.isRequired,
   resetMeLoaded: PropTypes.func.isRequired,
   checkIfUserIsFollowed: PropTypes.func.isRequired,
   followUnfollow: PropTypes.func.isRequired,
   resetFollowUnfollowDone: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired,
   me: PropTypes.object.isRequired,
   user: PropTypes.object.isRequired,
   follow: PropTypes.object.isRequired,
   content: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth,
   me: state.me,
   user: state.user,
   social: state.social,
   follow: state.follow,
   content: state.content
});

export default connect(mapStateToProps, {
   getUser,
   resetMeLoaded,
   checkIfUserIsFollowed,
   followUnfollow,
   resetFollowUnfollowDone
})(Profile);
