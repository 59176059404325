// React.
import React, { Fragment } from 'react';

const MusicListTrackResults = ({
   results,
   selectTrack,
   track_dropdown,
   closeResultsDropdown
}) => {
   const handleClick = (e) => {
      const id = e.target.dataset.id;
      const title = e.target.dataset.title;
      const concatTitle = `${title}`;
      selectTrack({ id, concatTitle });
   };

   let unique_tracks = [...new Set(results.map((item) => item.title))];

   let ListSelect;
   let modalMask;

   if (unique_tracks.length > 0 && track_dropdown) {
      ListSelect = (
         <div className="absolute rounded-md text-sm lg:text-xl z-50 px-2 py-1 w-60 lg:w-9/12 lg-1.5 mt-2 ml-2 shadow-modalLight h-auto bg-white cursor-pointer">
            <div className="rounded-sm font-semibold">
               {unique_tracks.map((track, index) => {
                  return (
                     <div
                        key={index}
                        onClick={handleClick}
                        className="hover:bg-gray-100 rounded md:text-xl px-2 md:px-3 pt-1 pb-1.5 my-1"
                        data-title={track}
                     >
                        <span data-title={track}>{track}</span>
                     </div>
                  );
               })}
            </div>
         </div>
      );
   }

   if (track_dropdown) {
      modalMask = (
         <div
            className="fixed top-0 left-0 w-screen h-screen z-40"
            onClick={closeResultsDropdown}
         ></div>
      );
   }

   return (
      <Fragment>
         {ListSelect}
         {modalMask}
      </Fragment>
   );
};

export default MusicListTrackResults;
