import React, { Fragment } from 'react';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import { blockUser } from '../actions/user';
import { followUnfollow } from '../actions/follow';

// Import icons.
import followIcon from '../assets/follow.svg';
import unfollowIcon from '../assets/unfollow.svg';
import blockIcon from '../assets/block.svg';
import reportIcon from '../assets/report.svg';
import deleteIcon from '../assets/delete.svg';

const PostMenu = ({
   showModal,
   handleDelete,
   followUnfollow,
   openPostReport,
   is_author_me,
   user_id,
   blockUser,
   display_follow_toggle_button
}) => {

   // Unfollow user.
   const handleClick1 = () => {
      followUnfollow(user_id);
   };

   // Block user.
   const handleClick2 = () => {
      blockUser(user_id);
   };

   // Post report.
   const handleClick3 = (e) => {
      openPostReport(e);
   };

   // Instantiate components.
   const modalMe = (
      <div>
         <div
            className="absolute top-3 right-3 z-30 h-auto w-36 p-2 bg-white rounded-lg shadow-modal cursor-pointer"
            onClick={handleDelete}
         >
            <div className="flex flex-row w-full pb-1 pl-2 h-10 items-center hover:bg-gray-200 rounded">
               <div
                  className="bg-cover bg-no-repeat bg-center h-7 w-7"
                  style={{
                     backgroundImage: `url(${deleteIcon})`
                  }}
                  alt={'delete'}
               ></div>
               <div className="h-7 pl-2 font-normal text-md">
                  <span className="align-middle">Delete</span>
               </div>
            </div>
         </div>
      </div>
   );

   const modalUser = (
      <div>
         <div className="absolute cursor-pointer z-30 top-3 right-3 h-auto w-36 p-2 bg-white rounded-lg shadow-lg">
            <div>
               {display_follow_toggle_button ? (
                  <div
                     onClick={handleClick1}
                     className="flex flex-row w-full pb-1 pl-2 h-10 items-center hover:bg-gray-200 rounded"
                  >
                     <div
                        className="bg-cover bg-no-repeat bg-center h-7 w-7"
                        style={{
                           backgroundImage: `url(${followIcon})`
                        }}
                        alt={'logout'}
                     ></div>
                     <div className="h-7 pl-2 font-normal text-md">
                        <span className="align-middle">Follow</span>
                     </div>
                  </div>
               ) : (
                  <div
                     onClick={handleClick1}
                     className="flex flex-row w-full pb-1 pl-2 h-10 items-center hover:bg-gray-200 rounded"
                  >
                     <div
                        className="bg-cover bg-no-repeat bg-center h-7 w-7"
                        style={{
                           backgroundImage: `url(${unfollowIcon})`
                        }}
                        alt={'logout'}
                     ></div>
                     <div className="h-7 pl-2 font-normal text-md">
                        <span className="align-middle">Unfollow</span>
                     </div>
                  </div>
               )}

               <div
                  onClick={handleClick2}
                  className="flex flex-row w-full pb-1 pl-2 h-10 items-center hover:bg-gray-200 rounded"
               >
                  <div
                     className="bg-cover bg-no-repeat bg-center h-7 w-7"
                     style={{
                        backgroundImage: `url(${blockIcon})`
                     }}
                     alt={'logout'}
                  ></div>
                  <div className="h-7 pl-2 font-normal text-md">
                     <span className="align-middle">Block</span>
                  </div>
               </div>

               <div
                  onClick={handleClick3}
                  className="flex flex-row w-full pb-1 pl-2 h-10 items-center hover:bg-gray-200 rounded"
               >
                  <div
                     className="bg-cover bg-no-repeat bg-center h-7 w-7"
                     style={{
                        backgroundImage: `url(${reportIcon})`
                     }}
                     alt={'logout'}
                  ></div>
                  <div className="h-7 pl-2 font-normal text-md">
                     <span className="align-middle">Report</span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );

   // Instantiate modal component.
   let modal;

   if (showModal && is_author_me) {
      modal = modalMe;
   } else if (showModal && !is_author_me) {
      modal = modalUser;
   }

   return <Fragment>{modal}</Fragment>;
};

PostMenu.propTypes = {
   blockUser: PropTypes.func.isRequired,
   followUnfollow: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { blockUser, followUnfollow })(PostMenu);
