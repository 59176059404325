// Import React.
import React, { Fragment } from 'react';

// Import icons.
import deleteIcon from '../assets/delete.svg';

// Import styles.
import styles from '../css/styles.module.css';

const UploadTrackListing = ({
   index,
   cover,
   title,
   creators,
   label,
   handleDelete,
   handleAdd,
   moveUp,
   moveDown,
   tracklist
}) => {
   const coverComponent = cover ? (
      <div
         className="flex-none mt-0.5 rounded-sm w-14 h-14 bg-no-repeat bg-center bg-cover"
         style={{
            backgroundImage: `url(${cover})`
         }}
      ></div>
   ) : (
      <div className="flex-none border-gray-200 border-3 rounded-md w-14 h-14">
         <div className="grid content-center h-full">
            <div className="w-8 h-8 mx-auto border-3 border-gray-200 rounded-full"></div>
         </div>
      </div>
   );

   let is_last_track = false;

   let track_listing_style = 'border-b border-gray-300 pl-2';

   if (is_last_track) {
      track_listing_style = 'border-b border-gray-300 pl-2';
   }

   const trackIndex = (
      <div className="relative grid content-center justify-center h-full w-5 mr-4 ml-1 text-center text-sm">
         {index === 0 ? null : (
            <div
               onClick={() => moveUp(index)}
               className="absolute hidden group-hover:block pt-0.5 -top-2 w-5 h-5 border-2 border-white z-20 rounded-full bg-gray-400 hover:bg-gray-800 cursor-pointer shadow-modal"
            >
               <div className="flex justify-center">
                  <div className={styles.upArrowTracks}></div>
               </div>
            </div>
         )}

         {index + 1}

         {tracklist.length - 1 === index ? null : (
            <div
               onClick={() => moveDown(index)}
               className="absolute flex justify-center hidden group-hover:block pt-1 top-11 w-5 h-5 border-2 border-white z-20 rounded-full bg-gray-400 hover:bg-gray-800 cursor-pointer shadow-modal"
            >
               <div className="flex justify-center">
                  <div className={styles.downArrowTracks}></div>
               </div>
            </div>
         )}
      </div>
   );

   return (
      <Fragment>
         <div className={track_listing_style}>
            <div className="flex justify-between group w-full">
               <div className="flex mr-4 py-2">
                  {trackIndex}

                  <div className="flex">
                     {coverComponent}
                     <div className="ml-3 w-full">
                        <div className="text-base font-semibold font-Pulp cursor-default">
                           {title}
                        </div>
                        <div className="text-xs font-semibold">
                           Creators:{' '}
                           <span className="font-normal cursor-default">
                              {creators}
                           </span>
                        </div>
                        <div className="text-xs font-semibold">
                           Label:{' '}
                           <span className="font-normal cursor-default">{label}</span>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="flex mr-3">
                  <div className="grid content-center">
                     <button
                        onClick={() => handleAdd(index)}
                        className="px-2 py-0.5 flex-none w-10 mt-0.5 font-Pulp font-normal text-sm hover:text-white hover:bg-red-400 cursor-pointer bg-gray-400 text-white focus:outline-none rounded"
                     >
                        Hot
                     </button>
                  </div>
                  <div className="grid content-center">
                     <div className="grid content-center justify-center w-10 h-10 rounded-full hover:bg-gray-100 cursor-pointer flex-none mr-1 ml-2">
                        <div
                           onClick={() => handleDelete(index)}
                           className="bg-cover bg-no-repeat bg-center h-8 w-8 cursor-pointer"
                           style={{
                              backgroundImage: `url(${deleteIcon})`
                           }}
                           alt={'delete'}
                        ></div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default UploadTrackListing;
