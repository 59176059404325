import axios from 'axios';

import {
   GET_NOTIFICATIONS_SUCCESS,
   GET_NOTIFICATIONS_FAIL,
   GET_MORE_NOTIFICATIONS_SUCCESS,
   GET_MORE_NOTIFICATIONS_FAIL,
   GET_NEW_NOTIFICATIONS_SUCCESS,
   GET_NEW_NOTIFICATIONS_FAIL,
   MARK_AS_NOTIFIED_SUCCESS,
   MARK_AS_NOTIFIED_FAIL,
   RESET_NOTIFICATION_LOADING,
   MARK_ALL_AS_NOTIFIED_SUCCESS,
   MARK_ALL_AS_NOTIFIED_FAIL
} from './types';

// Get notifications.
export const getNotifications = () => async (dispatch) => {
   try {
      const res = await axios.get(
         `api/notification?page=${1}&limit=20&last_notification_pull=2022-01-18T11:33:42.797Z`
      );

      const results = res.data.result;
      const data = res.data.data.notifications;
      const new_page_number = 2;

      dispatch({
         type: GET_NOTIFICATIONS_SUCCESS,
         payload: data,
         results: results,
         page: new_page_number
      });
   } catch (err) {
      dispatch({
         type: GET_NOTIFICATIONS_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// Get more notifications.
export const getMoreNotifications = (page) => async (dispatch) => {
   try {
      const res = await axios.get(
         `api/notification?page=${page}&limit=20&last_notification_pull=2022-01-18T11:33:42.797Z`
      );

      const results = res.data.result;
      const data = res.data.data.notifications;
      const new_page_number = page + 1;

      dispatch({
         type: GET_MORE_NOTIFICATIONS_SUCCESS,
         payload: data,
         results: results,
         page: new_page_number
      });
   } catch (err) {
      dispatch({
         type: GET_MORE_NOTIFICATIONS_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// Get new notifications where is_notified === false
export const getNotificationsCount = () => async (dispatch) => {
   try {
      const res = await axios.get(`/api/notification/notifications-count`);
      dispatch({
         type: GET_NEW_NOTIFICATIONS_SUCCESS,
         payload: res.data
      });
   } catch (err) {
      dispatch({
         type: GET_NEW_NOTIFICATIONS_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// markAsNotified
export const markAsNotified = (id) => async (dispatch) => {
   try {
      const res = await axios.get(`api/notification/mark-as-notified/${id}`);
      dispatch({
         type: MARK_AS_NOTIFIED_SUCCESS,
         payload: res.data.data
      });
   } catch (err) {
      dispatch({
         type: MARK_AS_NOTIFIED_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// markAsNotified
export const markAllAsNotified = () => async (dispatch) => {
   try {
      const res = await axios.get(`api/notification/mark-all-as-notified`);
      dispatch({
         type: MARK_ALL_AS_NOTIFIED_SUCCESS,
         payload: res.data.data
      });
   } catch (err) {
      dispatch({
         type: MARK_ALL_AS_NOTIFIED_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// markAsNotified
export const resetNotificationLoading = () => async (dispatch) => {
   dispatch({
      type: RESET_NOTIFICATION_LOADING
   });
};
