import React, { Fragment } from 'react';

const PostContextModalMask = ({ showModal, closePostProfileCard }) => {
   let modalMask;

   // Instantiate component.
   if (showModal) {
      return (modalMask = (
         <div
            className="fixed top-0 left-0 w-screen h-screen z-40 cursor-pointer"
            onClick={closePostProfileCard}
         ></div>
      ));
   }

   return <Fragment>{modalMask}</Fragment>;
};

export default PostContextModalMask;
