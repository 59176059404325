// Import React.
import React, { Fragment, useState } from 'react';
import styles from '../css/styles.module.css';

// Import components.

const MixPlayerButtonLoad = ({ handleClickLoad }) => {
   const [isHovering, setIsHovered] = useState(false);
   const onMouseEnter = () => setIsHovered(true);
   const onMouseLeave = () => setIsHovered(false);

   const playButton = (
      <div
         className="flex items-center group"
         onMouseEnter={onMouseEnter}
         onMouseLeave={onMouseLeave}
      >
         {isHovering ? (
            <div className="group">
               <div
                  onClick={handleClickLoad}
                  className="h-12 w-12 grid content-center justify-center cursor-pointer rounded-full border group-hover:border-mixinlab-indigo_mid group-hover:bg-mixinlab-indigo_mid"
               >
                  <div className="pl-0.5 w-full">
                     <div className={styles.playArrowWhiteMix}></div>
                  </div>
               </div>
            </div>
         ) : (
            <div
               onClick={handleClickLoad}
               className="h-12 w-12 grid content-center justify-center cursor-pointer rounded-full border border-gray-800"
            >
               <div className="pl-0.5 w-full">
                  <div className={styles.playArrowMix}></div>
               </div>
            </div>
         )}
      </div>
   );

   return <Fragment>{playButton}</Fragment>;
};

export default MixPlayerButtonLoad;
