import React, { Fragment } from 'react';

const MixMenuMask = ({ showModal, closeModal }) => {
   const modalMask = showModal ? (
      <div
         className="fixed top-0 left-0 w-screen h-screen z-20"
         onClick={closeModal}
      ></div>
   ) : null;

   return <Fragment>{modalMask}</Fragment>;
};

export default MixMenuMask;
