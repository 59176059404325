// React.
import React, { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import {
   getNotifications,
   getMoreNotifications,
   resetNotificationLoading,
   getNotificationsCount,
   markAsNotified
} from '../actions/notification';

import { getMe } from '../actions/me';

// Import components
import Notification from '../components/Notification';
import Spinner from '../components/Spinner';
import moment from 'moment';
import Marque from '../assets/mixinlab-marque-end-feed.svg';

const NotificationsFeed = ({
   auth,
   notification,
   getNotificationsCount,
   resetNotificationLoading,
   getNotifications,
   getMoreNotifications,
   markAsNotified,
   getMe
}) => {
   // Instantiate usehistory.
   const history = useHistory();

   // Get notifications on load
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         getMe();
         getNotificationsCount();
      }
   }, [auth]);

   // Get new notifications on page load.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         console.log('fire 1')
         getNotifications(1);
      }
   }, [auth]);

   // Reload notifications when user visits a notification link or clicks 'Mark all as notified'.
   // Response returns the number of records that have been changed to is_notified: false.
   useEffect(() => {
      if (
         !auth.loading &&
         auth.isAuthenticated &&
         notification.mark_all_notified?.n > 0
         ) {
         console.log('fire 2')
         getNotifications(1);
      }
   }, [auth, notification.mark_all_notified]);


   // Get new notifications when 'Load more notifications' is clicked.
   const handleLoadMoreNotifications = () => {
      resetNotificationLoading();
      getMoreNotifications(notification.page_num);
   };

   const gotoSinglePage = (content_id, notification_id) => {
      markAsNotified(notification_id);
      history.push(`/content/${content_id}`);
   };

   // Compile Notifications.
   let notificationComps = notification.notifications.map((item, index) => {
      return (
         <Fragment key={index}>
            <Notification
               key={item?._id}
               id={item?._id}
               is_notified={item?.is_notified}
               content={item?.content}
               shared_content={item?.shared_content}
               mix_details={item?.mix_details}
               mix_user_details={item?.mix_user_details}
               shared_mix_details={item?.shared_mix_details}
               shared_mix_user_details={item?.shared_mix_user_details}
               event={item?.event}
               actioned_user={item?.actioned_user}
               notify_user={item?.notify_user}
               comment={item?.comment}
               created_at={moment(item?.createdAt).fromNow()}
               gotoSinglePage={gotoSinglePage}
            />
         </Fragment>
      );
   });

   const spinner = notification.loading ? <Spinner /> : null;

   // Display correct feed end / Load more posts
   const Load =
      notification.notifications_num_results < 20 ? (
         <div className="w-full h-32 flex justify-center pt-12">
            <div
               className="no-repeat bg-center w-18 h-18 bg-contain"
               style={{
                  backgroundImage: `url(${Marque})`
               }}
            ></div>
         </div>
      ) : (
         <div
            onClick={handleLoadMoreNotifications}
            className="w-full h-32 pt-12 font-Pulp text-center text-mixinlab-teal text-xl"
         >
            <span className="cursor-pointer">Load more notifications</span>
         </div>
      );

   return (
      <Fragment>
         <div className="w-full pb-60">
            <div>{notificationComps}</div>
            {spinner}
            {Load}
         </div>
      </Fragment>
   );
};

NotificationsFeed.propTypes = {
   auth: PropTypes.object.isRequired,
   notification: PropTypes.object.isRequired,
   getNotifications: PropTypes.func.isRequired,
   resetNotificationLoading: PropTypes.func.isRequired,
   getMoreNotifications: PropTypes.func.isRequired,
   getNotificationsCount: PropTypes.func.isRequired,
   markAsNotified: PropTypes.func.isRequired,
   getMe: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
   notification: state.notification,
   auth: state.auth
});

export default connect(mapStateToProps, {
   getNotifications,
   getMoreNotifications,
   getNotificationsCount,
   resetNotificationLoading,
   markAsNotified,
   getMe
})(NotificationsFeed);
