// React and PropTypes.
import React, { Fragment } from 'react';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import components.
import CloseButton from './CloseButton';
import Giphy from './Giphy';

// Component.
const GiphyModal = ({ modalState, close, handleSelectGiphy, hideGiphyModalMask }) => {
   // Clear local state on modal close.
   const handleClose = () => {
      close();
   };

   // Instantiate components.
   let modal;
   let modalMask;

   if (modalState && !hideGiphyModalMask) {
      modal = (
         <div className="fixed top-2 left-1/2 transform -translate-x-1/2 translate-y-6 md:translate-y-0 md:top-0 h-600 md:h-screen md:w-full w-600 z-50 p-6 pt-3 bg-white rounded-lg md:rounded-none shadow-modal">
            <div className="mx-auto items-center w-full">
               <div className="grid grid-cols-2 gap-4">
                  <div className="flex flex-wrap content-end">
                     <h1 className="font-Pulp font-bold text-3xl ml-1">
                        Insert Giphy
                     </h1>
                  </div>
                  <div className="flex justify-end">
                     <CloseButton close={handleClose} />
                  </div>
               </div>
            </div>
            <div className="flex flex-col mt-4 sm:mt-6">
               <Giphy
                  handleClose={handleClose}
                  handleSelectGiphy={handleSelectGiphy}
               />
            </div>
         </div>
      );

      modalMask = (
         <div
            className="fixed top-0 left-0 w-screen h-screen z-40 bg-black opacity-30 cursor-pointer"
            onClick={handleClose}
         ></div>
      );
   }

   if (modalState && hideGiphyModalMask) {
      modal = (
         <div className="fixed -top-6 left-1/2 transform -translate-x-1/2 translate-y-6 md:translate-y-0 md:top-0 h-600 md:h-screen md:w-full w-600 z-70 p-6 pt-3 bg-white rounded-lg md:rounded-none shadow-modal">
            <div className="mx-auto items-center w-full">
               <div className="grid grid-cols-2 gap-4">
                  <div className="flex flex-wrap content-end">
                     <h1 className="font-Pulp font-bold text-3xl ml-1">
                        Insert Giphy
                     </h1>
                  </div>
                  <div className="flex justify-end">
                     <CloseButton close={handleClose} />
                  </div>
               </div>
            </div>
            <div className="flex flex-col mt-4 sm:mt-6">
               <Giphy
                  handleClose={handleClose}
                  handleSelectGiphy={handleSelectGiphy}
               />
            </div>
         </div>
      );
   }

   return (
      <Fragment>
         {modal}
         {modalMask}
      </Fragment>
   );
};

GiphyModal.propTypes = {
   auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth
});

export default connect(mapStateToProps, {})(GiphyModal);
