import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// { alert } is an array of alerts.
const Alert = ({ alert }) => {
   const allAlerts =
      alert !== null &&
      alert.length > 0 &&
      alert.map((alert) => (
         <div
            key={alert.id}
            className="bg-red-100 border border-red-400 text-red-700 mb-5 px-4 py-3 rounded relative"
            role="alert"
         >
            {alert.msg}
         </div>
      ));
   return <div>{allAlerts}</div>;
};

Alert.propTypes = {
   alert: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
   alert: state.alert
});

export default connect(mapStateToProps)(Alert);
