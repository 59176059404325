function validateUrl(url) {
   let pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
         '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
         '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
         '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
         '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
         '(\\#[-a-z\\d_]*)?$',
      'i'
   ); // fragment locator

   const is_valid_url = !!pattern.test(url);
   let full;

   if (is_valid_url) {
      !(url.indexOf('http://') === 0 || url.indexOf('https://') === 0)
         ? (full = 'http://' + url)
         : (full = url);
   }

   try {
      const url = new URL(full);

      return {
         is_valid: true,
         display: url.hostname,
         full: full
      };
   } catch (err) {
      console.error(err);
      return {
         is_valid: false
      };
   }
}

export default validateUrl;
