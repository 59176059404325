// React and PropTypes.
import React from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Components
import GaPageView from "../components/GaPageView";

// Auth is global state or Redux store.
const EmailVerify = () => {
   return (
      <div className="container mx-auto">
         <GaPageView/>
         <div className="grid grid-cols-1">
            <div className="h-52 flex flex-wrap content-center">
               <div className="bg-logo-beta bg-no-repeat bg-center w-80 h-full mx-auto"></div>
            </div>
            <div>
               <h1 className="font-Pulp font-bold text-3xl text-center pb-2 px-5 leading-tight">
                  Verify your email address
               </h1>
               <p className="font-Pulp font-thin text-base text-center text-gray-400 mt-5 flex flex-wrap content-end justify-center">
                  Please verify that [EMAIL HOLDER] is your email address
                  <br />
                  and start using Mixinlab straight away. Join our communityand
                  discover new DJs, <br />
                  Creators,and music fans today.
               </p>
            </div>
         </div>
      </div>
   );
};

// This is a checker like TypeScript. SignUp.proptypes refers to this component.
EmailVerify.propTypes = {
   auth: PropTypes.object.isRequired,
   user: PropTypes.object.isRequired
};

// Allows component to access state in store. Sets WHICH state I want to grab from store.
// state.auth is coming from reducers/auth.js
// auth must match the parameter above inside the function call.
const mapStateToProps = (state) => ({
   auth: state.auth,
   user: state.user
});

// Passes the chosen state into the exported component. Connect action to component as an object. Kind of binding.
export default connect(mapStateToProps, {})(EmailVerify);
