import axios from 'axios';
import ReactGA from 'react-ga'


import {
   SIGNUP_SUCCESS,
   SIGNUP_FAIL,
   TOKEN_LOADED_SUCCESS,
   TOKEN_LOADED_FAIL,
   LOGIN_SUCCESS,
   LOGIN_FAIL,
   CHECK_PASSWORD_SUCCESS,
   CHECK_PASSWORD_FAIL,
   LOG_OUT,
   RESET_STATUS
} from './types';

//Import auth token func.
import setAuthToken from '../utils/setAuthToken';

// Load token.
export const loadToken = () => (dispatch) => {
   if (localStorage.token) {
      dispatch({
         type: TOKEN_LOADED_SUCCESS
      });
      setAuthToken(localStorage.token);
   } else {
      dispatch({
         type: TOKEN_LOADED_FAIL
      });
   }
};

// Signup user.
export const signUp =
   ({ licence, display_name, user_name, email, password, dob }) =>
   async (dispatch) => {
      const config = {
         headers: {
            'Content-Type': 'application/json'
         }
      };

      const body = { licence, display_name, user_name, email, password, dob };

      try {
         const res = await axios.post('/api/user/signup', body, config);
         console.log(res)

         dispatch({
            type: SIGNUP_SUCCESS,
            payload: res.data // {token: 'eyJhbGciOiJIUzI1NiI', status: 'success', msg: 'Signup successful'}
         });
      } catch (err) {
         console.log(err)
         dispatch({
            type: SIGNUP_FAIL,
            payload: err.response.data
         });
      }
   };

// Login user.
export const login =
   ({ email, password }) =>
   async (dispatch) => {
      const config = {
         headers: {
            'Content-Type': 'application/json'
         }
      };

      const body = {
         email,
         password
      };

      // The axios call success/fail determines which reducer is used.
      try {
         const res = await axios.post('/api/auth', body, config);
         console.log(res.data)
         dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data // {token: 'wejwb5340534hwktow9ut!£&!£&ef', status: 'success'}
         });
          ReactGA.event({category:'User', action:'Login', label: res.data.username  , value: 1 });
         dispatch(loadToken()); // Checks local storage for token. If (token) sets header with token, fetches user obj. from DB, sets loading: false and isAuthenticate: true
      } catch (err) {
         dispatch({
            type: LOGIN_FAIL,
            payload: err.response.data // {status: 'fail', msg: 'Invalid credentials'}
         });
      }
   };

// Check password.
export const checkPassword =
   ({ password }) =>
   async (dispatch) => {
      const config = {
         headers: {
            'Content-Type': 'application/json'
         }
      };

      const body = {
         password
      };

      try {
         const res = await axios.post('/api/auth/check-password', body, config);

         dispatch({
            type: CHECK_PASSWORD_SUCCESS,
            payload: res.data
         });
      } catch (err) {
         dispatch({
            type: CHECK_PASSWORD_FAIL,
            payload: err.response.data
         });
      }
   };

// Logout / clear profile
export const logout = () => (dispatch) => {
   dispatch({ type: LOG_OUT });
};

// Clear status
export const resetStatus = () => (dispatch) => {
   dispatch({ type: RESET_STATUS });
};
