// React.
import React, { Fragment } from 'react';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import { agreeToTerms } from '../actions/user';

const TermsOfServiceModal = ({
   showModal,
   setShowTermsOfServiceModal,
   agreeToTerms
}) => {
   const handleClick = () => {
      setShowTermsOfServiceModal(false);
      agreeToTerms();
   };
   const modal = (
      <>
         <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto  max-w-3xl">
               {/*content*/}
               <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t ">
                     <h3 className="text-3xl font-semibold">Terms of Service</h3>
                     <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowTermsOfServiceModal(false)}
                     >
                        <span className="text-black">×</span>
                     </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto max-h-500 mx-2 my-2 overflow-scroll overflow-x-hidden">
                     <p className="my-4 text-blueGray-500 text-lg leading-relaxed font-semibold">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse faucibus sapien ut arcu venenatis, ut feugiat ex
                        scelerisque. Praesent at vestibulum diam. Cras libero eros,
                        sollicitudin suscipit ornare nec, lacinia vitae velit. Duis
                        ligula ante, egestas vitae maximus vel, commodo vel enim.
                        Integer a dignissim nulla, quis luctus mauris. Vivamus magna
                     </p>
                     <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                        I always felt like I could do anything. That’s the main thing
                        people are controlled by! Thoughts- their perception of
                        themselves! They're slowed down by their perception of themse
                        lves. If you're taught you can’t do anything, you won’t do
                        anything. I was taught I could do everything.
                     </p>
                     <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                        Nulla, finibus in ligula in, accumsan rutrum tellus. Nulla
                        aliquam leo placerat efficitur aliquet. Sed eros velit,
                        viverra interdum interdum eget, scelerisque id enim. Praesent
                        pharetra sagittis accumsan. Nam id sapien sed nunc bibendum
                        sodales quis in dolor. Integer mauris est, gravida efficitur
                        felis at, accumsan laoreet mi. Suspendisse sagittis rutrum
                        nunc, sed blandit neque sollicitudin convallis. Phasellus sem
                        massa, dignissim ut sem sit amet, interdum euismod sem. Cras
                        id facilisis turpis. In viverra, sem quis auctor consectetur,
                        risus mauris elementum nisi, iaculis dictum nunc ante et diam.
                     </p>
                     <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                        Accumsan rutrum tellus. Nulla aliquam leo placerat efficitur
                        aliquet. Sed eros velit, viverra interdum interdum eget,
                        scelerisque id enim. Praesent pharetra sagittis accumsan. Nam
                        id sapien sed nunc bibendum sodales quis in dolor. Integer
                        mauris est, gravida efficitur felis at.
                     </p>
                  </div>

                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                     <button
                        className="text-center m-3 h-10 px-5 w-auto font-Pulp font-normal text-xl tracking-wide text-mixinlab-teal rounded-lg border-2 border-mixinlab-teal hover:bg-mixinlab-teal hover:text-white cursor-pointer focus:outline-none"
                        type="button"
                        onClick={handleClick}
                     >
                        I agree to Mixinlab's terms and conditions
                     </button>
                  </div>
               </div>
            </div>
         </div>
         <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
   );

   if (showModal) {
      return modal;
   }

   return <Fragment>{modal}</Fragment>;
};

TermsOfServiceModal.propTypes = {
   agreeToTerms: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { agreeToTerms })(TermsOfServiceModal);
