import axios from 'axios';

import {
   FOLLOW_UNFOLLOW_SUCCESS,
   FOLLOW_UNFOLLOW_FAIL,
   IS_FOLLOWED_SUCCESS,
   IS_FOLLOWED_FAIL,
   RESET_FOLLOW_UNFOLLOW_DONE,
   USER_FOLLOWER_LOADED_SUCCESS,
   USER_FOLLOWER_LOADED_FAIL,
   USER_FOLLOWING_LOADED_SUCCESS,
   USER_FOLLOWING_LOADED_FAIL
} from './types';

export const followUnfollow = (id) => async (dispatch) => {
   try {
      const res = await axios.get(`/api/user/follow/${id}`);

      dispatch({
         type: FOLLOW_UNFOLLOW_SUCCESS,
         payload: res.data
      });
   } catch (err) {
      dispatch({
         type: FOLLOW_UNFOLLOW_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// Get current user's profile.
export const getFollowing = (user_name) => async (dispatch) => {
   try {
      const res = await axios.get(`/api/user/get-following/${user_name}`);

      const orgFollowing = () => {
         let arr = [];
         res.data[0].following.map((following) => {
            return arr.push(following.user);
         });
         return arr;
      };

      dispatch({
         type: USER_FOLLOWING_LOADED_SUCCESS,
         payload: orgFollowing()
      });
   } catch (err) {
      dispatch({
         type: USER_FOLLOWING_LOADED_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// Get current user's profile.
export const getFollowers = (user_name) => async (dispatch) => {
   try {
      const res = await axios.get(`/api/user/get-followers/${user_name}`);

      const orgFollowers = () => {
         let arr = [];
         res.data[0].followers.map((follower) => {
            return arr.push(follower.user); // added return to get rid of error in console
         });
         return arr;
      };

      dispatch({
         type: USER_FOLLOWER_LOADED_SUCCESS,
         payload: orgFollowers()
      });
   } catch (err) {
      dispatch({
         type: USER_FOLLOWER_LOADED_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// check if Me is following a particular user.
export const checkIfUserIsFollowed = (id) => async (dispatch) => {
   try {
      const res = await axios.get(`/api/user/is-user-followed/${id}`);

      dispatch({
         type: IS_FOLLOWED_SUCCESS,
         payload: res.data
      });
   } catch (err) {
      dispatch({
         type: IS_FOLLOWED_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// Reset the me.loaded flag after user is loaded via useEffect hook, (Profile.js)
export const resetFollowUnfollowDone = () => async (dispatch) => {
   dispatch({
      type: RESET_FOLLOW_UNFOLLOW_DONE
   });
};
