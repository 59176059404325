import axios from 'axios';
import { SEND_REPORT_SUCCESS, SEND_REPORT_FAIL } from './types';

// Load token.
export const sendReport = (report_obj) => async (dispatch) => {
   try {
      const toStr = JSON.stringify(report_obj);

      const res = await axios.post('/api/report', toStr, {
         headers: {
            // Overwrite Axios's automatically set Content-Type
            'Content-Type': 'application/json'
         }
      });

      dispatch({
         type: SEND_REPORT_SUCCESS,
         payload: res.data
      });
      dispatch({
         type: SEND_REPORT_FAIL
      });
   } catch (err) {
      dispatch({
         type: SEND_REPORT_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};
