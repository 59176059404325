// Import React.
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import utils.
import dataStorePath from '../configs/dataStorePath';

// Import actions.
import { deleteComment } from '../actions/contentSingle';

// Import icons.
import profilePlaceholderIconPerson from '../assets/placeholder_person.svg';
import deleteIcon from '../assets/delete.svg';

const CommentFeedUnit = ({
   me,
   id,
   user_id,
   comment_id,
   user_image,
   display_name,
   user_name,
   created_at,
   text,
   deleteComment
}) => {
   const handleDelete = () => {
      deleteComment(id, comment_id);
   };

   const deleteBin =
      me?.id === user_id ? (
         <div className="grid content-center h-auto w-10">
            <div className="w-10 flex justify-center">
               <div className="group group-hover:bg-gray-200 rounded-full cursor-pointer w-8 h-8 flex justify-center">
                  <div className="grid content-center">
                     <div
                        onClick={handleDelete}
                        className="bg-cover bg-no-repeat bg-center h-6 w-6 cursor-pointer opacity-30 group-hover:opacity-100"
                        style={{
                           backgroundImage: `url(${deleteIcon})`
                        }}
                        alt={'delete'}
                     ></div>
                  </div>
               </div>
            </div>
         </div>
      ) : null;

   const profileImage = user_image ? (
      <div className="pt-1">
         <div
            className="h-12 w-12 mr-5 sm:mr-2 rounded-full bg-cover bg-no-repeat bg-center cursor-pointer"
            style={{
               backgroundImage: `url(${
                  dataStorePath.user_profile_images + user_image
               })`
            }}
         ></div>
      </div>
   ) : (
      <div className="pt-2">
         <div
            className="h-12 w-12 mr-5 sm:mr-2 rounded-full bg-50 bg-gray-100 bg-center bg-no-repeat cursor-pointer"
            style={{
               backgroundImage: `url(${profilePlaceholderIconPerson})`
            }}
         ></div>
      </div>
   );

   return (
      <Fragment>
         <div className="py-2.5 px-3 border-gray-200 border-t-2 cursor-default bg-white">
            <div className="flex">
               {/* Profile image */}
               {profileImage}

               <div className="w-full">
                  <div className="flex pb-0.5">
                     <div className="flex flex-wrap w-full">
                        {/* Display name */}
                        <h4 className="font-Pulp font-normal text-xl leading-none tracking-wide pt-1.5 pr-2">
                           <Link to={`/user/${user_name}`}>
                              <span className="hover:underline cursor-pointer">
                                 {display_name}
                              </span>
                           </Link>
                           {/* Created at */}
                           <span className="font-Pulp font-extralight text-xl tracking-wide text-gray-500 pt-1 pl-2">
                              {created_at}
                           </span>
                        </h4>
                     </div>
                  </div>

                  {/* Text */}
                  <div className="mr-10">
                     <p className="font-normal w-full font-base leading-snug pb-1 sm:mt-1">
                        <span className="break-words">{text}</span>
                     </p>
                  </div>
               </div>

               {deleteBin}
            </div>
         </div>
      </Fragment>
   );
};

CommentFeedUnit.propTypes = {
   me: PropTypes.object.isRequired,
   deleteComment: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
   me: state.me
});

export default connect(mapStateToProps, { deleteComment })(CommentFeedUnit);
