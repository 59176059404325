// Import React.
import React, { Fragment, useState } from 'react';
import styles from '../css/styles.module.css';

const AudioPlayerButtonPause = ({ handlePause }) => {
   const [isHovering, setIsHovered] = useState(false);
   const onMouseEnter = () => setIsHovered(true);
   const onMouseLeave = () => setIsHovered(false);

   const playButton = (
      <div
         className="flex flex-row items-center group"
         onMouseEnter={onMouseEnter}
         onMouseLeave={onMouseLeave}
      >
         {isHovering ? (
            <div className="group">
               <div
                  onClick={handlePause}
                  className="h-10 w-10 lg:h-14 lg:w-14  grid content-center justify-center  cursor-pointer rounded-full border group-hover:border-mixinlab-indigo_mid group-hover:bg-mixinlab-indigo_mid"
               >
                  <div className="flex">
                     <div className={`${styles.pauseWhite} mr-1 lg:mr-1.5`}></div>
                     <div className={styles.pauseWhite}></div>
                  </div>
               </div>
            </div>
         ) : (
            <div
               onClick={handlePause}
               className="h-10 w-10 lg:h-14 lg:w-14 grid content-center justify-center cursor-pointer rounded-full border border-black"
            >
               <div className="flex">
                  <div className={`${styles.pause} mr-1 lg:mr-1.5`}></div>
                  <div className={styles.pause}></div>
               </div>
            </div>
         )}
      </div>
   );

   return <Fragment>{playButton}</Fragment>;
};

export default AudioPlayerButtonPause;
