// Import React.
import React, { Fragment } from 'react';

const UploadTrackListingApi = ({
   index,
   cover,
   title,
   creators,
   label,
   track_data,
   setTracklist,
   is_last_track,
   clearTracks,
   clearInput
}) => {
   const coverComponent = cover ? (
      <div
         className="flex-none rounded-sm w-14 h-14 mt-1 bg-no-repeat bg-center bg-cover"
         style={{
            backgroundImage: `url(${cover})`
         }}
      ></div>
   ) : (
      <div className="flex-none border-gray-200 border-3 rounded-md w-14 h-14 mt-1">
         <div className="grid content-center h-full">
            <div className="w-8 h-8 mx-auto border-3 border-gray-200 rounded-full"></div>
         </div>
      </div>
   );

   const parseApi = (track_data) => {
      // check if it has deezer data
      let trackObj = {};
      // if (track_data.external_metadata.deezer) {
      //    trackObj['name'] = track_data.external_metadata.deezer[0].name;
      //    trackObj['isrc'] = track_data.external_metadata.deezer[0].isrc;
      //    trackObj['deezer_track_id'] = track_data.external_metadata.deezer[0].id;
      //    trackObj['creators'] = track_data.external_metadata.deezer[0].artists;
      //    trackObj['label'] = track_data.external_metadata.deezer[0].label;
      //    trackObj['length'] = track_data.external_metadata.deezer[0].duration_ms;
      //    trackObj['release'] = track_data.external_metadata.deezer[0].album;
      // } else {
      trackObj['name'] = track_data.name;
      trackObj['isrc'] = track_data.isrc;
      trackObj['deezer_track_id'] = null;
      trackObj['creators'] = track_data.artists;
      trackObj['label'] = track_data.label;
      trackObj['length'] = track_data.duration_ms;
      trackObj['release'] = track_data.album;
      trackObj.is_hot_track = false; // Adding this property to all selected tracks from the API calls.
      // }
      return trackObj;
   };

   const handleAdd = () => {
      setTracklist((oldArray) => [...oldArray, parseApi(track_data)]);
      clearTracks();
      clearInput();
   };

   let track_listing_style = 'border-white border-b pl-2 pr-2 mx-2';

   if (is_last_track) {
      track_listing_style = 'border-b-0 pl-2 pr-2 mx-2';
   }

   return (
      <Fragment>
         <div className={track_listing_style}>
            <div className="flex justify-between mt-1.5 mb-2.5">
               <div className="flex pr-8">
                  <div className="flex-none h-full w-3 mr-3 text-center text-sm pt-5">
                     {index + 1}
                  </div>
                  {coverComponent}
                  <div className="ml-5">
                     <div className="text-base font-semibold font-Pulp">{title}</div>
                     <div className="text-xs font-semibold">
                        Creators: <span className="font-normal">{creators}</span>
                     </div>
                     <div className="text-xs font-semibold">
                        Label: <span className="font-normal">{label}</span>
                     </div>
                  </div>
               </div>
               <div className="grid content-center">
                  <button
                     onClick={handleAdd}
                     className="px-2 py-0.5 font-Pulp font-normal text-base hover:text-white cursor-pointer border-2 border-gray-400 hover:bg-gray-400 text-gray-400 focus:outline-none rounded"
                  >
                     Add
                  </button>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default UploadTrackListingApi;
