import {
   TOGGLE_DISCOVER_MODAL,
   SET_DISCOVER_SUCCESS,
   CLEAR_DISCOVER_SUCCESS
} from './types';

export const toggleDiscoverModal = () => async (dispatch) => {
   dispatch({
      type: TOGGLE_DISCOVER_MODAL,
   });
};

// Set search params in Redux to display them at the top of HomeFeedSearch.
export const setDiscover = (query) => async (dispatch) => {
   dispatch({
      type: SET_DISCOVER_SUCCESS,
      payload: query
   });
};

export const clearDiscover = () => async (dispatch) => {
   dispatch({
      type: CLEAR_DISCOVER_SUCCESS
   });
};
