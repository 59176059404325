// Alert.
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Auth.
export const LOADING_AUTH_REQUEST = 'LOADING_AUTH_REQUEST';
export const TOKEN_LOADED_SUCCESS = 'TOKEN_LOADED_SUCCESS';
export const TOKEN_LOADED_FAIL = 'TOKEN_LOADED_FAIL';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const CHECK_PASSWORD_SUCCESS = 'CHECK_PASSWORD_SUCCESS';
export const CHECK_PASSWORD_FAIL = 'CHECK_PASSWORD_FAIL';
export const RESET_STATUS = 'RESET_STATUS';

// Content.
export const HOME_CONTENT_LOADED_SUCCESS = 'HOME_CONTENT_LOADED_SUCCESS';
export const HOME_CONTENT_LOADED_FAIL = 'HOME_CONTENT_LOADED_FAIL';
export const HOME_CONTENT_SCROLL_LOADED_SUCCESS =
   'HOME_CONTENT_SCROLL_LOADED_SUCCESS';
export const HOME_CONTENT_LATEST_POST_LOADED_SUCCESS =
   'HOME_CONTENT_LATEST_POST_LOADED_SUCCESS';
export const HOME_CONTENT_LATEST_POST_LOADED_FAIL =
   'HOME_CONTENT_LATEST_POST_LOADED_FAIL';
export const HOME_CONTENT_SCROLL_LOADED_FAIL = 'HOME_CONTENT_SCROLL_LOADED_FAIL';
export const HOME_CONTENT_FINISHED = 'HOME_CONTENT_FINISHED';
export const USER_CONTENT_LOADED_SUCCESS = 'USER_CONTENT_LOADED_SUCCESS';
export const USER_CONTENT_LOADED_FAIL = 'USER_CONTENT_LOADED_FAIL';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const UPDATE_FAIL = 'UPDATE_FAIL';
export const UPDATE_RESET = 'UPDATE_RESET';
export const GET_UPDATE_LINK_DETAILS_SUCCESS = 'GET_UPDATE_LINK_DETAILS_SUCCESS';
export const GET_UPDATE_LINK_DETAILS_FAIL = 'GET_UPDATE_LINK_DETAILS_FAIL';
export const SET_UPDATE_LINK_DETAILS_TO_NULL = 'SET_UPDATE_LINK_DETAILS_TO_NULL';
export const SHARE_CONTENT_SUCCESS = 'SHARE_CONTENT_SUCCESS';
export const SHARE_CONTENT_FAIL = 'SHARE_CONTENT_FAIL';
export const UPDATE_LIKE_ARRAY_SUCCESS_CONTENT = 'UPDATE_LIKE_ARRAY_SUCCESS_CONTENT';
export const UPDATE_LIKE_ARRAY_FAIL_CONTENT = 'UPDATE_LIKE_ARRAY_FAIL_CONTENT';
export const DELETE_CONTENT_SUCCESS = 'DELETE_CONTENT_SUCCESS';
export const DELETE_CONTENT_FAIL = 'DELETE_CONTENT_FAIL';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAIL = 'DELETE_COMMENT_FAIL';
export const GET_ID_SUCCESS = 'GET_ID_SUCCESS';
export const SHOW_SEARCH_FEED = 'SHOW_SEARCH_FEED';
export const HIDE_SEARCH_FEED = 'HIDE_SEARCH_FEED';
export const RESET_SHOW_SEARCH_FEED = 'RESET_SHOW_SEARCH_FEED';
export const GET_SINGLE_CONTENT_SUCCESS = 'GET_SINGLE_CONTENT_SUCCESS';
export const GET_SINGLE_CONTENT_FAIL = 'GET_SINGLE_CONTENT_FAIL';
export const RESET_STATUS_SHARE_CONTENT = 'RESET_STATUS_SHARE_CONTENT';
export const RESET_IS_DELETED = 'RESET_IS_DELETED';

// Content Single.
export const COMMENT_SUCCESS = 'COMMENT_SUCCESS';
export const COMMENT_FAIL = 'COMMENT_FAIL';
export const CLEAR_SINGLE_CONTENT = 'CLEAR_SINGLE_CONTENT';
export const UPDATE_LIKE_ARRAY_SUCCESS_CONTENT_SINGLE =
   'UPDATE_LIKE_ARRAY_SUCCESS_CONTENT_SINGLE';
export const UPDATE_LIKE_ARRAY_FAIL_CONTENT_SINGLE =
   'UPDATE_LIKE_ARRAY_FAIL_CONTENT_SINGLE';

// Discover.
export const TOGGLE_DISCOVER_MODAL = 'TOGGLE_DISCOVER_MODAL';
export const SET_DISCOVER_SUCCESS = 'SET_DISCOVER_SUCCESS';
export const CLEAR_DISCOVER_SUCCESS = 'CLEAR_DISCOVER_SUCCESS';

// Mix.
export const LOAD_TIMELINE_MIX_SUCCESS = 'LOAD_TIMELINE_MIX_SUCCESS';
export const LOAD_TIMELINE_MIX_FAIL = 'LOAD_TIMELINE_MIX_FAIL';
export const CLEAR_TIMELINE_MIX = 'CLEAR_TIMELINE_MIX';
export const GET_CURRENT_MIX_SUCCESS = 'GET_CURRENT_MIX_SUCCESS';
export const GET_CURRENT_MIX_FAIL = 'GET_CURRENT_MIX_FAIL';
export const SAVE_CURRENT_MIX_POSITION_SUCCESS = 'SAVE_CURRENT_MIX_POSITION_SUCCESS';
export const SAVE_CURRENT_MIX_POSITION_FAIL = 'SAVE_CURRENT_MIX_POSITION_FAIL';
export const UPLOAD_MIX_SUCCESS = 'UPLOAD_MIX_SUCCESS';
export const UPLOAD_MIX_FAIL = 'UPLOAD_MIX_FAIL';
export const FIND_TRACKS_SUCCESS = 'FIND_TRACKS_SUCCESS';
export const FIND_TRACKS_FAIL = 'FIND_TRACKS_FAIL';
export const CLEAR_TRACKS = 'CLEAR_TRACKS';
export const UPLOAD_TRACKLIST_SUCCESS = 'UPLOAD_TRACKLIST_SUCCESS';
export const UPLOAD_TRACKLIST_FAIL = 'UPLOAD_TRACKLIST_FAIL';
export const UPLOAD_PROGRESS = 'UPLOAD PROGRESS';
export const UPLOAD_CANCEL_SUCCESS = 'UPLOAD_CANCEL_SUCCESS';
export const DISCOVER_MUSIC_RESULTS_LOADED_SUCCESS = 'DISCOVER_MUSIC_RESULTS_SUCCESS';
export const DISCOVER_MUSIC_RESULTS_LOADED_FAIL =
   'DISCOVER_MUSIC_RESULTS_LOADED_FAIL';
export const DISCOVER_MUSIC_RESULTS_RESET = 'DISCOVER_MUSIC_RESULTS_RESET';
export const AUDIO_PLAY = 'AUDIO_PLAY';
export const AUDIO_PAUSE = 'AUDIO_PAUSE';
export const SET_API_IS_LOADING = 'SET_API_IS_LOADING';
export const SET_UPLOAD_TRACKLIST_IS_LOADING = 'SET_UPLOAD_TRACKLIST_IS_LOADING';

// Follow.
export const FOLLOW_UNFOLLOW_SUCCESS = 'FOLLOW_UNFOLLOW_SUCCESS';
export const FOLLOW_UNFOLLOW_FAIL = 'FOLLOW_UNFOLLOW_FAIL';
export const IS_FOLLOWED_SUCCESS = 'IS_FOLLOWED_SUCCESS';
export const IS_FOLLOWED_FAIL = 'IS_FOLLOWED_FAIL';
export const USER_FOLLOWER_LOADED_SUCCESS = 'USER_FOLLOWER_LOADED_SUCCESS';
export const USER_FOLLOWER_LOADED_FAIL = 'USER_FOLLOWER_LOADED_FAIL';
export const RESET_FOLLOW_UNFOLLOW_DONE = 'RESET_FOLLOW_UNFOLLOW_DONE';
export const USER_FOLLOWING_LOADED_SUCCESS = 'USER_FOLLOWING_LOADED_SUCCESS';
export const USER_FOLLOWING_LOADED_FAIL = 'USER_FOLLOWING_LOADED_FAIL';
export const CLEAR_USER_FOLLOW_STATUS = 'CLEAR_USER_FOLLOW_STATUS';

// Me.
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS';
export const GET_ME_FAIL = 'GET_ME_FAIL';
export const RESET_ME_LOADED = 'RESET_ME_LOADED';
export const ME_LOADED_SUCCESS = 'ME_LOADED_SUCCESS';
export const ME_LOADED_FAIL = 'ME_LOADED_FAIL';
export const UPDATE_ME_SUCCESS = 'UPDATE_ME_SUCCESS';
export const UPDATE_ME_FAIL = 'UPDATE_ME_FAIL';
export const DEACTIVATE_USER_SUCCESS = 'DEACTIVATE_USER_SUCCESS';
export const DEACTIVATE_USER_FAIL = 'DEACTIVATE_USER_FAIL';
export const REACTIVATE_USER_SUCCESS = 'REACTIVATE_USER_SUCCESS';
export const REACTIVATE_USER_FAIL = 'REACTIVATE_USER_FAIL';
export const USER_PASSWORD_UPDATE_SUCCESS = 'USER_PASSWORD_UPDATE_SUCCESS';
export const USER_PASSWORD_UPDATE_FAIL = 'USER_PASSWORD_UPDATE_FAIL';

// Notifications.
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';
export const GET_MORE_NOTIFICATIONS_SUCCESS = 'GET_MORE_NOTIFICATIONS_SUCCESS';
export const GET_MORE_NOTIFICATIONS_FAIL = 'GET_MORE_NOTIFICATIONS_FAIL';
export const GET_NEW_NOTIFICATIONS_SUCCESS = 'GET_NEW_NOTIFICATIONS_SUCCESS';
export const GET_NEW_NOTIFICATIONS_FAIL = 'GET_NEW_NOTIFICATIONS_FAIL';
export const MARK_AS_NOTIFIED_SUCCESS = 'GET_NEW_NOTIFIED_SUCCESS';
export const MARK_AS_NOTIFIED_FAIL = 'GET_NEW_NOTIFIED_FAIL';
export const RESET_NOTIFICATION_LOADING = 'RESET_NOTIFICATION_LOADING';
export const MARK_ALL_AS_NOTIFIED_SUCCESS = 'MARK_ALL_AS_NOTIFIED_SUCCESS';
export const MARK_ALL_AS_NOTIFIED_FAIL = 'MARK_ALL_AS_NOTIFIED_FAIL';

// Report.
export const SEND_REPORT_SUCCESS = 'SEND_REPORT_SUCCESS';
export const SEND_REPORT_FAIL = 'SEND_REPORT_FAIL';

// Top Five.
export const GET_TOP_FIVE_SUCCESS = 'GET_TOP_FIVE_SUCCESS';
export const GET_TOP_FIVE_FAIL = 'GET_TOP_FIVE_FAIL';
export const CREATE_TOP_FIVE_SUCCESS = 'CREATE_TOP_FIVE_SUCCESS';
export const CREATE_TOP_FIVE_FAIL = 'CREATE_TOP_FIVE_FAIL';
export const UPDATE_TOP_FIVE_SUCCESS = 'UPDATE_TOP_FIVE_SUCCESS';
export const UPDATE_TOP_FIVE_FAIL = 'UPDATE_TOP_FIVE_FAIL';
export const DELETE_TOP_FIVE_SUCCESS = 'DELETE_TOP_FIVE_SUCCESS';
export const DELETE_TOP_FIVE_FAIL = 'DELETE_TOP_FIVE_FAIL';
export const RESET_TOP_FIVE_LOADED = 'RESET_TOP_FIVE_LOADED';

// User.
export const AGREE_TO_TERMS = 'AGREE_TO_TERMS';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const GET_SUGGESTED_USER_SUCCESS = 'GET_SUGGESTED_USER_SUCCESS';
export const GET_SUGGESTED_USER_FAIL = 'GET_SUGGESTED_USER_FAIL';
export const GET_USER_BY_USERNAME_SUCCESS = 'GET_USER_BY_USERNAME_SUCCESS';
export const GET_USER_BY_USERNAME_FAIL = 'GET_USER_BY_USERNAME_FAIL';
export const ADD_SUGGESTED_USER_SUCCESS = 'ADD_SUGGESTED_USER_SUCCESS';
export const ADD_SUGGESTED_USER_FAIL = 'ADD_SUGGESTED_USER_FAIL';
export const PLAN_CHOSEN = 'PLAN_CHOSEN';
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS';
export const BLOCK_USER_FAIL = 'BLOCK_USER_FAIL';
export const SEND_EMAIL_PASSWORD_SUCCESS = 'SEND_EMAIL_PASSWORD_SUCCESS';
export const SEND_EMAIL_PASSWORD_FAIL = 'SEND_EMAIL_PASSWORD_FAIL';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_USER_REFERENCE_SUGGESTIONS_SUCCESS = 'RESET_USER_REFERENCE_SUGGESTIONS_SUCCESS';
export const RESET_USER_REFERENCE_SUGGESTIONS_FAIL = 'RESET_USER_REFERENCE_SUGGESTIONS_FAIL';



// User Context.
export const USER_CONTEXT_LOADED_SUCCESS = 'USER_CONTEXT_LOADED_SUCCESS';
export const USER_CONTEXT_LOADED_FAIL = 'USER_CONTEXT_LOADED_FAIL';
export const USER_CONTEXT_CLEARED = 'USER_CONTEXT_CLEARED';

// Logout.
export const LOG_OUT = 'LOG_OUT';
