//Import React.
import React, { useState } from 'react';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { updateTopFive } from '../actions/topFive';

import CloseButton from './CloseButton';
import AlertFrontEnd from './AlertFrontEnd';

const TopFiveEditModal = ({
   updateTopFive,
   closeEdit,
   openEditModal,
   id,
   title,
   topFives
}) => {
   const [state, setState] = useState({
      id: id,
      title: title,
      top_five_1: topFives[0].title,
      top_five_2: topFives[1].title,
      top_five_3: topFives[2].title,
      top_five_4: topFives[3].title,
      top_five_5: topFives[4].title,
      input_field_count: 0,
      error_msg: ''
   });

   // Update field.
   const handleChange = (e) => {
      const { name, value } = e.target;

      setState({
         ...state,
         input_field_count: value.length
      });

      setState((prevState) => {
         return {
            ...prevState,
            [name]: value
         };
      });
   };

   // Count character length.
   const updateLength = (e) => {
      const { value } = e.target;
      setState({
         ...state,
         input_field_count: value.length
      });
   };

   const onSubmit = async (e) => {
      e.preventDefault();
      const top_five = {
         id: id,
         sort_order: 1,
         title: state.title,
         items: [
            { title: state.top_five_1 },
            { title: state.top_five_2 },
            { title: state.top_five_3 },
            { title: state.top_five_4 },
            { title: state.top_five_5 }
         ]
      };
      updateTopFive(top_five);
      closeEdit();
   };

   const modalComponent = (
      <div className="fixed group p-8 pr-14 pt-3 top-2 left-1/2 transform -translate-x-1/2 translate-y-6 shadow-modal w-600 z-40 rounded-lg bg-white">
         <div className="flex py-3 w-full mb-1">
            <div className="mr-3 w-7"></div>
            <h1 className="font-Pulp font-bold w-500 text-2xl mt-0.5">Edit top 5</h1>
            <div className="flex w-12 justify-end">
               <CloseButton close={closeEdit} />
            </div>
         </div>

         <div>
            <div className="ml-8">
               {state.error_msg !== '' ? (
                  <AlertFrontEnd msg={state.error_msg} />
               ) : null}
            </div>

            <form onSubmit={onSubmit}>
               {/* Title */}
               <div className="flex flex-row w-full">
                  <div className="mr-3 w-6"></div>
                  <div className="flex items-center w-full border border-gray-300 rounded-md mb-4 py-2 px-1">
                     <label htmlFor="title"></label>
                     <input
                        className="text-xl font-semibold w-full text-black focus:outline-none pl-2"
                        type="text"
                        name="title"
                        placeholder="Title"
                        onChange={handleChange}
                        onFocus={updateLength}
                        value={state.title}
                     />
                  </div>
               </div>

               <div className="flex flex-row w-full">
                  <div className="text-lg font-semibold mr-3 mt-2 w-6">1</div>
                  <div className="flex items-center w-full border border-gray-300 rounded-md mb-4 py-2 px-1">
                     <label htmlFor="top_five_1"></label>
                     <input
                        className="text-xl font-semibold text-black focus:outline-none pl-2"
                        type="text"
                        name="top_five_1"
                        placeholder=""
                        onChange={handleChange}
                        onFocus={updateLength}
                        value={state.top_five_1}
                     />
                  </div>
               </div>

               <div className="flex flex-row w-full">
                  <div className="text-lg font-semibold mr-3 mt-2 w-6">2</div>
                  <div className="flex items-center w-full border border-gray-300 rounded-md mb-4 py-2 px-1">
                     <label htmlFor="top_five_2"></label>
                     <input
                        className="text-xl font-semibold text-black focus:outline-none pl-2"
                        type="text"
                        name="top_five_2"
                        placeholder=""
                        onChange={handleChange}
                        onFocus={updateLength}
                        value={state.top_five_2}
                     />
                  </div>
               </div>

               <div className="flex flex-row w-full">
                  <div className="text-lg font-semibold mr-3 mt-2 w-6">3</div>
                  <div className="flex items-center w-full border border-gray-300 rounded-md mb-4 py-2 px-1">
                     <label htmlFor="top_five_3"></label>
                     <input
                        className="text-xl font-semibold text-black focus:outline-none pl-2"
                        type="text"
                        name="top_five_3"
                        placeholder=""
                        onChange={handleChange}
                        onFocus={updateLength}
                        value={state.top_five_3}
                     />
                  </div>
               </div>

               <div className="flex flex-row w-full">
                  <div className="text-lg font-semibold mr-3 mt-2 w-6">4</div>
                  <div className="flex items-center w-full border border-gray-300 rounded-md mb-4 py-2 px-1">
                     <label htmlFor="top_five_4"></label>
                     <input
                        className="text-xl font-semibold text-black focus:outline-none pl-2"
                        type="text"
                        name="top_five_4"
                        placeholder=""
                        onChange={handleChange}
                        onFocus={updateLength}
                        value={state.top_five_4}
                     />
                  </div>
               </div>

               <div className="flex flex-row w-full">
                  <div className="text-lg font-semibold mr-3 mt-2 w-6">5</div>
                  <div className="flex items-center w-full border border-gray-300 rounded-md mb-4 py-2 px-1">
                     <label htmlFor="top_five_5"></label>
                     <input
                        className="text-xl font-semibold text-black focus:outline-none pl-2"
                        type="text"
                        name="top_five_5"
                        placeholder=""
                        onChange={handleChange}
                        onFocus={updateLength}
                        value={state.top_five_5}
                     />
                  </div>
               </div>

               <div>
                  <h6 className="text-sm text-gray-500 pl-9 mt-1 mb-2">
                     <span
                        className={
                           state.input_field_count > 30
                              ? 'text-mixinlab-cherry font-bold'
                              : 'text-mixinlab-teal'
                        }
                     >
                        {state.input_field_count}
                     </span>
                     <span className="text-black"> / 30 chars.</span>
                  </h6>
               </div>

               <div className="mt-3 text-center">
                  <button
                     type="submit"
                     className="h-11 w-24 font-Pulp font-normal text-xl tracking-wide text-white rounded-lg border-2 bg-mixinlab-teal border-mixinlab-teal hover:bg-white hover:text-mixinlab-teal cursor-pointer focus:outline-none"
                  >
                     Save
                  </button>
               </div>
            </form>
         </div>
      </div>
   );

   const modalMaskComponent = (
      <div
         onClick={closeEdit}
         className="fixed top-0 left-0 w-screen h-screen z-20 bg-black opacity-30 cursor-pointer"
      ></div>
   );

   // Instantiate components.
   let modal;
   let modalMask;

   // Conditionally display modal.
   if (openEditModal) {
      modal = modalComponent;
      modalMask = modalMaskComponent;
   } else {
      modal = null;
      modalMask = null;
   }

   return (
      <div>
         {modal}
         {modalMask}
      </div>
   );
};

TopFiveEditModal.propTypes = {
   updateTopFive: PropTypes.func.isRequired,
   topFive: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({ topFive: state.topFive });

export default connect(mapStateToProps, { updateTopFive })(TopFiveEditModal);
