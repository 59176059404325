// React.
import React from 'react';

const PageHeaderNotifications = ({
   page,
   is_bottom_border_on = true,
   handleMarkAllAsNotifiedClick
}) => {
   let header_border_class = 'border-b-2';

   if (!is_bottom_border_on) header_border_class = 'border-b-0';

   return (
      <div
         className={`${header_border_class} flex justify-between h-12 pl-1 pt-1 pb-11 font-Pulp tracking-wide border-gray-200`}
      >
         <h1 className="mt-0.5 xs:mt-2 ml-3 text-2xl xs:text-lg font-semibold">
            {page}
         </h1>
         <h2
            onClick={handleMarkAllAsNotifiedClick}
            className="mt-1 xs:mt-2 mr-3 text-gray-300 hover:text-gray-500 text-xl xs:text-lg font-thin cursor-pointer"
         >
            Mark all as notified
         </h2>
      </div>
   );
};

export default PageHeaderNotifications;
