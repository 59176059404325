import React from 'react';

const TopFive = ({ title, items }) => {
   const TopFive = (
      <div className="border-2 bg-white border-gray-200 p-3 pb-1.5 mb-7 w-full">
         <h5 className="font-semibold text-lg text-black w-full pl-2.5">{title}</h5>
         <div className="p-3">
            {items.map((item, index) => {
               return (
                  <div className="flex" key={index}>
                     <h6 className="pt-1 text-sm font-semibold mr-2">{index + 1}</h6>
                     <h6 className="text-sm px-2 py-1" key={index}>
                        {item.title}
                     </h6>
                  </div>
               );
            })}
         </div>
      </div>
   );

   return <div>{TopFive}</div>;
};

export default TopFive;
