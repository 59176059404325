// React.
import React, { Fragment, useEffect } from 'react';

// Moment.
import moment from 'moment';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import { getMe } from '../actions/me';

// Import components
import CommentFeedUnit from './CommentFeedUnit';

const CommentFeedNEW = ({ comments, is_single_content, auth, getMe, id }) => {
   // Get me on feed load.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         getMe();
      }
   }, [auth]);

   const commentsArr = comments
      .map((comment, index) => {
         return (
            <CommentFeedUnit
               key={index}
               id={id}
               comment_id={comment?._id}
               is_single_content={is_single_content}
               user_image={comment?.user?.user_image}
               user_id={comment?.user?.id}
               display_name={comment?.user?.display_name}
               user_name={comment?.user?.user_name}
               created_at={moment(
                  moment.utc(comment.date).utcOffset(new Date().getTimezoneOffset()),
                  'YYYY-MM-DD HH:mm:ss'
               ).fromNow()}
               text={comment?.text}
            />
         );
      })
      .reverse();

   return <Fragment>{commentsArr}</Fragment>;
};

CommentFeedNEW.propTypes = {
   auth: PropTypes.object.isRequired,
   getMe: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth
});

export default connect(mapStateToProps, { getMe })(CommentFeedNEW);
