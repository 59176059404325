import {
   TOGGLE_DISCOVER_MODAL,
   SET_DISCOVER_SUCCESS,
   CLEAR_DISCOVER_SUCCESS
} from '../actions/types';

const initialState = {
   show_discover_modal: false,
   user_name: '',
   mix_title: '',
   track_title: '',
   creator_name: '',
   label_name: '',
   start_date: '',
   finish_date: ''
};

const discover = (state = initialState, action) => {
   switch (action.type) {
      case TOGGLE_DISCOVER_MODAL:
         return { ...state, show_discover_modal: !state.show_discover_modal };
      case SET_DISCOVER_SUCCESS:
         return {
            ...state,
            user_name: action.payload.user_name,
            mix_title: action.payload.mix_title,
            track_title: action.payload.track_title,
            creator_name: action.payload.creator_name,
            label_name: action.payload.label_name,
            start_date: action.payload.start_date,
            finish_date: action.payload.finish_date
         };
      case CLEAR_DISCOVER_SUCCESS:
         return null;
      default:
         return state;
   }
};

export default discover;
