// Import React.
import React, { Fragment } from 'react';

// Redux
import { connect } from 'react-redux';

// Import CSS.
import styles from '../css/styles.module.css';

// Import Components
import NotificationDisplayActionType from '../components/NotificationDisplayActionType';
import MixWidget from './MixWidget';

// import data store path
import dataStorePath from '../configs/dataStorePath';

const Notification = ({
                         gotoSinglePage,
                         id,
                         is_notified,
                         content,
                         mix_details,
                         mix_user_details,
                         shared_mix_details,
                         shared_mix_user_details,
                         shared_content,
                         event = null,
                         actioned_user,
                         comment = 'Comment not available',
                         created_at
                      }) => {
   let is_notified_css = !is_notified ? 'bg-gray-50' : null;

   const handleClick = () => {
      // if content[0]?._id is undefined do not make the event clickable
      if(content[0]?._id){
         gotoSinglePage(content[0]._id, id)
      } else return
   };

   let textPost = null;

   // before we calculate the .length we have to determine if the content[0]?.text is not undefined
   if (content[0]?.text) {
      if (content[0]?.text.length > 0) {
         textPost = (
             <div className="text-left ml-3 my-2.5 leading-5">"{content[0]?.text}"</div>
         );
      }

   }

   const commentText = event === 'comment' ? (
       <div className="bg-mixinlab-yellow italic leading-5 px-5 py-3 mt-2 mb-0.5 rounded-md w-auto">
          {comment}
       </div>
   ) : null;

   const selectEventType = () => {

      // like share or comment on your text
      if ((event === 'like' || event === 'share' || event=== 'comment' || event ==='mention') && content[0]?.type === 'text') {
         return (
             (
                 <div>
                    <span className="font-normal">{textPost}</span>
                    {commentText}
                 </div>
             ) ?? 'Comment not available'
         );
      }

      // like share or comment on your image
      if ((event === 'like' || event === 'share' || event=== 'comment' || event ==='mention') && content[0]?.type === 'image') {
         let img = null;

         if (content[0]?.media !== null) {
            img = dataStorePath.content_images + content[0].media;
         }

         return (
             (
                 <div className="flex sm:block">
                    <img
                        src={img}
                        className={`rounded-lg mt-2 mb-1 mr-3 ${styles.notificationImg}`}
                        alt={'Post'}
                    />
                    <div>
                       <span className="font-normal">{textPost}</span>
                       {commentText}
                    </div>
                 </div>
             )
         );
      }

      // like share or comment on your mix
      if ((event === 'like' || event === 'share' || event=== 'comment') && content[0]?.type === 'mix') {
         return (
             (
                 <div className="mt-4 mb-1">
                    <MixWidget
                        cover={mix_details[0]?.cover ?? 'Mix cover not available'}
                        mix_title={mix_details[0]?.title ?? 'Mix title not available'}
                        mix_owner_user_name={mix_user_details[0]?.user_name ?? 'Mix Dj user name not available'}
                        mix_owner_display_name={mix_user_details[0]?.display_name ?? 'Mix Dj display not available'}
                    />
                    {commentText}
                 </div>
             ) ?? 'Comment not available'
         );
      }

      // like share or comment on a text you shared
      if (
          (event === 'like' || event === 'share' || event=== 'comment') &&
          content[0]?.type === 'share' &&
          shared_content[0]?.type === 'text'
      ) {
         return (
             (
                 <div>
                    <span className="font-normal">{shared_content[0]?.text ?? 'Shared text not available'}</span>
                    {commentText}
                    <div className="leading-5 px-3 pt-1.5 pb-2 mt-1 mb-0.5 font-normal"></div>
                 </div>
             )
         );
      }

      // like share or comment on a image you shared
      if (
          (event === 'like' || event === 'share' || event=== 'comment') &&
          content[0]?.type === 'share' &&
          shared_content[0]?.type === 'image'
      ) {
         let img = null;
         // conditions for share
         if (content[0]?.media !== null) {
            img = dataStorePath.content_images + shared_content[0]?.media;
         }

         return (
             <div className="flex sm:block">
                <img
                    src={img}
                    className={`rounded-md mt-2 mb-1 mr-3 ${styles.notificationImg}`}
                    alt={'Post'}
                />
                <div>
                   <span className="font-normal">{shared_content[0]?.text ? '"' + shared_content[0]?.text + '"' : null}</span>
                   {commentText}
                </div>
             </div>
         );
      }

      // like share or comment on a mix you shared
      if (
          (event === 'like' || event === 'share' || event=== 'comment') &&
          content[0]?.type === 'share' &&
          shared_content[0]?.type === 'mix'
      ) {
         let img = null;

         if (content[0]?.media !== null) {
            img = dataStorePath.content_images + shared_content[0]?.media;
         }

         return (
             <div>
                <div className="pt-1.5">
                   <div className="pt-1.5 w-full">
                      <MixWidget
                          cover={shared_mix_details[0]?.cover ?? 'Mix cover not available'}
                          mix_title={shared_mix_details[0]?.title ?? 'Mix title not available'}
                          mix_owner_user_name={shared_mix_user_details[0]?.user_name ?? 'Mix user name not available'}
                          mix_owner_display_name={
                             shared_mix_user_details[0]?.display_name ?? 'Mix display name not available'
                          }
                      />
                   </div>
                   {commentText}
                </div>
             </div>
         );
      }
   };

   const getEventNotificationText = () => {
      let action = null;
      let type_of_content = null;
      switch (event) {
         case 'like':
            action = 'Liked';
            break;
         case 'share':
            action = 'Shared';
            break;
         case 'comment':
            action = 'Commented on';
            break;
          case 'mention':
              action = 'Mentioned you in';
              break;
         default:
            return 'undefined event'
      }

      let party = 'your';
      if (event === 'mention') {party = 'their'};

      switch (content[0]?.type) {
         case 'image':
            type_of_content = ` ${party} post`;
            break;
         case 'mix':
            type_of_content = ` ${party} mix`;
            break;
         case 'text':
            type_of_content = ` ${party} post`;
            break;
         case 'share':
            let shared_content_type = shared_content[0]?.type ?? 'post';
            type_of_content = shared_content_type === 'mix' ? ' the mix you shared' :' the post you shared';
            break;
         default:
            return 'undefined content type';
      }

      return action + type_of_content;
   };

   if (getEventNotificationText() !== 'undefined event') {
      // if non defined event is in notification collection do not display it
      return (
          <Fragment>
             <div
                 onClick={handleClick}
                 className={`relative ${is_notified_css} w-full pl-4 pr-10 sm:pr-4 py-3 sm:border-r-0 border-r-2 border-b-2 sm:border-l-0 border-l-2 border-gray-200 hover:bg-gray-50 cursor-pointer`}
             >
                {/* Comment text */}
                <div className="flex">
                   {/* Profile image */}
                   <div className="w-11 mr-3">
                      <div
                          className="h-10 w-10 rounded-full bg-cover bg-no-repeat bg-center mt-0.5"
                          style={{
                             backgroundImage: `url(${
                                 dataStorePath.user_profile_images +
                                 actioned_user[0]?.user_image
                             })`
                          }}
                      ></div>
                   </div>

                   <div className="w-full">
                      {/* Display name */}
                      <div className="font-Pulp font-medium text-lg">
                         <div className="tracking-wide ml-1 leading-6">
                            {actioned_user[0]?.display_name ?? 'User details missing'}
                         </div>

                         <div className="flex tracking-wide leading-5 my-0.5">
                            <NotificationDisplayActionType event={event} />
                            <div className="text-mixinlab-teal_dark font-Pulp font-thin text-base mr-1.5">
                               {getEventNotificationText()}

                               {/* Created at */}
                               <span className="font-Pulp font-thin text-gray-500 sm:pl-0 sm:text-base text-base">
                              <span className="text-base mr-1.5 ml-1.5">~</span>
                                  {created_at}
                           </span>
                            </div>
                         </div>
                      </div>
                      {selectEventType()}
                   </div>
                </div>
             </div>
          </Fragment>
      );
   } else {
      return null;
   }

};

Notification.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Notification);
