import { v4 as uuidv4 } from 'uuid';
import { SET_ALERT, REMOVE_ALERT, RESET_STATUS } from './types';

export const setAlert =
   ({ msg }) =>
   (dispatch) => {
      const id = uuidv4();
      dispatch({
         type: SET_ALERT,
         payload: { msg, id }
      });

      setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 3000);
      setTimeout(() => dispatch({ type: RESET_STATUS, payload: id }), 3000);
   };
