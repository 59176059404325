// Import React.
import React from 'react';

// Import event icons.
import like from '../assets/fire_teal_focus.svg';
import share from '../assets/share_teal_focus.svg';
import comment from '../assets/chat_teal_focus.svg';

const NotificationDisplayActionType = ({ event }) => {
   const getEventIcon = (event) => {
      let icon;
      switch (event) {
         case 'like':
            icon = like;
            break;
         case 'share':
            icon = share;
            break;
         case 'comment':
            icon = comment;
            break;
         case 'mention':
            icon = comment;
            break;
         default:
            icon = null;
      }
      return icon;
   };

   return (
      <div className="w-5 mr-2">
         <div
            className="w-5 h-5 bg-contain bg-no-repeat"
            style={{
               backgroundImage: `url(${getEventIcon(event)})`
            }}
         ></div>
      </div>
   );
};

export default NotificationDisplayActionType;
