// Axios.
import axios from 'axios';

// Import actions.
import {
   LOAD_TIMELINE_MIX_SUCCESS,
   LOAD_TIMELINE_MIX_FAIL,
   CLEAR_TIMELINE_MIX,
   GET_CURRENT_MIX_SUCCESS,
   GET_CURRENT_MIX_FAIL,
   SET_API_IS_LOADING,
   SET_UPLOAD_TRACKLIST_IS_LOADING,
   FIND_TRACKS_SUCCESS,
   FIND_TRACKS_FAIL,
   CLEAR_TRACKS,
   SAVE_CURRENT_MIX_POSITION_SUCCESS,
   SAVE_CURRENT_MIX_POSITION_FAIL,
   UPLOAD_MIX_SUCCESS,
   UPLOAD_MIX_FAIL,
   UPLOAD_TRACKLIST_SUCCESS,
   UPLOAD_TRACKLIST_FAIL,
   UPLOAD_CANCEL_SUCCESS,
   UPLOAD_PROGRESS,
   DISCOVER_MUSIC_RESULTS_LOADED_SUCCESS,
   DISCOVER_MUSIC_RESULTS_LOADED_FAIL,
   DISCOVER_MUSIC_RESULTS_RESET,
   AUDIO_PLAY,
   AUDIO_PAUSE
} from './types';
import ReactGA from "react-ga";

// Get selected mix.
export const discoverMusicSearch = (query) => async (dispatch) => {
   try {
      const config = {
         headers: {
            'Content-Type': 'application/json'
         }
      };
      const res = await axios.post('/api/mix/search-music', query, config);
      dispatch({
         type: DISCOVER_MUSIC_RESULTS_LOADED_SUCCESS,
         payload: res.data
      });
   } catch (err) {
      dispatch({
         type: DISCOVER_MUSIC_RESULTS_LOADED_FAIL,
         payload: { msg: err.response }
      });
   }
};

// Get selected mix.
export const discoverMusicReset = () => async (dispatch) => {
   dispatch({
      type: DISCOVER_MUSIC_RESULTS_RESET
   });
};

// Get last saved mix in DB. (converted to seperate reducer state)
export const getCurrentMix = () => async (dispatch) => {
   try {
      const res = await axios.get(`/api/user/current-mix`);
      const mix_data = res.data.current_mix;
      const mix_meta = res.data;
      const mix_content_id = res.data.content_id;

      dispatch({
         type: GET_CURRENT_MIX_SUCCESS,
         payload: mix_data,
         meta: mix_meta,
         post_id: mix_content_id
      });
   } catch (err) {
      dispatch({
         type: GET_CURRENT_MIX_FAIL,
         payload: { msg: err.response }
      });
   }
};

// Get selected mix.
export const clearTimelineMix = () => async (dispatch) => {
   dispatch({
      type: CLEAR_TIMELINE_MIX
   });
};

// Get selected mix.
export const loadTimelineMix = (post_id, mix_id) => async (dispatch) => {

   try {
      const res = await axios.get(`/api/mix/${mix_id}`);
      const mix_data = res.data.current_mix;
      const mix_meta = res.data;

      dispatch({
         type: LOAD_TIMELINE_MIX_SUCCESS,
         payload: mix_data,
         meta: mix_meta,
         post_id: post_id
      });

      await dispatch(
         saveMixPosition({
            current_mix: mix_id,
            current_mix_pos: '0'
         })
      );
   } catch (err) {
      dispatch({
         type: LOAD_TIMELINE_MIX_FAIL,
         payload: { msg: err.response }
      });
   }
};

// Save current mix position.
export const saveMixPosition = (query) => async (dispatch) => {
   const config = {
      headers: {
         'Content-Type': 'application/json'
      }
   };
   try {
      const res = await axios.post(`/api/user/set-current-mix`, query, config);
      dispatch({
         type: SAVE_CURRENT_MIX_POSITION_SUCCESS,
         payload: res.data
      });
   } catch (err) {
      dispatch({
         type: SAVE_CURRENT_MIX_POSITION_FAIL,
         payload: { msg: err.response }
      });
   }
};

// Uploads mp£ file to DigitalOcean.
let cancelTokenSource = axios.CancelToken.source();
export const uploadMix = (formData) => async (dispatch) => {
   const config = {
      headers: {
         accept: 'application/json',
         'Accept-Language': 'en-US,en;q=0.8',
         'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
      },
      cancelToken: cancelTokenSource.token,
      onUploadProgress: (data) => {
         let progress = Math.round((100 * data.loaded) / data.total);
         dispatch({
            type: UPLOAD_PROGRESS,
            payload: progress
         });
      }
   };

   try {
      const res = await axios.post('/api/mix', formData, config);
      dispatch({
         type: UPLOAD_MIX_SUCCESS,
         payload: res.data.status,
         id: res.data.mix.id
      });
   } catch (err) {
      dispatch({
         type: UPLOAD_MIX_FAIL,
         payload: { msg: err.response }
      });
   }
};

// Cancels upload to DigitalOcean.
export const cancelUploadMix = () => async (dispatch) => {
   cancelTokenSource.cancel('Upload cancelled');
   dispatch({
      type: UPLOAD_CANCEL_SUCCESS,
      payload: { status: true, msg: 'Upload cancelled' }
   });
};

// Find track from ACRCloud search.
export const findTracks = (query) => async (dispatch) => {
   const config = {
      headers: {
         'Content-Type': 'application/json'
      }
   };

   dispatch({
      type: SET_API_IS_LOADING
   });

   try {
      const res = await axios.post('/api/mix/find-tracks', query, config);

      dispatch({
         type: FIND_TRACKS_SUCCESS,
         payload: res.data.data
      });
   } catch (err) {
      console.error(err);
      dispatch({
         type: FIND_TRACKS_FAIL,
         payload: { msg: err.response }
      });
   }
};

// Clear tracklist
export const clearTracks = () => async (dispatch) => {
   dispatch({
      type: CLEAR_TRACKS
   });
};

// Update mix with tracklist and publish to timeline.
export const updateMixWithTracklistAndPublish = (data) => async (dispatch) => {
   dispatch({
      type: SET_UPLOAD_TRACKLIST_IS_LOADING
   });

   try {
      const res = await axios.patch(`api/mix/mix-update/${data.mix_id}`, data);
      ReactGA.event({category:'Mix', action:'Upload', label: res.data.content._id  , value: 1 });
      dispatch({
         type: UPLOAD_TRACKLIST_SUCCESS,
         payload: res.data
      });
   } catch (err) {
      dispatch({
         type: UPLOAD_TRACKLIST_FAIL,
         payload: { msg: err.response }
      });
   }
};

// Set mix reducer state to play.
export const playAudio = () => async (dispatch) => {
   dispatch({
      type: AUDIO_PLAY
   });
};

// Set mix reducer state to pause.
export const pauseAudio = () => async (dispatch) => {
   dispatch({
      type: AUDIO_PAUSE
   });
};
