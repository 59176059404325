// React.
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import { getFollowing } from '../actions/follow';
import { getMe } from '../actions/me';

// Import components
import Follow from '../components/Follow';
import Spinner from '../components/Spinner';

const FollowingFeed = ({
   auth,
   follow: { following, loading, follow_unfollow_done },
   getFollowing,
   getMe,
   // user,
   me,
   backButton
}) => {
   // Params.
   const { user_name } = useParams();
   const [state, setState] = useState({ isLoggedInUser: true });

   // Gets users that I am Following.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         getFollowing(user_name);
      }
   }, [auth, getFollowing]);

   // Re-renders users that I am Following after I Unfollow a user.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated && follow_unfollow_done) {
         getFollowing(user_name);
         getMe();
      }
   }, [auth, getFollowing, follow_unfollow_done, user_name]);

   // Check if we are displaying Following of the logged in user
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         if (me.user_name === user_name) {
            setState({
               ...state,
               isLoggedInUser: true
            });
         } else {
            setState({
               ...state,
               isLoggedInUser: false
            });
         }
      }
   }, [auth, user_name, state.isLoggedInUser]);

   // Compile Following.
   const Following = following.map((user, index) => {
      console.log(state.isLoggedInUser);
      let amIFollowingUser = false;

      if (user?._id) {
         if (state.isLoggedInUser) {
            amIFollowingUser = true;
         } else {
            amIFollowingUser = me.following.some(
               (meFollowing) => user?.id === meFollowing?.user?._id // hotfix ?
            );
         }
         return (
            <Follow
               id={user?._id}
               key={index}
               licence={user?.licence?.display_name}
               user_image={user?.user_image}
               display_name={user?.display_name}
               user_name={user?.user_name}
               short_bio={user?.short_bio}
               followsMe={amIFollowingUser}
               backButton={backButton}
            />
         );
      }
   });

   return (
      <Fragment>
         <div className="w-full">
            {loading && following.length !== 0 ? <Spinner /> : <div>{Following}</div>}
         </div>
      </Fragment>
   );
};

FollowingFeed.propTypes = {
   getFollowing: PropTypes.func.isRequired,
   getMe: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired,
   follow: PropTypes.object.isRequired,
   user: PropTypes.object.isRequired,
   me: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   follow: state.follow,
   auth: state.auth,
   user: state.user,
   me: state.me
});

export default connect(mapStateToProps, {
   getFollowing,
   getMe
})(FollowingFeed);
