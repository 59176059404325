import {
   FOLLOW_UNFOLLOW_SUCCESS,
   FOLLOW_UNFOLLOW_FAIL,
   IS_FOLLOWED_FAIL,
   IS_FOLLOWED_SUCCESS,
   RESET_FOLLOW_UNFOLLOW_DONE,
   USER_FOLLOWER_LOADED_SUCCESS,
   USER_FOLLOWER_LOADED_FAIL,
   USER_FOLLOWING_LOADED_SUCCESS,
   USER_FOLLOWING_LOADED_FAIL,
   CLEAR_USER_FOLLOW_STATUS,
   LOG_OUT
} from '../actions/types';

const initialState = {
   status: null,
   followers: [],
   following: [],
   follow_unfollow_done: false,
   is_followed: null,
   loaded_followers: false,
   loaded_following: false,
   error: {}
};

const follow = (state = initialState, action) => {
   switch (action.type) {
      case FOLLOW_UNFOLLOW_SUCCESS:
         return {
            ...state,
            follow_unfollow_done: true
         };
      case FOLLOW_UNFOLLOW_FAIL:
         return {
            ...state,
            follow_unfollow_done: false,
            error: action.payload
         };
      case IS_FOLLOWED_SUCCESS:
         return {
            ...state,
            loading: false,
            is_followed: action.payload.data.message
         };
      case IS_FOLLOWED_FAIL:
         return {
            ...state,
            loading: false,
            error: action.payload
         };

      case RESET_FOLLOW_UNFOLLOW_DONE:
         return {
            ...state,
            follow_unfollow_done: false
         };

      case USER_FOLLOWER_LOADED_SUCCESS:
         return {
            ...state,
            loaded_followers: true,
            follow_unfollow_done: false,
            followers: action.payload
         };
      case USER_FOLLOWER_LOADED_FAIL:
         return {
            ...state,
            loading_followers: false,
            follow_unfollow_done: false,
            error: action.payload
         };
      case USER_FOLLOWING_LOADED_SUCCESS:
         return {
            ...state,
            loaded_following: true,
            follow_unfollow_done: false,
            following: action.payload
         };
      case USER_FOLLOWING_LOADED_FAIL:
         return {
            ...state,
            follow_unfollow_done: false,
            loaded_following: false,
            error: action.payload
         };
      case CLEAR_USER_FOLLOW_STATUS:
         return {
            ...state,
            follow_unfollow_done: false,
            loaded_followers: false,
            loaded_following: false
         };
      case LOG_OUT:
         return {
            status: null,
            followers: [],
            following: [],
            follow_unfollow_done: false,
            loaded_followers: false,
            loaded_following: false,
            error: {}
         };
      default:
         return state;
   }
};

export default follow;
