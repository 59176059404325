import {
   AGREE_TO_TERMS,
   GET_USER_SUCCESS,
   GET_USER_FAIL,
   GET_SUGGESTED_USER_SUCCESS,
   GET_SUGGESTED_USER_FAIL,
   GET_USER_BY_USERNAME_SUCCESS,
   GET_USER_BY_USERNAME_FAIL,
   RESET_USER_REFERENCE_SUGGESTIONS_SUCCESS,
   RESET_USER_REFERENCE_SUGGESTIONS_FAIL,
   ADD_SUGGESTED_USER_SUCCESS,
   ADD_SUGGESTED_USER_FAIL,
   PLAN_CHOSEN, // Add fail case.
   BLOCK_USER_SUCCESS,
   BLOCK_USER_FAIL,
   LOG_OUT,
   SEND_EMAIL_PASSWORD_SUCCESS,
   SEND_EMAIL_PASSWORD_FAIL,
   RESET_PASSWORD_SUCCESS,
   RESET_PASSWORD_FAIL
} from '../actions/types';

const initialState = {
   agreed_to_terms: false,
   plan_id: null,
   plan_name: null,
   plan_chosen: false,
   time_stamp: null,
   id: null,
   licence: null,
   user_name: null,
   display_name: null,
   user_image: null,
   user_bg: null,
   short_bio: null,
   bio: null,
   location: null,
   web_display: null,
   web_full: null,
   following_length: null,
   followers_length: null,
   user_block_done: null,
   user_block_response: null,
   suggested_users: [],
   user_reference_suggestions: [],
   followed_suggested_users: [],
   date: null,
   is_password_reset_email_sent: false,
   is_password_reset: null,
   loading: true,
   error: {}
};

const user = (state = initialState, action) => {
   switch (action.type) {
      case AGREE_TO_TERMS:
         return {
            agreed_to_terms: true
         };
      case PLAN_CHOSEN:
         return {
            ...state,
            plan_id: action.payload.plan_id,
            plan_name: action.payload.plan,
            plan_chosen: true
         };
      case GET_USER_SUCCESS:
         return {
            ...state,
            time_stamp: Date.now(),
            loading: false,
            id: action.payload._id,
            licence: action.payload.licence?.display_name,
            user_name: action.payload.user_name,
            display_name: action.payload.display_name,
            user_image: action.payload.user_image,
            user_bg: action.payload.user_bg,
            short_bio: action.payload.short_bio,
            bio: action.payload.bio,
            location: action.payload.location,
            web_display: action.payload.web_display,
            web_full: action.payload.web_full,
            following_length: action.payload.followingCount,
            followers_length: action.payload.followersCount,
            date: action.payload.date
         };
      case GET_USER_FAIL:
         return {
            ...state,
            loading: false,
            error: action.payload
         };
      case GET_SUGGESTED_USER_SUCCESS:
         return {
            ...state,
            suggested_users: action.payload
         };
      case GET_SUGGESTED_USER_FAIL:
         return {
            ...state,
            loading: false,
            error: action.payload
         };
      case GET_USER_BY_USERNAME_SUCCESS:
         return {
            ...state,
            user_reference_suggestions: action.payload
         };
      case GET_USER_BY_USERNAME_FAIL:
         return {
            ...state,
            user_reference_suggestions: []
         };
      case RESET_USER_REFERENCE_SUGGESTIONS_SUCCESS:
         return {
            ...state,
            user_reference_suggestions: []
         };
      case RESET_USER_REFERENCE_SUGGESTIONS_FAIL:
         return {
            ...state,
            user_reference_suggestions: []
         };
      case ADD_SUGGESTED_USER_SUCCESS:
         return {
            ...state,
            followed_suggested_users: state.followed_suggested_users.concat(
               action.payload
            )
         };
      case ADD_SUGGESTED_USER_FAIL:
         return {
            ...state,
            loading: false,
            error: action.payload
         };
      case BLOCK_USER_SUCCESS:
         return {
            ...state,
            user_block_done: true,
            user_block_response: action.payload.data
         };
      case BLOCK_USER_FAIL:
         return {
            ...state,
            user_block_done: null,
            user_block_response: action.payload
         };
      case SEND_EMAIL_PASSWORD_SUCCESS:
         return {
            ...state,
            is_password_reset_email_sent: true
         };
      case SEND_EMAIL_PASSWORD_FAIL:
         return {
            ...state,
            is_password_reset_email_sent: false,
            error: action.payload
         };
      case RESET_PASSWORD_SUCCESS:
         return {
            ...state,
            is_password_reset: true
         };
      case RESET_PASSWORD_FAIL:
         return {
            ...state,
            is_password_reset: false,
            error: action.payload
         };
      case LOG_OUT:
         return {
            ...state,
            plan_details: null,
            plan_chosen: false,
            time_stamp: null,
            id: null,
            user_name: null,
            display_name: null,
            user_image: null,
            user_bg: null,
            short_bio: null,
            bio: null,
            location: null,
            web_display: null,
            web_full: null,
            following_length: null,
            followers_length: null,
            user_block_done: null,
            user_block_response: null,
            date: null,
            loading: true,
            error: {},
            is_password_email_sending: false,
            is_password_reset_email_sent: false
         };
      default:
         return state;
   }
};

export default user;
