// React.
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Moment.
import moment from 'moment';

// Components.
import PageHeader from './PageHeader.js';
import Post from './Post';
import PostShare from './PostShare';
import MixPost from './MixPost';

// Import actions.
import { getSingleContentById } from '../actions/contentSingle';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MixPostShare from './MixPostShare';

const SingleContentCommentWrapper = ({
   auth,
   mix,
   content,
   getSingleContentById
}) => {
   const [singlePost, setSinglePost] = useState(null);
   const [likeCount, setLikeCount] = useState(0);
   const [shareCount, setShareCount] = useState(0);
   const [commentCount, setCommentCount] = useState(0);
   const { content_id } = useParams();

   // Get single content on load
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         getSingleContentById(content_id);
      }
   }, [auth, content_id, commentCount]);

   // Assign singleContent.post to local state upon API response
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated && content.single_content) {
         setSinglePost(content.single_content);
      }
   }, [auth, content.single_content]);

   // Update Likes, Comments, Shares counts on load
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated && content.single_content) {
         setLikeCount(singlePost?.like_count_VIRTUAL);
         setCommentCount(singlePost?.comment_count_VIRTUAL);
         setShareCount(singlePost?.share_count_VIRTUAL);
      }
   }, [auth, singlePost?.like_count_VIRTUAL, singlePost?.comment_count_VIRTUAL]);

   // Update Like Count upon action click
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated && content.single_content) {
         setLikeCount(content.single_content_like_count);
      }
   }, [auth, content.single_content_like_count]);

   // Update Comment Count upon action clicks
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated && content.single_content) {
         setCommentCount(content.single_content_comment_count);
      }
   }, [auth, content.single_content_comment_count]);

   // Update Share Count upon action clicks
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated && content.single_content) {
         setShareCount(content.single_content_share_count + 1);
      }
   }, [auth, content.single_content_share_count]);

   /**
    * Detect correct post type and Switch correct Post component
    * @param type
    * @constructor
    */
   const SelectAndDisplayPostType = (type) => {
      switch (type) {
         case 'text':
         case 'image':
            {
               return (
                  <Post
                     key={singlePost._id}
                     id={singlePost._id}
                     user_id={singlePost.user._id}
                     plan={singlePost.user.licence?.display_name}
                     liked_by={null}
                     user_image={singlePost.user?.user_image}
                     display_name={singlePost.user?.display_name}
                     user_name={singlePost.user?.user_name}
                     created_at={moment(
                        moment
                           .utc(singlePost?.createdAt)
                           .utcOffset(new Date().getTimezoneOffset()),
                        'YYYY-MM-DD HH:mm:ss'
                     ).fromNow()}
                     text={singlePost?.text ?? ''}
                     media={singlePost?.media}
                     comment_count={commentCount}
                     share_count={shareCount}
                     like_count={likeCount}
                     is_author_me={false}
                     display_follow_toggle_button={null}
                     social_context={null}
                  />
               );
            }
            break;
         case 'share':
            {
               if (singlePost.shared_content.type === 'mix') {
                  return (
                     <MixPostShare
                        key={singlePost._id}
                        id={singlePost.id}
                        in_focus={true}
                        is_playing={mix.current_mix_is_playing ?? false}
                        cover={singlePost?.shared_content?.mix?.cover ?? null}
                        mix_id={singlePost?.shared_content?.mix?._id ?? null}
                        mix_title={
                           singlePost?.shared_content?.mix?.title ?? 'Not available'
                        }
                        display_name={
                           singlePost?.user?.display_name ?? 'Not available'
                        }
                        user_name={singlePost?.user?.user_name ?? null}
                        tracks={singlePost?.shared_content?.mix?.tracks ?? null}
                        comment_count={commentCount}
                        share_count={shareCount}
                        like_count={likeCount}
                        user_image={
                           singlePost?.shared_content?.user?.user_image ?? null
                        }
                        share_id={singlePost?.shared_content?.id ?? null}
                        mix_owner_display_name={
                           singlePost?.shared_content?.user?.display_name ??
                           'Not available'
                        }
                        mix_owner_user_name={
                           singlePost?.shared_content?.user?.display_name ??
                           'Not available'
                        }
                        created_at={moment(
                           singlePost?.createdAt,
                           'YYYYMMDD'
                        ).fromNow()}
                        is_author_me={false}
                        time_stamp={null}
                     />
                  );
               } else {
                  return (
                     <PostShare
                        key={singlePost._id}
                        id={singlePost._id}
                        user_id={singlePost.user._id}
                        plan={
                           singlePost?.shared_content?.user?.licence?.display_name ??
                           'Licence type not available'
                        }
                        share_id={
                           singlePost?.shared_content?.id ?? 'Share id not available'
                        }
                        share={singlePost?.user?.display_name ?? 'User not available'}
                        share_user_name={
                           singlePost?.user?.user_name ?? 'User not available'
                        }
                        share_comment={
                           singlePost?.text ?? 'Share comment not available'
                        }
                        share_user_id={
                           singlePost?.shared_content?.user?._id ??
                           'User not available'
                        }
                        user_image={
                           singlePost?.shared_content?.user?.user_image ??
                           'User image not available'
                        }
                        display_name={
                           singlePost?.shared_content?.user?.display_name ??
                           'User not available'
                        }
                        user_name={
                           singlePost?.shared_content?.user?.user_name ??
                           'User not available'
                        }
                        created_at={moment(
                           singlePost.createdAt,
                           'YYYYMMDD'
                        ).fromNow()}
                        text={
                           singlePost?.shared_content?.text ??
                           'Text content not available'
                        }
                        media={singlePost?.shared_content?.media ?? null}
                        comment_count={commentCount}
                        share_count={shareCount}
                        like_count={likeCount}
                        is_author_me={false}
                        display_follow_toggle_button={null}
                        showComments={null}
                     />
                  );
               }
            }
            break;
         case 'mix':
            return (
               <MixPost
                  key={singlePost?._id}
                  id={singlePost?.id}
                  in_focus={true}
                  is_playing={mix.current_mix_is_playing ?? false}
                  cover={singlePost?.mix?.cover ?? null}
                  mix_id={singlePost?.mix?.did ?? null}
                  mix_title={singlePost?.mix.title ?? 'Not available'}
                  display_name={singlePost?.user.display_name ?? 'Not available'}
                  user_name={singlePost?.user.user_name ?? null}
                  tracks={singlePost?.mix.tracks ?? null}
                  comment_count={commentCount}
                  share_count={shareCount}
                  like_count={likeCount}
                  user_image={singlePost?.user.user_image ?? null}
                  created_at={moment(singlePost?.createdAt, 'YYYYMMDD').fromNow()}
                  is_author_me={false}
                  time_stamp={null}
                  showComments={null}
               />
            );
            break;
         case 'video':
            break;
         case 'audio':
            break;
         default:
            return;
      }
   };

   /**
    * We must have following properties in singlePost to successfully
    * display any type of post
    */
   if (singlePost?.type && singlePost?.user) {
      return (
         <Fragment>
            <div>
               <PageHeader page={'Single Page Content'} />
               {SelectAndDisplayPostType(singlePost.type)}
            </div>
         </Fragment>
      );
   } else {
      return (
         <Fragment>
            <div>
               <PageHeader page={singlePost?.error ?? 'Content Loading ... '} />
            </div>
         </Fragment>
      );
   }
};

SingleContentCommentWrapper.propTypes = {
   getSingleContentById: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired,
   mix: PropTypes.object.isRequired,
   content: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth,
   mix: state.mix,
   content: state.content
});

export default connect(mapStateToProps, { getSingleContentById })(
   SingleContentCommentWrapper
);
