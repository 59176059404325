// React.
import React, { Fragment, useEffect, useState } from 'react';

// Axios.
import axios from 'axios';

// Import components.
import UserListResults from './UserListResults';

const UserSearch = ({ onUserSelect }) => {
   const [userList, setUserList] = useState([]);
   const [userSearchInput, setUserSearchInput] = useState('');

   useEffect(() => {
      const getUser = async () => {
         try {
            const config = {
               headers: {
                  'Content-Type': 'application/json'
               }
            };
            const res = await axios.post(
               '/api/user/search-users?limit=5&page=1&fields=user_name,display_name',
               { search: userSearchInput },
               config
            );
            setUserList(res.data);
         } catch (error) {}
      };
      getUser();
   }, [userSearchInput]);

   const clearSearchUser = () => {
      setUserSearchInput('');
      setUserList([]);
   };

   return (
      <Fragment>
         <div className="pl-1 lg:pl-2 pt-1 text-base lg:text-2xl mb-4 font-Pulp">
            Find someone
         </div>

         <form>
            {/* Display name */}
            <div className="flex w-full items-center border border-gray-300 rounded-md p-1 lg:p-1.5">
               <label htmlFor="user"></label>
               <input
                  className="text-sm lg:pb-1 font-normal text-sm lg:text-xl lg:px-2 w-full text-black focus:outline-none pl-1 md:pl-2"
                  type="text"
                  name="user"
                  placeholder="Display name"
                  autoComplete="off"
                  onKeyUp={(e) => {
                     setUserSearchInput(e.target.value);
                  }}
                  onChange={(e) => setUserSearchInput(e.target.value)}
                  value={userSearchInput}
               />
            </div>
            <UserListResults
               onUserSelect={onUserSelect}
               userList={userList}
               clearSearchUser={clearSearchUser}
            />
         </form>
      </Fragment>
   );
};

export default UserSearch;
