// React.
import React from 'react';

// Import icons.
import closeIcon from '../assets/close.svg';

const CloseButton = ({close}) => {
   return (
      <div className="h-10 w-10 grid content-center hover:bg-gray-100 rounded-full" onClick={close}>
         <div
            className="mx-auto bg-cover bg-no-repeat bg-center h-6 w-6 cursor-pointer"
            style={{
               backgroundImage: `url(${closeIcon})`
            }}
            alt={'close'}
         ></div>
      </div>
   );
};

export default CloseButton;
