import { SEND_REPORT_SUCCESS, SEND_REPORT_FAIL, LOG_OUT } from '../actions/types';

const initialState = {
   report: {},
   report_loaded: false,
   error: {}
};

const admin = (state = initialState, action) => {
   switch (action.type) {
      case SEND_REPORT_SUCCESS:
         return {
            ...state,
            report: {},
            report_loaded: false,
            error: {}
         };
      case SEND_REPORT_FAIL:
         return {
            ...state,
            error: action.payload,
            loading: false
         };
      case LOG_OUT:
         return {
            ...state,
            report: {},
            report_loaded: false,
            error: {}
         };
      default:
         return state;
   }
};

export default admin;
