// React.
import React, { Fragment } from 'react';

// Import helpers.
import {
   youtubeDomain,
   twitchDomain,
   otherDomain
} from '../helpers/platformHandlers';

const RichContentDomainFeed = ({ shared_content }) => {

   // Instantiate component.
   let domain;

   switch (shared_content?.platform) {
      case 'YOUTUBE':
         domain = youtubeDomain(shared_content);
         break;
      case 'TWITCH':
         domain = twitchDomain(shared_content);
         break;
      default:
         domain = otherDomain(shared_content);
   }

   return <Fragment>{domain}</Fragment>;
};

export default RichContentDomainFeed;
