// React.
import React from 'react';

// Placeholder image.
import profilePlaceholder from '../assets/placeholder.svg';

// Import utils.
import addDataStorePath from '../utils/addDataStorePath';
import dataStorePath from '../configs/dataStorePath';

const ProfileBgModal = ({ blob, user_bg, handleInput }) => {
   const placeholder = (
      <div className="flex sm:justify-end w-full h-44 bg-gray-100 bg-contain bg-no-repeat bg-center rounded-xl">
         <div className="w-full sm:w-8/12">
            <label htmlFor="background" className="w-full h-full">
               <div
                  className="mx-auto mt-14 w-14 h-14 sm:w-12 sm:h-12 sm:mt-16 bg-center rounded-xl bg-no-repeat cursor-pointer"
                  style={{
                     backgroundImage: `url(${profilePlaceholder})`
                  }}
                  alt={'Placeholder'}
               ></div>
            </label>
         </div>

         <input
            className="hidden bg-green-200 w-full h-full"
            id="background"
            type="file"
            onChange={handleInput}
         />
      </div>
   );

   const initial_bg = (
      <div className="flex w-auto h-44 bg-contain bg-no-repeat bg-center">
         <label
            htmlFor="background"
            className="w-full h-full bg-cover bg-no-repeat bg-center rounded-xl cursor-pointer hover:opacity-80"
            style={{
               backgroundImage: `url(${
                  dataStorePath.user_profile_bg_images + user_bg
               })`
            }}
         ></label>
         <input
            className="hidden w-full h-full"
            id="background"
            type="file"
            onChange={handleInput}
         />
      </div>
   );

   const uploading = (
      <div className="flex content-center w-full h-44 bg-gray-100 bg-contain bg-no-repeat bg-center">
         <label
            htmlFor="background"
            className="w-full h-full bg-cover bg-no-repeat bg-center rounded-xl cursor-pointer hover:opacity-80"
            style={{
               backgroundImage: `url(${blob})`
            }}
         ></label>
         <input
            className="hidden w-full h-full"
            id="background"
            type="file"
            onChange={handleInput}
         />
      </div>
   );

   // Instantiate bio component.
   let bg_image;

   if (!user_bg && !blob) {
      bg_image = placeholder;
      return bg_image;
   } else if (!user_bg && blob) {
      bg_image = uploading;
      return bg_image;
   } else if (user_bg && !blob) {
      bg_image = initial_bg;
      return bg_image;
   } else if (user_bg && blob) {
      bg_image = uploading;
      return bg_image;
   }

   return <form>{bg_image}</form>;
};

export default ProfileBgModal;
