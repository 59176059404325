// React and PropTypes.
import React, { Fragment, useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { validate as isValidUUID } from 'uuid';


// Import constants.
import constants from '../configs/constants';

// Stripe
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

// Axio
import axios from 'axios';

// Moment.
import moment from 'moment';

// Import utils.
import addDataStorePathMixImages from '../utils/addDataStorePathMixImages';

// Components
import PageHeader from '../components/PageHeader';
import AlertFrontEnd from '../components/AlertFrontEnd';
import Spinner from '../components/Spinner';

// Import icons.
import tick from '../assets/tick.svg';
import dataStorePath from "../configs/dataStorePath";

/**
 * Enables users to purchase mixcamp minutes
 * http://mixcamp.com/purchase/<product-slug-required-here>
 * @param auth
 * @returns {JSX.Element}
 * @constructor
 */
const Purchase = ({ auth }) => {
   const { product_slug, helper_id } = useParams();

   const bill_form_min_characters = {
      name: 3,
      address_line_1: 10,
      city: 5,
      postal_code: 4,
      state: 2,
      country: 2
   };

   const [state, setState] = useState({
      name: '',
      is_name_valid: false,
      address_line_1: '',
      is_address_line_1_valid: false,
      city: '',
      is_city_valid: false,
      state: '',
      is_state_valid: false,
      postal_code: '',
      is_postal_code_valid: false,
      country: '',
      is_country_valid: false,
      is_save_billing_details: true,
      selected_product: null,
      is_selected_product_valid:false,
      is_got_product: false,
      selected_mix: null,
      is_selected_mix_valid:false,
      is_got_mix: false,
      alert: '',
      errorArr: [],
      is_card_element_completed: false,
      is_billing_form_valid: false,
      is_submit_button_disabled: true,
      payment_processing: false,
      is_display_form: true,
      is_payment_succeeded: false,
      payment_details: null
   });

   let getProduct = async () => {
      try {
         const selectedProduct = await axios.get(
             `/api/product/product/${product_slug}`
         );
         setState({
            ...state,
            selected_product: selectedProduct.data.data.product ?? selectedProduct.data.data.product,
            is_got_product: true,
            is_selected_product_valid:true
         });
      } catch (err) {
         setState({
            ...state,
            selected_product:null,
            is_got_product: true,
            is_selected_product_valid:false
         });
      }
   }

   let getMixById = async () => {
      try {
         if(helper_id.length === 24) {
            const selectedMix = await axios.get(
                `/api/mix/${helper_id}`
            );
            setState({
               ...state,
               selected_mix: selectedMix.data.current_mix ?? selectedMix.data.current_mix,
               is_got_mix: true,
               is_selected_mix_valid:true
            });
         } else {
            throw new Error('Helper id is not valid')
         }

      } catch (err) {
         setState({
            ...state,
            selected_mix: null,
            is_got_product: true,
            is_selected_mix_valid: false
         });
         console.log(err)
      }
   }

   // onLoad
   useEffect(async () => {
      if (!auth.loading && auth.isAuthenticated && product_slug) {
         await getProduct();
      }
   }, [auth]);

   // Once a valid product is accuired from API act according to product type
   useEffect(async () => {
      if (!auth.loading && auth.isAuthenticated && state.is_selected_product_valid) {
         switch (state.selected_product.type) {
            case 'publish-mix':
               await getMixById();
               break;
            case 're-publish-mix':
               // code block
               break;
            default:
               // do somithing
         }
      }
   }, [auth, state.is_selected_product_valid]);

   // Display error if product cannot be found on DB
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         if (!state.selected_product && state.is_got_product) {
            setState({
               ...state,
               alert: 'Invalid Product! Please contact admin'
            });
         }
      }
   }, [auth, state.is_got_product]);

   // Check if Billing Details Form is Valid upon field changes
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         isBillingDetailsFormValid();
      }
   }, [
      auth,
      state.name,
      state.address_line_1,
      state.city,
      state.postal_code,
      state.country,
      state.state
   ]);

   // If both Billing Details Form and Card Element Form is completed to enable submit button
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         if (!state.is_billing_form_valid || !state.is_card_element_completed) {
            setState({
               ...state,
               is_submit_button_disabled: true
            });
         } else {
            setState({
               ...state,
               is_submit_button_disabled: false
            });
         }
      }
   }, [auth, state.is_billing_form_valid, state.is_card_element_completed]);

   // Hide forms whilst payment is processing
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         if (state.payment_processing) {
            setState({
               ...state,
               is_hide_forms: true
            });
         } else {
            setState({
               ...state,
               is_hide_forms: false
            });
         }
      }
   }, [auth, state.payment_processing]);

   const isBillingDetailsFormValid = () => {
      const is_name_valid = state.name.length >= bill_form_min_characters.name;
      const is_address_line_1_valid =
         state.address_line_1.length >= bill_form_min_characters.address_line_1;
      const is_city_valid = state.city.length >= bill_form_min_characters.city;
      const is_postal_code_valid =
         state.postal_code.length >= bill_form_min_characters.postal_code;
      const is_state_valid = state.state.length >= bill_form_min_characters.state;
      const is_country_valid =
         state.country.length >= bill_form_min_characters.country;
      if (
         is_name_valid &&
         is_address_line_1_valid &&
         is_city_valid &&
         is_postal_code_valid &&
         is_state_valid &&
         is_country_valid
      ) {
         setState({
            ...state,
            is_billing_form_valid: true,
            is_name_valid,
            is_address_line_1_valid,
            is_city_valid,
            is_postal_code_valid,
            is_state_valid,
            is_country_valid
         });
      } else {
         setState({
            ...state,
            is_billing_form_valid: false,
            is_name_valid,
            is_address_line_1_valid,
            is_city_valid,
            is_postal_code_valid,
            is_state_valid,
            is_country_valid
         });
      }
   };

   // Check if Card Element Form is Valid upon field changes
   const isCardElementCompleted = (event) => {
      if (event.complete) {
         setState({
            ...state,
            is_card_element_completed: true
         });
      } else {
         setState({
            ...state,
            is_card_element_completed: false
         });
      }
   };

   const stripe = useStripe();
   const elements = useElements();

   //@TODO Build billingDetails Component with Verifications -
   //@TODO Save paymentMethodId for later use (to Retrieve Payment method details)
   //@TODO read product id from params to get price , description etc. - done
   //@TODO Setup Webhook to complete backend - done

   // Count character length.
   const handleChange = (e) => {
      const { name, value } = e.target;
      setState((prevState) => {
         return {
            ...prevState,
            [name]: value
         };
      });
   };

   const handleCheck = () => {
      setState({
         ...state,
         is_save_billing_details: !state.is_save_billing_details
      });
   };

   const handleFormSubmit = async (e) => {
      e.preventDefault();
      setState({
         ...state,
         payment_processing: true,
         is_submit_button_disabled: true
      });

      const billingDetails = {
         name: state.name,
         address: {
            line_1: state.address_line_1,
            city: state.city,
            state: state.state,
            postal_code: state.postal_code,
            country: state.country
         }
      };

      console.log(billingDetails)

      const { data: clientSecret } = await axios.post(
         '/api/stripe/create-payment-intent',
         {
            product_slug: product_slug,
            helper_id : helper_id,
            billingDetails: billingDetails,
            is_save_billing_details: state.is_save_billing_details
         }
      );

      const cardElement = elements.getElement(CardElement);

      const paymentMethodReq = await stripe.createPaymentMethod({
         type: 'card',
         card: cardElement,
         billing_details: billingDetails.add
      });

      console.log(paymentMethodReq);

      const confirmedCardPayment = await stripe.confirmCardPayment(
         clientSecret.payment_intent_client_secret,
         {
            payment_method: paymentMethodReq.paymentMethod.id
         }
      );

      console.log(confirmedCardPayment);

      if (confirmedCardPayment?.paymentIntent?.status === 'succeeded') {
         console.log(confirmedCardPayment);
         console.log('payment successful');
         setState({
            ...state,
            is_submit_button_disabled: true,
            is_payment_succeeded: true,
            is_display_form: false,
            payment_processing: false,
            payment_details: confirmedCardPayment,
            alert: ''
         });
      } else {
         console.log(confirmedCardPayment);
         console.log('payment failed');
         setState({
            ...state,
            is_submit_button_disabled: false,
            is_payment_succeeded: false,
            payment_processing: false,
            alert: confirmedCardPayment.error.message
         });
      }
   };

   const billingFormFieldValidation = (is_field_vaild) => {
      if (is_field_vaild) {
         return 'bg-green-50';
      } else {
         return 'bg-red-50';
      }
   };

   const greenTickOnValidation = (is_field_valid) => {
      if (is_field_valid) {
         return `url(${tick})`;
      } else {
         return null;
      }
   };

   //stripe.com/docs.js for mre details on styling options
   const cardElementOptions = {
      hidePostalCode: true,
      style: {
         base: {
            fontSize: `17px`,
            color: `#515356`,
            '::placeholder': {
               color: `#9d9e9f`
            }
         },
         invalid: {
            color: `red`,
            iconColor: `red`
         },
         complete: {
            color: `green`
         }
      }
   };
   const productDetails = state?.selected_product ? (
      <div className="w-full border border-gray-300 rounded-md p-2 mb-1 mt-3">
         <div className="w-4/6">
            <div className="w-full">{state?.selected_product?.name}</div>
            <div className="w-full">{state?.selected_product?.description}</div>
            <div className="w-full">{state?.selected_product?.minutes}</div>
            <div className="w-full">{state?.selected_product?.price}</div>
         </div>
      </div>
   ) : null;

   const mixDetails = state?.selected_mix ? (
       <div className="relative w-full sm:border-r-0 border-r-2 sm:border-l-0 border-l-2 border-b-2 border-gray-200 cursor-default">
          <div className="flex bg-gray-800">
             <div
                 className="flex-none h-100 w-100 bg-cover bg-no-repeat bg-center xs:hidden"
                 style={{
                    backgroundImage: `url(${addDataStorePathMixImages(state?.selected_mix?.cover)})`
                 }}
             ></div>

             <div className="flex w-full justify-end">
                <div className="w-full p-2 pl-4">
                   <h2 className="font-Pulp font-normal text-2xl sm:text-xl xs:mt-2 mt-1 tracking-wide leading-tight text-white">
                      {state?.selected_mix?.title}
                   </h2>
                   <div>
                      <h5 className="text-sm text-mixinlab-pewter mt-0.5">
                         Mixed by{' '}
                         <span className="text-mixinlab-indigo_mid font-semibold">
                           {state?.selected_mix?.user.display_name}
                         </span>
                      </h5>
                   </div>
                   <div>
                      {moment(state?.selected_mix?.createdAt, 'YYYYMMDD').fromNow()}
                   </div>
                </div>
             </div>
          </div>
       </div>
   ) : null;

   const alert = state.alert ? <AlertFrontEnd msg={state.alert} /> : null;
   const submitButton = (
      <button
         type="submit"
         disabled={state.is_submit_button_disabled}
         className={`${
            state.is_submit_button_disabled
               ? 'opacity-30'
               : 'hover:text-mixinlab-teal hover:bg-white cursor-pointer'
         }   h-10 w-24 p-1 font-Pulp font-normal text-xl tracking-wide text-white rounded-lg border-2 bg-mixinlab-teal border-mixinlab-teal focus:outline-none`}
      >
         Pay
      </button>
   );
   const spinner = state.payment_processing ? <Spinner /> : null;
   const paymentSuccessMessage = state.is_payment_succeeded ? (
      <div className="w-full border border-gray-300 rounded-md p-2 mb-1 mt-3">
         Thank you! Your Payment Was Successful
      </div>
   ) : null;

   const optInAddressSave = (
      <div className="w-full border border-gray-300 rounded-md p-2 mb-1 mt-1 mb-2">
         <input
            type="checkbox"
            className="w-8 h-8"
            id="is_save_billing_details"
            name="is_save_billing_details"
            className="m-2"
            defaultChecked={state.is_save_billing_details}
            onChange={handleCheck}
         />
         Save address details
      </div>
   );

   const purchaseForm =
      state?.selected_product && state.is_display_form ? (
         <form onSubmit={handleFormSubmit} className="w-full mx-auto">
            <br />
            <div className="w-full border border-gray-300 rounded-md p-2 mb-1 mt-3">
               <div className="w-full p-2 mb-1 mt-3 ">
                  <input
                     className={`text-lg font-semibold bg-no-repeat bg-right w-full text-black focus:outline-none p-1 border-b-2 border-gray-200 p-1 ${billingFormFieldValidation(
                        state.is_name_valid
                     )}`}
                     type="text"
                     name="name"
                     placeholder="Name"
                     value={state.name}
                     required
                     onChange={handleChange}
                     style={{
                        backgroundImage: greenTickOnValidation(state.is_name_valid)
                     }}
                  />
               </div>

               <div className="w-full p-2 mb-1 mt-3">
                  <input
                     className={`text-lg font-semibold bg-no-repeat bg-right w-full text-black focus:outline-none border-b-2 border-gray-200 p-1 ${billingFormFieldValidation(
                        state.is_address_line_1_valid
                     )}`}
                     type="text"
                     name="address_line_1"
                     placeholder="Address Line 1"
                     value={state.address_line_1}
                     required
                     onChange={handleChange}
                     style={{
                        backgroundImage: greenTickOnValidation(
                           state.is_address_line_1_valid
                        )
                     }}
                  />
               </div>
               <div className="flex items-center p-2 mb-1 mt-3">
                  <input
                     className={`text-lg font-semibold bg-no-repeat bg-right w-full text-black focus:outline-none border-b-2 border-gray-200 p-1 ${billingFormFieldValidation(
                        state.is_city_valid
                     )}`}
                     type="text"
                     name="city"
                     placeholder="City"
                     value={state.city}
                     onChange={handleChange}
                     style={{
                        backgroundImage: greenTickOnValidation(state.is_city_valid)
                     }}
                  />
                  <input
                     className={`text-lg font-semibold bg-no-repeat bg-right w-full text-black focus:outline-none border-b-2 border-gray-200 p-1 ${billingFormFieldValidation(
                        state.is_state_valid
                     )}`}
                     type="text"
                     name="state"
                     placeholder="State"
                     value={state.state}
                     onChange={handleChange}
                     style={{
                        backgroundImage: greenTickOnValidation(state.is_state_valid)
                     }}
                  />
               </div>

               <div className="flex items-center p-2 mb-1 mt-3">
                  <input
                     className={`text-lg font-semibold bg-no-repeat bg-right w-full text-black focus:outline-none border-b-2 border-gray-200 p-1 ${billingFormFieldValidation(
                        state.is_postal_code_valid
                     )}`}
                     type="text"
                     name="postal_code"
                     placeholder="Postal Code / Zip Code"
                     value={state.postal_code}
                     onChange={handleChange}
                     style={{
                        backgroundImage: greenTickOnValidation(
                           state.is_postal_code_valid
                        )
                     }}
                  />
               </div>
               <div className="flex items-center p-2 mb-1 mt-3">
                  <select
                     className={`text-lg font-semibold  bg-no-repeat bg-right w-full p-1  focus:outline-none ${billingFormFieldValidation(
                        state.is_country_valid
                     )}`}
                     name="country"
                     id="country"
                     onChange={handleChange}
                     style={{
                        backgroundImage: greenTickOnValidation(state.is_country_valid)
                     }}
                  >
                     <option value="" className="text-gray-400">
                        Country
                     </option>
                     {constants.stripe_countries.map((option) => (
                        <option
                           key={'country-' + option.code}
                           className="text-black"
                           value={option.code}
                        >
                           {option.country}
                        </option>
                     ))}
                  </select>
               </div>
            </div>
            <br />
            <div className="border border-gray-300 rounded-md p-3 mb-1 mt-3">
               <CardElement
                  options={cardElementOptions}
                  onChange={isCardElementCompleted}
               />
            </div>
            <br />
            {spinner}
            {optInAddressSave}
            {submitButton}
         </form>
      ) : null;

   return (
      <Fragment>
         <div className="w-600 sm:w-full h-screen m-1">
            <PageHeader page={'Buy Minutes'} is_bottom_border_on={false} />
            <div className="grid grid-cols md:grid-cols-1 gap-2 content-center pl-2.5 ">
               {productDetails}
               {mixDetails}
               {paymentSuccessMessage}
               {alert}
               {purchaseForm}
            </div>
         </div>
         <div className="w-72 pt-64 mt-6 lg:hidden ml-14 xl:ml-6"></div>
      </Fragment>
   );
};

Purchase.propTypes = {
   auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth
});

export default connect(mapStateToProps)(Purchase);
