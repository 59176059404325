// React and PropTypes.
import { Fragment, useEffect, useState } from 'react';
import React, { Link, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Import components.
import Alert from '../components/Alert';
import TermsOfServiceModal from '../components/TermsOfServiceModal';
import GaPageView from '../components/GaPageView';

// Formik & Yup.
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

// Import actions.
import { setAlert } from '../actions/alert';
import { resetStatus } from '../actions/auth';
import { signUp } from '../actions/auth';

// Auth is global state or Redux store.
const SignUp = ({ signUp, setAlert, auth, user }) => {
   const [showTermsOfServiceModal, setShowTermsOfServiceModal] = useState(false);
   const history = useHistory();

   useEffect(() => {
      if (!auth.loading && auth.status === 'success') {
         setTimeout(() => history.push('/login'), 500);
      }
      if (!auth.loading && auth.status === 'fail') {
         setAlert(auth);
      }
   }, [auth, setAlert]);

   // Redirect if logged in.
   useEffect(() => {
      if (auth.isAuthenticated) {
         return <Redirect to="/home" />;
      }
   }, [auth.isAuthenticated]);

   // Redirect to login after successful signup
   useEffect(() => {
      if (auth.isAuthenticated) {
         return <Redirect to="/login" />;
      }
   }, [auth.isAuthenticated]);

   const displayLicence = () => {
      let chosenLicence = null;
      let plan = localStorage.getItem('plan');

      if (plan) {
         chosenLicence = plan;
      }
      return chosenLicence;
   };

   // Formik init values.
   let initialValues = {};

   initialValues = {
      display_name: '',
      user_name: '',
      email: '',
      password: '',
      password_confirm: ''
   };

   const validationSchema = Yup.object({
      display_name: Yup.string()
         .max(30, 'Must be 30 characters or less')
         .required('Required'),
      user_name: Yup.string()
         .matches(/^[a-z]+(?:_+[a-z]+)*$/, 'Lowercase and underscores only')
         .min(5, 'Min. 5 characters')
         .max(50, 'Max. 50 characters')
         .required('Required'),
      email: Yup.string().email(`Invalid email`).required('Required'),
      password: Yup.string()
         .min(8, 'Min. 8 characters')
         .max(30, 'Max. 30 characters')
         .required('Required')
         .matches(
            '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
         ),
      password_confirm: Yup.string().oneOf(
         [Yup.ref('password'), null],
         'Passwords must match'
      )
   });

   const onSubmit = async (data) => {
      const { display_name, user_name, email, password } = data;

      const licence = localStorage.getItem('plan_id');

      signUp({
         licence,
         display_name,
         user_name,
         email,
         password
      });
   };

   return (
      <Fragment>
         <GaPageView/>
         <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
         >
            {({ errors, touched }) => (
               <div className="flex justify-center">
                  <div className="w-10/12 max-w-400 mb-24">
                     <div className="h-24 flex flex-wrap content-end">
                        <h1 className="font-Pulp font-bold text-3xl mb-5 pt-2 ml-1">
                           Create your{' '}
                           <span className="text-mixinlab-indigo">
                              {displayLicence()}{' '}
                           </span>{' '}
                           account
                        </h1>
                     </div>

                     <Alert />

                     {/* Form tag. */}
                     <Form className="flex flex-col">
                        {/* Display name */}
                        <div className="mb-5">
                           <label htmlFor="display_name"></label>
                           <div className="flex items-center border rounded-md border-gray-400 p-1">
                              <Field
                                 id="display_name"
                                 type="text"
                                 name="display_name"
                                 placeholder="Artist or nickname"
                                 className="placeholder-black appearance-none pb-1.5 pt-0.5 px-3 block text-xl font-semibold bg-transparent border-none w-full text-black focus:outline-none"
                                 aria-label="Display name"
                              />
                           </div>
                           <div>
                              {errors.display_name && touched.display_name ? (
                                 <h6 className="text-sm text-red-600 pl-2">
                                    {errors.display_name}
                                 </h6>
                              ) : (
                                 <h6 className="text-sm text-gray-600 pl-2">
                                    Display name (change anytime)
                                 </h6>
                              )}
                           </div>
                        </div>

                        {/* User name. */}
                        <div className="mb-5">
                           <label htmlFor="user_name"></label>
                           <div className="flex items-center border rounded-md border-gray-400 p-1">
                              <Field
                                 id="user_name"
                                 type="text"
                                 name="user_name"
                                 placeholder="user_name"
                                 className="placeholder-black appearance-none pb-1.5 pt-0.5 px-3 block text-xl font-semibold bg-transparent border-none w-full text-black focus:outline-none"
                                 aria-label="User name"
                              />
                           </div>
                           <div>
                              {errors.user_name && touched.user_name ? (
                                 <h6 className="text-sm text-red-600 pl-2">
                                    {errors.user_name}
                                 </h6>
                              ) : (
                                 <h6 className="text-sm text-gray-600 pl-2">
                                    Min. 5 characters (lowercase and underscores)
                                 </h6>
                              )}
                           </div>
                        </div>

                        {/* Email. */}
                        <div className="mb-5">
                           <label htmlFor="email"></label>
                           <div className="flex items-center border rounded-md border-gray-400 p-1">
                              <Field
                                 id="email"
                                 type="email"
                                 name="email"
                                 placeholder="Email"
                                 className="placeholder-black appearance-none pb-1.5 pt-0.5 px-3 block text-xl font-semibold bg-transparent border-none w-full text-black focus:outline-none"
                                 aria-label="Email"
                              />
                           </div>
                           <div>
                              {errors.email && touched.email ? (
                                 <h6 className="text-sm text-red-600 pl-2">
                                    {errors.email}
                                 </h6>
                              ) : (
                                 <h6 className="text-sm text-gray-600 pl-2">Email</h6>
                              )}
                           </div>
                        </div>

                        {/* Password. */}
                        <div className="mb-5">
                           <label htmlFor="password"></label>
                           <div className="flex items-center border rounded-md border-gray-400 p-1">
                              <Field
                                 id="password"
                                 type="password"
                                 name="password"
                                 placeholder="Password"
                                 className="placeholder-black appearance-none pb-1.5 pt-0.5 px-3 block text-xl font-semibold bg-transparent border-none w-full text-black focus:outline-none"
                                 aria-label="Password"
                              />
                           </div>
                           <div>
                              {errors.password && touched.password ? (
                                 <h6 className="text-sm text-red-600 pl-2">
                                    {errors.password}
                                 </h6>
                              ) : (
                                 <h6 className="text-sm text-gray-600 pl-2">
                                    Password (min. 8 Characters)
                                 </h6>
                              )}
                           </div>
                        </div>

                        {/* Confirm password. */}
                        <div className="mb-5">
                           <label htmlFor="confirm"></label>
                           <div className="flex items-center border rounded-md border-gray-400 p-1">
                              <Field
                                 id="password_confirm"
                                 type="password"
                                 name="password_confirm"
                                 placeholder="Confirm password"
                                 className="placeholder-black appearance-none pb-1.5 pt-0.5 px-3 block text-xl font-semibold bg-transparent border-none w-full text-black focus:outline-none"
                                 aria-label="confirm password"
                              />
                           </div>
                           <div>
                              {errors.password_confirm && touched.password_confirm ? (
                                 <h6 className="text-sm text-red-600 pl-2">
                                    {errors.password_confirm}
                                 </h6>
                              ) : (
                                 <h6 className="text-sm text-gray-500 pl-2">
                                    Confirm password
                                 </h6>
                              )}
                           </div>
                        </div>

                        {/* Next button. */}
                        <div className="mx-auto mt-1">
                           <button
                              type="submit"
                              className="mt-5 py-1.5 px-5 font-Pulp font-normal text-xl tracking-wide text-white rounded-lg border-2 bg-mixinlab-teal border-mixinlab-teal hover:bg-white hover:text-mixinlab-teal cursor-pointer focus:outline-none"
                           >
                              Next
                           </button>
                        </div>
                        <h6 className="text-sm mx-auto text-gray-500 mt-3 pl-1">
                           Already have an account?
                           <Link
                              onClick={resetStatus}
                              to="/login"
                              className="font-bold pl-1 text-mixinlab-teal"
                           >
                              Login
                           </Link>
                        </h6>
                     </Form>
                  </div>
               </div>
            )}
         </Formik>
         {showTermsOfServiceModal ? (
            <TermsOfServiceModal
               showModal={showTermsOfServiceModal}
               setShowTermsOfServiceModal={setShowTermsOfServiceModal}
            />
         ) : null}
      </Fragment>
   );
};

SignUp.propTypes = {
   signUp: PropTypes.func.isRequired,
   setAlert: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired,
   user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth,
   user: state.user
});

export default connect(mapStateToProps, { setAlert, signUp })(SignUp);
