import React, { Fragment } from 'react';
import { matchPath } from 'react-router-dom';

import PlayerSpacer from './PlayerSpacer';
import AudioPlayer from './AudioPlayer';
import NavMobile from './NavMobile';

// Import utils.
import constants from '../configs/constants';

const RightColumn = () => {
   const is_active_route = matchPath(window.location.pathname, {
      path: constants.valid_routes,
      exact: true,
      strict: false
   });

   if (is_active_route) {
      return (
         <Fragment>
            <div className="flex justify-end lg:justify-center">
               <div className="fixed z-10 w-72 lg:w-screen lg:bottom-0">
                  <div className="lg:hidden">
                     <PlayerSpacer />
                  </div>
                  <div>
                     <AudioPlayer />
                  </div>
                  <div className="hidden md:block justify-start">
                     <NavMobile />
                  </div>
               </div>
            </div>
         </Fragment>
      );
   } else {
      return (
         <h2 className="text-center font-Pulp text-xl mt-24">(404) Page Not Found</h2>
      );
   }
};

export default RightColumn;
