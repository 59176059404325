// Import React.
import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// Redux.
import { connect } from 'react-redux';

// Google Analytics
import ReactGA from "react-ga";

// Import images.
import smiley from '../assets/smiley.svg';

// Import actions.
import { uploadMix, cancelUploadMix } from '../actions/mix';

// Import components.
import AlertFrontEnd from './AlertFrontEnd';


const UploadFile = ({
   mix,
   uploadMix,
   cancelUploadMix,
   hasChosen,
   pre_tracklist,
   has_chosen,
   mix_title,
   file_img,
   addTracklist
}) => {
   const [state, setState] = useState({
      audio_file: null,
      audio_file_name: '',
      upload: true,
      progress_bar: false,
      finishing_up: false,
      cancel: false,
      success: false,
      file_err_msg: ''
   });

   let mp3 = useRef(null);

   // Add finishing up after mix uploads.
   useEffect(() => {
      if (mix.progress === 100) {
         setState({
            ...state,
            progress_bar: false,
            finishing_up: true
         });
      }
   }, [mix.progress]);

   // Show success message when upload is complete.
   useEffect(() => {
      if (mix.mix_uploaded_status === 'success') {
         setState({
            ...state,
            progress_bar: false,
            finishing_up: false,
            success: true
         });
      }
   }, [mix.mix_uploaded_status]);

   // Handle input for Update.
   const handleMP3Input = (e) => {
      e.preventDefault();

      hasChosen();

      let reader = new FileReader();
      let file = e.target.files[0];

      // Format check conditions.
      const checkFileFormat = () => {
         const formats = 'audio/mpeg';
         const checkFormats = formats.includes(file.type);
         return checkFormats;
      };

      // Check file.
      const checkFile = () => {
         let file_err;
         if (file.size > 300000000) {
            file_err = 'File too large (max. 300MB)';

            setTimeout(function () {
               setState({
                  ...state,
                  audio_file: null,
                  file_err_msg: null
               });
            }, 3000);
         }
         if (file.size <= 300000000) {
            file_err = null;
         }
         if (!checkFileFormat()) {
            file_err = 'Wrong format! We only accept mp3 right now.';
            setTimeout(function () {
               setState({
                  ...state,
                  audio_file: null,
                  file_err_msg: null
               });
            }, 3000);
         }

         if (file_err) {
            mp3.current.value = null;
         }

         return file_err;
      };

      reader.onloadend = () => {
         setState({
            ...state,
            audio_file: file,
            audio_file_name: file.name,
            file_err_msg: checkFile()
         });
      };

      if (file) {
         reader.readAsDataURL(file);
      }
   };

   // Submit mix.
   const Submit = async (e) => {
      e.preventDefault();

      setState({
         ...state,
         upload: false,
         progress_bar: true
      });

      // Instantiate new form.
      const formData = new FormData();

      // Append text fields.
      formData.append('cover', file_img);
      formData.append('title', mix_title);
      formData.append('mp3', state.audio_file);

      await uploadMix(formData);
   };

   const cancelUpload = async () => {
      cancelUploadMix();
      setState({
         ...state,
         progress_bar: false,
         cancel: true
      });
   };

   // Show chosen file details.
   const instruction = has_chosen ? (
      <div className="grid content-center">
         <div className="flex mt-0.5">
            <h2 className="font-Pulp text-xl">
               File:
               <span className="font-sans text-base ml-3 pt-0.5">
                  '{state.audio_file_name}'
               </span>{' '}
            </h2>
         </div>
      </div>
   ) : (
      <div className="grid content-center">
         <div className="flex mt-0.5">
            <h2 className="font-Pulp text-xl">
               Choose file:
               <span className="font-sans text-sm ml-3 mt-0.5">
                  We only accept MP3s right now, (min. 5 tracks - no albums).
               </span>
            </h2>
         </div>
      </div>
   );

   // Upload button.
   const upload =
      state.upload && pre_tracklist ? (
         <div className="mt-4 mb-1 flex justify-end">
            <button
               onClick={() => {
                  mp3.current.click();
               }}
               className="h-9 px-3 pt-0.5 grid content-center font-Pulp text-lg hover:text-white bg-white cursor-pointer border-2 border-mixinlab-indigo hover:bg-mixinlab-indigo text-mixinlab-indigo focus:outline-none rounded"
            >
               Choose file
            </button>

            {has_chosen ? (
               <button
                  onClick={Submit}
                  className="h-9 px-3 pt-0.5 grid content-center ml-4 font-Pulp text-lg text-white bg-mixinlab-indigo cursor-pointer border-2 border-mixinlab-indigo hover:bg-white hover:text-mixinlab-indigo focus:outline-none rounded"
               >
                  Upload
               </button>
            ) : (
               <button className="h-9 px-3 pt-0.5 tracking-wide grid content-center opacity-30 ml-4 font-Pulp text-lg text-white bg-mixinlab-indigo cursor-pointer border-2 border-mixinlab-indigo focus:outline-none rounded">
                  Upload
               </button>
            )}
         </div>
      ) : null;

   // Alert.
   const alert = state.file_err_msg ? (
      <AlertFrontEnd msg={state.file_err_msg} />
   ) : null;

   // Progress bar.
   const progressBar =
      state.progress_bar && pre_tracklist ? (
         <div className="flex justify-between h-10 mt-3">
            <div className="grid content-center w-full">
               <div className="bg-gray-100 h-6 rounded-md">
                  <div
                     className="bg-mixinlab-indigo rounded-md h-6 text-white text-center grid content-center font-Pulp text-sm"
                     style={{ width: `${mix.progress}%` }}
                  >
                     {mix.progress}%
                  </div>
               </div>
            </div>

            <div className="grid content-center pl-12">
               <button
                  onClick={cancelUpload}
                  className="h-10 w-24 font-Pulp font-normal text-lg hover:text-white bg-white cursor-pointer border-2 border-mixinlab-indigo hover:bg-mixinlab-indigo text-mixinlab-indigo focus:outline-none rounded"
               >
                  Cancel
               </button>
            </div>
         </div>
      ) : null;

   // Finishing up.
   const finishingUp =
      state.finishing_up && pre_tracklist ? (
         <div className="flex justify-end h-10 mt-3 mr-2">
            <div className="flex">
               <div className="pt-1.5 mr-4 font-Pulp font-normal text-xl text-mixinlab-indigo">
                  Finishing up... won't be a minute
               </div>

               <img
                  src={smiley}
                  alt="basketball"
                  className="w-12 h-12 animate-bounce"
               />
            </div>
         </div>
      ) : null;

   // Cancel.
   const cancel =
      state.cancel && pre_tracklist ? (
         <div className="flex h-10 mt-3">
            <div className="w-full text-center pt-1.5 font-Pulp font-normal text-xl text-mixinlab-indigo">
               Upload cancelled
            </div>
         </div>
      ) : null;

   return (
      <Fragment>
         <div className="border-gray-200 border-l-2 border-b-2 border-r-2 sm:border-l-0 sm:border-r-0 px-4 py-3">
            <input
               className="hidden"
               name="mp3"
               id="mp3"
               ref={mp3}
               type="file"
               accept="audio/*"
               onChange={handleMP3Input}
            />
            {alert}
            {instruction}
            {progressBar}
            {finishingUp}
            {upload}
            {cancel}
         </div>
      </Fragment>
   );
};

UploadFile.propTypes = {
   uploadMix: PropTypes.func.isRequired,
   cancelUploadMix: PropTypes.func.isRequired,
   mix: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({ mix: state.mix });

export default connect(mapStateToProps, {
   uploadMix,
   cancelUploadMix
})(UploadFile);
