// React
import React from 'react';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions
import { discoverMusicSearch, discoverMusicReset } from '../../../actions/mix';
import MusicSearch from './MusicSearch';

const MusicWrapper = ({
   showUser,
   mix,
   discoverMusicSearch,
   discoverMusicReset
}) => {
   return (
      <div>
         <div className="flex relative">
            <div
               onClick={showUser}
               className="w-36 pt-1 border-r-0 border-t-2 border-l-2 border-b-2 border-gray-200 font-Pulp font-semibold text-black text-lg text-center cursor-pointer"
            >
               People
            </div>
            <div className="h-10 border-r-2 border-gray-200"></div>
            <div className="w-36 pr-0.5 h-10 pt-1 border-l-0 border-t-2 border-r-2 border-gray-200 font-Pulp font-semibold text-lg text-center cursor-default">
               Music
            </div>
         </div>

         <div className="h-350 border-l-2 border-r-2 border-b-2 py-3 px-4 pt-2 border-gray-200">
            <MusicSearch
               mix={mix}
               discoverMusicSearch={discoverMusicSearch}
               discoverMusicReset={discoverMusicReset}
            />
         </div>
      </div>
   );
};

MusicWrapper.propTypes = {
   auth: PropTypes.object.isRequired,
   mix: PropTypes.object.isRequired,
   discoverMusicReset: PropTypes.func.isRequired,
   discoverMusicSearch: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
   mix: state.mix,
   auth: state.auth
});

export default connect(mapStateToProps, {
   discoverMusicSearch,
   discoverMusicReset
})(MusicWrapper);
