// React.
import React, { Fragment } from 'react';

// Import components.
import MusicSearch from '../../music/MusicSearch';

const ModalMusicWrapper = ({ showUser }) => {
   return (
      <Fragment>
         <div onClick={showUser} className="flex justify-around mb-8">
            <div className="pl-1 pt-1 font-Pulp text-base text-3xl font-bold text-gray-300 hover:text-black cursor-pointer">
               People
            </div>

            <div className="pl-1 pt-1 font-Pulp text-base text-3xl font-bold cursor-default">
               Music
            </div>
         </div>
         <MusicSearch />
      </Fragment>
   );
};

export default ModalMusicWrapper;
