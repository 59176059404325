// Import React.
import React, { Fragment, useState } from 'react';
import styles from '../css/styles.module.css';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import { pauseAudio } from '../actions/mix';

const MixPlayerButtonPause = ({ pauseAudio }) => {
   const [isHovering, setIsHovered] = useState(false);
   const onMouseEnter = () => setIsHovered(true);
   const onMouseLeave = () => setIsHovered(false);

   const handlePauseAudio = (e) => {
      e.stopPropagation();
      pauseAudio();
   };

   const playButton = (
      <div
         className="flex flex-row items-center group"
         onMouseEnter={onMouseEnter}
         onMouseLeave={onMouseLeave}
      >
         {isHovering ? (
            <div className="group">
               <div
                  onClick={pauseAudio}
                  className="h-12 w-12 grid content-center justify-center cursor-pointer rounded-full border group-hover:border-mixinlab-indigo_mid group-hover:bg-mixinlab-indigo_mid"
               >
                  <div className="grid content-center">
                     <div className="flex">
                        <div className={`${styles.pauseWhiteMix} mr-1`}></div>
                        <div className={styles.pauseWhiteMix}></div>
                     </div>
                  </div>
               </div>
            </div>
         ) : (
            <div
               onClick={handlePauseAudio}
               className="h-12 w-12 grid content-center justify-center cursor-pointer rounded-full border border-gray-800"
            >
               <div className="grid content-center">
                  <div className="flex">
                     <div className={`${styles.pause} mr-1`}></div>
                     <div className={styles.pause}></div>
                  </div>
               </div>
            </div>
         )}
      </div>
   );

   return <Fragment>{playButton}</Fragment>;
};

MixPlayerButtonPause.propTypes = {
   pauseAudio: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
   pauseAudio
})(MixPlayerButtonPause);
