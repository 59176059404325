// React.
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import { getFollowers, getFollowing } from '../actions/follow';
import { getMe } from '../actions/me';

// Import components
import Follow from '../components/Follow';
import Spinner from '../components/Spinner';

const FollowerFeed = ({
   auth,
   me,
   follow: {
      followers,
      following,
      loading_followers,
      loading_following,
      follow_unfollow_done
   },
   getFollowers,
   getFollowing,
   getMe,
   backButton
}) => {
   // Params.
   const { user_name } = useParams();
   const [state, setState] = useState({ isLoggedInUser: true });

   // getFollowing first. Use this data to check if my followers and following me.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         getFollowing(user_name);
      }
   }, [auth, getFollowing, user_name]);

   // Update getFollowing once user unfollows/follows.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated && follow_unfollow_done) {
         getFollowing(user_name);
         getMe();
      }
   }, [auth, getFollowing, follow_unfollow_done, user_name]);

   // getFollowers. Compare user ids.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated && !loading_following) {
         getFollowers(user_name);
      }
   }, [auth, getFollowers, loading_following, user_name]);

   // getFollowers once I unfollow a user I am following on Following feed.
   useEffect(() => {
      if (
         !auth.loading &&
         auth.isAuthenticated &&
         follow_unfollow_done &&
         !loading_following
      ) {
         getFollowers(user_name);
      }
   }, [auth, getFollowers, follow_unfollow_done, loading_following, user_name]);

   // Check if we are displaying Followers of the logged in user
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         if (me.user_name === user_name) {
            setState({
               ...state,
               isLoggedInUser: true
            });
         } else {
            setState({
               ...state,
               isLoggedInUser: false
            });
         }
      }
   }, [auth, user_name, state.isLoggedInUser]);

   // Compile followers.
   const Followers = followers.map((follower, index) => {
      if (follower?._id) {
         // Check if I am following a follower?
         let amIFollowingUser;

         if (state.isLoggedInUser) {
            amIFollowingUser = following.some(
               (following) => following?.id === follower?.id
            );
         } else {
            amIFollowingUser = me.following.some(
               (meFollowing) => follower?.id === meFollowing?.user?._id
            );
         }

         return (
            <Follow
               id={follower._id}
               key={index}
               licence={follower?.licence?.display_name}
               user_image={follower?.user_image}
               display_name={follower?.display_name}
               user_name={follower?.user_name}
               short_bio={follower?.short_bio}
               followsMe={amIFollowingUser}
               backButton={backButton}
            />
         );
      }
   });

   return (
      <Fragment>
         <div className="w-full">
            {loading_followers ? <Spinner /> : <div>{Followers}</div>}
         </div>
      </Fragment>
   );
};

FollowerFeed.propTypes = {
   auth: PropTypes.object.isRequired,
   follow: PropTypes.object.isRequired,
   getFollowers: PropTypes.func.isRequired,
   getMe: PropTypes.func.isRequired,
   getFollowing: PropTypes.func.isRequired,
   me: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   follow: state.follow,
   auth: state.auth,
   me: state.me
});

export default connect(mapStateToProps, {
   getFollowers,
   getFollowing,
   getMe
})(FollowerFeed);
