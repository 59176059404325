// React.
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Moment.
import moment from 'moment';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import { getUserMixes } from '../actions/content';

// Import components
import MixPost from './MixPost';
import Spinner from './Spinner';

const MixesWrapperUser = ({ auth, me, mix, content, getUserMixes, showComments }) => {
   const { user_name } = useParams();

   const [state, setState] = useState({
      posts_arr: []
   });

   // On PAGE LOAD.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         getUserMixes(user_name);
      }
   }, [auth, getUserMixes, user_name]);

   // On PROFILE IMG / BG IMG CHANGE.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated && me.loaded) {
         getUserMixes(user_name);
      }
   }, [auth, me.loaded, getUserMixes, user_name]);

   // 3. Set local state with content.posts from content reducer.
   useEffect(() => {
      const PostsArr = content.posts;
      if (!auth.loading && auth.isAuthenticated) {
         setState({ ...state, posts_arr: PostsArr });
      }
      const newPostsArr = PostsArr.map((obj) => {
         // 👇️ if id equals 2 replace object
         if (obj.type == 'mix') {
            if (
               obj.mix._id === mix.current_mix_id &&
               obj._id === mix.current_mix_post_id
            ) {
               obj.in_focus = true;
               obj.current_mix_is_playing = mix.current_mix_is_playing;
               return obj;
            } else {
               obj.in_focus = false;
               obj.current_mix_is_playing = false;
               return obj;
            }
         }
         if (obj.type == 'share') {
            if (obj.shared_content?.type === 'mix') {
               if (
                  obj.shared_content.mix?._id === mix.current_mix_id &&
                  obj._id === mix.current_mix_post_id
               ) {
                  obj.in_focus = true;
                  obj.current_mix_is_playing = mix.current_mix_is_playing;
                  return obj;
               } else {
                  obj.in_focus = false;
                  obj.current_mix_is_playing = false;
                  return obj;
               }
            }
         }
         // 👇️ otherwise return object as is
         return obj;
      });

      setState({ ...state, posts_arr: newPostsArr });
   }, [auth, content.posts, mix.current_mix_id, mix.current_mix_is_playing]);

   // On SHARE.
   useEffect(() => {
      if (
         !auth.loading &&
         auth.isAuthenticated &&
         content.status_share_content === 'success'
      ) {
         getUserMixes(user_name);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [auth, getUserMixes, content.status_share_content]);

   // On DELETE.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated && content.is_deleted) {
         getUserMixes(user_name);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [auth, getUserMixes, content.is_deleted]);

   // On COMMENT.
   useEffect(() => {
      if (
         !auth.loading &&
         auth.isAuthenticated &&
         content.status_comment === 'success'
      ) {
         getUserMixes(user_name);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [auth, getUserMixes, content.status_comment]);

   // Compile feed posts.
   const Mixes = content.posts.map((post, index) => {
      let isAuthorMe = false;

      if (me.id === post.user._id) {
         isAuthorMe = true;
      }

      if (post.type === 'mix') {
         return (
            <MixPost
               key={index}
               id={post.id}
               in_focus={post.in_focus}
               is_playing={post.current_mix_is_playing}
               mix_id={post.mix.id}
               cover={post.mix.cover}
               mix_title={post.mix.title}
               display_name={post.user.display_name}
               user_name={post.user.user_name}
               tracks={post.mix.tracks}
               comment_count={post.comment_count_VIRTUAL}
               share_count={post.share_count_VIRTUAL}
               like_count={post.like_count_VIRTUAL}
               user_image={post.user.user_image}
               created_at={moment(post.createdAt, 'YYYYMMDD').fromNow()}
               is_author_me={isAuthorMe}
               time_stamp={me.time_stamp}
               showComments={showComments}
            />
         );
      }
      return null;
   });

   if (Mixes.length == 0) {
      Mixes.push(
         <div className="w-full mt-3 p-5 font-Pulp text-center text-lg text-gray-600 tracking-wide font-thin bg-gray-50 rounded-md">
            No mixes uploaded
         </div>
      );
   }

   return (
      <Fragment>
         <div className="w-full pb-60">
            {content.loading ? (
               <Spinner />
            ) : (
               <div>
                  {Mixes}
                  {/* <div className="h-10 w-full bg-gray-50" ref={lastPost}></div> */}
               </div>
            )}
         </div>
      </Fragment>
   );
};

MixesWrapperUser.propTypes = {
   auth: PropTypes.object.isRequired,
   me: PropTypes.object.isRequired,
   mix: PropTypes.object.isRequired,
   content: PropTypes.object.isRequired,
   getUserMixes: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth,
   me: state.me,
   content: state.content,
   mix: state.mix
});

export default connect(mapStateToProps, {
   getUserMixes
})(MixesWrapperUser);
