// React and PropTypes.
import React from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

// Connect Redux to component.
import { connect } from 'react-redux';

// Components.
import Alert from '../components/Alert';
import GaPageView from '../components/GaPageView';

// Formik & Yup
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

// Import actions.
import { setAlert } from '../actions/alert';
import { login } from '../actions/auth';
import ReactGA from 'react-ga';

const initialValues = {
   email: '',
   password: ''
};

const validationSchema = Yup.object({
   email: Yup.string().email(`Invalid email`).required('Required'),
   password: Yup.string().required('Required')
});

// isAuthenticated is global state or Redux store.
const Login = ({ login, setAlert, auth }) => {
   const history = useHistory();

   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated === true) {
         setTimeout(() => history.push('/home'), 300);
      } else if (auth.status === 'fail') {
         setAlert(auth); // {token: null, isAuthenticated: false, loading: false, user: null, status: 'fail', msg: 'no user found' }
      }
   });

   // Redirect if logged in.
   if (auth.isAuthenticated) {
      return <Redirect to="/home" />;
   }

   const onSubmit = async (formData) => {
      const { email, password } = formData; // {email: 'billy1@gmail.com', password: '12345678'}

      // Call login action with useParams.
      login({ email, password });

      localStorage.removeItem('plan');
      localStorage.removeItem('plan_id');
   };

   return (
      <Formik
         initialValues={initialValues}
         validationSchema={validationSchema}
         onSubmit={onSubmit}
      >
         {({ errors, touched }) => (
            <div className="flex justify-center">
               <GaPageView />
               <div className="w-10/12 max-w-400 mb-24">
                  <div className="h-24 flex flex-wrap content-end justify-between">
                     <h1 className="font-Pulp font-bold text-3xl mb-5 ml-2">Login</h1>
                     <Link to="/">
                        <h1 className="font-Pulp font-bold opacity-30 hover:opacity-100 cursor-pointer text-3xl mb-5 ml-2">
                           Signup?
                        </h1>
                     </Link>
                  </div>

                  <Alert />

                  {/* Form tag. */}
                  <Form className="flex flex-col">
                     {/* Email. */}
                     <div className="mb-5">
                        <label htmlFor="email"></label>
                        <div className="flex items-center border rounded-md border-gray-400 p-1">
                           <Field
                              id="email"
                              type="email"
                              name="email"
                              placeholder="Email"
                              className="placeholder-black appearance-none pb-1.5 pt-0.5 px-3 block text-xl font-semibold bg-transparent border-none w-full text-black focus:outline-none"
                              aria-label="Email"
                           />
                        </div>
                        <div>
                           {errors.email && touched.email ? (
                              <h6 className="text-sm text-red-600 pl-2">
                                 {errors.email}
                              </h6>
                           ) : (
                              <h6 className="text-sm text-gray-500 pl-2">Email</h6>
                           )}
                        </div>
                     </div>

                     {/* Password. */}
                     <div className="mb-5">
                        <label htmlFor="password"></label>
                        <div className="flex items-center border rounded-md border-gray-400 p-1">
                           <Field
                              id="password"
                              type="password"
                              name="password"
                              placeholder="Password"
                              className="placeholder-black appearance-none pb-1.5 pt-0.5 px-3 block text-xl font-semibold bg-transparent border-none w-full text-black focus:outline-none"
                              aria-label="Password"
                           />
                        </div>
                        <div>
                           {errors.password && touched.password ? (
                              <h6 className="text-sm text-red-600 pl-2">
                                 {errors.password}
                              </h6>
                           ) : (
                              <h6 className="text-sm text-gray-500 pl-2">
                                 Min. 8 Characters
                              </h6>
                           )}
                        </div>
                     </div>

                     {/* Next button. */}
                     <div className="mx-auto mt-1">
                        <button
                           type="submit"
                           className="mt-5 pt-2 pb-1.5 px-5 font-Pulp font-normal text-xl tracking-wide text-white rounded border-1.5 bg-mixinlab-teal border-2 border-mixinlab-teal hover:bg-white hover:text-mixinlab-teal cursor-pointer focus:outline-none"
                        >
                           Next
                        </button>
                     </div>
                     <h6 className="text-sm mx-auto text-gray-500 mt-6 pl-1">
                        Forgotten password?
                        <Link
                           to="/reset"
                           className="font-bold pl-1 text-mixinlab-teal"
                        >
                           click here
                        </Link>
                     </h6>
                  </Form>
               </div>
            </div>
         )}
      </Formik>
   );
};

// This is a checker like TypeScript.
Login.propTypes = {
   login: PropTypes.func.isRequired,
   setAlert: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth
});

export default connect(mapStateToProps, { setAlert, login })(Login);
