import {
    USER_CONTEXT_LOADED_SUCCESS,
    USER_CONTEXT_LOADED_FAIL,
    USER_CONTEXT_CLEARED,
    LOG_OUT
 } from '../actions/types';
 
 const initialState = {
    id: null,
    user_name: null,
    display_name: null,
    profile_image: null,
    profile_bg: null,
    short_bio: null,
    bio: null,
    location: null,
    web_display: null,
    web_full: null,
    following_length: null,
    followers_length: null,
    date: null,
    loading: true,
    error: {}
 };
 
 const userContext = (state = initialState, action) => {
    switch (action.type) {
       case USER_CONTEXT_LOADED_SUCCESS:
          return {
             ...state,
             loading: false,
             id: action.payload._id,
             user_name: action.payload.user_name,
             display_name: action.payload.display_name,
             profile_image: action.payload.user_image,
             profile_bg: action.payload.user_bg,
             short_bio: action.payload.short_bio,
             bio: action.payload.bio,
             location: action.payload.location,
             web_display: action.payload.web_display,
             web_full: action.payload.web_full,
             following_length: action.payload.followingCount,
             followers_length: action.payload.followersCount,
             date: action.payload.date
          };
       case USER_CONTEXT_LOADED_FAIL:
          return {
             ...state,
             loading: false,
             error: action.payload // Need to test and refine errors
          };
          case USER_CONTEXT_CLEARED:
            return {
               ...state,
               id: null,
               user_name: null,
               display_name: null,
               profile_image: null,
               profile_bg: null,
               short_bio: null,
               bio: null,
               location: null,
               web_display: null,
               web_full: null,
               date: null,
               loading: false,
               error: {}
            };
       case LOG_OUT:
          return {
             ...state,
             id: null,
             user_name: null,
             display_name: null,
             profile_image: null,
             profile_bg: null,
             short_bio: null,
             bio: null,
             location: null,
             web_display: null,
             web_full: null,
             following_length: null,
             followers_length: null,
             date: null,
             loading: true,
             error: {}
          };
       default:
          return state;
    }
 };

 export default userContext;
 