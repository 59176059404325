// Import React.
import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import { getUserContext, clearUserContext } from '../actions/userContext';
import {
   shareContent,
   deleteContent,
   getPostId,
   likeContent
} from '../actions/content';

// Import components.
import PostMenu from './PostMenu';
import PostMenuMask from './PostMenuMask';
import PostProfileCard from './PostProfileCard';
import PostProfileCardMask from './PostProfileCardMask';
import PostComment from './PostComment';
import PostReport from './PostReport';
import Comment from './Comment';
import Share from './Share';
import Like from './Like';
import ContentIcon from './Content';
import RichContentFeed from './RichContentFeed';
import CommentFeedNEW from './CommentFeedNEW';
import PostCommentInput from './PostCommentInput';

// Import utils.
import {
   extractLinksFromString,
   getLinkDetailsFromUrl,
   formatRichPost
} from '../utils/richUpdate';
import dataStorePath from '../configs/dataStorePath';
// import socialContextDisplay from '../utils/socialContextDisplay';

// Import icons.
import moreIcon from '../assets/more.svg';
import profilePlaceholderIconPerson from '../assets/placeholder_person.svg';

const Post = ({
   auth,
   is_single_content,
   content,
   comments,
   follow,
   getPostId,
   shareContent,
   deleteContent,
   getUserContext,
   clearUserContext,
   likeContent,
   id,
   user_id,
   plan,
   is_author_me,
   user_image,
   display_name,
   user_name,
   created_at,
   text,
   media,
   giphy,
   comment_count,
   share_count,
   like_count,
   display_follow_toggle_button,
   time_stamp
   // social_context
}) => {
   const [state, setState] = useState({
      post_menu: false,
      post_profile_card: false,
      post_comment_modal: false,
      post_report: false,
      is_followed: false,
      rich_content_api_success: false,
      rich_content_api_results: null,
      rich_content_display: false,
      links_in_text: []
   });

   // Extract rich content data from text and call APIs to get data obj, when text loads.
   useEffect(() => {
      // This function checks for valid domains in body copy and if domain exists pushes it to an array.
      let links_in_text = extractLinksFromString(text);
      // If a domain array exists, then this fucntion extracts url to call the relevant rich content API.
      if (links_in_text.length > 0) {
         getLinkDetailsFromUrl(links_in_text[0])
            .then((response) => {
               // Sets state with rich content API resonse.
               setState({
                  ...state,
                  rich_content_api_success: true,
                  rich_content_api_results: response
               });
            })
            .catch((err) => {
               console.log(err);
            });
      }
   }, [text]);

   // Wait for response from API to be true.
   useEffect(() => {
      if (state.rich_content_api_success === true) {
         setState({
            ...state,
            rich_content_display: true
         });
      }
   }, [state.rich_content_api_success]);

   // Close comment modal once comment is done.
   useEffect(() => {
      if (
         !auth.loading &&
         auth.isAuthenticated &&
         content.status_comment === 'success'
      )
         setState({
            ...state,
            post_comment_modal: false,
            post_comment_modal_allowed: true
         });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [auth, content.status_comment]);

   // Check if I am following post author.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         follow?.following?.some((follows) => follows?.id === user_id);
         setState({
            ...state,
            is_followed: true
         });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [auth, follow, user_id]);

   // Social context
   // let social_context_output_arr = socialContextDisplay(social_context, id);

   // Close PostProfileCard when unfollow / Follow is done.
   const closePostProfileCardOnFollowUnfollow = () => {
      setState({
         ...state,
         post_profile_card: false
      });
   };

   // Post profile card.
   const openPostProfileCard = () => {
      getUserContext(user_name);
      setState({
         ...state,
         post_profile_card: true,
         post_comment_modal_allowed: false
      });
   };

   const closePostProfileCard = () => {
      clearUserContext();
      setState({
         ...state,
         post_profile_card: false,
         post_comment_modal_allowed: true,
         is_followed: false
      });
   };

   // Open post modal.
   const openPostMenu = () => {
      setState({
         ...state,
         post_menu: true
      });
   };

   const closePostMenu = () => {
      setState({
         ...state,
         post_menu: false
      });
   };

   // Comment modals.
   const openCommentModal = () => {
      getPostId(id);
      setState({
         ...state,
         post_comment_modal: true
      });
   };

   const closeCommentModal = () => {
      setState({
         ...state,
         post_comment_modal: false
      });
   };

   // Post report.
   const openPostReport = () => {
      setState({
         ...state,
         post_report: true,
         post_menu: false
      });
   };

   const closePostReport = () => {
      setState({
         ...state,
         post_report: false
      });
   };

   const handleShareContent = () => {
      // Instantiate new form.
      const formData = new FormData();

      // Append.
      formData.append('type', 'share');
      formData.append('shared_content', id);

      shareContent(formData);
   };

   const handleLikeContent = () => {
      likeContent(id);
   };

   const handleBlockUser = () => {};

   const handleDeleteContent = () => {
      deleteContent(id);
      setState({
         ...state,
         post_menu: false
      });
   };

   const postMedia = media ? (
      <div className="pl-14 pr-12 mr-1">
         <img
            src={`${dataStorePath.content_images + media}`}
            className="w-auto max-h-56 rounded-lg bg-gray-100"
            alt={'Post'}
            onError={(e) => {
               e.target.onerror = null;
               e.target.src = '/images/content_not_available.png';
            }}
         />
      </div>
   ) : null;

   const postGiphy = giphy ? (
      <div className="pl-14 pr-12 mr-1">
         <img src={`${giphy}`} className="w-auto max-h-56 rounded-lg" alt={'Post'} />
      </div>
   ) : null;

   const richContent = state.rich_content_display ? (
      <div className="w-full mt-2">
         <RichContentFeed data={state.rich_content_api_results} />
      </div>
   ) : null;

   const profileImage = user_image ? (
      <div className="flex-none mt-1 w-14 sm:w-12 mr-3">
         <div
            className="h-12 w-12 mr-5 sm:mr-2 rounded-full bg-gray-100 bg-cover bg-no-repeat bg-center cursor-pointer"
            onClick={openPostProfileCard}
            style={{
               backgroundImage: `url(${
                  dataStorePath.user_profile_images + user_image
               }?${time_stamp})`
            }}
         ></div>
      </div>
   ) : (
      <div className="flex-none mt-1 w-14 sm:w-12 mr-3">
         <div
            className="h-12 w-12 rounded-full bg-50 bg-gray-100 bg-center bg-no-repeat cursor-pointer"
            style={{
               backgroundImage: `url(${profilePlaceholderIconPerson})`
            }}
         ></div>
      </div>
   );

   // const socialContext =
   //    social_context_output_arr.length === 0 ? null : (
   //       <div className="flex mt-1.5 w-11/12">{social_context_output_arr}</div>
   //    );

   const commentFeed = is_single_content ? (
      <CommentFeedNEW comments={comments} id={id} />
   ) : null;

   const socialWidgets = is_single_content ? null : (
      <div className="flex justify-around pb-1.5 pt-1 px-2">
         <Comment openCommentModal={openCommentModal} commentCount={comment_count} />
         <Share handleShareContent={handleShareContent} shareCount={share_count} />
         <Like handleLikeContent={handleLikeContent} likeCount={like_count} />
         <ContentIcon contentId={id} is_single_content={is_single_content} />
      </div>
   );

   const postCommentInput = is_single_content ? (
      <PostCommentInput postId={id} />
   ) : null;

   const hover = is_single_content ? null : 'hover:bg-gray-50';

   const more = is_single_content ? null : (
      <div
         className="h-8 w-8 items-center hover:bg-mixinlab-teal_15 rounded-full cursor-pointer"
         onClick={openPostMenu}
      >
         <div
            className="bg-80 bg-no-repeat bg-center h-8 w-8"
            style={{
               backgroundImage: `url(${moreIcon})`
            }}
            alt={'More'}
         ></div>
      </div>
   );

   return (
      <Fragment>
         <div
            className={`relative sm:border-r-0 border-r-2 border-b-2 sm:border-l-0 border-l-2 border-gray-200 ${hover} cursor-default`}
         >
            {/* PostMenu component. */}
            <PostMenu
               showModal={state.post_menu}
               handleDelete={handleDeleteContent}
               handleBlockUser={handleBlockUser}
               openPostReport={openPostReport}
               closePostReport={closePostReport}
               is_author_me={is_author_me}
               user_id={user_id}
            />

            <PostMenuMask showModal={state.post_menu} closeModal={closePostMenu} />

            <PostProfileCard
               showModal={state.post_profile_card}
               is_author_me={is_author_me}
               plan={plan}
               is_followed={state.is_followed}
               user_id={user_id}
               display_follow_toggle_button={display_follow_toggle_button}
               closePostProfileCardOnFollowUnfollow={
                  closePostProfileCardOnFollowUnfollow
               }
               closePostProfileCard={closePostProfileCard}
            />

            <PostProfileCardMask
               closePostProfileCard={closePostProfileCard}
               showModal={state.post_profile_card}
               is_author_me={is_author_me}
            />

            <PostComment
               postId={id}
               user_image={user_image}
               display_name={display_name}
               created_at={created_at}
               text={text}
               showCommentModal={state.post_comment_modal}
               closeCommentModal={closeCommentModal}
            />

            <PostReport
               postId={id}
               user_image={user_image}
               display_name={display_name}
               created_at={created_at}
               text={text}
               show_post_report={state.post_report}
               closePostReport={closePostReport}
            />

            {/* Profile image */}
            <div className="px-3 pt-3 pb-1">
               <div className="flex justify-between">
                  <div className="flex">
                     {profileImage}
                     <div className="">
                        {/* Display name */}
                        {/* {socialContext} */}
                        <h4 className="font-Pulp font-semibold text-xl leading-none tracking-wide pr-2 pt-1 pb-1 cursor-pointer">
                           <Link to={`/user/${user_name}`}>
                              <span className="hover:underline">{display_name}</span>
                           </Link>

                           {/* Created at */}
                           <span className="font-Pulp font-thin text-lg tracking-wide text-gray-500 pt-1 pl-2">
                              <span className="text-sm mr-1.5">~</span>
                              {created_at}
                           </span>
                        </h4>

                        {/* Text */}
                        {/* This width strategy is causing the width to not be fixed when page is in mobile view.
                        Need a new strategy for fixed width break-words */}
                        {/* <div className={styles.postTextWidth}> */}
                        <div>
                           <p className="font-normal mb-2 w-full break-words font-base whitespace-pre-line pb-1 mt-1 sm:mt-1">
                              {formatRichPost(text)}
                           </p>
                        </div>
                     </div>
                  </div>

                  <div className="flex justify-end w-10">{more}</div>
               </div>

               {postMedia}
               {postGiphy}
               {richContent}
            </div>

            {socialWidgets}

            {postCommentInput}

            {commentFeed}
         </div>
      </Fragment>
   );
};

Post.propTypes = {
   getUserContext: PropTypes.func.isRequired,
   clearUserContext: PropTypes.func.isRequired,
   shareContent: PropTypes.func.isRequired,
   getPostId: PropTypes.func.isRequired,
   deleteContent: PropTypes.func.isRequired,
   likeContent: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired,
   content: PropTypes.object.isRequired,
   follow: PropTypes.object.isRequired,
   me: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth,
   content: state.content,
   follow: state.follow,
   me: state.me
});

export default connect(mapStateToProps, {
   getUserContext,
   clearUserContext,
   shareContent,
   deleteContent,
   getPostId,
   likeContent
})(Post);
