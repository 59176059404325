// React.
import React from 'react';

// Import icons.
import closeIcon from '../assets/close_white.svg';

const CloseButtonSmallGray = ({ close, clearInput }) => {
   const handleClick = () => {
      close();
      clearInput();
   };

   return (
      <div className="h-7 w-7 hover:bg-gray-500 rounded-full" onClick={handleClick}>
         <div className="grid content-center">
            <div
               className="mx-auto mt-1 bg-cover bg-no-repeat bg-center h-5 w-5 cursor-pointer"
               style={{
                  backgroundImage: `url(${closeIcon})`
               }}
               alt={'close'}
            ></div>
         </div>
      </div>
   );
};

export default CloseButtonSmallGray;
