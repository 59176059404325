// React.
import React from 'react';

const AlertFrontEnd = ({ msg }) => {
   const alert = (
      <div
         className="bg-red-100 border border-red-400 text-red-700 mb-5 px-3 py-2 rounded relative"
         role="alert"
      >
         {msg}
      </div>
   );
   return <div>{alert}</div>;
};

export default AlertFrontEnd;
