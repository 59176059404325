// Import react.
import React, { Fragment, useState, useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import { getTopFive, updateTopFiveComSortOrder } from '../actions/topFive';

// Import NPM packages.
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Import components.
import TopFiveAddModal from './TopFiveAddModal';
import TopFiveEdit from './TopFiveEdit';
import TopFiveComponent from './TopFive';

const TopFiveFeed = ({
   auth,
   me,
   user,
   topFive,
   getTopFive,
   updateTopFiveComSortOrder
}) => {
   const location = useLocation();

   const [state, setState] = useState({ isLoggedInUser: true });

   useEffect(() => {
      if (!user.loading && me.user_name === user.user_name) {
         setState({
            ...state,
            isLoggedInUser: true
         });
      } else if (!user.loading && me.user_name !== user.user_name) {
         setState({
            ...state,
            isLoggedInUser: false
         });
      }
   }, [me.user_name, user.user_name, user.loading]); // added state 13.05.22 might be wrong

   const match = matchPath(window.location.pathname, {
      path: '/user/:username',
      exact: true,
      strict: false
   });

   const [openAddModal, setOpenAddModal] = useState({ open: false });
   const [topFiveFeedState, setTopFiveFeedState] = useState([]);
   const [isUserPage, setIsUserPage] = useState(false);

   const openAdd = () => {
      setOpenAddModal({ ...openAddModal, open: true });
   };

   const closeAdd = () => {
      setOpenAddModal({ ...openAddModal, open: false });
   };

   // Use loaction to hide show topfives.
   useEffect(() => {
      setIsUserPage(location.pathname.startsWith('/user/'));
   }, [location]);

   // Set local state with topFive.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated && topFive.loaded) {
         setTopFiveFeedState(topFive.items);
      }
   }, [auth, topFive]);

   // Get topFive based on the user_name in the params.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated && match !== null) {
         if (match?.params?.username) {
            getTopFive(match.params?.username);
         }
      }
   }, [
      auth,
      getTopFive,
      match.params.username,
      topFive.updated,
      topFive.added,
      topFive.deleted,
      topFive.loaded
   ]);

   const handleClickEdit = (title, topFives) => {
      let topFiveArr = [title];
      topFives.forEach((topFive) => {
         topFiveArr.push(topFive.title);
      });
   };

   function handlOnDragEnd(result) {
      if (result.destination !== null) {
         const items = Array.from(topFiveFeedState);
         const [recodedItem] = items.splice(result.source.index, 1);
         items.splice(result.destination.index, 0, recodedItem);
         updateTopFiveComSortOrder(items);
         setTopFiveFeedState(items);
      }
   }
   let AddButton = null;
   if (state.isLoggedInUser) {
      AddButton = (
         <div className="w-full flex justify-end">
            <div
               className="cursor-pointer font-semibold hover:text-gray-400 text-gray-200"
               onClick={openAdd}
            >
               {topFiveFeedState.length < 5 ? '+Add' : null}
            </div>
         </div>
      );
   }

   const TopFiveTitle = (
      <div className="flex w-full pl-3 pr-3 pb-2.5 font-Pulp font-semibold text-2xl">
         <div className="w-32 cursor-default">Top 5s</div>
         {AddButton}
      </div>
   );

   const TopFiveFeedComponent = state.isLoggedInUser ? (
      <div className="mb-60 cursor-default">
         <DragDropContext onDragEnd={handlOnDragEnd}>
            <Droppable droppableId="topfivefeed">
               {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                     {topFiveFeedState.length > 0
                        ? topFiveFeedState.map(({ id, title, items }, index) => {
                             return (
                                <Draggable key={id} draggableId={id} index={index}>
                                   {(provided) => (
                                      <div
                                         {...provided.draggableProps}
                                         ref={provided.innerRef}
                                      >
                                         <TopFiveEdit
                                            handleClickEdit={handleClickEdit}
                                            id={id}
                                            myList={items}
                                            feedDragHandle={{
                                               ...provided.dragHandleProps
                                            }}
                                            title={title}
                                         />
                                      </div>
                                   )}
                                </Draggable>
                             );
                          })
                        : null}
                     {provided.placeholder}
                  </div>
               )}
            </Droppable>
         </DragDropContext>
      </div>
   ) : (
      <div>
         {topFiveFeedState.map(({ title, items }) => {
            return <TopFiveComponent title={title} items={items} />;
         })}
      </div>
   );

   const TopFiveModal = openAddModal.open ? (
      <TopFiveAddModal closeAdd={closeAdd} />
   ) : null;

   return (
      <Fragment>
         {TopFiveTitle}
         {TopFiveModal}
         {TopFiveFeedComponent}
      </Fragment>
   );
};

TopFiveFeed.propTypes = {
   getTopFive: PropTypes.func.isRequired,
   updateTopFiveComSortOrder: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired,
   me: PropTypes.object.isRequired,
   user: PropTypes.object.isRequired,
   topFive: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth,
   me: state.me,
   user: state.user,
   topFive: state.topFive
});

export default connect(mapStateToProps, {
   getTopFive,
   updateTopFiveComSortOrder
})(TopFiveFeed);
