import axios from 'axios';

import {
   COMMENT_SUCCESS,
   COMMENT_FAIL,
   GET_SINGLE_CONTENT_SUCCESS,
   GET_SINGLE_CONTENT_FAIL,
   DELETE_COMMENT_SUCCESS,
   DELETE_COMMENT_FAIL,
   CLEAR_SINGLE_CONTENT,
} from './types';

// Get ContentById
export const getSingleContentById = (content_id) => async (dispatch) => {
   try {
      console.log('getSingleContentById')
      const res = await axios.get(`/api/content/${content_id}`);
      dispatch({
         type: GET_SINGLE_CONTENT_SUCCESS,
         payload: res.data.data.content,
         comments: res.data.data.content.comments
      });
   } catch (err) {
      dispatch({
         type: GET_SINGLE_CONTENT_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

export const deleteComment = (content_id, comment_id) => async (dispatch) => {
   try {
      const res = await axios.delete(`/api/content/comment/${content_id}`, {
         data: { commentId: comment_id }
      });

      dispatch({
         type: DELETE_COMMENT_SUCCESS,
         payload: res.data.data
      });
   } catch (err) {
      dispatch({
         type: DELETE_COMMENT_FAIL,
         payload: { msg: err.response }
      });
   }
};

export const comment = (comment, id) => async (dispatch) => {
   try {
      const res = await axios.patch(`/api/content/comment/${id}`, {
         text: comment
      });
      dispatch({
         type: COMMENT_SUCCESS,
         payload: res.data
      });
   } catch (err) {
      dispatch({
         type: COMMENT_FAIL,
         payload: { msg: err.response }
      });
   }
};

export const clearSingleContent = () => async (dispatch) => {
   dispatch({
      type: CLEAR_SINGLE_CONTENT
   });
};

