// React.
import React, { Fragment, useEffect } from 'react';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import { getMe } from '../actions/me';
import { getNotificationsCount } from '../actions/notification';

// Import components.
import HomeFeed from '../components/HomeFeed';
import HomeFeedSearch from '../components/HomeFeedSearch';
import DiscoverWrapper from '../components/discover/DiscoverWrapper';
import GaPageView from '../components/GaPageView';

const Home = ({
   auth,
   mix,
   getMe,
   getNotificationsCount,
   searchParams
}) => {
   // Get Me and notifications count.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         getMe();
         getNotificationsCount();
      }
   }, [auth]);


   // Change HomeFeed to MixFeed after music search is entered.
   let HomeFeedComponent;

   // NEED TO REVIEW STRATEGY HERE- PROBLEM OF CONFLICTING SEACRH PARAMS YEILDING 0 RESULTS.

   // if mixes are present = false && mixes loaded = false - show home feed.
   // if mixes are present = false && mixes loaded = true- show search feed
   // if mixes are present = true && mixes loaded = true- show search feed

   if (mix.user_mixes_count === 0) {
      HomeFeedComponent = <HomeFeed />;
   }

   if (mix.user_mixes_count !== 0) {
      HomeFeedComponent = (
         <HomeFeedSearch searchParams={searchParams} />
      );
   }

   return (
      <Fragment>
         <GaPageView/>
         <div className="w-600 sm:w-full h-screen">{HomeFeedComponent}</div>
         <div className="w-72 pt-72 mt-6 lg:hidden ml-14 xl:ml-6">
            <DiscoverWrapper />
         </div>
      </Fragment>
   );
};

Home.propTypes = {
   getMe: PropTypes.func.isRequired,
   getNotificationsCount: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired,
   mix: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth,
   mix: state.mix
});

export default connect(mapStateToProps, {
   getMe,
   getNotificationsCount
})(Home);
