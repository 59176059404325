// Import React.
import React from 'react';
import { Link } from 'react-router-dom';

// Import utils.
import addDataStorePathMixImages from '../utils/addDataStorePathMixImages';

// Import styles.
import styles from '../css/styles.module.css';

// Import Actions
import { clearSingleContent } from '../actions/contentSingle';

const MixWidget = ({
   cover,
   content_id,
   mix_title,
   mix_owner_user_name,
   mix_owner_display_name
}) => {
   return (
      <div className="flex justify-center mb-3 mt-1">
         <div className={`${styles.mixWidget} flex w-full xs:h-auto`}>
            <Link
               onClick={() => {
                  clearSingleContent();
               }}
               to={`/content/${content_id}`}
            >
               <div
                  className={`h-full w-17 flex-none bg-gray-200 border-gray-300 border-l border-t border-b bg-cover bg-no-repeat bg-center rounded-l-md`}
                  style={{
                     backgroundImage: `url(${addDataStorePathMixImages(cover)})`
                  }}
               ></div>
            </Link>
            <div className="grid content-center pl-4 py-2 leading-tight bg-white border-gray-300 w-full h-full border-r border-t border-b xs:border-l xs:rounded-l-md rounded-r-md">
               <Link
                  onClick={() => {
                     clearSingleContent();
                  }}
                  to={`/content/${content_id}`}
               >
                  <h2 className="font-Pulp font-normal text-xl w-full pr-4 sm:text-lg tracking-wide leading-5">
                     {mix_title}
                  </h2>
               </Link>

               <h5 className="text-xs text-mixinlab-pewter font-normal">
                  Mixed by{' '}
                  <span className="text-mixinlab-indigo font-semibold">
                     <Link to={`/user/${mix_owner_user_name}`}>
                        {mix_owner_display_name}
                     </Link>
                  </span>
               </h5>
            </div>
         </div>
      </div>
   );
};

export default MixWidget;
