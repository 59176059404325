// React.
import React from 'react';

// Placeholder image.
import picture from '../assets/picture.svg';

const MixUploadTitleImage = ({ blob, handleImageInput }) => {
   const placeholder = (
      <div className="group flex h-full w-full z-10 absolute border-r-2 border-gray-200 bg-gray-100 cursor-pointer">
         <label htmlFor="mix" className="flex justify-center w-full h-full">
            <div
               className="w-full h-full bg-no-repeat bg-center bg-70 cursor-pointer opacity-20 group-hover:opacity-40"
               style={{
                  backgroundImage: `url(${picture})`
               }}
               alt={'Placeholder'}
            ></div>
         </label>
         <input
            className="hidden w-full h-full"
            id="mix"
            type="file"
            onChange={handleImageInput}
            accept="image/*"
         />
      </div>
   );

   const uploading = (
      <div className="flex h-full w-full z-10 absolute border-r-2 border-gray-200 bg-gray-100 cursor-pointer">
         <label
            htmlFor="mix"
            className="w-full h-full bg-cover bg-no-repeat bg-center cursor-pointer hover:opacity-70"
            style={{
               backgroundImage: `url(${blob})`
            }}
         ></label>
         <input
            className="hidden w-full h-full"
            id="mix"
            type="file"
            onChange={handleImageInput}
            accept="image/*"
         />
      </div>
   );

   // Instantiate bio component.
   let profile_image;

   if (!blob) {
      profile_image = placeholder;
      return profile_image;
   } else if (blob) {
      profile_image = uploading;
      return profile_image;
   }

   return <form>{profile_image}</form>;
};

export default MixUploadTitleImage;
