// React.
import React, { Fragment } from 'react';

const MusicListLabelResults = ({
   results,
   selectLabel,
   label_dropdown,
   closeResultsDropdown
}) => {
   const handleClick = (e) => {
      const id = e.target.dataset.id;
      const name = e.target.dataset.name;
      selectLabel({ id, name });
   };

   let ListSelect;
   let modalMask;

   if (results.length > 0 && label_dropdown) {
      ListSelect = (
         <div className="absolute rounded-md text-sm lg:text-xl z-50 px-2 py-1 w-60 lg:w-9/12 lg-1.5 mt-2 ml-2 shadow-modalLight h-auto bg-white cursor-pointer">
            <div className="rounded-sm font-semibold">
               {results.map((label, index) => {
                  return (
                     <div
                        key={index}
                        onClick={handleClick}
                        className="hover:bg-gray-100 rounded md:text-xl px-2 md:px-3 pt-1 pb-1.5 my-1"
                        data-id={label._id}
                        data-name={label.name}
                     >
                        {label.name}
                     </div>
                  );
               })}
            </div>
         </div>
      );
   }

   if (label_dropdown) {
      modalMask = (
         <div
            className="fixed top-0 left-0 w-screen h-screen z-40"
            onClick={closeResultsDropdown}
         ></div>
      );
   }

   return (
      <Fragment>
         {ListSelect}
         {modalMask}
      </Fragment>
   );
};

export default MusicListLabelResults;
