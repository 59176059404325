import React, { useEffect }  from 'react';
import ReactGA from 'react-ga'

const GaPageView = () => {
   useEffect(() => {
      console.log('ReactGA - Page View');
      ReactGA.pageview(window.location.pathname + window.location.search)
   }, [])

   return null;
};

export default GaPageView;
