import axios from 'axios';

import {
   AGREE_TO_TERMS,
   GET_USER_SUCCESS,
   GET_USER_FAIL,
   GET_SUGGESTED_USER_SUCCESS,
   GET_SUGGESTED_USER_FAIL,
   GET_USER_BY_USERNAME_SUCCESS,
   GET_USER_BY_USERNAME_FAIL,
   RESET_USER_REFERENCE_SUGGESTIONS_SUCCESS,
   RESET_USER_REFERENCE_SUGGESTIONS_FAIL,
   ADD_SUGGESTED_USER_SUCCESS,
   ADD_SUGGESTED_USER_FAIL,
   PLAN_CHOSEN,
   BLOCK_USER_SUCCESS,
   BLOCK_USER_FAIL,
   SEND_EMAIL_PASSWORD_SUCCESS,
   SEND_EMAIL_PASSWORD_FAIL,
   RESET_PASSWORD_SUCCESS,
   RESET_PASSWORD_FAIL
} from './types';

// Choose plan.
export const choosePlan = (licence_details) => (dispatch) => {
   dispatch({
      type: PLAN_CHOSEN,
      payload: licence_details
   });
};

// Agree to terms.
export const agreeToTerms = () => (dispatch) => {
   dispatch({
      type: AGREE_TO_TERMS
   });
};

export const getUser = (user_name) => async (dispatch) => {
   try {
      const res = await axios.get(`/api/user/get-user/${user_name}`);
      dispatch({
         type: GET_USER_SUCCESS,
         payload: res.data
      });
   } catch (err) {
      dispatch({
         type: GET_USER_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// Get suggested users
export const getSuggestedUsers = () => async (dispatch) => {
   try {
      const res = await axios.get(`/api/user/random-users`);
      dispatch({
         type: GET_SUGGESTED_USER_SUCCESS,
         payload: res.data.data
      });
   } catch (err) {
      dispatch({
         type: GET_SUGGESTED_USER_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// Get users by user_name
export const getUsersByUserName = (user_name) => async (dispatch) => {
   try {
      const res = await axios.get(`/api/user/by-user-name/${user_name.replace('@', '')}`);
      dispatch({
         type: GET_USER_BY_USERNAME_SUCCESS,
         payload: res.data.data
      });
   } catch (err) {
      dispatch({
         type: GET_USER_BY_USERNAME_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// Reset user_reference_suggestions to []
export const resetUserReferenceSuggestions = () => async (dispatch) => {
   try {
      dispatch({
         type: RESET_USER_REFERENCE_SUGGESTIONS_SUCCESS,
         payload: null
      });
   } catch (err) {
      dispatch({
         type: RESET_USER_REFERENCE_SUGGESTIONS_FAIL,
         payload: null
      });
   }
};

// Get suggested users
export const addUserToFollowedSuggestedUsers = (id) => async (dispatch) => {
   try {
      dispatch({
         type: ADD_SUGGESTED_USER_SUCCESS,
         payload: id
      });
   } catch (err) {
      dispatch({
         type: ADD_SUGGESTED_USER_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// Choose plan.
export const blockUser = (block_user_id) => async (dispatch) => {
   try {
      const res = await axios.get(`/api/user/block/${block_user_id}`);
      dispatch({
         type: BLOCK_USER_SUCCESS,
         payload: res.data
      });
   } catch (err) {
      dispatch({
         type: BLOCK_USER_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// Send Rest Password Email.
export const sendResetPassword = (email) => async (dispatch) => {
   try {
      const res = await axios.post(`/api/user/send-reset-password`, { email });
      dispatch({
         type: SEND_EMAIL_PASSWORD_SUCCESS,
         payload: res.data
      });
   } catch (err) {
      dispatch({
         type: SEND_EMAIL_PASSWORD_FAIL,
         payload: { status: 'fail', message: err.response.statusText }
      });
   }
};

// Rest Password.
export const resetPassword =
   (password_reset_string, email, new_password) => async (dispatch) => {
      try {
         const res = await axios.post(
            `/api/user/reset-password?password_reset_string=${password_reset_string}&email=${email}`,
            { new_password }
         );
         dispatch({
            type: RESET_PASSWORD_SUCCESS,
            payload: res.data
         });
      } catch (err) {
         dispatch({
            type: RESET_PASSWORD_FAIL,
            payload: { status: 'fail', message: err.response.statusText }
         });
      }
   };
