import {
   HOME_CONTENT_LOADED_SUCCESS,
   HOME_CONTENT_LOADED_FAIL,
   HOME_CONTENT_SCROLL_LOADED_SUCCESS,
   HOME_CONTENT_SCROLL_LOADED_FAIL,
   HOME_CONTENT_LATEST_POST_LOADED_SUCCESS,
   USER_CONTENT_LOADED_SUCCESS,
   USER_CONTENT_LOADED_FAIL,
   UPDATE_SUCCESS,
   UPDATE_FAIL,
   UPDATE_RESET,
   GET_UPDATE_LINK_DETAILS_SUCCESS,
   GET_UPDATE_LINK_DETAILS_FAIL,
   SET_UPDATE_LINK_DETAILS_TO_NULL,
   SHARE_CONTENT_SUCCESS,
   SHARE_CONTENT_FAIL,
   UPDATE_LIKE_ARRAY_SUCCESS_CONTENT,
   UPDATE_LIKE_ARRAY_FAIL_CONTENT,
   DELETE_CONTENT_SUCCESS,
   DELETE_CONTENT_FAIL,
   GET_ID_SUCCESS,
   SHOW_SEARCH_FEED,
   HIDE_SEARCH_FEED,
   RESET_SHOW_SEARCH_FEED,
   COMMENT_SUCCESS,
   COMMENT_FAIL,
   RESET_STATUS_SHARE_CONTENT,
   LOG_OUT
} from '../actions/types';

const initialState = {
   loading: true,
   is_deleted: false,
   status_update: null,
   status_like: null,
   status_comment: null,
   status_scroll: null,
   status_share_content: null,
   post_id_for_comments: null,
   last_home_content_loaded_time: null,
   number_of_new_home_content_post_available: 0,
   posts: [],
   new_shared_post: null,
   update_link_details: null,
   post_num_results: 0,
   page_num: 1,
   show_search_feed: false,
   error: {}
};

const content = (state = initialState, action) => {
   switch (action.type) {
      case HOME_CONTENT_LOADED_SUCCESS:
         return {
            ...state,
            loading: false,
            is_deleted: false,
            posts: action.payload,
            last_home_content_loaded_time: new Date().toISOString(),
            number_of_new_home_content_post_available: 0,
            post_num_results: action.results,
            status_share_content: null,
            status_comment: null,
            post_id_for_comments: null,
            status_update: null,
            page_num: action.page
         };
      case HOME_CONTENT_LOADED_FAIL:
         return {
            ...state,
            loading: false,
            is_deleted: false,
            status_share_content: null,
            post_num_results: 0,
            status_update: null,
            status_comment: null,
            error: action.payload,
            post_id_for_comments: null,
            post_id_for_comments_loaded: false
         };
      case HOME_CONTENT_SCROLL_LOADED_SUCCESS:
         return {
            ...state,
            loading: false,
            is_deleted: false,
            posts: state.posts.concat(action.payload),
            status_share_content: null,
            status_update: null,
            status_comment: null,
            post_id_for_comments: null,
            post_num_results: action.results,
            page_num: action.page
         };
      case HOME_CONTENT_SCROLL_LOADED_FAIL:
         return {
            ...state,
            loading: false,
            is_deleted: false,
            status_share_content: null,
            status_update: null,
            status_comment: null,
            post_num_results: 0,
            error: action.payload,
            post_id_for_comments: null,
            post_id_for_comments_loaded: false
         };
      case HOME_CONTENT_LATEST_POST_LOADED_SUCCESS:
         return {
            ...state,
            number_of_new_home_content_post_available: action.payload
         };
      case USER_CONTENT_LOADED_SUCCESS:
         return {
            ...state,
            loading: false,
            is_deleted: false,
            posts: action.payload,
            status_share_content: null
         };
      case USER_CONTENT_LOADED_FAIL:
         return {
            ...state,
            loading: false,
            is_deleted: false,
            error: action.payload
         };
      case UPDATE_SUCCESS:
         return {
            ...state,
            status_update: 'success',
            loading: false,
            is_deleted: false,
            error: {}
         };
      case UPDATE_FAIL:
         return {
            ...state,
            status_update: null,
            loading: true,
            is_deleted: false,
            error: action.payload
         };
      case GET_UPDATE_LINK_DETAILS_SUCCESS:
         return {
            ...state,
            update_link_details: action.payload,
            error: {}
         };
      case GET_UPDATE_LINK_DETAILS_FAIL:
         return {
            ...state,
            update_link_details: null,
            error: action.payload.message
         };
      case SET_UPDATE_LINK_DETAILS_TO_NULL:
         return {
            ...state,
            update_link_details: null
         };
      case UPDATE_RESET:
         return {
            ...state,
            status_update: null,
            loading: true,
            error: {}
         };
      case SHARE_CONTENT_SUCCESS:
         return {
            ...state,
            status_share_content: action.payload.status,
            posts: [action.payload.data.content, ...state.posts]
         };
      case SHARE_CONTENT_FAIL:
         return {
            ...state,
            error: action.payload
         };
      case RESET_STATUS_SHARE_CONTENT:
         return {
            ...state,
            status_share_content: null
         };
      case UPDATE_LIKE_ARRAY_SUCCESS_CONTENT:
         return {
            ...state,
            loading: false,
            posts: state.posts.map((post) =>
               post._id === action.payload.contentId
                  ? {
                       ...post,
                       like_count_VIRTUAL: action.payload.likeCount
                    }
                  : post
            ),
            status_like: 'success'
         };
      case UPDATE_LIKE_ARRAY_FAIL_CONTENT:
         return {
            ...state,
            loading: false,
            status_like: 'fail'
         };
      case GET_ID_SUCCESS:
         return {
            ...state,
            status_comment: null,
            post_id_for_comments: action.payload,
            post_id_for_comments_loaded: true
         };
      case DELETE_CONTENT_SUCCESS:
         return {
            ...state,
            is_deleted: true,
            posts: state.posts.filter((post) => {
               if (post._id !== action.payload.content.id) {
                  return post;
               }
            })
         };
      case DELETE_CONTENT_FAIL:
         return {
            ...state
         };
      case SHOW_SEARCH_FEED:
         return {
            ...state,
            show_search_feed: true
         };
      case HIDE_SEARCH_FEED:
         return {
            ...state,
            show_search_feed: false
         };
      case RESET_SHOW_SEARCH_FEED:
         return {
            ...state,
            show_search_feed: false
         };
      case COMMENT_SUCCESS:
         return {
            ...state,
            loading: false,
            status_comment: action.payload.status,
            posts: state.posts.map((post) =>
               post._id === action.payload.data.content.id
                  ? {
                       ...post,
                       comment_count_VIRTUAL:
                          action.payload.data.content.comment_count_VIRTUAL
                    }
                  : post
            )
         };
      case COMMENT_FAIL:
         return {
            ...state,
            loading: false,
            status_comment: null,
            error: action.payload
         };
      case LOG_OUT:
         return {
            loading: true,
            is_deleted: false,
            status_update: '',
            status_like: null,
            status_comment: null,
            status_scroll: null,
            status_share_content: null,
            post_id_for_comments: null,
            last_home_content_loaded_time: null,
            number_of_new_home_content_post_available: 0,
            posts: [],
            update_link_details: null,
            post_num_results: 0,
            page_num: 1,
            show_search_feed: false,
            error: {}
         };
      default:
         return state;
   }
};

export default content;
