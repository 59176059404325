import React, { useState, useEffect } from 'react';

// NPM
import TextareaAutosize from 'react-textarea-autosize';

// Import actions.
import { comment } from '../actions/contentSingle';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const PostCommentInput = ({ postId, comment }) => {
   const [state, setState] = useState({
      comment: '',
      comment_count: 0,
      file_err_msg: null
   });

   const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === 'comment') {
         setState({
            ...state,
            comment_count: value.length
         });
      }

      setState((prevState) => {
         return {
            ...prevState,
            [name]: value
         };
      });
   };

   const submit = (e) => {
      e.preventDefault();
      if (state.comment.length > 1) {
         comment(state.comment, postId);
         setState({
            ...state,
            comment: ''
         });
      }
   };

   return (
      <div className="bg-white px-5 pt-4 pb-4">
         <div className="border-t-2 border-gray-300 border-dotted">
            <div className="flex items-center border rounded-md border-gray-400 p-1 mt-5">
               <TextareaAutosize
                  type="text"
                  name="comment"
                  className="placeholder-black overflow-hidden appearance-none pb-1 pt-0.5 px-3 block text-lg font-normal bg-transparent border-none w-full text-black focus:outline-none"
                  placeholder="Comment on this post..."
                  onChange={handleChange}
                  value={state.comment}
               />
            </div>

            <div className="flex flex-row mt-4">
               <div className="w-full flex justify-end">
                  <h6 className="text-sm text-gray-500 pt-2 pr-4">
                     <span
                        className={
                           state.update_count > 240
                              ? 'text-mixinlab-cherry font-bold'
                              : 'text-mixinlab-teal'
                        }
                     >
                        {state.comment_count}
                     </span>
                     <span className="text-black"> / 240 chars.</span>
                  </h6>

                  {state.comment_count > 240 ? (
                     <div className="ease-in-out duration-75 h-9 px-3 pt-1 font-Pulp text-white border-2 opacity-50 bg-mixinlab-cherry border-mixinlab-cherry focus:outline-none rounded">
                        Comment
                     </div>
                  ) : (
                     <button
                        onClick={submit}
                        className="ease-in-out duration-75 h-9 px-3 font-Pulp text-white bg-mixinlab-teal cursor-pointer border-2 border-mixinlab-teal hover:bg-white hover:text-mixinlab-teal focus:outline-none rounded"
                     >
                        Comment
                     </button>
                  )}
               </div>
            </div>
         </div>
      </div>
   );
};

PostCommentInput.propTypes = {
   comment: PropTypes.func.isRequired,
   me: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   me: state.me
});

export default connect(mapStateToProps, { comment })(PostCommentInput);
