import {
   LOAD_TIMELINE_MIX_SUCCESS,
   LOAD_TIMELINE_MIX_FAIL,
   CLEAR_TIMELINE_MIX,
   GET_CURRENT_MIX_SUCCESS,
   GET_CURRENT_MIX_FAIL,
   SET_API_IS_LOADING,
   SET_UPLOAD_TRACKLIST_IS_LOADING,
   FIND_TRACKS_SUCCESS,
   FIND_TRACKS_FAIL,
   CLEAR_TRACKS,
   UPLOAD_CANCEL_SUCCESS,
   UPLOAD_MIX_SUCCESS,
   UPLOAD_MIX_FAIL,
   UPLOAD_TRACKLIST_SUCCESS,
   UPLOAD_TRACKLIST_FAIL,
   UPLOAD_PROGRESS,
   DISCOVER_MUSIC_RESULTS_LOADED_SUCCESS,
   DISCOVER_MUSIC_RESULTS_LOADED_FAIL,
   DISCOVER_MUSIC_RESULTS_RESET,
   AUDIO_PLAY,
   AUDIO_PAUSE
} from '../actions/types';

const initialState = {
   // Mix loaded into AudioPlayer.js.
   current_mix_status: '',
   current_mix_id: '',
   current_mix_post_id: '',
   current_mix_mp3: '',
   current_mix_cover: '',
   current_mix_title: '',
   current_mix_user: {},
   current_mix_pos: 0,
   current_mix_is_playing: false,
   current_mix_is_loaded_from_timeline: false,
   cover: null,
   discover_mix_search_loaded: false,
   user_mixes: [],
   user_mixes_count: 0,
   progress: 0,
   api_response_tracks: [],
   api_is_loading: false,
   mix_uploaded_status: '',
   mix_uploaded_id: null,
   mix_upload_err_msg: '',
   tracklist_uploading: false,
   tracklist_uploaded: false,
   tracklist_upload_err_msg: '',
   cancel_status: false,
   cancel_message: ''
};

const mix = (state = initialState, action) => {
   switch (action.type) {
      case AUDIO_PLAY:
         return {
            ...state,
            current_mix_is_playing: true
         };
      case AUDIO_PAUSE:
         return {
            ...state,
            current_mix_is_playing: false
         };
      case CLEAR_TIMELINE_MIX:
         return {
            ...state,
            current_mix_status: '',
            current_mix_id: '',
            current_mix_mp3: '',
            // current_mix_cover: '',
            // current_mix_title: '',
            // current_mix_user: {},
            // current_mix_pos: 0,
            current_mix_is_playing: false,
            current_mix_is_loaded_from_timeline: false
         };
      case LOAD_TIMELINE_MIX_SUCCESS:
         return {
            ...state,
            current_mix_is_playing: true,
            current_mix_id: action.payload.id,
            current_mix_post_id: action.post_id,
            current_mix_mp3: action.payload.mp3,
            current_mix_cover: action.payload.cover,
            current_mix_title: action.payload.title,
            current_mix_user: action.payload.user,
            current_mix_status: action.meta.status,
            current_mix_pos: action.meta.current_mix_pos,
            current_mix_is_loaded_from_timeline: true
         };
      case LOAD_TIMELINE_MIX_FAIL:
         return {
            ...state,
            current_mix_status: 'fail',
            current_mix_is_playing: false,
            current_mix_is_loaded_from_timeline: false
         };
      case GET_CURRENT_MIX_SUCCESS:
         return {
            ...state,
            current_mix_id: action.payload.id,
            current_mix_mp3: action.payload.mp3,
            current_mix_cover: action.payload.cover,
            current_mix_title: action.payload.title,
            current_mix_user: action.payload.user,
            current_mix_status: action.meta.status,
            current_mix_pos: action.meta.current_mix_pos,
            current_mix_is_loaded_from_timeline: false,
            current_mix_post_id: action.post_id
         };
      case GET_CURRENT_MIX_FAIL:
         return {
            ...state,
            current_mix_status: 'fail',
            current_mix_is_playing: false,
            current_mix_is_loaded_from_timeline: false
         };
      case SET_API_IS_LOADING:
         return {
            ...state,
            api_is_loading: true
         };
      case FIND_TRACKS_SUCCESS:
         return {
            ...state,
            api_response_tracks: action.payload,
            api_is_loading: false
         };
      case FIND_TRACKS_FAIL:
         return {
            ...state,
            api_response_tracks: [],
            api_is_loading: false
         };
      case CLEAR_TRACKS:
         return {
            ...state,
            api_response_tracks: []
         };
      case UPLOAD_PROGRESS:
         return {
            ...state,
            progress: action.payload
         };
      case SET_UPLOAD_TRACKLIST_IS_LOADING:
         return {
            ...state,
            tracklist_uploading: true
         };
      case UPLOAD_MIX_SUCCESS:
         return {
            ...state,
            mix_uploaded_status: action.payload,
            mix_uploaded_id: action.id
         };
      case UPLOAD_MIX_FAIL:
         return {
            ...state,
            tracklist_upload_err_msg: action.payload
         };
      case UPLOAD_CANCEL_SUCCESS:
         return {
            ...state,
            cancel_status: action.payload.status,
            cancel_message: action.payload.msg
         };
      case UPLOAD_TRACKLIST_SUCCESS:
         return {
            ...state,
            tracklist_uploaded: action.payload.status,
            tracklist_uploading: false
         };
      case UPLOAD_TRACKLIST_FAIL:
         return {
            ...state,
            mix_upload_err_msg: action.payload,
            tracklist_uploading: false
         };
      case DISCOVER_MUSIC_RESULTS_LOADED_SUCCESS:
         return {
            ...state,
            discover_mix_search_loaded: true,
            user_mixes: action.payload.mixes,
            user_mixes_count: action.payload.mixes.length
         };
      case DISCOVER_MUSIC_RESULTS_LOADED_FAIL:
         return {
            ...state,
            discover_mix_search_loaded: false
         };
      case DISCOVER_MUSIC_RESULTS_RESET:
         return {
            ...state,
            discover_mix_search_loaded: false,
            user_mixes: [],
            user_mixes_count: 0
         };
      default:
         return state;
   }
};

export default mix;
