// React.
import React, { Fragment, useEffect, useState } from 'react';
import { Link, matchPath } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import styles.
import styles from '../css/styles.module.css';

// Import actions.
import { logout } from '../actions/auth';
import { getHomeContent, getUserContent } from '../actions/content';
import { discoverMusicReset } from '../actions/mix';
import { toggleDiscoverModal } from '../actions/discover';

// Import components.
import NavModal from './NavModal';

// Import icons.
import logo from '../assets/mixinlab.svg';
import logoMarque from '../assets/mixinlab-marque.svg';
import homeIcon from '../assets/home.svg';
import profileIcon from '../assets/profile.svg';
import notificationsIcon from '../assets/notifications.svg';
import moreIcon from '../assets/more.svg';
import constants from '../configs/constants';
import searchIcon from '../assets/search.svg';

const Nav = ({
   logout,
   getHomeContent,
   getUserContent,
   toggleDiscoverModal,
   discoverMusicReset,
   // me: { user_name },
   auth,
   // getNotifications,
   // getNewNotifications,
   me,
   notification
}) => {
   const [showModal, setShowModal] = useState(false);
   const [notificationsCount, setNotificationsCount] = useState(0);

   // Get new notifications as they are created by other users.
   useEffect(() => {
      if (
         !auth.loading &&
         auth.isAuthenticated &&
         notification?.new_notifications !== notificationsCount
      ) {
         setNotificationsCount(notification.new_notifications);
      }
   }, [auth, notification.new_notifications]);

   const toggleModalSettings = () =>
      setShowModal({ ...showModal, settings: !showModal.settings });

   const handleHomePageReload = () => {
      discoverMusicReset();
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      });
      getHomeContent();
   };

   const handleUserPageReload = () => {
      discoverMusicReset();
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      });
      getUserContent();
   };

   const handleNotificationsPageReload = () => {
      discoverMusicReset();
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      });
   };

   const handleUploadPageReload = () => {
      discoverMusicReset();
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      });
   };

   const notificationsCountWeb =
      notificationsCount === 0 ? null : (
         <div
            className={`${styles.alertBubble} absolute -left-1 top-0 grid content-center rounded-full px-1 bg-red-500 text-xs text-white font-semibold text-center`}
         >
            {notificationsCount}
         </div>
      );

   const notificationsCountMobile =
      notificationsCount === 0 ? null : (
         <div
            className={`${styles.alertBubble} absolute left-1 top-0 grid content-center rounded-full px-1 bg-red-500 text-xs text-white font-semibold text-center`}
         >
            {notificationsCount}
         </div>
      );

   const is_active_route = matchPath(window.location.pathname, {
      path: constants.valid_routes,
      exact: true,
      strict: false
   });

   const uploadLink = (
      <Link onClick={handleUploadPageReload} to="/upload">
         <button className="mt-3 w-44 h-11 font-Pulp font-normal text-xl tracking-wide text-white rounded border-2 border-mixinlab-indigo_mid bg-mixinlab-indigo_mid hover:bg-white hover:text-mixinlab-indigo_mid cursor-pointer focus:outline-none">
            Upload
         </button>
      </Link>
   );

   if (is_active_route) {
      return (
         <Fragment>
            <div className="pb-1 z-20">
               <NavModal
                  onClick1={toggleModalSettings}
                  onClick2={logout}
                  showModal={showModal.settings}
               />

               {/* Desktop nav.------------------------------------------------------------ */}
               <div className="container w-56 xl:hidden">
                  <Link onClick={handleHomePageReload} to="/home">
                     <img
                        className="w-40 p-1 mt-4 mb-3 cursor-pointer"
                        src={logo}
                        alt="mixinlab"
                     />
                  </Link>

                  <nav>
                     <Link to="/home" onClick={handleHomePageReload}>
                        <div className="flex w-44 pl-2 mb-2 h-11 items-center hover:bg-gray-200 rounded">
                           <div
                              className="bg-cover bg-no-repeat bg-center h-8 w-8 cursor-pointer"
                              style={{
                                 backgroundImage: `url(${homeIcon})`
                              }}
                              alt={'Home'}
                           ></div>
                           <div className="h-7 pl-3 font-Pulp font-normal text-xl tracking-wide">
                              <span className="align-middle cursor-pointer">
                                 Home
                              </span>
                           </div>
                        </div>
                     </Link>

                     <Link
                        to={`/user/${me.user_name}`}
                        onClick={handleUserPageReload}
                     >
                        <div className="flex w-44 pl-2 mb-2 h-11 items-center hover:bg-gray-200 rounded">
                           <div
                              className="bg-cover bg-no-repeat bg-center h-8 w-8 cursor-pointer"
                              style={{
                                 backgroundImage: `url(${profileIcon})`
                              }}
                              alt={'User'}
                           ></div>
                           <div className="h-7 pl-3 font-Pulp font-normal text-xl tracking-wide">
                              <span className="align-middle cursor-pointer">
                                 Profile
                              </span>
                           </div>
                        </div>
                     </Link>

                     <Link
                        to="/notifications"
                        onClick={handleNotificationsPageReload}
                     >
                        <div className="relative flex w-44 pl-2 mb-2 h-11 items-center hover:bg-gray-200 rounded">
                           {notificationsCountWeb}
                           <div
                              className="bg-cover bg-no-repeat bg-center h-8 w-8 cursor-pointer"
                              style={{
                                 backgroundImage: `url(${notificationsIcon})`
                              }}
                              alt={'Notifications'}
                           ></div>
                           <div className="h-7 pl-3 font-Pulp font-normal text-xl tracking-wide">
                              <span className="align-middle cursor-pointer">
                                 Notifications
                              </span>
                           </div>
                        </div>
                     </Link>

                     <div
                        className="flex w-44 pl-2 mb-2 h-11 items-center hover:bg-gray-200 rounded cursor-pointer"
                        onClick={toggleModalSettings}
                     >
                        <div
                           className="bg-cover bg-no-repeat bg-center h-8 w-8"
                           style={{
                              backgroundImage: `url(${moreIcon})`
                           }}
                           alt={'More'}
                        ></div>
                        <div className="h-7 pl-3 font-Pulp font-normal text-xl tracking-wide">
                           <span className="align-middle cursor-pointer">More</span>
                        </div>
                     </div>

                     {uploadLink}
                  </nav>
               </div>

               {/* Mobile nav.------------------------------------------------------------ */}

               <div className="container w-16 mx-auto hidden xl:block">
                  <Link onClick={handleHomePageReload} to="/home">
                     <img
                        className="w-14 mt-5 cursor-pointer mx-auto"
                        src={logoMarque}
                        alt="mixinlab"
                     />
                  </Link>

                  <nav>
                     <Link onClick={handleHomePageReload} to="/home">
                        <div className="mx-auto flex mt-7 pb-1 h-14 w-14 items-center justify-center hover:bg-gray-200 rounded-full cursor-pointer">
                           <div
                              className="bg-cover bg-no-repeat bg-center h-9 w-9"
                              style={{
                                 backgroundImage: `url(${homeIcon})`
                              }}
                              alt={'Home'}
                           ></div>
                        </div>
                     </Link>

                     <Link
                        onClick={handleUserPageReload}
                        to={`/user/${me.user_name}`}
                     >
                        <div className="mx-auto flex mt-7 pb-1 h-14 w-14 items-center justify-center hover:bg-gray-200 rounded-full cursor-pointer">
                           <div
                              className="bg-cover bg-no-repeat bg-center h-9 w-9"
                              style={{
                                 backgroundImage: `url(${profileIcon})`
                              }}
                              alt={'User'}
                           ></div>
                        </div>
                     </Link>

                     <Link
                        onClick={handleNotificationsPageReload}
                        to={`/notifications`}
                     >
                        <div className="relative mx-auto flex mt-7 pb-1 h-14 w-14 items-center justify-center hover:bg-gray-200 rounded-full cursor-pointer">
                           {notificationsCountMobile}

                           <div
                              className="bg-cover bg-no-repeat bg-center h-9 w-9"
                              style={{
                                 backgroundImage: `url(${notificationsIcon})`
                              }}
                              alt={'Notifications'}
                           ></div>
                        </div>
                     </Link>

                     <div
                        onClick={toggleDiscoverModal}
                        className="relative mx-auto hidden lg:block flex mt-7 h-14 w-14 items-center justify-center hover:bg-gray-200 rounded-full cursor-pointer"
                     >
                        <div
                           className="bg-60 bg-no-repeat bg-center h-14 w-14 cursor-pointer"
                           style={{
                              backgroundImage: `url(${searchIcon})`
                           }}
                           alt={'Notifications'}
                        ></div>
                     </div>

                     <div
                        className="mx-auto flex mt-7 pb-1 h-14 w-14 items-center justify-center hover:bg-gray-200 rounded-full cursor-pointer"
                        onClick={toggleModalSettings}
                     >
                        <div
                           className="bg-cover bg-no-repeat bg-center h-9 w-9"
                           style={{
                              backgroundImage: `url(${moreIcon})`
                           }}
                           alt={'More'}
                        ></div>
                     </div>
                  </nav>
               </div>
            </div>
         </Fragment>
      );
   } else {
      return null;
   }
};

Nav.propTypes = {
   logout: PropTypes.func.isRequired,
   getHomeContent: PropTypes.func.isRequired,
   getUserContent: PropTypes.func.isRequired,
   discoverMusicReset: PropTypes.func.isRequired,
   toggleDiscoverModal: PropTypes.func.isRequired,
   me: PropTypes.object.isRequired,
   notification: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   me: state.me,
   auth: state.auth,
   notification: state.notification
});

export default connect(mapStateToProps, {
   logout,
   getHomeContent,
   getUserContent,
   discoverMusicReset,
   toggleDiscoverModal
})(Nav);
