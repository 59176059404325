// React and PropTypes.
import React, { Fragment, useEffect } from 'react';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import { getNotificationsCount } from '../actions/notification';

const MinuteTile = ({ auth, title, description, price }) => {
   // Get Me and notifications count.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         getNotificationsCount();
      }
   }, [auth]);

   return (
      <Fragment>
         <div className="relative m-3 p-5 rounded-lg border-2 border-mixinlab-indigo_mid">
            <div className="absolute -top-4 -right-4 rounded-full w-16 h-16 text-center grid content-center bg-mixinlab-indigo_mid text-white">
               <div className="font-Pulp text-2xl h-5">100</div>
               <div className="font-normal text-xs">mins</div>
            </div>

            <h5 className="text-center mb-2 mt-8 text-2xl font-Pulp font-semibold text-black">
               {title}
            </h5>

            <div className="mb-3 text-center font-normal text-black">
               {description}
            </div>

            <h5 className="text-center mb-3 text-3xl font-semibold font-Pulp text-mixinlab-indigo">
               ${price}
            </h5>
            <div className="grid grid-flow-col gap-4">
               <button className="h-8 px-1 m-1 font-Pulp font-normal text-sm tracking-wide text-mixinlab-indigo rounded border-2 border-mixinlab-indigo hover:bg-mixinlab-indigo hover:text-white cursor-pointer focus:outline-none">
                  Buy
               </button>
               <button className="h-8 px-1 m-1 font-Pulp font-normal text-sm tracking-wide text-red-700 rounded border-2 border-red-700 hover:bg-red-700 hover:text-white cursor-pointer focus:outline-none">
                  Gift
               </button>
            </div>
         </div>
      </Fragment>
   );
};

MinuteTile.propTypes = {
   getNotificationsCount: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth
});

export default connect(mapStateToProps, {
   getNotificationsCount
})(MinuteTile);
