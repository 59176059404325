// React.
import React, { Fragment, useState } from 'react';

// Import actions.
import { getTopFive } from '../actions/topFive';

// Components.
import PageHeader from './PageHeader.js';
import CommentFeed from './CommentFeed';
import Profile from './Profile';
import BioMe from './BioMe';
import PostsWrapper from './PostsWrapper';
import MixesWrapperUser from './MixesWrapperUser';
import FollowNav from '../components/FollowNav';
import FollowingFeed from '../components/FollowingFeed';
import FollowerFeed from '../components/FollowerFeed';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const UserFeed = ({ user }) => {
   const [state, setState] = useState({
      show_posts: true,
      show_mixes: false,
      show_bio: false,
      show_comments: false,
      show_back_button: false,
      show_follow_menu: false,
      show_profile: true,
      show_following: false,
      show_followers: false,
      show_follow_nav: false
   });

   const showPosts = () => {
      setState({
         ...state,
         show_posts: true,
         show_mixes: false,
         show_bio: false,
         show_comments: false,
         show_back_button: false,
         show_follow_menu: false,
         show_profile: true,
         show_following: false,
         show_followers: false,
         show_follow_nav: false
      });
   };

   const showMixes = () => {
      setState({
         ...state,
         show_posts: false,
         show_mixes: true,
         show_bio: false,
         show_comments: false,
         show_back_button: false,
         show_follow_menu: false,
         show_profile: true,
         show_following: false,
         show_followers: false,
         show_follow_nav: false
      });
   };

   const showBio = () => {
      setState({
         ...state,
         show_posts: false,
         show_mixes: false,
         show_bio: true,
         show_comments: false,
         show_back_button: false,
         show_follow_menu: false,
         show_profile: true,
         show_following: false,
         show_followers: false,
         show_follow_nav: false
      });
   };

   const showComments = () => {
      setState({
         ...state,
         show_posts: true,
         show_mixes: false,
         show_bio: false,
         show_comments: false,
         show_back_button: false,
         show_follow_menu: false,
         show_profile: true,
         show_following: false,
         show_followers: false,
         show_follow_nav: false
      });
   };

   const showFollowing = () => {
      setState({
         ...state,
         user_feed_view: false,
         show_back_button: true,
         show_follow_menu: true,
         show_profile: false,
         show_posts: false,
         show_following: true,
         show_followers: false,
         show_mixes: false,
         show_bio: false,
         show_follow_nav: true
      });
   };

   const showFollowers = () => {
      setState({
         ...state,
         user_feed_view: false,
         show_back_button: true,
         show_follow_menu: true,
         show_profile: false,
         show_posts: false,
         show_following: false,
         show_followers: true,
         show_mixes: false,
         show_bio: false,
         show_follow_nav: true
      });
   };

   const backButton = () => {
      setState({
         ...state,
         user_feed_view: true,
         show_comments: false,
         show_posts: true,
         show_mixes: false,
         show_bio: false,
         show_back_button: false,
         show_follow_menu: false,
         show_profile: true,
         show_following: false,
         show_followers: false,
         show_follow_nav: false
      });
   };

   const ProfileComponent = state.show_profile ? (
      <Profile
         posts={showPosts}
         mixes={showMixes}
         bio={showBio}
         showFollowing={showFollowing}
         showFollowers={showFollowers}
      />
   ) : null;

   // Instantiate components.
   const PostWrapperComponent = state.show_posts ? (
      <PostsWrapper showComments={showComments} />
   ) : null;
   const MixWrapperComponent = state.show_mixes ? (
      <MixesWrapperUser showComments={showComments} />
   ) : null;
   const Bio = state.show_bio ? <BioMe /> : null;

   const CommentFeedComponent = state.show_comments ? <CommentFeed /> : null;
   const FollowingFeedComponent = state.show_following ? (
      <FollowingFeed backButton={backButton} />
   ) : null;
   const FollowerFeedComponent = state.show_followers ? (
      <FollowerFeed backButton={backButton} />
   ) : null;

   return (
      <Fragment>
         <div>
            <PageHeader
               page={user.display_name}
               show_back_button={state.show_back_button}
               backButton={backButton}
            />
            {ProfileComponent}
            <FollowNav
               show_follow_nav={state.show_follow_nav}
               is_show_following={state.show_following}
               is_show_followers={state.show_followers}
               showFollowing={showFollowing}
               showFollowers={showFollowers}
            />
            {PostWrapperComponent}
            {MixWrapperComponent}
            {Bio}

            {CommentFeedComponent}
            {FollowingFeedComponent}
            {FollowerFeedComponent}
         </div>
      </Fragment>
   );
};

UserFeed.propTypes = {
   getTopFive: PropTypes.func.isRequired,
   topFive: PropTypes.object.isRequired,
   user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   user: state.user,
   topFive: state.topFive
});

export default connect(mapStateToProps, { getTopFive })(UserFeed);
