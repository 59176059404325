// React and PropTypes.
import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import components.
import ModalUserWrapper from './user/ModalUserWrapper';
import ModalMusicWrapper from './music/ModalMusicWrapper';

// Import actions.
import { toggleDiscoverModal } from '../../../actions/discover';

// Import icons.
import closeIcon from '../../../assets/close.svg';

const ModalWrapper = ({ discover, toggleDiscoverModal }) => {
   const [state, setState] = useState({ show_user: true, show_music: false });

   // Use history.
   let history = useHistory();

   const showMusic = () => {
      setState({
         ...state,
         show_music: true,
         show_user: false
      });
   };

   const showUser = () => {
      setState({
         ...state,
         show_music: false,
         show_user: true
      });
   };

   const resetModal = () => {
      setState({
         ...state,
         show_music: false,
         show_user: true
      });
   };

   const onUserSelect = (user) => {
      // history.push must sit inside React Router. Cannot sit at App.js level.
      history.push(`/user/${user}`);
      resetModal();
      toggleDiscoverModal();
   };

   const user =
      state.show_user && !state.show_music ? (
         <ModalUserWrapper onUserSelect={onUserSelect} showMusic={showMusic} />
      ) : null;

   const music =
      !state.show_user && state.show_music ? (
         <ModalMusicWrapper showUser={showUser} />
      ) : null;

   let modal;

   if (discover.show_discover_modal) {
      modal = (
         <div className="fixed h-full w-full p-6 bg-white top-0 left-0 z-10 hidden lg:block">
            {/* Close Modal. */}
            <div className="flex justify-end">
               <div
                  className="h-12 w-12 hover:bg-gray-100 rounded-full"
                  onClick={toggleDiscoverModal}
               >
                  <div
                     className="mx-auto mt-2 bg-cover bg-no-repeat bg-center h-8 w-8 cursor-pointer"
                     style={{
                        backgroundImage: `url(${closeIcon})`
                     }}
                     alt={'close'}
                  ></div>
               </div>
            </div>

            <div className="w-4/5 sm:w-11/12 mx-auto">
               {user}
               {music}
            </div>
         </div>
      );
   }

   return <Fragment>{modal}</Fragment>;
};

ModalWrapper.propTypes = {
   discover: PropTypes.object.isRequired,
   toggleDiscoverModal: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
   discover: state.discover
});

export default connect(mapStateToProps, { toggleDiscoverModal })(ModalWrapper);
