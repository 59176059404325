// Import constants.
import constants from '../configs/constants';

// import styles.
import styles from '../css/styles.module.css';

// Import utils.
import { getPlatformDisplayName } from '../utils/richUpdate';

// Import images.
import OtherLink from '../assets/other_logo.svg';
import YoutubeLogo from '../assets/youtube_logo.svg';
import TwitchLogo from '../assets/twitch_logo.svg';
// import TwitterLogo from '../assets/twitter_logo.svg';
// import FacebookLogo from '../assets/facebook_logo.svg';

// Gets the ID of the video for Twitch.
const getParameterByName = (name, url = window.location.href) => {
   name = name.replace(/[\[\]]/g, '\\$&');
   var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
   if (!results) return null;
   if (!results[2]) return '';
   return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

// YouTube domain.
export function youtubeDomain(shared_content) {
   let domain = (
      <div className="flex sm:block">
         <a target="_blank" href={`${shared_content.url}`}>
            <img src={YoutubeLogo} className="h-5 mr-1.5 cursor-pointer" />
         </a>

         <a target="_blank" href={`${shared_content.url}`}>
            <div className="text-black cursor-pointer font-Pulp font-thin text-lg leading-5 sm:mt-1">
               {getPlatformDisplayName(shared_content.platform_domain)}
            </div>
         </a>
      </div>
   );
   return domain;
}

// YouTube thumb.
export function youtubeThumb(shared_content) {
   if (shared_content.platform === 'YOUTUBE') {
      return (
         <iframe
            width={constants.post_video_size.width}
            height={constants.post_video_size.height}
            src={`https://www.youtube.com/embed/${shared_content.id}`}
            title="YouTube video player"
            frameBorder="0"
            className={`rounded-md w-full aspect-video`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
         ></iframe>
      );
   }
}

// Twitch domain.
export function twitchDomain(shared_content) {
   if (shared_content.platform === 'TWITCH') {
      let domain = (
         <div className="flex sm:block">
            <a target="_blank" href={`${shared_content.url}`}>
               <img src={TwitchLogo} className="h-5 mr-1.5 cursor-pointer" />
            </a>

            <a target="_blank" href={`${shared_content.url}`}>
               <div className="text-black cursor-pointer font-Pulp font-thin text-lg leading-4 sm:mt-1">
                  Discover the best live streams
               </div>
            </a>
         </div>
      );
      return domain;
   }
}

// Twitch thumb.
export function twitchThumb(shared_content) {
   let video_id = getParameterByName('video', shared_content.video_link.url);
   if (video_id) {
      return (
         <iframe
            src={`https://player.twitch.tv/?autoplay=false&parent=localhost&video=v${video_id}&`}
            width={constants.post_video_size.width}
            height={constants.post_video_size.height}
            className={`rounded-md w-full aspect-video`}
            allowFullScreen
         ></iframe>
      );
   } else if (shared_content.thumbnail) {
      return (
         <img
            className={`${styles.richContentImageMaxSize} rounded-md mt-4 cursor-pointer`}
            src={shared_content.thumbnail}
            alt="Thumb"
         />
      );
   } else {
      return null;
   }
}

// if (shared_content.platform === 'TWITTER') {
//    domain = (
//       <div className="flex">
//          <a target="_blank" href={`${shared_content.url}`}>
//             <img src={TwitterLogo} className="h-5 mr-1.5 cursor-pointer" />
//          </a>

//          <a target="_blank" href={`${shared_content.url}`}>
//             <div className="text-black cursor-pointer font-Pulp font-thin text-lg leading-5">
//                {getPlatformDisplayName(shared_content.platform_domain)}
//             </div>
//          </a>
//       </div>
//    );
//    return domain;
// }

// if (shared_content.platform === 'FACEBOOK') {
//    domain = (
//       <div className="flex">
//          <a target="_blank" href={`${shared_content.url}`}>
//             <img src={FacebookLogo} className="h-5 mr-1.5 cursor-pointer" />
//          </a>

//          <a target="_blank" href={`${shared_content.url}`}>
//             <div className="text-black cursor-pointer font-Pulp font-thin text-lg leading-5">
//                {getPlatformDisplayName(shared_content.platform_domain)}
//             </div>
//          </a>
//       </div>
//    );
//    return domain;
// }

// Other domain.
export function otherDomain(shared_content) {
   let domain = (
      <div className="flex">
         <a target="_blank" href={`${shared_content.url_domain}`}>
            <img src={OtherLink} className="h-5 mr-1.5 cursor-pointer" />
         </a>

         <a target="_blank" href={`${shared_content.url}`}>
            <div className="text-black cursor-pointer font-Pulp font-thin text-lg leading-4">
               | {getPlatformDisplayName(shared_content.platform_domain)}
            </div>
         </a>
      </div>
   );
   return domain;
}

// Other thumb.
export function otherThumb(shared_content) {
   if (shared_content?.thumbnail) {
      return (
         <img
            className={`${styles.richContentImageMaxSize} rounded-lg mt-4 cursor-pointer`}
            src={shared_content.thumbnail}
            alt="Thumb"
         />
      );
   } else {
      return null;
   }
}
