import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const NewPosts = ({ content, getHomeContentHandleClick }) => {
   return (
      <Fragment>
         <div
            onClick={getHomeContentHandleClick}
            id="new-post-notification"
            className="w-full pt-3 pb-3 px-4 text-xl tracking-wide font-thin font-Pulp text-black hover:bg-gray-50 cursor-pointer sm:border-r-0 border-r-2 text-center border-b-2 sm:border-l-0 border-l-2 border-gray-200"
         >
            You have{' '}
            <span className="font-bold text-mixinlab-teal">
               {content.number_of_new_home_content_post_available}
            </span>{' '}
            new post
            {content.number_of_new_home_content_post_available > 1 ? 's' : null}
         </div>
      </Fragment>
   );
};
NewPosts.propTypes = {
   content: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   content: state.content
});

export default connect(mapStateToProps)(NewPosts);
