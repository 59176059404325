// React.
import React, { Fragment, useState, useRef, useEffect } from 'react';
import styles from '../css/styles.module.css';
import { Link } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import utils.
import addDataStorePathMixImages from '../utils/addDataStorePathMixImages';
import addDataStorePathMixes from '../utils/addDataStorePathMixes';

// Import components.
import AudioPlayerButtonPlay from './AudioPlayerButtonPlay';
import AudioPlayerButtonPause from './AudioPlayerButtonPause';

// Import actions.
import {
   saveMixPosition,
   loadTimelineMix,
   getCurrentMix,
   playAudio,
   pauseAudio
} from '../actions/mix';

// Import icons.
// import Spinner from './Spinner';
import back_arrow_scrub from '../assets/back_arrow_scrub.svg';
import forward_arrow_scrub from '../assets/forward_arrow_scrub.svg';
import volume_unmute from '../assets/volume.svg';
import volume_mute from '../assets/volume_mute.svg';
import ReactGA from "react-ga";

const AudioPlayer = ({
   mix,
   auth,
   getCurrentMix,
   saveMixPosition,
   playAudio,
   pauseAudio
}) => {
   const [speaker, setSpeaker] = useState(volume_unmute);
   const [lastVolume, setLastVolume] = useState(1);
   const [isMute, setIsMute] = useState(false);
   const [duration, setDuration] = useState(0);
   const [currentTime, setCurrentTime] = useState(0);
   const [currentMixLocal, setCurrentMixLocal] = useState({
      current_mix_status: '',
      current_mix_id: null,
      current_mix_mp3: '',
      current_mix_cover: '',
      current_mix_title: '',
      current_mix_user: '',
      current_mix_pos: 0
   });

   // References
   const audioPlayer = useRef(); // reference our audio component
   const progressBar = useRef(); // reference our progress bar
   const volumeBar = useRef(); // reference our progress bar
   const animationRef = useRef(); // reference the animation

   // 1. On audio player load get current mix.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         getCurrentMix();
      }
   }, [auth]);

   // 2. Play mix on load from timeline.
   // Auth should be take on off to make single content open
   useEffect(() => {
      if (
         mix.current_mix_is_playing === true
      ) {
         handlePlay();
      }
      if (
         mix.current_mix_is_playing === false
      ) {
         handlePause();
      }
   }, [mix.current_mix_is_playing]);

   // 3. On page load get mix in reducer.
   useEffect(() => {
      if (
         !auth.loading &&
         auth.isAuthenticated &&
         mix.current_mix_status === 'success'
      ) {
         setCurrentMixLocal({
            ...currentMixLocal,
            current_mix_status: mix.current_mix_success,
            current_mix_id: mix.current_mix_id,
            current_mix_mp3: mix.current_mix_mp3,
            current_mix_cover: mix.current_mix_cover,
            current_mix_title: mix.current_mix_title,
            current_mix_user: mix.current_mix_user.user_name,
            current_mix_user_display_name: mix.current_mix_user.display_name,
            current_mix_pos: mix.current_mix_pos
         });
         setCurrentTime(mix.current_mix_pos);
         changeRangeOnLoad();
      }
   }, [auth, mix.current_mix_status]);

   // 4. Get mix when loaded from timeline.
   // Auth should be take on off to make single content open
   useEffect(() => {
      if (
         mix.current_mix_id !== currentMixLocal.current_mix_id
      ) {
         setCurrentMixLocal({
            ...currentMixLocal,
            current_mix_status: mix.current_mix_success,
            current_mix_id: mix.current_mix_id,
            current_mix_mp3: mix.current_mix_mp3,
            current_mix_cover: mix.current_mix_cover,
            current_mix_title: mix.current_mix_title,
            current_mix_user: mix.current_mix_user.user_name,
            current_mix_user_display_name: mix.current_mix_user.display_name,
            current_mix_pos: mix.current_mix_pos
         });
         setCurrentTime(mix.current_mix_pos);
         changeRangeOnLoad();
         ReactGA.event({category:'Mix', action:'Loaded From Timeline', label: mix.current_mix_id + ' - ' + mix.current_mix_title  , value: 1 });
      }
   }, [mix.current_mix_id]);

   useEffect(() => {
      const seconds = Math.floor(audioPlayer.current.duration);
      setDuration(seconds);
      progressBar.current.max = seconds;
   }, [audioPlayer?.current?.readyState]);

   useEffect(() => {
      const interval = setInterval(() => {}, 1000000);
      return () => clearInterval(interval);
   }, []);

   const handleSaveMixPosition = () => {
      saveMixPosition({
         current_mix: currentMixLocal.current_mix_id,
         current_mix_pos: currentTime
      });
   };

   // Play audio.
   const handlePlay = async () => {
      if (audioPlayer.current.paused) {
         if (audioPlayer?.current?.readyState) {
            audioPlayer.current.play();
         } else {
            audioPlayer?.current.addEventListener('canplaythrough', function () {
               console.log('audio is ready to play');
               audioPlayer.current.play();
            });
         }
      }
      animationRef.current = requestAnimationFrame(whilePlaying);
      playAudio();
   };

   // Pause audio.
   const handlePause = () => {
      if (!audioPlayer.current.paused) {
         audioPlayer.current.pause();
      }
      cancelAnimationFrame(animationRef.current);
      handleSaveMixPosition();
      pauseAudio();
   };

   const calculateTime = (secs) => {
      if (!isNaN(secs)) {
         const minutes = Math.floor(secs / 60);
         const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
         const seconds = Math.floor(secs % 60);
         const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
         return `${returnedMinutes}:${returnedSeconds}`;
      }
      return `00:00`;
   };

   const onLoadedMetadata = () => {
      const seconds = Math.floor(audioPlayer.current.duration);
      setDuration(seconds);
      progressBar.current.max = seconds;
   };

   const whilePlaying = () => {
      if (audioPlayer.current) {
         progressBar.current.value = audioPlayer.current.currentTime;
         changePlayerCurrentTime();
         animationRef.current = requestAnimationFrame(whilePlaying);
      }
   };

   const changeRange = () => {
      audioPlayer.current.currentTime = progressBar.current.value;
      changePlayerCurrentTime();
      handleSaveMixPosition();
   };

   const changeRangeOnLoad = () => {
      audioPlayer.current.currentTime = mix.current_mix_pos;
      animationRef.current = requestAnimationFrame(whilePlaying);
   };

   const toggleVolume = () => {
      const prevValue = isMute;
      setIsMute(!isMute);
      if (!prevValue) {
         setSpeaker(volume_mute);
         volumeBar.current.value = 0;
         changeVolume();
      } else {
         setSpeaker(volume_unmute);
         lastVolume !== 0
            ? (volumeBar.current.value = lastVolume)
            : (volumeBar.current.value = 1);
         changeVolume();
      }
   };

   const changeVolume = () => {
      audioPlayer.current.volume = volumeBar.current.value;
      if (audioPlayer.current.volume !== 0) {
         setLastVolume(audioPlayer.current.volume);
      }
   };

   const changePlayerCurrentTime = () => {
      progressBar.current.style.setProperty(
         '--seek-before-width',
         `${(progressBar.current.value / duration) * 100}%`
      );
      setCurrentTime(progressBar.current.value);
   };

   const backThirty = () => {
      progressBar.current.value = Number(progressBar.current.value - 30);
      changeRange();
   };

   const forwardThirty = () => {
      audioPlayer.current.currentTime = audioPlayer.current.currentTime + 30;
      progressBar.current.value = Number(audioPlayer.current.currentTime);
      changeRange();
   };

   const player = (
      <div>
         <div className="w-full text-center p-3 lg:py-2 lg:px-6 mx-auto bg-white font-Pulp font-normal text-2xl lg:border-b-0 border-2 lg:border-l-0 lg:border-r-0 border-gray-200">
            {/* ================================== audio tag ==================================== */}
            <audio
               onLoadedMetadata={onLoadedMetadata}
               ref={audioPlayer}
               src={addDataStorePathMixes(currentMixLocal.current_mix_mp3)}
               preload="metadata"
            ></audio>

            <div className="flex lg:hidden">
               <div className="w-16">
                  <Link to={`/content/${mix.current_mix_post_id}`}>
                     <div
                        className="h-11 w-11 bg-no-repeat bg-cover rounded rounded-xs bg-gray-100 cursor-pointer"
                        style={{
                           backgroundImage: `url(${addDataStorePathMixImages(
                              currentMixLocal.current_mix_cover
                           )})`
                        }}
                     ></div>
                  </Link>
               </div>
               <div className="w-205 lg:w-full">
                  <Link to={`/content/${mix.current_mix_post_id}`}>
                     <div className="leading-5 text-left text-base font-bold truncate pt-0.5">
                        {currentMixLocal.current_mix_title}
                     </div>
                  </Link>
                  <div className="leading-6 text-left text-sm w-44">
                     <span className="font-thin">Mixed by </span>
                     <span className="text-mixinlab-indigo cursor-pointer">
                        <Link to={`/user/${currentMixLocal.current_mix_user}`}>
                           {currentMixLocal.current_mix_user_display_name}
                        </Link>
                     </span>
                  </div>
               </div>
            </div>

            <div>
               <div className="lg:flex flex-row-reverse">
                  <div className="w-full">
                     <div className="flex">
                        <div className="w-full">
                           <div className="lg:block hidden">
                              <div className="mt-1 mb-1 text-left text-xl">
                                 <Link to={`/content/${mix.current_mix_post_id}`}>
                                    <span className="mr-3 leading-3">
                                       {currentMixLocal.current_mix_title}
                                    </span>
                                 </Link>

                                 <span className="text-mixinlab-indigo cursor-pointer">
                                    <Link
                                       to={`/user/${currentMixLocal.current_mix_user}`}
                                    >
                                       {currentMixLocal.current_mix_user_display_name}
                                    </Link>
                                 </span>
                              </div>
                           </div>

                           <div className="flex">
                              <div className="w-24 h-7 tracking-wide lg:text-left text-center text-sm lg:text-base lg:pt-0 font-light pt-1">
                                 {calculateTime(currentTime)}
                              </div>

                              <input
                                 ref={progressBar}
                                 className={`${styles.progressBar} mx-1 mt-3`}
                                 type="range"
                                 defaultValue="0"
                                 onChange={changeRange}
                              />

                              <div className="w-24 h-7 tracking-wide lg:text-right text-center text-sm lg:text-base lg:pt-0 font-light pt-1">
                                 {calculateTime(duration)}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  {/* ========================= Player controls ======================== */}
                  <div className="flex justify-around w-full lg:w-16 lg:mr-4">
                     <div
                        onClick={backThirty}
                        className="flex w-17 h-10 mt-0.5 hover:bg-mixinlab-indigo_light cursor-pointer rounded-full lg:hidden"
                     >
                        {/* Back button */}
                        <button
                           className="w-10 h-10 bg-70 bg-center bg-no-repeat"
                           style={{
                              backgroundImage: `url(${back_arrow_scrub})`
                           }}
                        ></button>
                        <h5 className="pt-2.5 text-sm font-light">30</h5>
                     </div>

                     {/* Play button */}
                     <div className="w-10 h-10 lg:w-18 lg:h-14 mt-0.5">
                        {mix.current_mix_is_playing ? (
                           <AudioPlayerButtonPause handlePause={handlePause} />
                        ) : (
                           <AudioPlayerButtonPlay handlePlay={handlePlay} />
                        )}
                     </div>

                     <div
                        onClick={forwardThirty}
                        className="flex justify-end mt-0.5 w-17 h-10 hover:bg-mixinlab-indigo_light cursor-pointer rounded-full lg:hidden"
                     >
                        <h5 className="flex pt-2.5 text-sm font-light">30</h5>
                        {/* Forward button */}
                        <button
                           className="w-10 h-10 bg-70 bg-center bg-no-repeat"
                           style={{ backgroundImage: `url(${forward_arrow_scrub})` }}
                        ></button>
                     </div>
                  </div>
               </div>

               {/* ========================== Volume bar ======================== */}

               <div className="flex mt-2 lg:hidden">
                  <div className="w-24 h-7 flex justify-end text-sm font-light">
                     <div
                        onClick={toggleVolume}
                        className="h-7 w-7 mr-0.5 bg-center bg-no-repeat bg-contain"
                        style={{
                           backgroundImage: `url(
                            ${speaker}
                         )`
                        }}
                     ></div>
                  </div>

                  {/* Slider */}
                  <input
                     ref={volumeBar}
                     className={`${styles.progressBar} mx-1 mt-3`}
                     type="range"
                     defaultValue={lastVolume}
                     min="0"
                     max="1"
                     step="0.1"
                     onChange={changeVolume}
                     onClick={changeVolume}
                  />

                  <div className="w-24 h-7 text-sm font-light pt-1"></div>
               </div>
            </div>
         </div>
         <div className="h-8 lg:hidden bg-white border-gray-200 border-b-2"></div>
      </div>
   );

   return <Fragment>{player}</Fragment>;
};

AudioPlayer.propTypes = {
   playAudio: PropTypes.func.isRequired,
   pauseAudio: PropTypes.func.isRequired,
   loadTimelineMix: PropTypes.func.isRequired,
   getCurrentMix: PropTypes.func.isRequired,
   saveMixPosition: PropTypes.func.isRequired,
   mix: PropTypes.object.isRequired,
   auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({ mix: state.mix, auth: state.auth });

export default connect(mapStateToProps, {
   loadTimelineMix,
   getCurrentMix,
   saveMixPosition,
   playAudio,
   pauseAudio
})(AudioPlayer);
