import axios from 'axios';

import {
   USER_CONTEXT_LOADED_SUCCESS,
   USER_CONTEXT_LOADED_FAIL,
   USER_CONTEXT_CLEARED,
   CLEAR_USER_FOLLOW_STATUS
} from './types';

// Get any user profile by user_name.
export const getUserContext = (user) => async (dispatch) => {
   try {
      const res = await axios.get(`/api/user/get-user/${user}`);

      dispatch({
         type: USER_CONTEXT_LOADED_SUCCESS,
         payload: res.data
      });
   } catch (err) {
      dispatch({
         type: USER_CONTEXT_LOADED_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

export const clearUserContext = () => async (dispatch) => {
   dispatch({
      type: USER_CONTEXT_CLEARED
   });
   dispatch({
      type: CLEAR_USER_FOLLOW_STATUS
   });
};
