// Import React.
import React, { Fragment, useState } from 'react';

// Import images.
import Chat from '../assets/chat.gif';
import ChatTeal from '../assets/chat_teal.gif';
import ChatTealFocus from '../assets/chat_teal_focus.gif';

const Comment = ({ commentCount, openCommentModal }) => {
   const [isHovering, setIsHovered] = useState(false);
   const onMouseEnter = () => setIsHovered(true);
   const onMouseLeave = () => setIsHovered(false);

   const comment_component = (
      <div
         className="flex flex-row items-center group"
         onMouseEnter={onMouseEnter}
         onMouseLeave={onMouseLeave}
      >
         {commentCount === 0 ? (
            isHovering ? (
               <div
                  onClick={openCommentModal}
                  className="flex flex-row h-9 w-9 cursor-pointer bg-center bg-70 bg-no-repeat rounded-full group-hover:bg-mixinlab-teal_15"
                  style={{
                     backgroundImage: `url(${ChatTeal})`
                  }}
               ></div>
            ) : (
               <div
                  onClick={openCommentModal}
                  className="flex flex-row h-9 w-9 cursor-pointer bg-center bg-70 bg-no-repeat rounded-full group-hover:bg-mixinlab-teal_15"
                  style={{
                     backgroundImage: `url(${Chat})`
                  }}
               ></div>
            )
         ) : (
            <div
               onClick={openCommentModal}
               className="flex flex-row h-9 w-9 cursor-pointer bg-center bg-70 bg-no-repeat rounded-full group-hover:bg-mixinlab-teal_15"
               style={{
                  backgroundImage: `url(${ChatTealFocus})`
               }}
            ></div>
         )}

         <h6
            className={`${
               commentCount === 0
                  ? 'text-xs text-black group-hover:text-mixinlab-teal font-medium ml-1'
                  : 'text-xs text-mixinlab-teal font-medium ml-1'
            }`}
         >
            {commentCount}
         </h6>
      </div>
   );

   return <Fragment>{comment_component}</Fragment>;
};

export default Comment;
