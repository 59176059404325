// Import React.
import React, { Fragment, useState } from 'react';
import styles from '../css/styles.module.css';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import { playAudio } from '../actions/mix';

// Import components.
const MixPlayerButtonPlay = ({ playAudio }) => {
   const [isHovering, setIsHovered] = useState(false);
   const onMouseEnter = () => setIsHovered(true);
   const onMouseLeave = () => setIsHovered(false);

   const handlePlayAudio = (e) => {
      e.stopPropagation();
      playAudio();
   };

   const playButton = (
      <div
         className="flex items-center group"
         onMouseEnter={onMouseEnter}
         onMouseLeave={onMouseLeave}
      >
         {isHovering ? (
            <div className="group">
               <div
                  onClick={handlePlayAudio}
                  className="h-12 w-12 grid content-center justify-center cursor-pointer rounded-full border group-hover:border-mixinlab-indigo_mid group-hover:bg-mixinlab-indigo_mid"
               >
                  <div className="pl-0.5 w-full">
                     <div className={styles.playArrowWhiteMix}></div>
                  </div>
               </div>
            </div>
         ) : (
            <div
               onClick={playAudio}
               className="h-12 w-12 grid content-center justify-center cursor-pointer rounded-full border border-gray-800"
            >
               <div className="pl-0.5 w-full">
                  <div className={styles.playArrowMix}></div>
               </div>
            </div>
         )}
      </div>
   );

   return <Fragment>{playButton}</Fragment>;
};

MixPlayerButtonPlay.propTypes = {
   playAudio: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
   playAudio
})(MixPlayerButtonPlay);
