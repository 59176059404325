// React.
import React from 'react';

// Import icons.
import back_arrow from '../assets/back_arrow.svg';

const PageHeader = ({
   page,
   show_back_button,
   backButton,
   is_bottom_border_on = true
}) => {
   let header_border_class = 'border-b-2';

   if (!is_bottom_border_on) header_border_class = 'border-b-0';

   return (
      <div
         className={`${header_border_class}  flex h-12 pl-1 pt-1 pb-11 font-Pulp font-semibold text-2xl tracking-wide border-gray-200`}
         onClick={backButton}
      >
         {show_back_button ? (
            <div
               className="mr-1 bg-80 bg-no-repeat bg-center h-10 w-10 hover:bg-gray-200 rounded-full cursor-pointer"
               style={{
                  backgroundImage: `url(${back_arrow})`
               }}
               alt={'Back'}
            ></div>
         ) : null}

         <h1 className="mt-0.5 ml-3">{page}</h1>
      </div>
   );
};

export default PageHeader;
