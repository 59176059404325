// React and PropTypes.
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';

// Connect Redux to component.
import { connect } from 'react-redux';

// Components.
import AlertFrontEnd from '../components/AlertFrontEnd';
import GaPageView from '../components/GaPageView';

// Import actions.
import { resetPassword } from '../actions/user';
import tick from '../assets/tick.svg';

// isAuthenticated is global state or Redux store.
const ResetPassword = ({ auth, user, resetPassword }) => {
   const [state, setState] = useState({
      alert: false,
      alertMsg: '',
      newPasswordCorrect: false,
      confirmPasswordCorrect: false,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      isPasswordReset: null
   });

   const queryParams = queryString.parse(window.location.search);

   useEffect(() => {
      if (!auth.loading && !auth.isAuthenticated === true) {
         setState({
            ...state,
            isPasswordReset: user.is_password_reset
         });
      }
   }, [user.is_password_reset]);

   const handleChangeNew = (e) => {
      const { value } = e.target;

      setState((prevState) => {
         return {
            ...prevState,
            newPassword: value
         };
      });
   };

   const handleChangeConfirm = (e) => {
      const { value } = e.target;

      setState((prevState) => {
         return {
            ...prevState,
            confirmPassword: value
         };
      });
   };

   const handleValidate = () => {
      const validate = state.newPassword.search(
         /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
      );

      if (!validate === false) {
         setState({
            ...state,
            newPasswordCorrect: false,
            confirmPasswordCorrect: false
         });
      }

      if (!validate === true) {
         setState({
            ...state,
            newPasswordCorrect: true,
            confirmPasswordCorrect: false
         });
      }

      if (!validate === true && state.newPassword === state.confirmPassword) {
         setState({
            ...state,
            newPasswordCorrect: true,
            confirmPasswordCorrect: true
         });
      }
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      resetPassword(
         queryParams.password_reset_string,
         queryParams.email,
         state.newPassword
      );
   };

   const alert =
      state.isPasswordReset === false ? (
         <AlertFrontEnd msg={'ERROR: Updating your password'} />
      ) : null;

   const success =
      state.isPasswordReset === true ? (
         <div className="border border-mixinlab-teal text-mixinlab-teal mb-5 px-4 py-3 rounded relative">
            Password successfully updated!
         </div>
      ) : null;

   const validateNewPassword = state.newPasswordCorrect ? (
      <div
         className="w-9 h-9 bg-no-repeat mt-1 ml-3 cursor-default"
         style={{
            backgroundImage: `url(${tick})`
         }}
      ></div>
   ) : null;

   const confirmNewPassword = state.confirmPasswordCorrect ? (
      <div
         className="w-9 h-9 bg-no-repeat mt-1 ml-3 cursor-default"
         style={{
            backgroundImage: `url(${tick})`
         }}
      ></div>
   ) : null;

   const button = state.confirmPasswordCorrect ? (
      <button
         onClick={handleSubmit}
         type="submit"
         className="pt-2 pb-1.5 px-5 font-Pulp w-max font-normal text-xl tracking-wide text-white rounded border-1.5 bg-mixinlab-teal border-2 border-mixinlab-teal hover:bg-white hover:text-mixinlab-teal cursor-pointer focus:outline-none"
      >
         Change Password
      </button>
   ) : (
      <div className="pt-2 pb-1.5 px-5 opacity-40 w-max font-Pulp font-normal text-xl tracking-wide text-white rounded border-1.5 bg-mixinlab-teal border-2 border-mixinlab-teal cursor-pointer focus:outline-none">
         Change Password
      </div>
   );

   const showPasswordReset = !state.isPasswordReset ? (
      <div className="flex justify-center">
      <div className="w-10/12 max-w-400 mb-24">
         <div className="h-24 flex flex-wrap content-end">
         <h1 className="font-Pulp font-bold text-3xl ml-1 mb-5">Password Reset</h1>
            </div>
            <form>
               <div>
                  {alert} {success}
                  <div className="flex">
                     <div className="w-11/12">
                        <label htmlFor="new_password"></label>
                        <div className="flex items-center border rounded-md border-gray-500 p-1">
                           <input
                              className="placeholder-black appearance-none pb-1.5 pt-0.5 px-3 block text-xl font-semibold bg-transparent border-none w-full text-black focus:outline-none"
                              type="password"
                              autoComplete="off"
                              name="new_password"
                              placeholder="New Password"
                              value={state.newPassword}
                              onChange={handleChangeNew}
                              onKeyUp={handleValidate}
                           />
                        </div>
                        <div className="text-xs text-gray-500 ml-2">
                           Min 8 chars. Must contain a capital letter, number and
                           symbol
                        </div>
                     </div>
                     {validateNewPassword}
                  </div>
                  <div className="flex mt-5">
                     <div className="w-11/12">
                        <label htmlFor="new_password"></label>
                        <div className="flex items-center border rounded-md border-gray-500 p-1">
                           <div>
                              <input
                                 className="placeholder-black appearance-none pb-1.5 pt-0.5 px-3 block text-xl font-semibold bg-transparent border-none w-full text-black focus:outline-none"
                                 type="password"
                                 autoComplete="off"
                                 name="new_password_confirm"
                                 placeholder="Confirm password"
                                 value={state.confirmPassword}
                                 onChange={handleChangeConfirm}
                                 onKeyUp={handleValidate}
                              />
                           </div>
                        </div>
                        <div className="text-xs text-gray-500 ml-2">
                           Re-enter password
                        </div>
                     </div>
                     {confirmNewPassword}
                  </div>
                  <div className="mx-auto mt-6">{button}</div>
               </div>
            </form>
         </div>
      </div>
   ) : (
      <div className="mx-auto items-center max-w-sm">
         <div className="h-24 flex flex-wrap content-end">
            <h1 className="font-Pulp font-bold text-3xl mb-5 pt-2">Password Reset</h1>
         </div>
         <div>
            {alert} {success}
         </div>
         <Link to="/login">
            <button className="mt-5 pt-2 pb-1.5 px-5 font-Pulp font-normal text-xl tracking-wide text-white rounded border-1.5 bg-mixinlab-teal border-2 border-mixinlab-teal hover:bg-white hover:text-mixinlab-teal cursor-pointer focus:outline-none">
               Go to login page
            </button>
         </Link>
      </div>
   );

   return <Fragment><GaPageView/>{showPasswordReset}</Fragment>;
};

ResetPassword.propTypes = {
   resetPassword: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired,
   user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth,
   user: state.user
});

export default connect(mapStateToProps, { resetPassword })(ResetPassword);
