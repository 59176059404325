import React, { Fragment } from 'react';

const FollowNav = ({
   showFollowing,
   showFollowers,
   show_follow_nav,
   is_show_followers,
   is_show_following
}) => {
   const follow_nav = show_follow_nav ? (
      <div>
         <button
            onClick={showFollowing}
            className={`${
               is_show_following ? `bg-mixinlab-indigo text-white` : null
            } h-14 w-1/2 border-b-2 border-l-2 sm:border-l-0 border-gray-200 hover:border-r-mixinlab-indigo border-r-mixinlab-indigo hover:bg-mixinlab-indigo font-Pulp font-normal text-xl tracking-wide hover:text-white text-black cursor-pointer`}
         >
            <h5 className="text-lg pt-0.5">{'Following'}</h5>
         </button>

         <button
            onClick={showFollowers}
            className={`${
               is_show_followers ? `bg-mixinlab-indigo text-white` : null
            } h-14 w-1/2 border-b-2 border-r-2 sm:border-r-0 border-gray-200 hover:border-l-mixinlab-indigo border-l-mixinlab-indigo hover:bg-mixinlab-indigo font-Pulp font-normal text-xl tracking-wide hover:text-white text-black cursor-pointer`}
         >
            <h5 className="text-lg  pt-0.5">{'Followers'}</h5>
         </button>
      </div>
   ) : null;
   return <Fragment>{follow_nav}</Fragment>;
};

export default FollowNav;
