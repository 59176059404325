// React and PropTypes.
import React, { Fragment, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Icons
// import listen from '../assets/listener.svg';
// import headphones from '../assets/headphones.svg';
// import headphones_record from '../assets/headphones_record.svg';

// Components
// import PlanTile from '../components/PlanTile';
import PlanTileBeta from '../components/PlanTileBeta';
import GaPageView from '../components/GaPageView';

const Plan = ({ auth }) => {
   const [state, setState] = useState({
      beta_code: 'mixcamp-backstage-access',
      beta_code_match: ''
   });

   // Match beta code.
   const matchCode = (value) => {
      setState({
         ...state,
         beta_code_match: value.trim()
      });
   };


   // Redirect if logged in.
   if (auth.isAuthenticated) {
      return <Redirect to="/home" />;
   }
   const beta = <div></div>;
   // const free = <div></div>;
   // const listenText = (
   //    <div>
   //       30 Day <span className="font-bold">FREE TRIAL</span>
   //    </div>
   // );
   // const dj = (
   //    <div>
   //       30 Day <span className="font-bold">FREE TRIAL</span>
   //    </div>
   // );

   return (
      <Fragment>
         <div className="w-screen flex justify-center px-10 mb-5">
            <GaPageView />
            <div>
               <div className="flex flex-wrap justify-center gap-10">
                  <PlanTileBeta
                     title={beta}
                     icon={null}
                     price="Beta tester"
                     plan="Guest"
                     plan_id="62a2ac90eaad8a85ac0f2ffd"
                     plan_description="Welcome to Mixcamp Beta - you are one the first people here!"
                     button_text="Sign up"
                     type={'guest'}
                     matchCode={matchCode}
                     beta_code={state.beta_code}
                     beta_code_match={state.beta_code_match}
                  />
                  {/* <PlanTile
                     title={free}
                     icon={listen}
                     price="FREE"
                     plan="Hangout"
                     plan_id="61a81a2f2c9ce739f8374ec6"
                     plan_description="Checkout mixinlab with no commitment and upgrade to listen."
                     bullet1={'Create a profile'}
                     bullet2={'Post content'}
                     bullet3={'Get involved'}
                     button_text="Sign up"
                     type={'hangout'}
                  />
                  <PlanTile
                     title={listenText}
                     icon={headphones}
                     price="$1.99"
                     plan="Listener"
                     plan_id="61e0ebe53fdb1d4910e3e21b"
                     plan_description="Join the community and discover new music to soundtrack your life."
                     bullet1={'Unlimited streaming'}
                     bullet2={'Advanced music discovery'}
                     bullet3={'All your favorite artists'}
                     button_text="Sign up"
                     type={'listen'}
                  />
                  <PlanTile
                     title={dj}
                     icon={headphones_record}
                     price="$9.99"
                     plan="DJ"
                     plan_id="61e0ec093fdb1d4910e3e21e"
                     plan_description="Upload mixes and connect with the people that love what you play."
                     bullet1={'Create a profile'}
                     bullet2={'Advanced music discovery'}
                     bullet3={'Build base of Listeners'}
                     button_text="Sign up"
                     type={'dj'}
                  /> */}
               </div>
               <div className="w-full flex justify-center mt-1">
                  <h6 className="text-sm mx-auto text-gray-400">
                     Already have an account?
                     <Link to="/login" className="font-bold pl-1 text-mixinlab-teal">
                        Login
                     </Link>
                  </h6>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

Plan.propTypes = {
   auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth
});

export default connect(mapStateToProps)(Plan);
