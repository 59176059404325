// React and PropTypes.
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Actions
import { choosePlan } from '../actions/user';

import clipboard from '../assets/clipboard.svg';
import clipboard_white from '../assets/clipboard_white.svg';

const PlanTile = ({
   // title,
   // icon,
   // price,
   plan,
   plan_id,
   // plan_description,
   button_text,
   choosePlan,
   matchCode,
   beta_code,
   beta_code_match
}) => {
   const [state, setState] = useState({ show_code: false, show_copied: false });
   const [userInput, setUserInput] = useState({ value: '' });

   const handleClick = () => {
      choosePlan({ plan_id, plan });
      localStorage.setItem('plan_id', plan_id);
      localStorage.setItem('plan', plan);
   };

   const showCode = () => {
      setState({
         ...state,
         show_code: true
      });
   };

   const handleCopy = () => {
      navigator.clipboard.writeText('mixcamp-backstage-access');
      setState({
         ...state,
         show_copied: true
      });

      setTimeout(() => {
         setState({
            ...state,
            show_copied: false
         });
      }, 1500);
   };

   const handleChange = (value) => {
      setUserInput(value);
      matchCode(value);
   };

   const button =
      beta_code === beta_code_match ? (
         <Link to="/signup">
            <button
               onClick={handleClick}
               className="mt-5 h-9 w-24 font-Pulp font-normal text-lg tracking-wide text-white rounded border-2 bg-mixinlab-teal border-mixinlab-teal hover:bg-white hover:text-mixinlab-teal cursor-pointer focus:outline-none"
            >
               {button_text}
            </button>
         </Link>
      ) : (
         <button className="opacity-20 mt-5 h-9 w-24 font-Pulp font-normal text-lg tracking-wide text-white rounded border-2 bg-mixinlab-teal border-mixinlab-teal focus:outline-none">
            {button_text}
         </button>
      );

   const copied = state.show_copied ? (
      <div className="absolute -right-2 -top-3 grid content-center shadow-modal px-2 bg-gray-100 h-8 rounded text-sm">
         Code copied!
      </div>
   ) : null;

   const code = state.show_code ? (
      <div className="flex justify-between">
         <div className="h-10 w-56 grid content-center text-center text-base bg-gray-100 px-3 rounded rounded-lg">
            mixcamp-backstage-access
         </div>
         <div className="grid content-center justify-center h-10 w-10 rounded-lg bg-mixinlab-teal cursor-pointer">
            <div
               onClick={handleCopy}
               className="bg-center bg-no-repeat h-7 w-7"
               style={{
                  backgroundImage: `url(${clipboard_white})`
               }}
            ></div>
         </div>
      </div>
   ) : (
      <div className="flex justify-between">
              <div className="h-10 w-56 grid content-center text-center text-base bg-gray-100 px-3 rounded rounded-lg opacity-50">
            Click reveal code
         </div>

         <div className="grid content-center justify-center h-10 w-10 rounded-lg bg-gray-100">
            <div
               className="bg-center bg-no-repeat h-7 w-7 opacity-20"
               style={{
                  backgroundImage: `url(${clipboard})`
               }}
            ></div>
         </div>
      </div>
   );

   const code_reveal = (
      <div className="relative w-80 border-2 border-b-0 border-gray-200 p-5 pt-10 mt-20">
         {copied}
         {code}
         <div className="flex justify-center mt-6">
            <button
               onClick={showCode}
               className="mt-1 h-9 px-3 font-Pulp font-normal text-lg tracking-wide text-white rounded border-2 bg-mixinlab-teal border-mixinlab-teal hover:bg-white hover:text-mixinlab-teal cursor-pointer focus:outline-none"
            >
               Reveal code
            </button>
         </div>
      </div>
   );

   const inputOpacity = state.show_code ? 'opacity-100' : 'opacity-10';

   return (
      <div>
         {code_reveal}

         <div className="flex justify-center w-80 border-2 border-t-0 border-gray-200 mb-5 pb-4">
            <div className="bg-white text-center w-full">
               <div className="px-6 pb-3">
                  <div className={`${inputOpacity} h-20 w-full pt-6 mb-1 text-sm`}>
                     <div className="flex w-56 mx-auto items-center border-2 border-mixinlab-indigo rounded-md mt-3 pt-0.5 pb-1 px-2">
                        <label htmlFor="user"></label>
                        <input
                           className="text-base w-full font-normal text-mixinlab-indigo placeholder-mixinlab-indigo_mid focus:outline-none p-0.5"
                           type="text"
                           name="user"
                           placeholder="Enter code"
                           autoComplete="off"
                           onChange={(e) => handleChange(e.target.value)}
                           value={userInput.value}
                        />
                     </div>
                  </div>
                  {button}
               </div>
            </div>
         </div>
      </div>
   );
};

PlanTile.propTypes = {
   choosePlan: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { choosePlan })(PlanTile);
