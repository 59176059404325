// React.
import React, { Fragment, useEffect } from 'react';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import components
import SingleContentWrapper from '../components/SingleContentWrapper';
import GaPageView from "../components/GaPageView";

const SingleContent = ({ getNotificationsCount }) => {
   return (
      <Fragment>
         <div className="w-600 sm:w-full h-full pb-60">
             <GaPageView/>
            <SingleContentWrapper />
         </div>
         <div className="w-72 pt-64 mt-6 lg:hidden ml-14 xl:ml-6"></div>
      </Fragment>
   );
};

SingleContent.propTypes = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {})(SingleContent);
