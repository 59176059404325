// Axios.
import axios from 'axios';

// Import actions.
import {
   GET_ME_SUCCESS,
   GET_ME_FAIL,
   UPDATE_ME_SUCCESS,
   UPDATE_ME_FAIL,
   RESET_ME_LOADED,
   DEACTIVATE_USER_SUCCESS,
   DEACTIVATE_USER_FAIL,
   REACTIVATE_USER_SUCCESS,
   REACTIVATE_USER_FAIL,
   USER_PASSWORD_UPDATE_SUCCESS,
   USER_PASSWORD_UPDATE_FAIL
} from './types';

// Set logged in user.
export const getMe = () => async (dispatch) => {
   try {
      const res = await axios.get('/api/user/get-me');

      dispatch({
         type: GET_ME_SUCCESS,
         payload: res.data
      });
   } catch (err) {
      dispatch({
         type: GET_ME_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// Reset the me.loaded flag after user is loaded via useEffect hook, (Profile.js)
export const resetMeLoaded = () => async (dispatch) => {
   dispatch({
      type: RESET_ME_LOADED
   });
};

// Update profile.
export const updateMe = (formData) => async (dispatch) => {
   const config = {
      headers: {
         accept: 'application/json',
         'Accept-Language': 'en-US,en;q=0.8',
         'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
      }
   };

   try {
      const res = await axios.post('/api/user/update-user', formData, config);
      dispatch({
         type: UPDATE_ME_SUCCESS,
         payload: res.data
      });
   } catch (err) {
      dispatch({
         type: UPDATE_ME_FAIL,
         payload: err.response.data
      });
   }
};

// Update Long Bio.
export const updateLongBioMe = (longBio) => async (dispatch) => {
   const config = {
      headers: {
         'Content-Type': 'application/json'
      }
   };

   try {
      const res = await axios.post('/api/user/update-user', longBio, config);

      dispatch({
         type: UPDATE_ME_SUCCESS,
         payload: res.data
      });
   } catch (err) {
      dispatch({
         type: UPDATE_ME_FAIL,
         payload: err.response.data
      });
   }
};

// Update profile image.
export const updateMeImg = (formData) => async (dispatch) => {
   // Configs.
   const config_1 = {
      'Content-Type': 'multipart/form-data'
   };
   const config_2 = {
      headers: {
         'Content-Type': 'application/json'
      }
   };

   try {
      // First block saves file to Digital Ocean Spaces.
      const res1 = await axios.post(
         '/api/user/upload-profile-image',
         formData,
         config_1
      );

      const { image } = res1.data;

      // Second block save the file name to Mongo DB.
      const saveImageTypeToCorrectRoute = () => {
         let body;

         if (image.includes('PROFILE') === true) {
            body = {
               user_image: image
            };
         } else if (image.includes('BACKGROUND') === true) {
            body = {
               user_bg: image
            };
         }
         return body;
      };

      const res2 = await axios.post(
         '/api/user',
         saveImageTypeToCorrectRoute(),
         config_2
      );

      dispatch({
         type: UPDATE_ME_SUCCESS,
         payload: res2.data
      });
   } catch (err) {
      console.error(err.response.data);
      dispatch({
         type: UPDATE_ME_FAIL,
         payload: err.response.data
      });
   }
};

// Deactivate user.
export const deactivateUser = (deactivate_user_id) => async (dispatch) => {
   try {
      const res = await axios.get(`/api/user/deactivate/${deactivate_user_id}`);
      dispatch({
         type: DEACTIVATE_USER_SUCCESS,
         payload: res.data
      });
   } catch (err) {
      dispatch({
         type: DEACTIVATE_USER_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// Reactivate user.
export const reactivateUser = (reactivate_user_id) => async (dispatch) => {
   try {
      const res = await axios.get(`/api/user/reactivate/${reactivate_user_id}`);
      dispatch({
         type: REACTIVATE_USER_SUCCESS,
         payload: res.data
      });
   } catch (err) {
      dispatch({
         type: REACTIVATE_USER_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// Update User Password.
export const updateUserPassword = (passwordChange) => async (dispatch) => {
   //{ password: state.confirmPassword }
   const config = {
      headers: {
         'Content-Type': 'application/json'
      }
   };
   try {
      const res = await axios.post(
         '/api/user/update-user-password',
         JSON.stringify(passwordChange),
         config
      );
      dispatch({
         type: USER_PASSWORD_UPDATE_SUCCESS,
         payload: res.data
      });
   } catch (err) {
      dispatch({
         type: USER_PASSWORD_UPDATE_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};
