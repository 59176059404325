// React and PropTypes.
import React, { Fragment, useEffect } from 'react';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import { getNotificationsCount } from '../actions/notification';

// Components
import PageHeader from '../components/PageHeader';
import MinutesTile from '../components/MinutesTile';
import GaPageView from '../components/GaPageView';

const BuyMinutes = ({ auth, getNotificationsCount }) => {
   // Get notifications on load
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         getNotificationsCount();
      }
   }, [auth]);

   return (
      <Fragment>
         <div className="w-600 sm:w-full sm:px-10 h-screen">
             <GaPageView/>
            <PageHeader page={'Buy Minutes'} is_bottom_border_on={false} />
            <div className="grid grid-cols-2 md:grid-cols-1 gap-2 content-center">
               <MinutesTile
                  title={'Gift'}
                  description={
                     'Checkout a mix or gift minutes to friends and followers'
                  }
                  price={1.99}
               />
               <MinutesTile
                  title={'Weekend Blast'}
                  description={
                     'Soundtrack your weekend with tracks you love mixed byut the DJs you rate'
                  }
                  price={8.99}
               />
               <MinutesTile
                  title={'DJ'}
                  description={
                     'Discover new music mixed by DJs who buy tracks from your favourite artists and labels'
                  }
                  price={14.99}
               />
               <MinutesTile
                  title={'Life Soundtrack'}
                  description={
                     'Soundtrack your life with the latest sounds from the world of dance music'
                  }
                  price={59.99}
               />
            </div>
         </div>
         <div className="w-72 pt-64 mt-6 lg:hidden ml-14 xl:ml-6"></div>
      </Fragment>
   );
};

BuyMinutes.propTypes = {
   auth: PropTypes.object.isRequired,
   getNotificationsCount: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth
});

export default connect(mapStateToProps, {
   getNotificationsCount
})(BuyMinutes);
