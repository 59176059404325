import {
   RESET_STATUS,
   SIGNUP_SUCCESS,
   SIGNUP_FAIL,
   TOKEN_LOADED_SUCCESS,
   TOKEN_LOADED_FAIL,
   CHECK_PASSWORD_SUCCESS,
   CHECK_PASSWORD_FAIL,
   LOGIN_SUCCESS,
   LOGIN_FAIL,
   LOG_OUT
} from '../actions/types';

const initialState = {
   token: localStorage.getItem('token'),
   plan: null,
   id: null,
   user_name: null,
   isAuthenticated: null,
   status: null,
   msg: null,
   loading: true,
   match: false
};

const auth = (state = initialState, action) => {
   switch (action.type) {
      case TOKEN_LOADED_SUCCESS:
         return {
            ...state,
            isAuthenticated: true,
            loading: false
         };
      case TOKEN_LOADED_FAIL:
         localStorage.removeItem('token');
         return {
            ...state,
            token: null,
            isAuthenticated: false,
            loading: false
         };
      case SIGNUP_SUCCESS:
         return {
            ...state,
            loading: false,
            status: action.payload.status
         };
      case SIGNUP_FAIL:
         localStorage.removeItem('token');
         return {
            ...state,
            token: null,
            loading: false,
            status: action.payload.status,
            msg: action.payload.msg
         };
      case LOGIN_SUCCESS:
         localStorage.setItem('token', action.payload.token);
         return {
            ...state,
            loading: false,
            status: action.payload.status
         };
      case LOGIN_FAIL:
         localStorage.removeItem('token');
         return {
            ...state,
            token: null,
            isAuthenticated: false,
            loading: false,
            user: null,
            status: action.payload.status,
            msg: action.payload.msg
         };
      case RESET_STATUS:
         return {
            ...state,
            status: null,
            msg: null
         };
      case CHECK_PASSWORD_SUCCESS:
         return {
            ...state,
            match: action.payload.match
         };
         case CHECK_PASSWORD_FAIL:
            return {
               ...state,
              msg: action.payload  // Not sure what format this is?
            };
      case LOG_OUT:
         localStorage.removeItem('token');
         return {
            ...state,
            token: null,
            isAuthenticated: null,
            loading: false,
            status: null,
            msg: null,
            plan: null,
            id: null,
            user_name: null
         };
      default:
         return state;
   }
};

export default auth;
