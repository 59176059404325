// React.
import React, { Fragment, useEffect } from 'react';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import { getNotificationsCount } from '../actions/notification';

// Import components
import SingleContentCommentWrapper from '../components/SingleContentCommentWrapper';
import GaPageView from '../components/GaPageView';

const SingleContentComments = ({ auth, getNotificationsCount }) => {
   // Get notifications count.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         getNotificationsCount();
      }
   }, [auth]);

   return (
      <Fragment>
         <div className="w-600 sm:w-full h-screen pb-60">
             <GaPageView/>
            <SingleContentCommentWrapper />
         </div>
         <div className="w-72 pt-64 mt-6 lg:hidden ml-14 xl:ml-6"></div>
      </Fragment>
   );
};

SingleContentComments.propTypes = {
   auth: PropTypes.object.isRequired,
   getNotificationsCount: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth
});

export default connect(mapStateToProps, { getNotificationsCount })(
   SingleContentComments
);
