// React.
import React, { useEffect, useState, Fragment } from 'react';
import { useRouteMatch } from 'react-router-dom';

// Redux.
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import { updateMe } from '../actions/me';

// NPM packages.
import TextareaAutosize from 'react-textarea-autosize';

const BioMe = ({ auth, me, user, updateMe }) => {
   const [state, setState] = useState({
      edit: false,
      bio_prev: '',
      bio: '',
      bio_count: 0,
      is_user_me: false
   });

   // Route match.
   let is_user_page = useRouteMatch({
      path: '/user/:user',
      strict: true,
      sensitive: true
   });

   // 1. Get users bio if user is me.
   useEffect(() => {
      if (
         !auth.loading &&
         auth.isAuthenticated &&
         is_user_page.params.user === me.user_name
      ) {
         let meInit = {};

         if (me.bio) {
            meInit.bio = me.bio;
         } else {
            meInit.bio = '';
         }

         if (me.bio) {
            meInit.bio_count = me.bio.length;
         } else {
            meInit.bio_count = 0;
         }

         setState({
            bio_prev: meInit.bio,
            bio: meInit.bio,
            bio_count: meInit.bio_count,
            edit: false,
            is_user_me: true
         });
      } else {
         let userInit = {};

         if (user.bio) {
            userInit.bio = user.bio;
         } else {
            userInit.bio = '';
         }

         setState({
            bio_prev: userInit.bio,
            bio: userInit.bio,
            bio_count: 0,
            edit: false,
            is_user_me: false
         });
      }
   }, [auth.loading, auth.isAuthenticated, me.bio]);

   const handleChange = (e) => {
      const { name, value } = e.target;

      if (name === 'bio') {
         setState({
            ...state,
            bio_count: value.length
         });
      }

      setState((prevState) => {
         return {
            ...prevState,
            [name]: value
         };
      });
   };

   const handleClickCancel = () => {
      setState({
         ...state,
         edit: false,
         bio: state.bio_prev
      });
   };

   const handleClickEdit = () => {
      setState({
         ...state,
         edit: true
      });
   };

   const handleSubmit = (e) => {
      e.preventDefault();

      // Instantiate new form.
      const formData = new FormData();

      // Append text fields.
      formData.append('bio', state.bio);

      updateMe(formData);

      setState({
         ...state,
         edit: false
      });
   };

   const bio_edit = (
      <div className="p-5 pt-6 pb-6 border-l-2 border-r-2 border-b-2 sm:border-l-0 sm:border-r-0">
         <form onSubmit={handleSubmit}>
            <label htmlFor="bio"></label>
            <div className="w-full tracking-wide font-Pulp text-xl mb-6">
               <span className="font-Pulp text-xl font-bold">Edit bio</span>
            </div>
            <div className="flex items-center border border-gray-300 rounded-md p-3 mb-2 mt-4">
               <TextareaAutosize
                  type="text"
                  name="bio"
                  minRows="3"
                  onChange={handleChange}
                  className="text-md font-normal w-full text-black focus:outline-none pl-1 overflow-hidden resize-none"
                  value={state.bio}
                  placeholder="Write a bio"
               />
            </div>
            <div>
               <h6 className="text-sm text-gray-500 pl-2">
                  Bio
                  <span
                     className={`ml-2 ${
                        state.bio_count > 500
                           ? 'text-mixinlab-cherry font-bold'
                           : 'text-mixinlab-teal'
                     } `}
                  >
                     {state.bio_count}
                  </span>
                  <span className="text-black"> / 500 chars.</span>
               </h6>
            </div>
            <div className="flex justify-end w-full pt-2">
               {!state.bio ? null : (
                  <button
                     onClick={handleClickCancel}
                     className="h-10 px-4 mr-4 font-Pulp font-normal text-lg tracking-wide text-mixinlab-teal rounded border-2 border-mixinlab-teal hover:bg-mixinlab-teal hover:text-white cursor-pointer focus:outline-none"
                  >
                     Cancel
                  </button>
               )}

               {/* Save button. */}
               {state.bio_count > 400 ? (
                  <div className="h-10 px-4 font-Pulp font-normal pt-1 text-lg tracking-wide rounded border-2 opacity-50 bg-mixinlab-cherry border-mixinlab-cherry text-white cursor-pointer focus:outline-none">
                     <div className="mx-auto">Save</div>
                  </div>
               ) : (
                  <button
                     type="submit"
                     className="h-10 px-4 font-Pulp font-normal text-lg tracking-wide text-mixinlab-teal rounded border-2 border-mixinlab-teal hover:bg-mixinlab-teal hover:text-white cursor-pointer focus:outline-none"
                  >
                     Save
                  </button>
               )}
            </div>
         </form>
      </div>
   );

   const bio_display = (
      <div className="p-5 pt-6 pb-6 border-l-2 border-r-2 border-b-2 sm:border-l-0 sm:border-r-0">
         <div>
            <div className="w-full tracking-wide font-Pulp text-xl font-bold">
               <span className="font-Pulp text-xl font-bold">About</span>
            </div>
            <p className="mb-1 mt-4 leading-relaxed whitespace-pre-line">
               {state.bio === '' && state.is_user_me
                  ? 'Click Edit to add your bio'
                  : state.bio}
            </p>
         </div>
         {state.is_user_me ? (
            <div className="flex justify-end w-full pt-2">
               <button
                  onClick={handleClickEdit}
                  className="h-10 px-4 font-Pulp font-normal text-lg tracking-wide text-mixinlab-teal rounded border-2 border-mixinlab-teal hover:bg-mixinlab-teal hover:text-white cursor-pointer focus:outline-none"
               >
                  Edit
               </button>
            </div>
         ) : null}
      </div>
   );

   let bio;

   bio = bio_display;

   if (state.is_user_me && state.edit === true) {
      bio = bio_edit;
      return bio;
   }

   return (
      <Fragment>
         <div className="pb-60">{bio}</div>
      </Fragment>
   );
};

BioMe.propTypes = {
   updateMe: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired,
   me: PropTypes.object.isRequired,
   user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth,
   me: state.me,
   user: state.user
});

export default connect(mapStateToProps, { updateMe })(BioMe);
