// React and PropTypes.
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

// Import icons.
import closeIcon from '../assets/close.svg';
import logoutIcon from '../assets/logout.svg';
import settingsIcon from '../assets/settings.svg';

const NavModal = ({ onClick1, onClick2, showModal }) => {
   let modal;
   let modalMask;

   if (showModal) {
      modal = (
         <div className="absolute md:h-screen md:w-screen p-2 md:p-10 bottom-0 left-0 h-auto w-52 z-100 bg-white rounded-lg shadow-lg">
            {/* Close Modal. */}
            <div className="flex justify-end md:mt-6">
               <div
                  className="md:h-12 md:w-12 h-10 w-10 hover:bg-gray-100 rounded-full"
                  onClick={onClick1}
               >
                  <div
                     className="mx-auto mt-2 bg-cover bg-no-repeat bg-center h-6 w-6 md:h-8 md:w-8 cursor-pointer"
                     style={{
                        backgroundImage: `url(${closeIcon})`
                     }}
                     alt={'close'}
                  ></div>
               </div>
            </div>

            {/* Settings. */}
            <Link to="/settings">
               <div
                  onClick={onClick1}
                  className="flex flex-row w-full pb-1 pl-2 h-11 md:mb-4 items-center rounded hover:bg-gray-200"
               >
                  <div
                     className="bg-cover bg-no-repeat bg-center h-7 w-7 md:h-10 md:w-10 cursor-pointer"
                     style={{
                        backgroundImage: `url(${settingsIcon})`
                     }}
                     alt={'cog'}
                  ></div>
                  <div className="h-7 pl-3 pt-0.5 md:pt-0 font-Pulp font-normal text-xl md:text-2xl tracking-wide">
                     <span className="align-middle cursor-pointer">Settings</span>
                  </div>
               </div>
            </Link>

            {/* Logout. */}
            <div
               className="flex flex-row w-full pb-1 pl-2 h-11 items-center rounded hover:bg-gray-200"
               onClick={onClick2}
            >
               <div
                  className="bg-cover bg-no-repeat bg-center h-7 w-7 md:h-9 md:w-9 cursor-pointer"
                  style={{
                     backgroundImage: `url(${logoutIcon})`
                  }}
                  alt={'logout'}
               ></div>
               <div className="h-7 pl-3 pt-0.5 md:pt-0 font-Pulp font-normal text-xl md:text-2xl tracking-wide">
                  <span className="align-middle cursor-pointer">Logout</span>
               </div>
            </div>
         </div>
      );

      modalMask = (
         <div
            className="fixed top-0 left-0 w-screen h-screen z-90 bg-black opacity-10"
            onClick={onClick1}
         ></div>
      );
   }

   return (
      <Fragment>
         {modal}
         {modalMask}
      </Fragment>
   );
};

export default NavModal;
