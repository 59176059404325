// React.
import React, { Fragment } from 'react';

const MusicListDjResults = ({ results, selectDj, dj_dropdown, closeResultsDropdown }) => {
   const handleClick = (e) => {
      const name = e.target.dataset.username;
      selectDj({ name });
   };

   let ListSelect;
   let modalMask;

   if (results.length > 0 && dj_dropdown) {
      ListSelect = (
         <div className="absolute rounded-md text-sm lg:text-xl z-50 px-2 py-1 w-60 lg:w-9/12 lg-1.5 mt-2 ml-2 shadow-modalLight h-auto bg-white cursor-pointer">
            <div className="rounded-sm font-semibold">
               {results?.map((item, index) => {
                  return (
                     <div
                        key={index}
                        onClick={handleClick}
                        className="hover:bg-gray-100 rounded md:text-xl px-2 md:px-3 pt-1 pb-1.5 my-1"
                        data-username={item.user_name}
                     >
                        <span data-username={item.user_name}>
                           {item.display_name}
                        </span>{' '}
                        <span
                           data-username={item.user_name}
                           className="text-mixinlab-cerise text-xs font-normal"
                        >
                           {item.user_name}
                        </span>
                     </div>
                  );
               })}
            </div>
         </div>
      );
   }

   if (dj_dropdown) {
      modalMask = (
         <div
            className="fixed top-0 left-0 w-screen h-screen z-40"
            onClick={closeResultsDropdown}
         ></div>
      );
   }

   return (
      <Fragment>
         {ListSelect}
         {modalMask}
      </Fragment>
   );
};

export default MusicListDjResults;
