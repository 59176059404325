// React.
import React, { Fragment } from 'react';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import helpers.
import { youtubeThumb, twitchThumb, otherThumb } from '../helpers/platformHandlers';

const RichContentThumb = ({ content }) => {
   // Renamed for simplicity.
   const shared_content = content.update_link_details;

   // Instantiate component.
   let thumb;

   switch (shared_content?.platform) {
      case 'YOUTUBE':
         thumb = youtubeThumb(shared_content);
         break;
      case 'TWITCH':
         thumb = twitchThumb(shared_content);
         break;
      default:
         otherThumb(shared_content);
   }

   return <Fragment>{thumb}</Fragment>;
};

RichContentThumb.propTypes = {
   content: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({ content: state.content });

export default connect(mapStateToProps)(RichContentThumb);
