// React.
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Redux.
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Axios.
import axios from 'axios';

// Moment.
import moment from 'moment';

// Import actions.
import { discoverMusicSearch, discoverMusicReset } from '../../../actions/mix';
import { setDiscover, toggleDiscoverModal } from '../../../actions/discover';

//Import components.
import MusicListDjResults from './MusicListDjResults';
import MusicListMixTitleResults from './MusicListMixTitleResults';
import MusicListTrackResults from './MusicListTrackResults';
import MusicListCreatorResults from './MusicListCreatorResults';
import MusicListLabelResults from './MusicListLabelResults';
import MusicDateRange from './MusicDateRange';

const MusicSearch = ({
   mix,
   discoverMusicSearch,
   discoverMusicReset,
   setDiscover,
   toggleDiscoverModal
}) => {
   // Input state records the input string to be used in API call.
   const [state, setState] = useState({
      dj: '',
      dj_dropdown: false,
      mix: '',
      mix_id: '',
      mix_dropdown: false,
      track: '',
      track_id: '',
      track_dropdown: false,
      creator: '',
      creator_id: '',
      creator_dropdown: false,
      label: '',
      label_id: '',
      label_dropdown: false,
      start_date: '',
      finish_date: ''
   });

   // Stores array of results from API call.
   const [results, setResults] = useState({
      dj: [],
      mix: [],
      track: [],
      creator: [],
      label: []
   });

   // Use history.
   let history = useHistory();

   const changeDateRange = (e) => {
      const range = e.target.value;
      let now = moment().format();
      let date = moment().format();

      if (range === '1') {
         date = moment().subtract(7, 'days');
         date = date.format();
         setState({
            ...state,
            finish_date: now,
            start_date: date
         });
      }

      if (range === '2') {
         date = moment().subtract(30, 'days');
         date = date.format();
         setState({
            ...state,
            finish_date: now,
            start_date: date
         });
      }

      if (range === '3') {
         date = moment().subtract(10000, 'days');
         date = date.format();
         setState({
            ...state,
            finish_date: now,
            start_date: date
         });
      }
   };

   // useEffect(() => {
   //    if (
   //       !auth.loading &&
   //       auth.isAuthenticated &&
   //       mix.discover_mix_search_loaded &&
   //       Array.isArray(mix.mixes)
   //    ) {
   //       setState({
   //          ...state,
   //          mix_count: mix.mixes.length
   //       });
   //    }
   // }, [auth, mix]);

   const closeResultsDropdown = () => {
      setState({
         ...state,
         dj_dropdown: false,
         mix_dropdown: false,
         track_dropdown: false,
         creator_dropdown: false,
         label_dropdown: false
      });
   };

   const clear = () => {
      setState({
         dj: '',
         dj_dropdown: false,
         mix: '',
         mix_id: '',
         mix_dropdown: false,
         track: '',
         track_id: '',
         track_dropdown: false,
         creator: '',
         creator_id: '',
         creator_dropdown: false,
         label: '',
         label_id: '',
         label_dropdown: false,
         mix_count: 0
      });
      discoverMusicReset();
   };

   const handleChange = (e) => {
      const { name, value } = e.target;

      if (name === 'dj') {
         setState({
            ...state,
            dj_dropdown: true
         });
      }

      if (name === 'mix') {
         setState({
            ...state,
            mix_dropdown: true
         });
      }

      if (name === 'track') {
         setState({
            ...state,
            track_dropdown: true
         });
      }

      if (name === 'creator') {
         setState({
            ...state,
            creator_dropdown: true
         });
      }

      if (name === 'label') {
         setState({
            ...state,
            label_dropdown: true
         });
      }

      setState((prevState) => {
         return {
            ...prevState,
            [name]: value
         };
      });
   };

   // API calls =====================================
   const getResultsDj = async () => {
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json'
            }
         };
         const res = await axios.post(
            '/api/user/search-users?limit=5&page=1&fields=user_name,display_name',
            { search: state.dj },
            config
         );
         setResults({ ...results, dj: res.data });
      } catch (err) {}
   };

   const getResultsMix = async () => {
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json'
            }
         };
         const res = await axios.post(
            '/api/mix/list-mix-title?fields=title&limit=5',
            { title: state.mix },
            config
         );
         setResults({ ...results, mix: res.data });
      } catch (err) {}
   };

   const getResultsTrack = async () => {
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json'
            }
         };
         const res = await axios.post(
            '/api/mix/search-track',
            { track_title: state.track },
            config
         );
         setResults({ ...results, track: res.data.tracks });
      } catch (err) {}
   };

   const getResultsCreator = async () => {
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json'
            }
         };
         const res = await axios.post(
            '/api/mix/search-creator?limit=5',
            { creator: state.creator },
            config
         );
         setResults({ ...results, creator: res.data });
      } catch (err) {}
   };

   const getResultsLabel = async () => {
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json'
            }
         };
         const res = await axios.post(
            '/api/mix/list-label',
            { name: state.label },
            config
         );
         setResults({ ...results, label: res.data });
      } catch (error) {}
   };

   // Select ====================================
   const selectDj = (obj) => {
      console.log(obj);
      setState({
         ...state,
         dj_dropdown: false,
         dj: obj.name
      });
   };

   const selectMix = (obj) => {
      setState({
         ...state,
         mix_dropdown: false,
         mix: obj.title
      });
   };

   const selectTrack = (obj) => {
      setState({
         ...state,
         track_dropdown: false,
         track: obj.concatTitle
      });
   };

   const selectCreator = (obj) => {
      setState({
         ...state,
         creator_dropdown: false,
         creator: obj.name
      });
   };

   const selectLabel = (obj) => {
      setState({
         ...state,
         label_dropdown: false,
         label: obj.name
      });
   };

   const handleSubmit = () => {
      const query = {
         user_name: state.dj,
         mix_title: state.mix,
         track_title: state.track,
         creator_name: state.creator,
         label_name: state.label,
         start_date: state.start_date,
         finish_date: state.finish_date
      };
      // Sets search params in discover reducer so that they can be displayed in the HomeFeedSearch header.
      setDiscover(query);

      // Queries DB for mixes matching params and sets Mix reducer.
      discoverMusicSearch(query);

      // Used by modal mask for each results component to close the dropdown menus.
      closeResultsDropdown();

      // Close main modal reducer.
      toggleDiscoverModal();

      // Push to homepage
      history.push(`/home`);
   };

   return (
      <div>
         <div>
            <div className="pl-1 lg:pl-2 pt-1 pb-4 text-base lg:text-2xl font-Pulp">
               Search for mixes containing
            </div>

            <form>
               {/* DJ */}
               <div className="mb-3 lg:mb-5">
                  <label htmlFor="dj"></label>
                  <div className="flex w-full items-center border border-gray-300 rounded-md p-1 lg:p-1.5">
                     <input
                        className="text-sm lg:pb-1 font-normal text-sm lg:text-xl lg:px-2 w-full text-black focus:outline-none pl-1 md:pl-2"
                        type="text"
                        name="dj"
                        placeholder="DJ"
                        autoComplete="off"
                        onChange={handleChange}
                        onKeyUp={getResultsDj}
                        value={state.dj}
                     />
                  </div>
                  <MusicListDjResults
                     closeResultsDropdown={closeResultsDropdown}
                     results={results.dj}
                     selectDj={selectDj}
                     dj_dropdown={state.dj_dropdown}
                  />
               </div>

               {/* Mix title */}
               <div className="mb-3 lg:mb-5">
                  <label htmlFor="mix"></label>
                  <div className="flex w-full items-center border border-gray-300 rounded-md p-1 lg:p-1.5">
                     <input
                        className="text-sm lg:pb-1 font-normal text-sm lg:text-xl lg:px-2 w-full text-black focus:outline-none pl-1 md:pl-2"
                        type="text"
                        name="mix"
                        placeholder="Mix Title"
                        autoComplete="off"
                        onChange={handleChange}
                        onKeyUp={getResultsMix}
                        value={state.mix}
                     />
                  </div>
                  <MusicListMixTitleResults
                     closeResultsDropdown={closeResultsDropdown}
                     results={results.mix}
                     selectMix={selectMix}
                     mix_dropdown={state.mix_dropdown}
                  />
               </div>

               {/* Track */}
               <div className="mb-3 lg:mb-5">
                  <label htmlFor="track"></label>
                  <div className="flex w-full items-center border border-gray-300 rounded-md p-1 lg:p-1.5">
                     <input
                        className="text-sm lg:pb-1 font-normal text-sm lg:text-xl lg:px-2 w-full text-black focus:outline-none pl-1 md:pl-2"
                        type="text"
                        name="track"
                        placeholder="Track"
                        autoComplete="off"
                        onChange={handleChange}
                        onKeyUp={getResultsTrack}
                        value={state.track}
                     />
                  </div>
                  <MusicListTrackResults
                     closeResultsDropdown={closeResultsDropdown}
                     results={results.track}
                     selectTrack={selectTrack}
                     track_dropdown={state.track_dropdown}
                  />
               </div>

               {/* Creator */}
               <div className="mb-3 lg:mb-5">
                  <label htmlFor="creator"></label>
                  <div className="flex w-full items-center border border-gray-300 rounded-md p-1 lg:p-1.5">
                     <input
                        className="text-sm lg:pb-1 font-normal text-sm lg:text-xl lg:px-2 w-full text-black focus:outline-none pl-1 md:pl-2"
                        type="text"
                        name="creator"
                        placeholder="Creator"
                        autoComplete="off"
                        onChange={handleChange}
                        onKeyUp={getResultsCreator}
                        value={state.creator}
                     />
                  </div>
                  <MusicListCreatorResults
                     closeResultsDropdown={closeResultsDropdown}
                     results={results.creator}
                     selectCreator={selectCreator}
                     creator_dropdown={state.creator_dropdown}
                  />
               </div>

               {/* Label */}
               <div className="mb-3 lg:mb-8">
                  <label htmlFor="label"></label>
                  <div className="flex w-full items-center border border-gray-300 rounded-md p-1 lg:p-1.5">
                     <input
                        className="text-sm lg:pb-1 font-normal text-sm lg:text-xl lg:px-2 w-full text-black focus:outline-none pl-1 md:pl-2"
                        type="text"
                        name="label"
                        placeholder="Label"
                        autoComplete="off"
                        onChange={handleChange}
                        onKeyUp={getResultsLabel}
                        value={state.label}
                     />
                  </div>
                  <MusicListLabelResults
                     closeResultsDropdown={closeResultsDropdown}
                     results={results.label}
                     selectLabel={selectLabel}
                     label_dropdown={state.label_dropdown}
                  />
               </div>

               {/* Date Range */}

               <div className="flex w-full items-center py-1 px-3 lg:px-4">
                  <MusicDateRange changeDateRange={changeDateRange} />
               </div>

               <div className="flex justify-between text-xs lg:text-base font-semibold px-3 mt-2 lg:mt-4 mb-3 lg:mb-10">
                  <h6>7 days</h6>
                  <h6 className="ml-1.5">Month</h6>
                  <h6>All time</h6>
               </div>

               <div className="w-full flex justify-between">
                  <div className="font-Pulp w-32 text-mixinlab-cerise font-semibold text-base lg:hidden pt-1.5 ml-1 cursor-default">
                     {mix.user_mixes_count}
                     <span className="ml-1.5 font-Pulp font-light text-black">
                        results
                     </span>
                  </div>

                  <div className="flex lg:w-full justify-center">
                     <div
                        onClick={clear}
                        className="mt-1 mb-1 px-2 lg:px-4 grid content-center py-0.5 lg:py-1.5 text-center font-Pulp text-base lg:text-xl text-mixinlab-cerise border-2 bg-white hover:bg-mixinlab-cerise hover:text-white border-mixinlab-cerise focus:outline-none rounded cursor-pointer"
                     >
                        Clear
                     </div>
                     <div
                        onClick={handleSubmit}
                        className="mt-1 mb-1 ml-3 lg:ml-14 px-2 lg:px-4 grid content-center py-0.5 lg:py-1.5 text-center font-Pulp text-base lg:text-xl text-white border-2 bg-mixinlab-cerise hover:bg-white hover:text-mixinlab-cerise border-mixinlab-cerise focus:outline-none rounded cursor-pointer"
                     >
                        Go
                     </div>
                  </div>
               </div>
            </form>
         </div>
      </div>
   );
};

MusicSearch.propTypes = {
   auth: PropTypes.object.isRequired,
   mix: PropTypes.object.isRequired,
   discoverMusicReset: PropTypes.func.isRequired,
   discoverMusicSearch: PropTypes.func.isRequired,
   setDiscover: PropTypes.func.isRequired,
   toggleDiscoverModal: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
   mix: state.mix,
   auth: state.auth
});

export default connect(mapStateToProps, {
   discoverMusicSearch,
   discoverMusicReset,
   setDiscover,
   toggleDiscoverModal
})(MusicSearch);
