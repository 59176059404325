// Import React.
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import { clearSingleContent } from '../actions/contentSingle';

// Import images.
import contentArrow from '../assets/content_arrow.gif';
import contentArrowteal from '../assets/content_arrow_teal.gif';

const Content = ({ contentId, is_single_content, clearSingleContent }) => {
   const [isHovering, setIsHovered] = useState(false);
   const onMouseEnter = () => setIsHovered(true);
   const onMouseLeave = () => setIsHovered(false);

   const content_component = (
      <div
         className="flex flex-row items-center group"
         onMouseEnter={onMouseEnter}
         onMouseLeave={onMouseLeave}
      >
         {isHovering ? (
            <Link
               onClick={() => {
                  clearSingleContent();
               }}
               to={`/content/${contentId}`}
            >
               <div
                  className="flex flex-row h-9 w-9 cursor-pointer bg-center bg-70 bg-no-repeat rounded-full group-hover:bg-mixinlab-teal_15"
                  style={{
                     backgroundImage: `url(${contentArrowteal})`
                  }}
               ></div>
            </Link>
         ) : (
            <Link
               onClick={() => {
                  clearSingleContent();
               }}
               to={`/content/${contentId}`}
            >
               <div
                  className="flex flex-row h-9 w-9 cursor-pointer bg-center bg-70 bg-no-repeat rounded-full group-hover:bg-mixinlab-teal_15"
                  style={{
                     backgroundImage: `url(${contentArrow})`
                  }}
               ></div>
            </Link>
         )}
      </div>
   );

   let showContentComponent;

   if (is_single_content) {
      showContentComponent = <div></div>;
   } else {
      showContentComponent = content_component;
   }

   return <Fragment>{showContentComponent}</Fragment>;
};

Content.propTypes = {
   clearSingleContent: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
   clearSingleContent
})(Content);
