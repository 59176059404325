// React.
import React, { Fragment } from 'react';

// Import helpers.
import { youtubeThumb, twitchThumb, otherThumb } from '../helpers/platformHandlers';

const RichContentThumbFeed = ({ shared_content }) => {

   // Instantiate component.
   let thumb;

   switch (shared_content?.platform) {
      case 'YOUTUBE':
         thumb = youtubeThumb(shared_content);
         break;
      case 'TWITCH':
         thumb = twitchThumb(shared_content);
         break;
      default:
         otherThumb(shared_content);
   }

   return <Fragment>{thumb}</Fragment>;
};

export default RichContentThumbFeed;
