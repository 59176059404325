// React.
import React from 'react';

// Import icons.
import closeIcon from '../assets/close.svg';

const CloseButtonSmall = ({close}) => {
   return (
      <div className="grid content-center h-7 w-7 hover:bg-gray-100 rounded-full" onClick={close}>
         <div
            className="mx-auto bg-cover bg-no-repeat bg-center h-5 w-5 cursor-pointer"
            style={{
               backgroundImage: `url(${closeIcon})`
            }}
            alt={'close'}
         ></div>
      </div>
   );
};

export default CloseButtonSmall;