// Import React.
import React, { Fragment } from 'react';

// Import icons.
import deleteIcon from '../assets/delete_white.svg';

// Import styles.
import styles from '../css/styles.module.css';

const UploadTrackListingHotTrack = ({
   index,
   cover,
   title,
   creators,
   label,
   handleDelete,
   handleRemove,
   moveUp,
   moveDown,
   tracklist
}) => {
   const coverComponent = cover ? (
      <div
         className="flex-none rounded-sm w-14 h-14 bg-no-repeat bg-center bg-cover"
         style={{
            backgroundImage: `url(${cover})`
         }}
      ></div>
   ) : (
      <div className="flex-none border-gray-200 border-3 rounded-md w-14 h-14">
         <div className="grid content-center h-full">
            <div className="w-8 h-8 mx-auto border-3 border-gray-200 rounded-full"></div>
         </div>
      </div>
   );

   let is_last_track = false;

   let track_listing_style = 'border-b border-gray-300 pl-2';

   if (is_last_track) {
      track_listing_style = 'border-b border-gray-300 pl-2';
   }

   const trackIndex = (
      <div className="relative grid content-center justify-center h-full w-5 mr-4 ml-1 text-center text-sm">
         {index === 0 ? null : (
            <div
               onClick={() => moveUp(index)}
               className="absolute hidden group-hover:block pt-0.5 -top-2 w-5 h-5 border-2 border-white z-20 rounded-full bg-gray-400 hover:bg-gray-800 cursor-pointer shadow-modal"
            >
               <div className="flex justify-center">
                  <div className={styles.upArrowTracks}></div>
               </div>
            </div>
         )}

         {index + 1}

         {tracklist.length - 1 === index ? null : (
            <div
               onClick={() => moveDown(index)}
               className="absolute flex justify-center hidden group-hover:block pt-1 top-11 w-5 h-5 border-2 border-white z-20 rounded-full bg-gray-400 hover:bg-gray-800 cursor-pointer shadow-modal"
            >
               <div className="flex justify-center">
                  <div className={styles.downArrowTracks}></div>
               </div>
            </div>
         )}
      </div>
   );

   return (
      <Fragment>
         <div className={track_listing_style}>
            <div className="flex justify-between group w-full">
               <div className="flex mr-2 py-1 w-full">
                  {trackIndex}

                  <div className="grid content-center ml-1 bg-mixinlab-cherry w-17 rounded-l-lg">
                     <div className="transform -rotate-90 text-center origin-center">
                        <div className="font-Pulp text-white text-xl h-4 mb-0.5">
                           Hot
                        </div>
                        <div className="font-Pulp text-sm text-white">track</div>
                     </div>
                  </div>

                  <div
                     className={`${styles.hotTrack} flex xs:block justify-between w-full bg-gray-400 rounded-r-lg`}
                  >
                     <div className="ml-3 w-full flex sm:my-2">
                        <div className="grid content-center">{coverComponent}</div>
                        <div className="ml-4 w-full mr-3 pr-3 leading-tight py-1">
                           <div className="text-base text-white font-semibold tracking-wide font-Pulp">
                              {title}
                           </div>
                           <div className="text-xs font-bold">
                              Creators:{' '}
                              <span className="font-normal cursor-default">
                                 {creators}
                              </span>
                           </div>
                           <div className="text-xs font-bold">
                              Label:{' '}
                              <span className="font-normal cursor-default">
                                 {label}
                              </span>
                           </div>
                        </div>
                     </div>

                     <div className="flex mr-1 ml-1 justify-end xs:my-2">
                        <div className="group grid content-center">
                           <button
                              onClick={() => handleRemove(index)}
                              className="px-2 py-0.5 flex-none w-10 mt-0.5 font-Pulp font-normal text-sm hover:text-gray-500 hover:bg-white cursor-pointer bg-gray-400 border-2 border-white text-white focus:outline-none rounded"
                           >
                              Hot
                           </button>
                        </div>
                        <div className="grid content-center">
                           <div className="grid content-center justify-center w-10 h-10 rounded-full hover:bg-gray-500 cursor-pointer flex-none mr-1 ml-2">
                              <div
                                 onClick={() => handleDelete(index)}
                                 className="bg-cover bg-no-repeat bg-center h-8 w-8 cursor-pointer"
                                 style={{
                                    backgroundImage: `url(${deleteIcon})`
                                 }}
                                 alt={'delete'}
                              ></div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               {/* <div className='h-100 bg-green-200'></div> */}
            </div>
         </div>
      </Fragment>
   );
};

export default UploadTrackListingHotTrack;
