// Import React.
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Actions
import { getHomeContent, getNewHomeContent } from '../actions/content';

// Components.
import PageHeader from './PageHeader.js';
import PostsWrapper from './PostsWrapper';
import Update from './Update';
import CommentFeed from './CommentFeed';
import NewPosts from './NewPosts';
import SuggestedPeople from './SuggestedPeople';

const HomeFeed = ({ auth, content, me, getHomeContent, getNewHomeContent }) => {
   // const [scrollPosition, setScrollPosition] = useState(0);
   const [state, setState] = useState({
      page_header_text: 'Home',
      show_update: true,
      show_new_posts: true,
      show_post_wrapper: true,
      show_post_wrapper_mixes: false,
      show_back_button: false,
      show_posts_finished: false
   });

   // Hide suggested users component when user clicks 'hide'.
   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated && !me.is_display_suggested_user ) {
         setState({
            ...state,
            page_header_text: 'Home',
            show_update: true,
            show_new_posts: true,
            show_post_wrapper: true,
            show_post_wrapper_mixes: false,
            show_back_button: false,
            show_posts_finished: false
         });
      }
   }, [auth, me.is_display_suggested_user]);


   // TODO does this need to be in PostWrapperHome?
   useEffect(() => {
      const newContentInterval = setInterval(
         () => getNewHomeContent(content.last_home_content_loaded_time),
         45000
      );
      return () => clearInterval(newContentInterval);
   }, [content.last_home_content_loaded_time, getNewHomeContent]);

   const backButton = () => {
      setState({
         ...state,
         page_header_text: 'Home',
         show_update: true,
         show_post_wrapper: true,
         show_post_wrapper_mixes: false,
         show_back_button: false
      });
   };

   const getHomeContentHandleClick = () => {
      getHomeContent();
   };

   // Instantiate components.
   const UpdateComponent = state.show_update ? <Update /> : null;

   const SuggestedUsersComponent = me.is_display_suggested_user ? (
      <SuggestedPeople />
   ) : null;

   const NewPostsComponent =
      content.number_of_new_home_content_post_available > 0 ? (
         <NewPosts getHomeContentHandleClick={getHomeContentHandleClick} />
      ) : null;

   const CommentFeedComponent = state.show_comments ? <CommentFeed /> : null;

   const PostWrapperComponent = state.show_post_wrapper ? (
      <PostsWrapper />
   ) : null;

   return (
      <Fragment>
         <div className="pb-60 w-full">
            <PageHeader
               page={state.page_header_text}
               show_back_button={state.show_back_button}
               backButton={backButton}
            />
            {UpdateComponent}
            {SuggestedUsersComponent}
            {NewPostsComponent}
            {CommentFeedComponent}
            {PostWrapperComponent}
         </div>
      </Fragment>
   );
};

HomeFeed.propTypes = {
   auth: PropTypes.object.isRequired,
   content: PropTypes.object.isRequired,
   mix: PropTypes.object.isRequired,
   getHomeContent: PropTypes.func.isRequired,
   getNewHomeContent: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.content,
   content: state.content,
   mix: state.mix,
   me: state.me
});
export default connect(mapStateToProps, { getHomeContent, getNewHomeContent })(
   HomeFeed
);