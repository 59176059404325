// react.
import React, { Fragment } from 'react';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import components
import NotificationsFeed from '../components/NotificationsFeed';
import PageHeaderNotifications from '../components/PageHeaderNotifications';
import GaPageView from "../components/GaPageView";

// Import actions
import { markAllAsNotified } from '../actions/notification';

import { getMe } from '../actions/me';


const Notifications = ({ markAllAsNotified }) => {
   // Get new notifications when 'Load more notifications' is clicked.
   const handleMarkAllAsNotified = () => {
      markAllAsNotified();
   };

   return (
      <Fragment>
         <div className="w-600 sm:w-full h-screen">
             <GaPageView/>
            <PageHeaderNotifications
               page={'Notifications'}
               handleMarkAllAsNotifiedClick={handleMarkAllAsNotified}
            />
            <NotificationsFeed />
         </div>

         <div className="w-72 mt-64 pt-5 lg:hidden ml-14 xl:ml-6">
            <div className="w-32 pl-3"></div>
         </div>
      </Fragment>
   );
};

Notifications.propTypes = {
   auth: PropTypes.object.isRequired,
   notification: PropTypes.object.isRequired,
   markAllAsNotified: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
   notification: state.notification,
   auth: state.auth
});

export default connect(mapStateToProps, {
   markAllAsNotified
})(Notifications);
