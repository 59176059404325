//Import React.
import React, { useState } from 'react';

// NPM
import TextareaAutosize from 'react-textarea-autosize';

// Import actions.
import { comment } from '../actions/content';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import components.
import CloseButton from './CloseButton';

// Import assets.
import profilePlaceholderIconPerson from '../assets/placeholder_person.svg';
import dataStorePath from '../configs/dataStorePath';

const PostComment = ({
   postId,
   me,
   comment,
   showCommentModal,
   closeCommentModal,
   user_image,
   display_name,
   created_at,
   text
}) => {
   const [state, setState] = useState({
      comment: '',
      comment_count: 0,
      file_err_msg: null
   });

   const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === 'comment') {
         setState({
            ...state,
            comment_count: value.length
         });
      }

      setState((prevState) => {
         return {
            ...prevState,
            [name]: value
         };
      });
   };

   const onSubmit = (e) => {
      e.preventDefault();
      if (state.comment.length > 1) {
         comment(state.comment, postId);
         setState({
            ...state,
            comment: ''
         });
      }
   };

   const originalComment = (
      <div className="flex">
         {/* Profile image */}
         <div className="w-20 h-16">
            <div
               className="h-14 w-14 mr-6 rounded-full bg-cover bg-no-repeat bg-center bg-gray-100"
               style={{
                  backgroundImage: `url(${
                     dataStorePath.user_profile_images + user_image
                  })`
               }}
               alt={'Profile'}
            ></div>
         </div>

         <div className="w-full">
            <div className="flex">
               <div className="flex flex-wrap w-fit">
                  {/* Display name */}
                  <h4 className="font-Pulp font-normal text-xl leading-none tracking-wide pt-1.5 pr-2">
                     {display_name}

                     {/* Created at */}
                     <span className="font-Pulp font-extralight text-xl tracking-wide text-gray-500 pt-1 pl-2">
                        {created_at}
                     </span>
                  </h4>
               </div>
            </div>

            {/* Text */}
            <p className="font-normal w-fit font-base leading-snug pb-1 pr-4 sm:mt-1">
               <span className="break-words">{text}</span>
            </p>
         </div>
         <div className="w-10">
            <CloseButton close={closeCommentModal} />
         </div>
      </div>
   );

   // Instantiate components.
   let postCommentModal;
   let postCommentModalMask;

   if (showCommentModal) {
      postCommentModal = (
         <div>
            <form onSubmit={onSubmit}>
               <div className="fixed top-2 left-1/2 transform -translate-x-1/2 translate-y-6 md:translate-y-0 shadow-modal md:top-0 w-600 h-650 md:w-screen md:h-screen md:p-6 md:pl-8 md:pr-8 z-50 p-5 rounded-lg bg-white">
                  <div>{originalComment}</div>
                  <div className="mt-2 mb-4 h-2 border-b-2 border-dotted border-gray-300"></div>
                  <div className="flex flex-row">
                     {me.user_image ? (
                        <div className="w-20 h-14">
                           <div
                              className="h-12 w-12 mr-6 rounded-full bg-cover bg-no-repeat bg-center bg-gray-100"
                              style={{
                                 backgroundImage: `url(${
                                    dataStorePath.user_profile_images + me.user_image
                                 })`
                              }}
                              alt={'Profile'}
                           ></div>
                        </div>
                     ) : (
                        <div className="w-20 h-14">
                           <div
                              className="h-12 w-12 mr-6 mt-1 rounded-full bg-50 bg-no-repeat bg-center bg-gray-100"
                              style={{
                                 backgroundImage: `url(${profilePlaceholderIconPerson})`
                              }}
                              alt={'Profile'}
                           ></div>
                        </div>
                     )}

                     {/* Comment */}
                     <div className="w-full pr-4">
                        <div className="flex items-center mt-1 pb-2 pt-1 px-4 border border-gray-300 rounded-lg">
                           <TextareaAutosize
                              type="text"
                              name="comment"
                              className="text-xl font-normal resize-none w-full overflow-hidden text-black focus:outline-none"
                              placeholder="Comment on this post..."
                              onChange={handleChange}
                              defaultValue={state.update}
                           />
                        </div>

                        <div className="flex flex-row mt-4">
                           <div className="w-full flex justify-end">
                              <h6 className="text-sm text-gray-500 pt-2 pr-4">
                                 <span
                                    className={
                                       state.update_count > 240
                                          ? 'text-mixinlab-cherry font-bold'
                                          : 'text-mixinlab-teal'
                                    }
                                 >
                                    {state.comment_count}
                                 </span>
                                 <span className="text-black"> / 240 chars.</span>
                              </h6>

                              {state.comment_count > 240 ? (
                                 <div className="ease-in-out duration-75 h-10 px-3 pt-1 font-Pulp font-normal text-lg text-white border-2 opacity-50 bg-mixinlab-cherry border-mixinlab-cherry focus:outline-none rounded">
                                    Comment
                                 </div>
                              ) : (
                                 <button
                                    type="submit"
                                    className="ease-in-out duration-75 h-10 px-3 font-Pulp font-normal text-lg text-white bg-mixinlab-teal cursor-pointer border-2 border-mixinlab-teal hover:bg-white hover:text-mixinlab-teal focus:outline-none rounded"
                                 >
                                    Comment
                                 </button>
                              )}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </form>
         </div>
      );

      postCommentModalMask = (
         <div
            onClick={closeCommentModal}
            className="fixed top-0 left-0 w-screen h-screen z-20 bg-black opacity-30 cursor-pointer"
         ></div>
      );
   }

   return (
      <div>
         {postCommentModal}
         {postCommentModalMask}
      </div>
   );
};

PostComment.propTypes = {
   comment: PropTypes.func.isRequired,
   me: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   me: state.me
});

export default connect(mapStateToProps, { comment })(PostComment);
