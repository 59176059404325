// React.
import React, { Fragment, useEffect, useState } from 'react';


// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import placeholder images to show functionality.
import SettingsResetPassword from './SettingsResetPassword';
import SettingsDeactivate from './SettingsDeactivate';
import SettingsContact from './SettingsContact';

const SettingsWrapper = ({ me, auth }) => {
   const [state, setState] = useState({
      show_password: true,
      show_deactivate: false,
      show_contact: false
   });
   const [deactivateText, setDeactivateText] = useState({
      text: null,
      deactivatedAt: !!me.deactivatedAt ? true : false
   });

   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated && !me.loading) {
         me.isDeactivated === false
            ? setDeactivateText({ text: 'Deactivate account', isDeactivated: true })
            : setDeactivateText({ text: 'Reactivate account', isDeactivated: false });
      }
   }, [auth, me.loaded, me.isDeactivated, me.loading]);

   const showPassword = () => {
      setState({
         show_password: true,
         show_deactivate: false,
         show_contact: false
      });
   };

   const showDeactivate = () => {
      setState({
         show_password: false,
         show_deactivate: true,
         show_contact: false
      });
   };

   const showContact = () => {
      setState({
         show_password: false,
         show_deactivate: false,
         show_contact: true
      });
   };

   const password = (
      <div className="h-full w-full bg-contain bg-no-repeat bg-center-top cursor-pointer">
         <SettingsResetPassword />
      </div>
   );
   const deactivate = (
      <div className="h-full w-full bg-contain bg-no-repeat bg-center-top cursor-pointer">
         <SettingsDeactivate isDectivated={me.isDeactivated} />
      </div>
   );
   const contact = (
      <div className="h-full w-full bg-contain bg-no-repeat bg-center-top cursor-pointer">
         <SettingsContact />
      </div>
   );

   return (
      <Fragment>
         <div className="w-full flex border-gray-200 border-l-2 border-r-2 border-b-2 sm:border-l-0 sm:border-r-0">
            <div className="w-9/12 font-Pulp text-lg">
               <div
                  onClick={showPassword}
                  className="grid content-center pl-4 pr-4 w-full h-14 border-b-2 border-gray-200 hover:bg-mixinlab-teal hover:text-white cursor-pointer"
               >
                  <div className="flex justify-center">
                     <div>Change password</div>
                  </div>
               </div>
               <div
                  onClick={showDeactivate}
                  className="grid content-center pl-4 pr-4 w-full h-14 border-b-2 border-gray-200 hover:bg-mixinlab-teal hover:text-white cursor-pointer"
               >
                  <div className="flex justify-center">
                     <div>{deactivateText.text}</div>
                  </div>
               </div>
               <div
                  onClick={showContact}
                  className="grid content-center pl-4 pr-4 w-full h-14 border-b-2 border-gray-200 hover:bg-mixinlab-teal hover:text-white cursor-pointer"
               >
                  <div className="flex justify-center">
                     <div>Contact us</div>
                  </div>
               </div>
            </div>
            <div className="border-gray-200 border-r-2"></div>

            {/* Right hand panel. */}
            <div className="w-full p-4">
               {state.show_password ? password : null}
               {state.show_deactivate ? deactivate : null}
               {state.show_contact ? contact : null}
            </div>
         </div>
      </Fragment>
   );
};

SettingsWrapper.propTypes = {
   auth: PropTypes.object.isRequired,
   me: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth,
   me: state.me
});

export default connect(mapStateToProps, {})(SettingsWrapper);
