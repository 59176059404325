// React.
import React from 'react';

// Import icons.
import closeIcon from '../assets/close.svg';

const CloseButtonDarker = ({ close }) => {
   return (
      <div
         className="grid content-center h-10 w-10 border border-gray-300 hover:bg-gray-200 bg-white rounded-full  cursor-pointer"
         onClick={close}
      >
         <div
            className="mx-auto bg-cover bg-no-repeat bg-center h-6 w-6 cursor-pointer"
            style={{
               backgroundImage: `url(${closeIcon})`
            }}
            alt={'close'}
         ></div>
      </div>
   );
};

export default CloseButtonDarker;
