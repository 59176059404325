// Import React.
import React, { Fragment, useState } from 'react';

// Import images.
import share from '../assets/share.gif';
import shareTeal from '../assets/share_teal.gif';
import shareTealFocus from '../assets/share_teal_focus.gif';

const Share = ({ shareCount, handleShareContent }) => {
   const [isHovering, setIsHovered] = useState(false);
   const onMouseEnter = () => setIsHovered(true);
   const onMouseLeave = () => setIsHovered(false);

   const share_component = (
      <div
         className="flex flex-row items-center group"
         onMouseEnter={onMouseEnter}
         onMouseLeave={onMouseLeave}
      >
         {shareCount === 0 ? (
            isHovering ? (
               <div
                  onClick={handleShareContent}
                  className="flex flex-row h-9 w-9 cursor-pointer bg-center bg-70 bg-no-repeat rounded-full group-hover:bg-mixinlab-teal_15"
                  style={{
                     backgroundImage: `url(${shareTeal})`
                  }}
               ></div>
            ) : (
               <div
                  onClick={handleShareContent}
                  className="flex flex-row h-9 w-9 cursor-pointer bg-center bg-70 bg-no-repeat rounded-full group-hover:bg-mixinlab-teal_15"
                  style={{
                     backgroundImage: `url(${share})`
                  }}
               ></div>
            )
         ) : (
            <div
               onClick={handleShareContent}
               className="flex flex-row h-9 w-9 cursor-pointer bg-center bg-70 bg-no-repeat rounded-full group-hover:bg-mixinlab-teal_15"
               style={{
                  backgroundImage: `url(${shareTealFocus})`
               }}
            ></div>
         )}

         <h6
            className={`${
               shareCount === 0
                  ? 'text-xs text-black group-hover:text-mixinlab-teal font-medium ml-1'
                  : 'text-xs text-mixinlab-teal font-medium ml-1'
            }`}
         >
            {shareCount}
         </h6>
      </div>
   );

   return <Fragment>{share_component}</Fragment>;
};

export default Share;
