// React.
import React, { Fragment } from 'react';

// Moment.
import moment from 'moment';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import components
import CommentFeedUnit from './CommentFeedUnit';

const CommentFeed = ({ content }) => {
   /**
    * detect how selectedPost should be depending on what page we are in
    * i.e. home, user, content, comment
    */
   const firstPath = window.location.pathname.split('/')[1];
   let selectedPost = [];
   switch (firstPath) {
      case 'home':
      case 'user':
         selectedPost = content.posts.filter(
            (post) => post._id === content.post_id_for_comments
         );
         break;
      case 'comment':
         selectedPost = [content.single_content].filter(
            (post) => post._id === content.post_id_for_comments
         );
         break;
      case 'content':
         selectedPost = [content.single_content].filter(
            (post) => post._id === content.post_id_for_comments
         );
         break;
   }

   // Instantiate comments array.
   let commentsArr;
   let showHeader = false;

   // This fires if params contain '/comments'
   // Get comments for selected post when comment feed is invoked from PostWrapper - grabs from content.posts arr.

   // // this fires if params include '/content'
   // // Get comments for selected post when post is loaded from external link - grabs from content.single_content
   // const selectedPost = content.single_content.filter(
   //    (post) => post._id === content.post_id_for_comments
   // );

   // 1. Create Page and Wrapper for comments
   // 2. Import Posts into Comments page
   // 3. Use Params() to control which filter function runs when CommentFeed loads
   // 4. Push the response for single page comments into single_content reducer prop.
   // 5. Test

   // Map through comments and show.
   commentsArr = selectedPost.map((post) => {
      return post.comments
         .map((comment, index) => {
            return (
               <CommentFeedUnit
                  key={index}
                  user_image={comment.user.user_image}
                  display_name={comment.user.display_name}
                  user_name={comment.user.user_name}
                  created_at={moment(
                     moment
                        .utc(comment.date)
                        .utcOffset(new Date().getTimezoneOffset()),
                     'YYYY-MM-DD HH:mm:ss'
                  ).fromNow()}
                  text={comment.text}
               />
            );
         })
         .reverse();
   });

   // Check if post has comments to show/hide header.
   selectedPost.map((post) => {
      if (post.comments.length > 0) {
         showHeader = true;
      }
   });

   // Comments header.
   const commentsHeader = showHeader ? (
      <div>
         <div className="mt-5 border-b-2 border-gray-200"></div>
         <h2 className="font-Pulp text-xl mt-4 mb-1">Comments</h2>
      </div>
   ) : null;

   return (
      <Fragment>
         <div className="w-full relative">
            {commentsHeader}
            <div className="relative w-full h-300 md:h-500 overflow-y-auto overflow-x-hidden">
               <div className="absolute w-full">{commentsArr}</div>
            </div>
         </div>
      </Fragment>
   );
};

CommentFeed.propTypes = {
   auth: PropTypes.object.isRequired,
   content: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth,
   content: state.content
});

export default connect(mapStateToProps)(CommentFeed);
