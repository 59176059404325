// Import React.
import React, { Fragment } from 'react';

// Import components.
import MixUploadTitleImage from './MixUploadTitleImage';

const UploadTitle = ({
   handleAddTitle,
   handleImageInput,
   titleNext,
   title_next,
   mix_title,
   blob_img
}) => {
   const title = (
      <div className="flex border-gray-200 border-2 sm:border-r-0 sm:border-l-0">
         <div className="flex-none w-20 h-20 relative">
            <MixUploadTitleImage
               blob={blob_img}
               handleImageInput={handleImageInput}
            />
         </div>
         <form className="flex w-full justify-between p-5">
            <div className="grid content-center w-full">
               <label htmlFor="mix_title"></label>
               <input
                  className="text-xl px-2 font-Pulp font-normal w-full text-black focus:outline-none"
                  type="text"
                  name="mix_title"
                  placeholder="Mix title"
                  onChange={handleAddTitle}
                  value={mix_title}
                  autoComplete="off"
               />
            </div>
            {title_next ? null : (
               <div className="grid content-center ml-5">
                  {mix_title && blob_img ? (
                     <button
                        onClick={titleNext}
                        className="h-9 px-3 grid content-center font-Pulp font-normal text-lg text-white bg-mixinlab-indigo cursor-pointer border-2 border-mixinlab-indigo hover:bg-white hover:text-mixinlab-indigo focus:outline-none rounded"
                     >
                        Next
                     </button>
                  ) : (
                     <div className="h-9 px-3 grid content-center opacity-30 font-Pulp font-normal text-lg text-white bg-mixinlab-indigo cursor-pointer border-2 border-mixinlab-indigo focus:outline-none rounded">
                        Next
                     </div>
                  )}
               </div>
            )}
         </form>
      </div>
   );

   return <Fragment>{title}</Fragment>;
};

export default UploadTitle;
