//Import React.
import React, { useState } from 'react';

// NPM packages.
import TextareaAutosize from 'react-textarea-autosize';

// Import actions.
import { sendReport } from '../actions/admin';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import utils.
import addDataStorePath from '../utils/addDataStorePath';

// Import assets.
import profilePlaceholderIconPerson from '../assets/placeholder_person.svg';

const PostReport = ({
   postId,
   me,
   sendReport,
   show_post_report,
   closePostReport,
   user_image,
   display_name,
   created_at,
   text
}) => {
   const [state, setState] = useState({
      report: '',
      report_count: 0,
      report_touched: true
   });

   const handleChange = (e) => {
      e.stopPropagation();
      const { name, value } = e.target;
      if (name === 'report') {
         setState({
            ...state,
            report_count: value.length
         });
      }

      setState((prevState) => {
         return {
            ...prevState,
            [name]: value
         };
      });
   };

   const handleClosePostReport = (e) => {
      closePostReport(e);
      setState({
         ...state,
         report: '',
         report_count: 0,
         report_touched: true
      });
   };

   const onSubmit = (e) => {
      e.preventDefault();
      const reportObj = {
         reported_object: {
            reported_object_id: postId,
            type: 'content'
         },
         reason: 'Rude content',
         message: state.report
      };
      sendReport(reportObj);
      closePostReport(e);
   };

   const originalComment = (
      <div className="flex">
         {/* Profile image */}
         <div className="w-20 h-16">
            <div
               className="h-14 w-14 mr-6 rounded-full bg-cover bg-no-repeat bg-center bg-gray-100"
               style={{
                  backgroundImage: `url(${addDataStorePath(user_image)})`
               }}
               alt={'Profile'}
            ></div>
         </div>

         <div className="w-full">
            <div className="flex">
               <div className="flex flex-wrap w-fit">
                  {/* Display name */}
                  <h4 className="font-Pulp font-normal text-xl leading-none tracking-wide pt-1.5 pr-2">
                     {display_name}

                     {/* Created at */}
                     <span className="font-Pulp font-extralight text-xl tracking-wide text-gray-500 pt-1 pl-2">
                        {created_at}
                     </span>
                  </h4>
               </div>
            </div>

            {/* Text */}
            <p className="font-normal w-fit font-base leading-snug pb-1 pr-4 sm:mt-1">
               <span className="break-words">{text}</span>
            </p>
         </div>
      </div>
   );

   // Instantiate components.
   let PostReport;
   let PostReportMask;

   if (show_post_report) {
      PostReport = (
         <form onSubmit={onSubmit}>
            <div className="fixed top-2 left-1/2 transform -translate-x-1/2 translate-y-6 md:translate-y-0 shadow-modal md:top-0 w-600 md:h-auto md:w-screen md:p-6 md:pl-8 md:pr-8 z-50 p-4 rounded-lg bg-white cursor-default">
               <div className="mb-5 h-10 px-3 pt-1 font-Pulp font-normal text-xl text-white bg-mixinlab-cherry border-2 border-mixinlab-cherry opacity-50 rounded">
                  Report a post or user
               </div>

               <div>{originalComment}</div>
               <div className="mx-3 mt-1 mb-6 h-5 border-b-2 border-dotted border-gray-300"></div>
               <div className="flex flex-row">
                  {me.user_image ? (
                     <div className="w-20 h-16">
                        <div
                           className="h-14 w-14 mr-6 rounded-full bg-cover bg-no-repeat bg-center bg-gray-100"
                           style={{
                              backgroundImage: `url(${addDataStorePath(
                                 me.user_image
                              )})`
                           }}
                           alt={'Profile'}
                        ></div>
                     </div>
                  ) : (
                     <div className="w-20 h-16">
                        <div
                           className="h-14 w-14 mr-6 mt-1 rounded-full bg-50 bg-no-repeat bg-center bg-gray-100"
                           style={{
                              backgroundImage: `url(${profilePlaceholderIconPerson})`
                           }}
                           alt={'Profile'}
                        ></div>
                     </div>
                  )}

                  {/* Report */}
                  <div className="w-full">
                     <div className="flex items-center mt-3 pr-2 pb-6">
                        <TextareaAutosize
                           type="text"
                           name="report"
                           className="text-xl font-normal resize-none w-full overflow-hidden text-black focus:outline-none"
                           placeholder="What's wrong?"
                           onChange={handleChange}
                           defaultValue={state.update}
                        />
                     </div>

                     <div className="flex flex-row mt-2">
                        <div className="w-full flex justify-end">
                           <h6 className="text-sm text-gray-500 pt-2 pr-4">
                              <span
                                 className={
                                    state.report_count > 240
                                       ? 'text-mixinlab-cherry font-bold'
                                       : 'text-gray-400'
                                 }
                              >
                                 {state.report_count}
                              </span>
                              <span className="text-black"> / 240 chars.</span>
                           </h6>

                           {state.report_count > 240 ? (
                              <div className="ease-in-out duration-75 h-10 px-3 pt-1 font-Pulp font-normal text-lg text-white border-2 opacity-50 bg-mixinlab-cherry border-mixinlab-cherry focus:outline-none rounded">
                                 Report
                              </div>
                           ) : (
                              <button
                                 type="submit"
                                 className="ease-in-out duration-75 h-10 px-3 font-Pulp hover:text-gray-600 border-gray-600 bg-gray-600 font-normal text-lg text-white cursor-pointer border-2 hover:bg-white focus:outline-none rounded"
                              >
                                 Report
                              </button>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </form>
      );
   }

   if (show_post_report) {
      PostReportMask = (
         <div
            onClick={handleClosePostReport}
            className="fixed top-0 left-0 w-screen h-screen z-30 bg-black opacity-30 cursor-pointer"
         ></div>
      );
   }

   return (
      <div>
         {PostReport}
         {PostReportMask}
      </div>
   );
};

PostReport.propTypes = {
   sendReport: PropTypes.func.isRequired,
   me: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   me: state.me
});

export default connect(mapStateToProps, { sendReport })(PostReport);
