// React.
import React, { Fragment } from 'react';

const SettingsContact = () => {
   return (
      <Fragment>
         <div className="p-2">
            <div className="text-xl font-Pulp font-bold">Contact Us</div>
            <div className="mt-5 text-base font-normal">
               Feel free to get in touch with us at the address below. We are a small
               team, but will endeavour to come back to you as soon as we can.
            </div>
            <div className="mt-5 text-sm font-thin">
               <a
                  className="text-indigo-600 text-lg font-normal hover:underline"
                  href="mailto:support@mixinlab.com"
               >
                  admin@mixcamp.com
               </a>
            </div>
         </div>
      </Fragment>
   );
};

export default SettingsContact;
