// Import React.
import React, { Fragment, useState } from 'react';
import styles from '../css/styles.module.css';

const AudioPlayerButtonPlay = ({ handlePlay }) => {
   const [isHovering, setIsHovered] = useState(false);
   const onMouseEnter = () => setIsHovered(true);
   const onMouseLeave = () => setIsHovered(false);

   const AudioPlayerButtonPlay = (
      <div
         className="flex items-center group"
         onMouseEnter={onMouseEnter}
         onMouseLeave={onMouseLeave}
      >
         {isHovering ? (
            <div className="group">
               <div
                  onClick={handlePlay}
                  className="h-10 w-10 lg:h-14 lg:w-14 grid content-center justify-center cursor-pointer rounded-full border group-hover:border-mixinlab-indigo_mid group-hover:bg-mixinlab-indigo_mid"
               >
                  <div
                     className={`${styles.playArrowWhite} lg:mt-0.5 lg:ml-0.5`}
                  ></div>
               </div>
            </div>
         ) : (
            <div
               onClick={handlePlay}
               className="h-10 w-10 lg:h-14 lg:w-14 grid content-center justify-center cursor-pointer rounded-full border border-gray-800"
            >
               <div className={`${styles.playArrow}`}></div>
            </div>
         )}
      </div>
   );

   return <Fragment>{AudioPlayerButtonPlay}</Fragment>;
};

export default AudioPlayerButtonPlay;
