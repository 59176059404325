// To be deleted...

// React.
import React from 'react';
import { Link } from 'react-router-dom';

// Import axios.
import axios from 'axios';

// Import constants.
import constants from '../configs/constants';

// Import styles.
import styles from '../css/styles.module.css';

// Import images.
import OtherLink from '../assets/other_logo.svg';
import YoutubeLogo from '../assets/youtube_logo.svg';
import TwitchLogo from '../assets/twitch_logo.svg';
import TwitterLogo from '../assets/twitter_logo.svg';
import FacebookLogo from '../assets/facebook_logo.svg';
import {clearSingleContent} from "../actions/contentSingle";

export function isValidUrl(url) {
   try {
      const urlRegex = new RegExp(
          '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
          'i'
      ); // fragment locator
      return urlRegex.test(url);
   } catch (e) {
      return false;
   }
}

export function isTheWordAUserMention(word) {
   let userMentionPattern = /^@{1}[a-z_]{1,}$/;
   return userMentionPattern.test(word);
}

// This function checks for valid URLs in body copy.
export function extractLinksFromString(update) {
   if (!update) {
      return false;
   }
   const words = update.split(/\s+/);
   let urls = words.filter((word) => {
      if (isValidUrl(word)) {
         return word;
      }
   });
   return urls;
}

export function getIcon(platform) {
   let icon = OtherLink;
   switch (platform) {
      case 'YOUTUBE':
         icon = YoutubeLogo;
         break;
      case 'TWITCH':
         icon = TwitchLogo;
         break;
      case 'TWITTER':
         icon = TwitterLogo;
         break;
      case 'FACEBOOK':
         icon = FacebookLogo;
         break;
   }
   return icon;
}

export async function getLinkDetailsFromUrl(url) {
   try {
      const config = {
         headers: {
            'Content-Type': 'application/json'
         }
      };
      const res = await axios.post('/api/content/get-url-data', { url: url }, config);
      return res.data.data;
   } catch (err) {
      return false;
   }
}

export function formatRichPost(text) {
   if (!text) {
      return false;
   }
   const words = text.split(/\s+/);
   let fulltext = words.map((word, index) => {
      if (isValidUrl(word)) {
         let display_url = word;
         // new URL(word).hostname === word ? word : new URL(word).hostname + '...';
         return (
            <a
               key={'rich-text-' + index}
               target="_blank"
               className={'text-mixinlab-teal_dark font-semibold cursor-pointer'}
               href={`${word}`}
               title={word}
            >
               {display_url.substring(0, 60) + ' '}
            </a>
         );
      } else if (isTheWordAUserMention(word)){
         return (
             <Link
                 key={'user-ref-' + index}
                 to={`/user/${word.substring(1)}`}
                 className={'text-mixinlab-teal_dark font-semibold cursor-pointer'}
             >
                {word + ' '}
             </Link>
         );
      }else {
         return word + ' ';
      }
   });

   return fulltext;
}

export function isRichPost(text) {
   if (!text) {
      return false;
   }
   const words = text.split(/\s+/);
   let is_rich_content = false;
   words.map((word) => {
      if (isValidUrl(word)) {
         is_rich_content = true;
      }
   });
   return is_rich_content;
}

export function getUrlDomain(url) {
   if (!isValidUrl(url)) {
      return false;
   }
   return new URL(url).hostname;
}

export function getPlatformDisplayName(display_name) {
   if (!isValidUrl(display_name)) {
      return display_name;
   }
   return getUrlDomain(display_name);
}

export function getParameterByName(name, url = window.location.href) {
   name = name.replace(/[\[\]]/g, '\\$&');
   var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
   if (!results) return null;
   if (!results[2]) return '';
   return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

//////////////////////////////////////////////////

export function displayMedia(data) {
   if (!data.url && !data.platform) {
      return false;
   }

   if (data.platform === 'YOUTUBE') {
      return (
         <iframe
            width={constants.post_video_size.width}
            height={constants.post_video_size.height}
            src={`https://www.youtube.com/embed/${data.id}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
         ></iframe>
      );
   } else if (data.platform === 'TWITCH') {
      let video_id = getParameterByName('video', data.video_link.url);
      if (video_id) {
         return (
            <iframe
               src={`https://player.twitch.tv/?autoplay=false&parent=localhost&video=v${video_id}&`}
               width={constants.post_video_size.width}
               height={constants.post_video_size.height}
               allowFullScreen
            ></iframe>
         );
      } else if (data.thumbnail) {
         return (
            <img
               className={`${styles.richContentImageMaxSize} rounded-md mt-4 cursor-pointer`}
               src={data.thumbnail}
               alt="Thumb"
            />
         );
      } else {
         return null;
      }
   } else {
      if (data.thumbnail) {
         return (
            <img
               className={`${styles.richContentImageMaxSize} rounded-lg mt-4 cursor-pointer`}
               src={data.thumbnail}
               alt="Thumb"
            />
         );
      } else {
         return null;
      }
   }
}
