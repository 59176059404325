const dataStorePath = {
   main: 'https://mixcamp-production.ams3.digitaloceanspaces.com/',
   user_profile_images:
      'https://mixcamp-production.ams3.digitaloceanspaces.com/user/profile-images/',
   user_profile_bg_images:
      'https://mixcamp-production.ams3.digitaloceanspaces.com/user/bg-images/',
   content_images:
      'https://mixcamp-production.ams3.digitaloceanspaces.com/content/images/',
   content_audio:
      'https://mixcamp-production.ams3.digitaloceanspaces.com/content/audio/',
   content_video:
      'https://mixcamp-production.ams3.digitaloceanspaces.com/content/video/',
   mixes: 'https://mixcamp-production.ams3.digitaloceanspaces.com/mix/mix-audio/',
   mix_images:
      'https://mixcamp-production.ams3.digitaloceanspaces.com/mix/mix-images/',
   release_covers:
      'https://mixcamp-production.ams3.digitaloceanspaces.com/release/cover-images/'
};

export default dataStorePath;
