// Config
import dataStorePath from '../configs/dataStorePath';

const addDataStorePath = (img) => {
   const main = dataStorePath.main; // Import base URL for Digital Ocean storage.
   const profile_image_concat = `${main}${img}`; // Concatentate.
   return profile_image_concat;
};

export default addDataStorePath;
