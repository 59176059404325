// Config
import dataStorePath from '../configs/dataStorePath';

const addDataStorePathMixImages = (img) => {
   const images = dataStorePath.mix_images; // Import base URL for Digital Ocean storage.
   const profile_image_concat = `${images}${img}`; // Concatentate.
   return profile_image_concat;
};

export default addDataStorePathMixImages;
