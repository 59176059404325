import React, { Fragment } from 'react';

// Import icons.
import deleteIcon from '../assets/delete.svg';

const MixMenu = ({ showModal, handleDelete }) => {
   const MixMenuComponent = showModal ? (
      <div
         className="absolute top-4 right-4 z-30 h-auto w-36 p-2 bg-white rounded-lg shadow-modal"
         onClick={handleDelete}
      >
         <div className="flex flex-row w-full pb-1 pl-2 h-10 items-center hover:bg-gray-300 rounded">
            <div
               className="bg-cover bg-no-repeat bg-center h-7 w-7 cursor-pointer"
               style={{
                  backgroundImage: `url(${deleteIcon})`
               }}
               alt={'delete'}
            ></div>
            <div className="h-7 pl-2 font-normal text-md">
               <span className="align-middle cursor-pointer">Delete</span>
            </div>
         </div>
      </div>
   ) : null;

   return <Fragment>{MixMenuComponent}</Fragment>;
};

export default MixMenu;
