// React.
import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import actions.
import {
   findTracks,
   clearTracks,
   updateMixWithTracklistAndPublish
} from '../actions/mix';

// NPM
import TextareaAutosize from 'react-textarea-autosize';

// Components.
import UploadTrackListingAPI from './UploadTrackListingApi';
import UploadTrackListing from './UploadTrackListing';
import UploadTrackListingHotTrack from './UploadTrackListingHotTrack';
import CloseButtonSmallGray from './CloseButtonSmallGray';
import UploadTracklistingPlaceholder from './UploadTracklistingPlaceholder';
import Spinner from './Spinner';

// Import assets.
import white_tick from '../assets/white_tick.svg';

const UploadTrackWrapper = ({
   findTracks,
   clearTracks,
   updateMixWithTracklistAndPublish,
   mix,
   mix_title,
   resetUploadState
}) => {
   // const [state, setState] = useState({
   //    api_response_tracks_is_loading: false,
   //    update_mix_with_tracks_is_loading: false
   // });

   const [input, setInput] = useState({
      creator: '',
      title: ''
   });

   // Instantiate history.
   let history = useHistory();

   // Build tracklist here for submission to DB.
   const [tracklist, setTracklist] = useState([]);

   console.log(tracklist);

   const clearInput = () => {
      setInput({
         ...input,
         creator: '',
         title: ''
      });
      // setState({
      //    ...state,
      //    api_response_tracks_is_loading: false,
      //    update_mix_with_tracks_is_loading: false
      // });
   };

   useEffect(() => {
      if (mix.tracklist_uploaded === 'success') {
         clearInput();
         resetUploadState();
         history.push(`/home`);
      }
   }, [mix.tracklist_uploaded]);

   const queryApi = () => {
      const query = input.creator + ' ' + input.title;
      // setState({
      //    ...state,
      //    api_response_tracks_is_loading: true
      // });
      findTracks({ query: query });
   };

   const moveUp = (index) => {
      let mix = tracklist[index];
      const tempTracks = [...tracklist];
      tempTracks.splice(index, 1);
      tempTracks.splice(index - 1, 0, mix);
      setTracklist(tempTracks);
   };

   const moveDown = (index) => {
      let mix = tracklist[index];
      const tempTracks = [...tracklist];
      tempTracks.splice(index, 1);
      tempTracks.splice(index + 1, 0, mix);
      setTracklist(tempTracks);
   };

   // Handle text input.
   const handleChange = (e) => {
      const { name, value } = e.target;

      setInput((prevState) => {
         return {
            ...prevState,
            [name]: value
         };
      });
   };

   const handleDelete = (index) => {
      const tempTracks = [...tracklist];
      tempTracks.splice(index, 1);
      setTracklist(tempTracks);
   };

   const handleAdd = (index) => {
      setTracklist((current) =>
         current.map((track, trackIndex) => {
            if (trackIndex === index) {
               return { ...track, is_hot_track: true };
            }
            return { ...track, is_hot_track: false };
         })
      );
   };

   // Add Hot Track 'true' to state.tracklist.
   const handleRemove = (index) => {
      const tempTracks = [...tracklist];

      if (tempTracks[index].is_hot_track === true) {
         tempTracks[index].is_hot_track = false;
         setTracklist([...tracklist]);
      }
   };

   const handleSubmit = () => {
      const data = {
         do_publish: true,
         mix_id: mix.mix_uploaded_id,
         title: mix_title,
         tracks: tracklist
      };
      // setState({
      //    ...state,
      //    update_mix_with_tracks_is_loading: true
      // });
      updateMixWithTracklistAndPublish(data);
   };

   const getCoverFromACRCloud = (track) => {
      let release_cover = null;

      if (track.album?.covers?.small) {
         release_cover = track.album.covers.small;
      } else if (track.album?.cover) {
         release_cover = track.album.cover;
      }
      return release_cover;
   };

   // Display results from API search call.
   const ApiWrapper =
      mix.api_response_tracks.length !== 0
         ? mix.api_response_tracks.map((track, index) => {
              let creators = '';
              let is_last_track = false;

              if (mix.api_response_tracks.length === index + 1) {
                 is_last_track = true;
              }

              track.artists.map((artist, index) => {
                 if (track.artists.length + 1 !== index) {
                    creators += artist.name + ', ';
                 } else {
                    creators += artist.name;
                 }
              });

              let label = track.label ? track.label : 'Unavailable';

              let selectCoverApi = getCoverFromACRCloud(track);

              return (
                 <UploadTrackListingAPI
                    key={index}
                    track_data={track}
                    setTracklist={setTracklist}
                    index={index}
                    cover={selectCoverApi}
                    title={track.name}
                    creators={creators}
                    label={label}
                    is_last_track={is_last_track}
                    clearTracks={clearTracks}
                    clearInput={clearInput}
                    moveUp={moveUp}
                    moveDown={moveDown}
                 />
              );
           })
         : null;

   // Display results from selected tracks for tracklist.
   const TracklistWrapper = tracklist.map((track, index) => {
      let release_cover = null;

      if (track.release?.covers?.small) {
         release_cover = track.release.covers.small;
      } else if (track.release?.cover) {
         release_cover = track.release.cover;
      }

      let creators = '';

      track.creators.map((artist, index) => {
         if (track.creators.length + 1 !== index) {
            creators += artist.name + ', ';
         } else {
            creators += artist.name;
         }
      });

      if (track.is_hot_track) {
         return (
            <UploadTrackListingHotTrack
               key={index}
               index={index}
               isrc={track.isrc}
               cover={release_cover}
               title={track.name}
               creators={creators}
               label={track.label}
               handleDelete={handleDelete}
               handleRemove={handleRemove}
               moveUp={moveUp}
               moveDown={moveDown}
               tracklist={tracklist}
            />
         );
      } else {
         return (
            <UploadTrackListing
               key={index}
               index={index}
               isrc={track.isrc}
               cover={release_cover}
               title={track.name}
               creators={creators}
               label={track.label}
               handleDelete={handleDelete}
               handleAdd={handleAdd}
               moveUp={moveUp}
               moveDown={moveDown}
               tracklist={tracklist}
            />
         );
      }
   });

   // Display api results.
   const apiList =
      mix.api_response_tracks.length !== 0 ? (
         <div>
            <div className="flex justify-between p-1.5 pl-6 pb-1.5 rounded-t-lg bg-gray-400 font-Pulp font-light tracking-wide text-white text-base">
               <div>Is this the track?</div>
               <div className="mr-1">
                  <CloseButtonSmallGray close={clearTracks} clearInput={clearInput} />
               </div>
            </div>
            <div className="py-1 bg-gray-200 rounded-b-lg">{ApiWrapper}</div>
         </div>
      ) : // When there are no results and response status is null
      mix.api_is_loading === true ? (
         <Spinner />
      ) : null;

   const UploadTracklistingPlaceholderComponent =
      tracklist.length === 0 ? <UploadTracklistingPlaceholder /> : null;

   // Display tracklist.
   const trackListHeader = (
      <div className="rounded-xl mt-4">
         <div className="pl-2 pb-2 font-Pulp tracking-wide text-xl">Top 3 picks</div>
         <div className="border-b border-gray-300"></div>
      </div>
   );

   // Pin your tracklist to mix and publish to timeline.
   const publish =
      tracklist.length > 2 ? (
         <div className="flex justify-end mt-4">
            <div className="grid content-center">
               <button
                  onClick={handleSubmit}
                  className="h-9 px-4 ml-4 font-Pulp font-normal text-lg tracking-wide text-white rounded border-2 border-mixinlab-indigo hover:bg-white bg-mixinlab-indigo hover:text-mixinlab-indigo cursor-pointer focus:outline-none"
               >
                  Publish
               </button>
            </div>
         </div>
      ) : (
         <div className="flex justify-end mt-4">
            <div className="grid content-center">
               <button className="opacity-30 grid content-center h-9 px-3 ml-4 font-Pulp font-normal text-lg tracking-wide text-mixinlab-indigo rounded border-2 border-mixinlab-indigo cursor-default focus:outline-none">
                  Publish
               </button>
            </div>
         </div>
      );

   const countDown =
      3 - tracklist.length <= 0 ? (
         <div className="flex absolute -top-6 -right-3 sm:right-3 z-10">
            <p className="bg-white p-3 pr-1 rounded rounded-full text-green-500">
               You can publish now
            </p>
            <div className="grid content-center justify-center h-12 w-12 border-3 border-white bg-green-500 rounded-full text-white text-xl text-center">
               <div
                  className="w-7 h-7 bg-no-repeat cursor-default"
                  style={{
                     backgroundImage: `url(${white_tick})`
                  }}
               ></div>
            </div>
         </div>
      ) : (
         <div className="flex absolute -top-6 -right-3 sm:right-3 z-10">
            <p className="bg-white p-3 pr-1 rounded rounded-full text-mixinlab-indigo">
               *tracks left to add
            </p>
            <div className="h-12 w-12 border-3 border-white bg-mixinlab-indigo rounded-full text-white text-xl text-center">
               <div className="grid content-center h-full pt-1">
                  {3 - tracklist.length}
               </div>
            </div>
         </div>
      );

   //Display all components so spinner works on publish.
   const componentGroupAndSpinner = mix.tracklist_uploading ? (
      <Spinner />
   ) : (
      <div>
         {apiList}
         {trackListHeader}
         {UploadTracklistingPlaceholderComponent}
         <div className="py-0.5">{TracklistWrapper}</div>
         {publish}
      </div>
   );

   return (
      <Fragment>
         <div className="relative flex w-full font-Pulp">
            {countDown}
            <div className="w-full p-4 pt-9 border-r-0 border-l-2 border-r-2 sm:border-l-0 sm:border-r-0 border-gray-200">
               <h2 className="font-Pulp text-xl leading-6">
                  Tracklist:
                  <span className="font-sans text-base ml-3">
                     List 5 tracks (min) or a full tracklisting!
                  </span>
               </h2>
               <p className="font-sans text-xs text-gray-400">
                  (If you can't find all your tracks, please add the ones you can
                  find)
               </p>
            </div>
         </div>
         <div className="p-5 pt-3 border-r-2 border-l-2 sm:border-r-0 sm:border-l-0 border-b-2 border-gray-200 ">
            <div className="flex justify-between pb-2 gap-4 w-full">
               <TextareaAutosize
                  onChange={handleChange}
                  type="text"
                  name="creator"
                  className="text-base w-full font-normal rounded-md border border-gray-300 resize-none overflow-hidden focus:outline-none p-1.5 pt-0.5 px-2"
                  placeholder="eg. Jamie Jones"
                  value={input.creator}
               />
               <TextareaAutosize
                  onChange={handleChange}
                  type="text"
                  name="title"
                  className="text-base w-full font-normal rounded-md border border-gray-300 resize-none overflow-hidden focus:outline-none p-1.5 pt-0.5 px-2"
                  placeholder="eg. My Paradise"
                  value={input.title}
               />
               <button
                  onClick={queryApi}
                  className="h-9 px-3 flex-none font-Pulp font-normal text-lg tracking-wide text-white rounded border-2 border-mixinlab-indigo hover:bg-white bg-mixinlab-indigo hover:text-mixinlab-indigo cursor-pointer focus:outline-none"
               >
                  Search tracks
               </button>
            </div>
            {componentGroupAndSpinner}
         </div>
      </Fragment>
   );
};

UploadTrackWrapper.propTypes = {
   findTracks: PropTypes.func.isRequired,
   clearTracks: PropTypes.func.isRequired,
   updateMixWithTracklistAndPublish: PropTypes.func.isRequired,
   mix: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({ mix: state.mix });

export default connect(mapStateToProps, {
   findTracks,
   clearTracks,
   updateMixWithTracklistAndPublish
})(UploadTrackWrapper);
