// React.
import React from 'react';

// Placeholder image.
import profilePlaceholderIconPerson from '../assets/placeholder_person.svg';

// Import utils.
import dataStorePath from '../configs/dataStorePath';

const ProfileImage = ({
   user_image,
   // time_stamp,
   openModal
}) => {
   const profile_image = user_image ? (
      <div
         onClick={openModal}
         className="flex flex-wrap content-center h-40 w-40 sm:h-36 sm:w-36  rounded-full absolute top-24 sm:top-3.5 left-5 bg-gray-100 border-5 border-white"
      >
         <label
            htmlFor="profile-input"
            className="w-full h-full bg-cover bg-no-repeat bg-center rounded-full"
            style={{
               backgroundImage: `url(${
                  dataStorePath.user_profile_images + user_image
               })`
            }}
         ></label>
      </div>
   ) : (
      <div
         onClick={openModal}
         className="flex flex-wrap content-center h-40 w-40 sm:h-36 sm:w-36 z-10 rounded-full absolute top-24 sm:top-3.5 left-5 bg-gray-100 border-5 border-white"
      >
         <label
            htmlFor="profile-input"
            className="flex justify-center w-full h-full rounded-full pt-11"
         >
            <div
               className="w-14 h-14 sm:w-12 sm:h-12 bg-auto bg-no-repeat bg-center"
               style={{
                  backgroundImage: `url(${profilePlaceholderIconPerson})`
               }}
               alt={'Image placeholder'}
            ></div>
         </label>
      </div>
   );

   return <form>{profile_image}</form>;
};

export default ProfileImage;
