// React and PropTypes.
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, auth, ...rest }) => {

   return (
      <Route
      {...rest}
      render={(props) =>
         !auth.isAuthenticated && !auth.loading ? (
            <Redirect to="/login" />
         ) : (
            <Component {...props} />
         )
      }
   />
   );
};

PrivateRoute.propTypes = {
   auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
