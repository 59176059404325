import axios from 'axios';

import {
   HOME_CONTENT_LOADED_SUCCESS,
   HOME_CONTENT_LOADED_FAIL,
   HOME_CONTENT_SCROLL_LOADED_SUCCESS,
   HOME_CONTENT_SCROLL_LOADED_FAIL,
   HOME_CONTENT_LATEST_POST_LOADED_SUCCESS,
   HOME_CONTENT_LATEST_POST_LOADED_FAIL,
   CLEAR_USER_FOLLOW_STATUS,
   USER_CONTENT_LOADED_SUCCESS,
   USER_CONTENT_LOADED_FAIL,
   SHARE_CONTENT_SUCCESS,
   SHARE_CONTENT_FAIL,
   UPDATE_LIKE_ARRAY_SUCCESS_CONTENT,
   UPDATE_LIKE_ARRAY_FAIL_CONTENT,
   UPDATE_SUCCESS,
   UPDATE_FAIL,
   GET_UPDATE_LINK_DETAILS_SUCCESS,
   GET_UPDATE_LINK_DETAILS_FAIL,
   SET_UPDATE_LINK_DETAILS_TO_NULL,
   DELETE_CONTENT_SUCCESS,
   DELETE_CONTENT_FAIL,
   GET_ID_SUCCESS,
   SHOW_SEARCH_FEED,
   HIDE_SEARCH_FEED,
   RESET_SHOW_SEARCH_FEED,
   COMMENT_SUCCESS,
   COMMENT_FAIL,
   RESET_STATUS_SHARE_CONTENT
} from './types';

// Import GA.
import ReactGA from 'react-ga';

// Get logged in users profile on page load.
export const getHomeContent = () => async (dispatch) => {
   try {
      const res = await axios.get(`/api/content/get-home-content?page=1`);
      const results = res.data.results;
      const data = res.data.data.content;
      const new_page_number = 2;

      dispatch({
         type: HOME_CONTENT_LOADED_SUCCESS,
         payload: data,
         results: results,
         page: new_page_number
      });
      dispatch({
         type: CLEAR_USER_FOLLOW_STATUS
      });
   } catch (err) {
      dispatch({
         type: HOME_CONTENT_LOADED_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// Get logged in users profile on scroll.
export const getHomeContentScroll = (page) => async (dispatch) => {
   try {
      const res = await axios.get(`/api/content/get-home-content?page=${page}`);
      const results = res.data.results;
      const data = res.data.data.content;
      const new_page_number = page + 1;

      dispatch({
         type: HOME_CONTENT_SCROLL_LOADED_SUCCESS,
         payload: data,
         results: results,
         page: new_page_number
      });
   } catch (err) {
      dispatch({
         type: HOME_CONTENT_SCROLL_LOADED_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// Get logged in users new posts.
export const getNewHomeContent =
   (last_home_content_loaded_time) => async (dispatch) => {
      try {
         const res = await axios.get(
            `/api/content/get-home-content?createdAt[gte]=${last_home_content_loaded_time}`
         );
         const data = res.data.results;

         dispatch({
            type: HOME_CONTENT_LATEST_POST_LOADED_SUCCESS,
            payload: data
         });
      } catch (err) {
         dispatch({
            type: HOME_CONTENT_LATEST_POST_LOADED_FAIL,
            payload: { msg: err.response.statusText, status: err.response.status }
         });
      }
   };

// Get selected user's profile.
export const getUserContent = (user_name) => async (dispatch) => {
   try {
      const res = await axios.get(`/api/content/get-user-content/${user_name}`);
      const data = res.data.data.content;
      dispatch({
         type: USER_CONTENT_LOADED_SUCCESS,
         payload: data
      });
   } catch (err) {
      dispatch({
         type: USER_CONTENT_LOADED_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// Get selected user's profile.
export const getUserMixes = (user_name) => async (dispatch) => {
   try {
      const res = await axios.get(
         `/api/content/get-user-content/${user_name}?type=mix`
      );
      const data = res.data.data.content;
      dispatch({
         type: USER_CONTENT_LOADED_SUCCESS,
         payload: data
      });
   } catch (err) {
      dispatch({
         type: USER_CONTENT_LOADED_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// Set user in user store dynamically.
export const update = (data) => async (dispatch) => {
   try {
      const res = await axios({
         method: 'post',
         url: '/api/content',
         headers: { 'Content-Type': 'multipart/form-data' },
         data: data
      });
      dispatch({
         type: UPDATE_SUCCESS,
         payload: res.data
      });

      console.log(res.data.data.content.type);

      ReactGA.event({
         category: 'Content',
         action: 'New',
         label: res.data.data.content.type,
         value: 1
      });
   } catch (err) {
      dispatch({
         type: UPDATE_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// get link details from update links
export const getUpdateLinkDetails = (url) => async (dispatch) => {
   try {
      const cancelTokenSource = axios.CancelToken.source();
      const config = {
         headers: {
            'Content-Type': 'application/json'
         },
         cancelToken: cancelTokenSource.token
      };

      // Udara - should THIS not be a utility rather than an Axios call?
      const res = await axios.post('/api/content/get-url-data', { url: url }, config);

      dispatch({
         type: GET_UPDATE_LINK_DETAILS_SUCCESS,
         payload: res.data.data
      });
   } catch (err) {
      dispatch({
         type: GET_UPDATE_LINK_DETAILS_FAIL,
         payload: { msg: err.response.statusText, status: err.response.status }
      });
   }
};

// get link details from update links
export const setUpdateLinkDetailsToNull = () => async (dispatch) => {
   try {
      dispatch({
         type: SET_UPDATE_LINK_DETAILS_TO_NULL
      });
   } catch (err) {
      dispatch({
         type: SET_UPDATE_LINK_DETAILS_TO_NULL
      });
   }
};

// Like content/post.
export const likeContent = (id) => async (dispatch) => {
   try {
      const res = await axios.patch(`/api/content/like/${id}`);
      // data: {likeCount: 9}, social_context_likes: [{user: 'Harry', id: 12o3i12ih23u'}]

      dispatch({
         type: UPDATE_LIKE_ARRAY_SUCCESS_CONTENT,
         payload: res.data.data
      });
      ReactGA.event({
         category: 'Content',
         action: 'Like',
         label: null,
         value: null
      });
   } catch (err) {
      console.log(err);
      dispatch({
         type: UPDATE_LIKE_ARRAY_FAIL_CONTENT,
         payload: { msg: err.response }
      });
   }
};

export const shareContent = (formData) => async (dispatch) => {
   const config = {
      headers: {
         accept: 'application/json',
         'Accept-Language': 'en-US,en;q=0.8',
         'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
      }
   };

   try {
      const res = await axios.post('/api/content', formData, config);
      ReactGA.event({ category: 'Content', action: 'Share', label: null, value: 1 });
      dispatch({
         type: SHARE_CONTENT_SUCCESS,
         payload: res.data
      });
   } catch (err) {
      dispatch({
         type: SHARE_CONTENT_FAIL,
         payload: { msg: err.response }
      });
   }
};

export const getPostId = (id) => async (dispatch) => {
   dispatch({
      type: GET_ID_SUCCESS,
      payload: id
   });
};

export const deleteContent = (id) => async (dispatch) => {
   try {
      const res = await axios.delete(`/api/content/${id}`);
      dispatch({
         type: DELETE_CONTENT_SUCCESS,
         payload: res.data.data
      });
   } catch (err) {
      console.error(err);
      dispatch({
         type: DELETE_CONTENT_FAIL,
         payload: { msg: err.response }
      });
   }
};

export const showSearchFeed = () => async (dispatch) => {
   dispatch({
      type: SHOW_SEARCH_FEED
   });
};

export const hideSearchFeed = () => async (dispatch) => {
   dispatch({
      type: HIDE_SEARCH_FEED
   });
};

export const resetShowSearchFeed = () => async (dispatch) => {
   dispatch({
      type: RESET_SHOW_SEARCH_FEED
   });
};

export const comment = (comment, id) => async (dispatch) => {
   try {
      const res = await axios.patch(`/api/content/comment/${id}`, {
         text: comment
      });
      dispatch({
         type: COMMENT_SUCCESS,
         payload: res.data
      });
      ReactGA.event({
         category: 'Content',
         action: 'Comment',
         label: null,
         value: null
      });
   } catch (err) {
      console.log(err);
      dispatch({
         type: COMMENT_FAIL,
         payload: { msg: err.response }
      });
   }
};

export const resetStatusShareContent = () => async (dispatch) => {
   dispatch({
      type: RESET_STATUS_SHARE_CONTENT
   });
};
