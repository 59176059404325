import {
   GET_NOTIFICATIONS_SUCCESS,
   GET_NOTIFICATIONS_FAIL,
   GET_MORE_NOTIFICATIONS_SUCCESS,
   GET_MORE_NOTIFICATIONS_FAIL,
   GET_NEW_NOTIFICATIONS_SUCCESS,
   GET_NEW_NOTIFICATIONS_FAIL,
   MARK_AS_NOTIFIED_SUCCESS,
   MARK_AS_NOTIFIED_FAIL,
   MARK_ALL_AS_NOTIFIED_SUCCESS,
   MARK_ALL_AS_NOTIFIED_FAIL,
   RESET_NOTIFICATION_LOADING,
   LOG_OUT
} from '../actions/types';

const initialState = {
   status: null,
   notifications: [],
   mark_all_notified: {},
   new_notifications: 0,
   page_num: 1,
   notifications_num_results: 0,
   error: {},
   loading: true
};

const notification = (state = initialState, action) => {
   switch (action.type) {
      case GET_NOTIFICATIONS_SUCCESS:
         return {
            ...state,
            notifications: action.payload,
            notifications_num_results: action.results,
            loading: false,
            page_num: action.page
         };
      case GET_NOTIFICATIONS_FAIL:
         return {
            ...state,
            error: action.payload
         };
      case GET_MORE_NOTIFICATIONS_SUCCESS:
         return {
            ...state,
            notifications: state.notifications.concat(action.payload),
            notifications_num_results: action.results,
            loading: false,
            page_num: action.page
         };
      case GET_MORE_NOTIFICATIONS_FAIL:
         return {
            ...state,
            error: action.payload
         };
      case GET_NEW_NOTIFICATIONS_SUCCESS:
         return {
            ...state,
            new_notifications: action.payload.new_results,
            loading: false
         };
      case GET_NEW_NOTIFICATIONS_FAIL:
         return {
            ...state,
            error: action.payload
         };
      case MARK_AS_NOTIFIED_SUCCESS:
         return {
            ...state,
            notifications: state.notifications.map((notification) =>
               notification._id === action.payload._id
                  ? {
                       ...notification,
                       is_notified: true
                    }
                  : notification
            )
         };
      case MARK_AS_NOTIFIED_FAIL:
         return {
            ...state,
            error: action.payload
         };
      case MARK_ALL_AS_NOTIFIED_SUCCESS:
         return {
            ...state,
            mark_all_notified: action.payload
         };
      case RESET_NOTIFICATION_LOADING:
         return {
            ...state,
            loading: true
         };
      case LOG_OUT:
         return {
            status: null,
            notifications: [],
            new_notifications: [],
            error: {},
            loading: true
         };
      default:
         return state;
   }
};

export default notification;
