// React.
import React, { useEffect } from 'react';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import Action
import { getSuggestedUsers, addUserToFollowedSuggestedUsers } from '../actions/user';
import { followUnfollow } from '../actions/follow';
import { updateMe } from '../actions/me';

// Import components.
import SuggestedPeopleCard from './SuggestedPeopleCard';
import CloseButtonDarker from './CloseButtonDarker';

// Import Moment.
import moment from 'moment';

const SuggestedPeople = ({
   auth,
   user,
   addUserToFollowedSuggestedUsers,
   updateMe,
   getSuggestedUsers,
   followUnfollow
}) => {
   const followUnfollowHandle = (id) => {
      followUnfollow(id);
      addUserToFollowedSuggestedUsers(id);
   };

   const loadSuggestedUsers = () => {
      getSuggestedUsers();
   };

   const hideSuggestedPeople = () => {
      // Instantiate new form.
      const formData = new FormData();
      formData.append('is_display_suggested_user', false);
      updateMe(formData);
   };

   useEffect(() => {
      if (!auth.loading && auth.isAuthenticated) {
         loadSuggestedUsers();
      }
   }, [auth.loading, auth.isAuthenticated]);

   // how many cards are displayed after excluding already followed users

   let displayed_cards = 0;

   const suggestedPeopleCardsArray = user.suggested_users.map(
      (suggested_user, index) => {
         if (
            displayed_cards === 3 ||
            user.followed_suggested_users.includes(suggested_user._id)
         ) {
            return null;
         } else {
            displayed_cards++;
            return (
               <SuggestedPeopleCard
                  key={index}
                  id={suggested_user?._id}
                  user_image={suggested_user?.user_image}
                  user_name={suggested_user?.user_name}
                  display_name={suggested_user?.user_name}
                  bio={suggested_user?.short_bio}
                  followingCount={suggested_user.followingCount}
                  followersCount={suggested_user.followersCount}
                  dateJoined={moment(
                     moment
                        .utc(suggested_user?.date)
                        .utcOffset(new Date().getTimezoneOffset()),
                     'YYYY-MM-DD HH:mm:ss'
                  ).fromNow()}
                  followClick={followUnfollowHandle}
               />
            );
         }
      }
   );

   return (
      <div className="w-full bg-gray-100 p-4 pb-5 sm:border-r-0 border-r-2 border-b-2 sm:border-l-0 border-l-2 border-gray-200 cursor-default">
         <div className="flex justify-between w-full text-center pb-4">
            <div className="w-10"></div>
            <h2 className="font-Pulp text-2xl font-semibold text-gray-500">
               People to follow
            </h2>

            <div className="w-10">
               <CloseButtonDarker close={hideSuggestedPeople} />
            </div>
         </div>

         <div className="px-3">{suggestedPeopleCardsArray}</div>

         <div className="flex justify-center pt-1 pb-1">
            <button
               onClick={loadSuggestedUsers}
               type="submit"
               className="px-2.5 py-1 font-Pulp text-lg tracking-wide text-white rounded border-2 cursor-pointer bg-mixinlab-teal border-mixinlab-teal cursor-pointer focus:outline-none"
            >
               Refresh
            </button>
         </div>
      </div>
   );
};

SuggestedPeople.propTypes = {
   getSuggestedUsers: PropTypes.func.isRequired,
   followUnfollow: PropTypes.func.isRequired,
   addUserToFollowedSuggestedUsers: PropTypes.func.isRequired,
   updateMe: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired,
   user: PropTypes.object.isRequired,
   follow: PropTypes.object.isRequired
};
const mapStateToProps = (state) => ({
   auth: state.auth,
   user: state.user,
   follow: state.follow
});
export default connect(mapStateToProps, {
   getSuggestedUsers,
   followUnfollow,
   addUserToFollowedSuggestedUsers,
   updateMe
})(SuggestedPeople);
