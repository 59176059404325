import {
   GET_ME_SUCCESS,
   GET_ME_FAIL,
   UPDATE_ME_SUCCESS,
   UPDATE_ME_FAIL,
   RESET_ME_LOADED,
   DEACTIVATE_USER_SUCCESS,
   DEACTIVATE_USER_FAIL,
   REACTIVATE_USER_SUCCESS,
   REACTIVATE_USER_FAIL,
   USER_PASSWORD_UPDATE_SUCCESS,
   USER_PASSWORD_UPDATE_FAIL,
   LOG_OUT
} from '../actions/types';

const initialState = {
   time_stamp: null,
   loading: true,
   loaded: false,
   id: null,
   user_name: null,
   display_name: null,
   user_image: null,
   user_bg: null,
   short_bio: null,
   bio: null,
   location: null,
   licence: null,
   web_display: null,
   web_full: null,
   following: null,
   followers: null,
   following_length: null,
   followers_length: null,
   date: null,
   error: {},
   isDeactivated: null,
   isPasswordChanged: null,
   is_display_suggested_user: null
};

const me = (state = initialState, action) => {
   switch (action.type) {
      case GET_ME_SUCCESS:
         return {
            ...state,
            loading: false,
            id: action.payload._id,
            user_name: action.payload.user_name,
            display_name: action.payload.display_name,
            user_image: action.payload.user_image,
            user_bg: action.payload.user_bg,
            short_bio: action.payload.short_bio,
            bio: action.payload.bio,
            location: action.payload.location,
            licence: action.payload.licence,
            web_display: action.payload.web_display,
            web_full: action.payload.web_full,
            following: action.payload.following,
            followers: action.payload.followers,
            following_length: action.payload.followingCount,
            followers_length: action.payload.followersCount,
            date: action.payload.date,
            isDeactivated: !!action.payload.deactivatedAt,
            is_display_suggested_user: action.payload.is_display_suggested_user,
            is_first_login: action.payload.is_first_login,
            last_notification_pull: action.payload.last_notification_pull
         };
      case GET_ME_FAIL:
         return {
            ...state,
            loading: false,
            error: action.payload
         };
      case UPDATE_ME_SUCCESS:
         return {
            ...state,
            time_stamp: Date.now(),
            loading: false,
            loaded: true,
            user_name: action.payload.user_name,
            display_name: action.payload.display_name,
            user_image: action.payload.user_image,
            user_bg: action.payload.user_bg,
            short_bio: action.payload.short_bio,
            bio: action.payload.bio,
            location: action.payload.location,
            web_display: action.payload.web_display,
            web_full: action.payload.web_full,
            is_display_suggested_user: action.payload.is_display_suggested_user,
            error: {}
         };
      case UPDATE_ME_FAIL:
         return {
            ...state,
            loading: false,
            loaded: false,
            error: action.payload // {status:"fail", msg: err.response.data}
         };
      case RESET_ME_LOADED:
         return {
            ...state,
            loaded: false
         };
      case DEACTIVATE_USER_SUCCESS:
         return {
            ...state,
            isDeactivated: true
         };
      case DEACTIVATE_USER_FAIL:
         return {
            ...state,
            error: action.payload
         };
      case REACTIVATE_USER_SUCCESS:
         return {
            ...state,
            isDeactivated: false
         };
      case REACTIVATE_USER_FAIL:
         return {
            ...state,
            error: action.payload
         };
      case USER_PASSWORD_UPDATE_SUCCESS:
         return {
            ...state,
            isPasswordChanged: true
         };
      case USER_PASSWORD_UPDATE_FAIL:
         return {
            ...state,
            isPasswordChanged: false
         };
      case LOG_OUT:
         return {
            ...state,
            time_stamp: null,
            loading: true,
            loaded: false,
            id: null,
            user_name: null,
            display_name: null,
            user_image: null,
            user_bg: null,
            short_bio: null,
            bio: null,
            location: null,
            web_display: null,
            web_full: null,
            following_length: null,
            followers_length: null,
            date: null,
            error: {}
         };
      default:
         return state;
   }
};

export default me;
