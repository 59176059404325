// React.
import React from 'react';
import { Link } from 'react-router-dom';

// Import Utils.
import dataStorePath from '../configs/dataStorePath';

// Import actions.
import { resetStatus } from '../actions/auth';

// Import styles.
import styles from '../css/styles.module.css';

// Import assets.
import profilePlaceholderIconPerson from '../assets/placeholder_person.svg';

const SuggestedPeopleCard = ({
   id,
   user_image,
   user_name,
   display_name,
   bio,
   // followingCount,
   // followersCount,
   followClick
}) => {
   const clickFollowHandle = () => {
      followClick(id);
   };

   const userProfileImage = user_image ? (
      <Link onClick={resetStatus} to={`/user/${user_name}`}>
         <div
            className="xs:w-14 xs:h-14 h-18 w-18 mr-3 mb-2 flex-none b bg-no-repeat bg-cover bg-center rounded-full"
            style={{
               backgroundImage: `url(${
                  dataStorePath.user_profile_images + user_image
               })`
            }}
         ></div>
      </Link>
   ) : (
      <Link onClick={resetStatus} to={`/user/${user_name}`}>
         <div
            className="xs:w-14 xs:h-14 h-18 w-18 mr-3 mb-2 flex-none border-gray-400 bg-no-repeat bg-50 bg-center rounded-full"
            style={{
               backgroundImage: `url(${profilePlaceholderIconPerson})`
            }}
         ></div>
      </Link>
   );

   return (
      <div
         key={id}
         className="flex sm:block w-full shadow-modalExtraLight mb-5 py-3.5 px-4 bg-white border border-gray-400 cursor-default rounded rounded-md"
      >
         <div className="w-full flex xs:block">
            {userProfileImage}

            <div className="w-full mr-3 sm:mr-0">
               <h2 className="font-Pulp tracking-wide leading-5 text-lg">
                  <Link onClick={resetStatus} to={`/user/${user_name}`}>
                     {display_name}
                  </Link>
               </h2>
               <h3 className="mb-1 font-Pulp font-thin tracking-wide leading-4 text-base text-mixinlab-teal">
                  <Link onClick={resetStatus} to={`/user/${user_name}`}>
                     @{user_name}
                  </Link>
               </h3>

               <p className="font-normal text-sm leading-4">
                  {bio}
                  {/* {dateJoined} */}
               </p>
            </div>
         </div>

         <div className="flex justify-end sm:mt-2">
            <div>
               <button
                  onClick={clickFollowHandle}
                  className="px-2 pt-1 pb-0.5 font-Pulp text-sm tracking-wide text-mixinlab-teal rounded border-2 cursor-pointer border-mixinlab-teal hover:bg-mixinlab-teal hover:text-white cursor-pointer focus:outline-none"
               >
                  Follow
               </button>
            </div>
         </div>
      </div>
   );
};

export default SuggestedPeopleCard;
