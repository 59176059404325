import React, { Fragment } from 'react';

const ProfileLicenceButtonSmall = ({ licence }) => {
   let licenceType;

   if (licence === 'Guest') {
      licenceType = (
         <button className="h-8 px-3 cursor-default font-Pulp font-normal text-sm tracking-wide tracking-wide text-white border-mixinlab-pewter bg-mixinlab-pewter rounded border-2 focus:outline-none">
            {licence}
         </button>
      );
      return licenceType;
   }

   if (licence === 'DJ') {
      licenceType = (
         <button className="h-8 px-3 cursor-default font-Pulp font-normal text-sm tracking-wide tracking-wide text-white border-mixinlab-grape bg-mixinlab-grape rounded border-2 focus:outline-none">
            {licence}
         </button>
      );
      return licenceType;
   }

   if (licence === 'Hangout') {
      licenceType = (
         <button className="h-8 px-3 cursor-default font-Pulp font-normal text-sm tracking-wide tracking-wide text-white border-mixinlab-pewter bg-mixinlab-pewter rounded border-2 focus:outline-none">
            {licence}
         </button>
      );
      return licenceType;
   }

   return <Fragment>{licenceType}</Fragment>;
};

export default ProfileLicenceButtonSmall;
