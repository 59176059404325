// Import react.
import React, { Fragment } from 'react';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import components.
import CloseButtonUpdate from './CloseButtonUpdate';
import RichContentDomain from './RichContentDomain';
import RichContentThumb from './RichContentThumb';

const RichContent = ({ content, clearUpdate }) => {
   // Renamed for simplicity.
   let shared_content = content.update_link_details;

   // Clear update state when rich content button is clicked.
   const handleCloseButtonClick = () => {
      clearUpdate();
   };

   // Declare key components.
   const title = shared_content?.title ? (
      <a target="_blank" href={`${shared_content?.url}`}>
         <div className="leading-4 mb-3 ml-0.5 text-sm font-semibold text-mixinlab-teal_dark cursor-pointer mr-10 sm:mr-0">
            {shared_content.title}
         </div>
      </a>
   ) : null;

   const richContent = (
      <div className="border border-gray-300 mb-4 p-4 mr-16 sm:mr-4 bg-white rounded-md shadow-modalRichUpdate">
         <div className="hidden sm:block" onClick={handleCloseButtonClick}>
            <div className="flex justify-end w-full">
               <CloseButtonUpdate />
            </div>
         </div>
         <div className="flex">
            <div className="flex justify-between w-full">
               <div className="flex">
                  <RichContentDomain />
               </div>
            </div>
            <div className="sm:hidden" onClick={handleCloseButtonClick}>
               <CloseButtonUpdate />
            </div>
         </div>
         {title}
         <RichContentThumb />
      </div>
   );

   return <Fragment>{richContent}</Fragment>;
};

RichContent.propTypes = {
   content: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({ content: state.content });

export default connect(mapStateToProps)(RichContent);
