//Import React.
import React, { useState, useRef, useEffect } from 'react';

// NPM
import TextareaAutosize from 'react-textarea-autosize';

// Import actions.
import {
   update,
   getUpdateLinkDetails,
   setUpdateLinkDetailsToNull
} from '../actions/content';

import { getUsersByUserName, resetUserReferenceSuggestions } from '../actions/user';

// Import components.
import AlertFrontEnd from './AlertFrontEnd';
import RichContent from './RichContent';
import GiphyModal from './GiphyModal';

// Redux
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Import utils.
import { extractLinksFromString } from '../utils/richUpdate';

// Import assets.
import Picture from '../assets/picture.svg';
import Gif from '../assets/gif.svg';
import profilePlaceholderIconPerson from '../assets/placeholder_person.svg';

// Import icons.
import closeIcon from '../assets/close.svg';
import dataStorePath from '../configs/dataStorePath';

const Update = ({
   me,
   auth,
   user,
   content,
   update,
   getUpdateLinkDetails,
   setUpdateLinkDetailsToNull,
   getUsersByUserName,
   resetUserReferenceSuggestions,
   defaultText = '',
   atModal,
   handleClose,
   showSpinner,
   hideGiphyModalMask
}) => {
   const [state, setState] = useState({
      blob_img: null,
      file_img: null,
      giphy: null,
      update: defaultText,
      update_count: 0,
      file_err_msg: null,
      link_to_populate: null,
      is_giphy_modal_open: false,
      user_reference: null,
      is_user_reference_suggestions_box_visible: false,
      update_cursor_pos: 0
   });

   let img = useRef(null);
   let updateInput = useRef(null);

   const openGiphyModal = () => {
      setState({ ...state, is_giphy_modal_open: true });
   };

   const closeGiphyModal = () => {
      setState({ ...state, is_giphy_modal_open: false });
   };

   const handleChange = (e) => {
      const { name, value } = e.target;

      if (name === 'update') {
         setState({
            ...state,
            update_count: value.length
         });
      }

      setState((prevState) => {
         return {
            ...prevState,
            [name]: value
         };
      });
   };

   // 1. Checks if a link is present in update str.
   const handleKeyUp = (e) => {
      const { name, value } = e.target;
      let username_ref = isTheCurrentWordUserMention(value, e.target.selectionStart);
      setState({
         ...state,
         link_to_populate: extractLinksFromString(state.update)[0],
         update_cursor_pos: e.target.selectionStart,
         user_reference: username_ref?.username
      });
   };

   const setUpdateCursorPos = (CursorPos) => {
      setState({
         ...state,
         update_cursor_pos: CursorPos
      });
   };

   const handleOnFocus = (e) => {
      e.currentTarget.setSelectionRange(
         e.currentTarget.value.length,
         e.currentTarget.value.length
      );
      setUpdateCursorPos(e.target.selectionStart);
   };

   const handleOnClick = (e) => {
      const { name, value } = e.target;
      let username_ref = isTheCurrentWordUserMention(value, e.target.selectionStart);
      setState({
         ...state,
         update_cursor_pos: e.target.selectionStart,
         user_reference: username_ref?.username,
         is_user_reference_suggestions_box_visible: username_ref?.username
            ? true
            : false
      });
   };

   const handleUserReferenceSuggestionsItemClick = (user_name) => {
      let update = autoCompleteUserName(user_name);
      setState({
         ...state,
         update: update,
         is_user_reference_suggestions_box_visible: false,
         user_reference: null
      });
   };

   useEffect(() => {
      if (state.link_to_populate) {
         getUpdateLinkDetails(state.link_to_populate);
      } else {
         setUpdateLinkDetailsToNull();
      }
   }, [auth, state.link_to_populate]);

   // Depending on is_username or not toggle User Reference Box
   useEffect(() => {
      if (state.user_reference) {
         getUsersByUserName(state.user_reference);
         setState({
            ...state,
            is_user_reference_suggestions_box_visible: true
         });
      } else {
         setState({
            ...state,
            is_user_reference_suggestions_box_visible: false
         });
         resetUserReferenceSuggestions();
      }
   }, [auth, state.user_reference, state.update]);

   useEffect(() => {
      updateInput.current.focus();
   }, [auth, state.update]);

   // Clears image so user can change the image before they post.
   const handleClearImage = () => {
      img.current.value = null;
      setState({
         ...state,
         blob_img: null
      });
   };

   const handleSelectGiphy = (image) => {
      setState({ ...state, giphy: image, is_giphy_modal_open: false });
   };

   const handleClearGiphy = () => {
      setState({
         ...state,
         giphy: null
      });
   };

   // Handle input for Update.
   const handleImageInput = (e) => {
      e.preventDefault();

      let reader = new FileReader();
      let file = e.target.files[0];

      // Format check conditions.
      const checkFileFormat = () => {
         const formats = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
         const checkFormats = formats.includes(file.type);
         return checkFormats;
      };

      // Disable placeholder change.
      const disablePlaceHolderChange = () => {
         if (checkFileFormat()) {
            return reader.result;
         } else {
            return null;
         }
      };

      // Check file.
      const checkFile = () => {
         let file_err;
         if (file.size > 2000000) {
            file_err = 'File too large (max. 2MB)';
            setTimeout(function () {
               setState({
                  ...state,
                  blob_img: null,
                  file_img: null,
                  file_err_msg: null
               });
            }, 3000);
         }
         if (file.size <= 2000000) {
            file_err = null;
         }
         if (!checkFileFormat()) {
            file_err = 'Wrong format! Try .jpg .png or .gif';
            setTimeout(function () {
               setState({
                  ...state,
                  blob_img: null,
                  file_img: null,
                  file_err_msg: null
               });
            }, 3000);
         }

         if (file_err) {
            img.current.value = null;
         }

         return file_err;
      };

      reader.onloadend = () => {
         setState({
            ...state,
            blob_img: disablePlaceHolderChange(),
            file_img: file,
            file_err_msg: checkFile(),
            success: true
         });
      };

      if (file) {
         reader.readAsDataURL(file);
      }
   };

   const clearUpdate = () => {
      // Reset all state
      setState({
         ...state,
         blob_img: null,
         file_img: null,
         giphy: null,
         update: '',
         update_count: 0,
         file_err_msg: null,
         link_to_populate: null,
         is_giphy_modal_open: false
      });
      setUpdateLinkDetailsToNull();
   };

   const onSubmit = async (e) => {
      e.preventDefault();

      if (atModal) {
         showSpinner();
      }

      // Instantiate new form.
      const formData = new FormData();

      // Append text fields.
      // 1. Has image
      if (state.file_img) {
         formData.append('type', 'image');
         formData.append('file', state.file_img);
         formData.append('text', state.update);
         // 2. Has giphy
      } else if (state.giphy) {
         formData.append('type', 'text');
         formData.append('text', state.update);
         formData.append('giphy', state.giphy);
         // 3. Text only
      } else if (state.file_img === null && state.giphy === null) {
         formData.append('type', 'text');
         formData.append('text', state.update);
      }

      // Update only if text is present or image is present or giphy is present.
      if (state.update !== '' || state.file_img !== null || state.giphy !== null) {
         update(formData);
      }

      // Reset all state on Submit.
      setState({
         ...state,
         blob_img: null,
         file_img: null,
         giphy: null,
         update: '',
         update_count: 0
      });
      setUpdateLinkDetailsToNull();
   };

   const richUpdate = content.update_link_details ? (
      <RichContent data={content.update_link_details} clearUpdate={clearUpdate} />
   ) : null;

   const userSuggestions = user?.user_reference_suggestions.map((user, index) => {
      return (
         <div
            className="mt-2 py-1.5 px-2 bg-gray-100 text-lg rounded rounded-sm cursor-pointer"
            key={index}
            onClick={() => {
               handleUserReferenceSuggestionsItemClick(user.user_name);
            }}
         >
            <div className="font-bold leading-6">{user.display_name}</div>
            <div className="text-mixinlab-cerise leading-5 text-base">
               @{user.user_name}
            </div>
         </div>
      );
   });

   const drop_down =
      user?.user_reference_suggestions.length > 0 ? (
         <div className="absolute z-20 w-80 xs:w-56 bg-white px-2 pb-2 left-20 -top-5 rounded rounded-md shadow-modal">
            {userSuggestions}
         </div>
      ) : null;

   let isTheCurrentWordUserMention = (updateString, cursorPos) => {
      let userMentionPattern = /^@{1}[a-z_]{1,}$/;
      // returns the word current cursor position is touching.
      let result = /\S+$/.exec(
         updateString.slice(0, updateString.indexOf('', cursorPos))
      );

      // result will ne null if the cursor position is not attached to a word
      if (result == null) {
         return null;
      }

      let username = userMentionPattern.test(result[0]) ? result[0] : null;

      return {
         is_username: username ? true : false,
         username: username
      };
   };

   let autoCompleteUserName = (username) => {
      return addAutoCompletedUserNameToUpdate(
         state.update,
         state.update_cursor_pos,
         username
      );
   };

   let addAutoCompletedUserNameToUpdate = (updateString, cursorPos, userName) => {
      /**
       * Should accept a String and cursorPos of the update box as well as Username
       * Then figure out partially completed username and replace it with the Username passed
       */
      let replaceBetweenUpdateString = (origin, startIndex, endIndex, insertion) => {
         return (
            origin.substring(0, endIndex) +
            ' ' +
            insertion +
            origin.substring(startIndex)
         );
      };
      // reverse updateString so we can use indexOf (as index of can only return the occurrence from the left)
      let splitUpdateString = updateString.split('').reverse().join('');
      // because splitUpdateString is reversed, endOfUserNameToBeCompleted look like the start and vise versa
      let endOfUserNameToBeCompleted = splitUpdateString.length - cursorPos;
      let startOfUserNameToBeCompleted = splitUpdateString.indexOf(
         '@',
         endOfUserNameToBeCompleted
      );
      // userName to be replaced should be reversed as the mainString is also reversed
      let reverseUsername = userName.split('').reverse().join('');
      let replacedString = replaceBetweenUpdateString(
         splitUpdateString,
         startOfUserNameToBeCompleted,
         endOfUserNameToBeCompleted,
         reverseUsername
      );
      return replacedString.split('').reverse().join('');
   };

   let spacingCss;
   if (atModal) {
      spacingCss = 'mt-16 flex';
   } else {
      spacingCss = 'flex flex-row';
   }

   let chooseCss;
   if (atModal) {
      chooseCss = 'w-full';
   } else {
      chooseCss =
         'w-full p-4 pb-3 sm:border-r-0 border-r-2 border-b-2 sm:border-l-0 border-l-2 border-gray-200';
   }

   let updateIconsCss;
   if (atModal) {
      updateIconsCss = 'relative w-1/2 sm:w-1/3 flex flex-row mt-1';
   } else {
      updateIconsCss = 'relative w-1/2 sm:w-1/3 flex flex-row pl-16 sm:pl-2 mt-1';
   }

   const update_component = (
      <form onSubmit={onSubmit}>
         <GiphyModal
            modalState={state.is_giphy_modal_open}
            close={closeGiphyModal}
            handleSelectGiphy={handleSelectGiphy}
            hideGiphyModalMask={hideGiphyModalMask}
         />

         <div className={chooseCss}>
            <div className="flex flex-row">
               {atModal ? null : (
                  <div>
                     {me.user_image ? (
                        <div className="w-20 sm:w-16 h-16">
                           <div
                              className="h-14 w-14 sm:h-12 sm:w-12 rounded-full bg-cover bg-no-repeat bg-center bg-gray-100"
                              style={{
                                 backgroundImage: `url(${
                                    dataStorePath.user_profile_images + me.user_image
                                 })`
                              }}
                              alt={'Profile'}
                           ></div>
                        </div>
                     ) : (
                        <div className="w-20 sm:w-16 h-16">
                           <div
                              className="h-14 w-14 mt-1 rounded-full bg-50 bg-no-repeat bg-center bg-gray-100"
                              style={{
                                 backgroundImage: `url(${profilePlaceholderIconPerson})`
                              }}
                              alt={'Profile'}
                           ></div>
                        </div>
                     )}
                  </div>
               )}

               {/* Update */}
               <div className="w-full">
                  <div className="flex justify-between">
                     <div className="flex items-center mt-3 pr-3 pb-6 w-full">
                        <TextareaAutosize
                           ref={updateInput}
                           type="text"
                           name="update"
                           className="text-xl font-normal resize-none w-full overflow-hidden text-black focus:outline-none pl-1"
                           placeholder="What's happening?"
                           onChange={handleChange}
                           onKeyUp={handleKeyUp}
                           onFocus={handleOnFocus}
                           onClick={handleOnClick}
                           value={state.update}
                        />
                     </div>
                     {atModal ? (
                        <div className="flex justify-end">
                           <div
                              className="h-10 w-10 grid content-center hover:bg-gray-100 rounded-full"
                              onClick={handleClose}
                           >
                              <div
                                 className="mx-auto bg-cover bg-no-repeat bg-center h-6 w-6 cursor-pointer"
                                 style={{
                                    backgroundImage: `url(${closeIcon})`
                                 }}
                                 alt={'close'}
                              ></div>
                           </div>
                        </div>
                     ) : null}
                  </div>

                  {state.file_err_msg ? (
                     <div className="pr-12">
                        <AlertFrontEnd msg={state.file_err_msg} />
                     </div>
                  ) : null}

                  {/* Image to go here */}
                  {state.blob_img ? (
                     <div className="relative">
                        <div className="absolute">
                           <div
                              className="mt-2 ml-2 h-7 w-7 bg-gray-100 pt-1 pl-1 opacity-40 hover:opacity-80 rounded-full cursor-pointer"
                              onClick={handleClearImage}
                           >
                              <div
                                 className="bg-no-repeat bg-center h-5 w-5"
                                 style={{
                                    backgroundImage: `url(${closeIcon})`
                                 }}
                                 alt={'close'}
                              ></div>
                           </div>
                        </div>
                        <img
                           src={state.blob_img}
                           className="flex mb-5 w-auto h-56 rounded-xl"
                           alt={'Post'}
                        />
                     </div>
                  ) : null}

                  {/* Giphy to go here */}
                  {state.giphy ? (
                     <div className="relative">
                        <div className="absolute">
                           <div
                              className="mt-2 ml-2 h-7 w-7 bg-gray-100 pt-1 pl-1 opacity-40 hover:opacity-80 rounded-full cursor-pointer"
                              onClick={handleClearGiphy}
                           >
                              <div
                                 className="bg-no-repeat bg-center h-5 w-5"
                                 style={{
                                    backgroundImage: `url(${closeIcon})`
                                 }}
                                 alt={'close'}
                              ></div>
                           </div>
                        </div>
                        <img
                           src={state.giphy}
                           className="flex mb-5 w-auto h-56 rounded-xl"
                           alt={'Giphy'}
                        />
                     </div>
                  ) : null}

                  {/* Rich content share to go here */}
                  {richUpdate}
               </div>
            </div>

            {/* Send it block */}
            <div className={spacingCss}>
               <div className={updateIconsCss}>
                  <div className="flex justify-center ml-1 mr-2 h-12 w-12 hover:bg-gray-200 rounded-full">
                     <label
                        htmlFor="profile"
                        className="w-full h-full bg-70 bg-no-repeat bg-center rounded-full cursor-pointer"
                        style={{
                           backgroundImage: `url(${Picture})`
                        }}
                     ></label>
                     <input
                        className="hidden w-full h-full "
                        name="filename"
                        id="profile"
                        ref={img}
                        type="file"
                        onChange={handleImageInput}
                     ></input>
                  </div>
                  <div className="flex items-center justify-center sm:mr-0 mr-2 h-12 w-12 hover:bg-gray-200 cursor-pointer rounded-full">
                     <div
                        onClick={openGiphyModal}
                        className="w-full h-full bg-70 bg-no-repeat bg-center rounded-full"
                        style={{
                           backgroundImage: `url(${Gif})`
                        }}
                     ></div>
                  </div>

                  {drop_down}
               </div>

               <div className="w-1/2 sm:w-2/3 flex justify-end mt-1">
                  <h6 className="text-sm text-gray-500 pt-3 pr-4">
                     <span
                        className={
                           state.update_count > 240
                              ? 'text-mixinlab-cherry font-bold'
                              : 'text-mixinlab-teal'
                        }
                     >
                        {state.update_count}
                     </span>
                     <span className="text-black"> / 240 chars.</span>
                  </h6>

                  {state.update_count > 240 ? (
                     <div className="ease-in-out duration-75 mt-1 h-10 lg:w-24 px-3 pt-1 font-Pulp font-normal text-lg text-white border-2 opacity-50 bg-mixinlab-cherry border-mixinlab-cherry focus:outline-none rounded">
                        Send it
                     </div>
                  ) : (
                     <button
                        type="submit"
                        className="ease-in-out duration-75 mt-1 h-10 lg:w-24 px-3 font-Pulp font-normal text-lg text-white bg-mixinlab-teal cursor-pointer border-2 border-mixinlab-teal hover:bg-white hover:text-mixinlab-teal focus:outline-none rounded"
                     >
                        Send it
                     </button>
                  )}
               </div>
            </div>
         </div>
      </form>
   );

   return <div>{update_component}</div>;
};

Update.propTypes = {
   update: PropTypes.func.isRequired,
   getUpdateLinkDetails: PropTypes.func.isRequired,
   setUpdateLinkDetailsToNull: PropTypes.func.isRequired,
   getUsersByUserName: PropTypes.func.isRequired,
   me: PropTypes.object.isRequired,
   auth: PropTypes.object.isRequired,
   content: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
   auth: state.auth,
   me: state.me,
   user: state.user,
   content: state.content
});

export default connect(mapStateToProps, {
   update,
   getUpdateLinkDetails,
   setUpdateLinkDetailsToNull,
   getUsersByUserName,
   resetUserReferenceSuggestions
})(Update);
