// React and PropTypes.
import React, { Fragment, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

// Connect Redux to component.
import { connect } from 'react-redux';

// Components.
import AlertFrontEnd from '../components/AlertFrontEnd';
import Spinner from '../components/Spinner';
import GaPageView from '../components/GaPageView';

// Import actions.
import { sendResetPassword } from '../actions/user';

// isAuthenticated is global state or Redux store.
const Reset = ({ auth, user, sendResetPassword }) => {
   const [state, setState] = useState({
      email: '',
      show_spinner: false
   });

   useEffect(() => {
      if (!auth.loading && !auth.isAuthenticated === true) {
         setState({ ...state, show_spinner: false });
      }
   }, [user.is_password_reset_email_sent]);

   // Redirect if logged in.
   if (auth.isAuthenticated) {
      return <Redirect to="/home" />;
   }

   const onSubmit = async (e) => {
      e.preventDefault();
      // Call login action with useParams.
      setState({ ...state, show_spinner: true });
      sendResetPassword(state.email);
   };

   let alert = null;

   // IF EMAIL FAILS
   if (!user.is_password_reset_email_sent && user.error.message) {
      alert = <AlertFrontEnd msg={'Invalid Email'} />;
   }

   // IF PASSWORD RESET IS SUCCESSFUL WE NEED A SPINNER HERE AS WELL
   if (user.is_password_reset_email_sent) {
      alert = (
         <div className="bg-green-100 border border-green-400 text-green-600 mb-5 px-4 py-3 rounded relative">
            <p>We've sent a password reset link to your email</p>
         </div>
      );
   }

   const handleChange = (e) => {
      const { name, value } = e.target;

      setState((prevState) => {
         return {
            ...prevState,
            [name]: value
         };
      });
   };

   let ResetPasswordForm = null;

   if (!user.is_password_reset_email_sent && !state.show_spinner) {
      ResetPasswordForm = (
         <div className="flex justify-center">
            <GaPageView/>
            <div className="w-10/12 max-w-400 mb-24">
               <div className="h-24 flex flex-wrap content-end">
                  <h1 className="font-Pulp font-bold text-3xl ml-1 mb-5">
                     Reset your password
                  </h1>
               </div>
               <div>{alert}</div>

               <form>
                  {/* Display name */}
                  <div>
                     <label htmlFor="email"></label>
                     <div className="flex items-center border rounded-md border-gray-400 p-1">
                        <input
                           className="placeholder-black appearance-none pb-1.5 pt-0.5 px-3 block text-xl font-semibold bg-transparent border-none w-full text-black focus:outline-none"
                           type="text"
                           name="email"
                           placeholder="Enter email"
                           onChange={handleChange}
                           value={state.email}
                        />
                     </div>
                     <h6 className="text-sm text-gray-600 pl-2">
                        Enter email to receive reset link in your inbox
                     </h6>
                     <div className="mx-auto pt-5 mt-5">
                        <button
                           onClick={onSubmit}
                           className="pt-2 pb-1.5 px-5 font-Pulp font-normal text-xl tracking-wide text-white rounded border-1.5 bg-mixinlab-teal border-2 border-mixinlab-teal hover:bg-white hover:text-mixinlab-teal cursor-pointer focus:outline-none"
                        >
                           Send email
                        </button>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      );
   } else if (user.is_password_reset_email_sent && !state.show_spinner) {
      ResetPasswordForm = (
         <div className="mx-auto items-center max-w-sm">
            <div className="h-24 flex flex-wrap content-end">
               <h1 className="font-Pulp font-bold text-3xl mb-5 pt-2">
                  Send password reset link
               </h1>
            </div>
            <div>{alert}</div>
         </div>
      );
   } else if (state.show_spinner) {
      ResetPasswordForm = <Spinner />;
   }

   return <Fragment>{ResetPasswordForm}</Fragment>;
};

Reset.propTypes = {
   sendResetPassword: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired,
   user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({ auth: state.auth, user: state.user });

export default connect(mapStateToProps, { sendResetPassword })(Reset);
