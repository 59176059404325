import { Link } from 'react-router-dom';
import React from 'react';
import likeIcon from '../assets/fire_gray.svg';

let socialContextDisplay = (social_context, id) => {
   let social_context_output_arr = [];
   let max_num_of_followers = 2;

   if (Array.isArray(social_context)) {
      social_context[0].found.forEach((el, index) => {
         let link = `/user/${el.user.user_name}`;

         if (index == 1) {
            social_context_output_arr.push(
               <span key={`sc-span-${id}-${index}`}>, </span>
            );
         }

         social_context_output_arr.push(
            <span
               key={`sc-user-links-${id}-${index}`}
               className="font-normal font-bold hover:underline cursor-pointer text-sm text-gray-500 mr-1 ml-1"
            >
               <Link to={link}>{el.user?.display_name?.trim()}</Link>
            </span>
         );
      });

      if (social_context[0].found.length >= 1 && social_context[1].others >= 1) {
         social_context_output_arr.push(
            <div className="flex" key={`sc-words-${id}`}>
               <span className="font-normal font-light  text-sm text-gray-500">{` and ${social_context[1].others} ${social_context[1].others_word}`}</span>
            </div>
         );
      } else if (
         !social_context[0].found.length >= 1 &&
         social_context[1].others >= 1
      ) {
         social_context_output_arr.push(
            <span
               key={`sc-person-${id}`}
               className="font-normal font-light  text-sm text-gray-500 ml-1"
            >{`${social_context[1].others} ${social_context[1].person_word}`}</span>
         );
      }
      if (social_context_output_arr.length >= 1) {
         social_context_output_arr.unshift(
            <div className="flex text-gray-500 text-sm" key={`sc-icon-para-${id}`}>
               <img
                  key={`sc-icon-${id}`}
                  src={likeIcon}
                  alt="share icon"
                  className="h-5"
               />
               <p key={`sc-word-liked-by${id}`} className="pl-2">
                  Liked by{' '}
               </p>
            </div>
         );
      }
   }

   return social_context_output_arr;
};

export default socialContextDisplay;
