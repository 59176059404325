// React and PropTypes.
import React, { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Components
import GaPageView from "../components/GaPageView";

// Auth is global state or Redux store.
const EmailVerify = () => {
   return (
      <div className="container mx-auto">
         <GaPageView/>
         <div className="grid grid-cols-1">
            <div className="h-52 flex flex-wrap content-center">
               <div className="bg-logo-beta bg-no-repeat bg-center w-80 h-full mx-auto"></div>
            </div>
            <div>
               <h1 className="font-Pulp font-bold text-3xl text-center pb-2 px-5 leading-tight">
                  Email Verified!
               </h1>
               <p className="font-Pulp font-thin text-base text-center text-gray-400 mt-5 flex flex-wrap content-end justify-center">
                  If you are not redirected shortly please use the button below.
               </p>
            </div>
            <div className="flex justify-center pt-3 mt-5">
               <Link
                  to="/login"
                  className="text-center pt-2 m-3 h-12 w-56 font-Pulp font-normal text-xl tracking-wide text-mixinlab-teal rounded-lg border-2 border-mixinlab-teal hover:bg-mixinlab-teal hover:text-white cursor-pointer focus:outline-none"
               >
                  Go to Mixinlab
               </Link>
            </div>
         </div>
      </div>
   );
};

// This is a checker like TypeScript. SignUp.proptypes refers to this component.
EmailVerify.propTypes = {
   auth: PropTypes.object.isRequired,
   user: PropTypes.object.isRequired
};

// Allows component to access state in store. Sets WHICH state I want to grab from store.
// state.auth is coming from reducers/auth.js
// auth must match the parameter above inside the function call.
const mapStateToProps = (state) => ({
   auth: state.auth,
   user: state.user
});

// Passes the chosen state into the exported component. Connect action to component as an object. Kind of binding.
export default connect(mapStateToProps, {})(EmailVerify);
