// React.
import React, { Fragment } from 'react';

// Import components.
import UserSearch from '../../user/UserSearch';

const ModalUserWrapper = ({ onUserSelect, showMusic }) => {
   return (
      <Fragment>
         <div className="flex justify-around mb-8">
            <div className="pl-1 pt-1 font-Pulp text-3xl font-bold cursor-default">
               People
            </div>

            <div
               onClick={showMusic}
               className="pl-1 pt-1 font-Pulp text-3xl font-bold text-gray-300 hover:text-black cursor-pointer"
            >
               Music
            </div>
         </div>

         <UserSearch onUserSelect={onUserSelect} />
      </Fragment>
   );
};

export default ModalUserWrapper;
